/*
     Login Api Constants
*/
import {
    actionCreator,
    createRequestActionTypes,
    jsonApiHeader
} from './../../../../actions/utilAction';
export {
    actionCreator,
    jsonApiHeader
};

export const documentActionTypes = {
    get_document: createRequestActionTypes('GET_DOCUMENT'),
    get_docs: createRequestActionTypes('GET_DOCS'),
    get_user_docs: createRequestActionTypes('GET_USER_DOCS'),
    get_shared_docs: createRequestActionTypes('GET_SHARED_DOCS'),
    get_filter_Obj: createRequestActionTypes('GET_FILTER_OBJ'),
    get_unapproved_docs:createRequestActionTypes('UNAPPROVED_DOCUMENTS'),
    get_approved_docs:createRequestActionTypes('APPROVED_DOCUMENTS')

};
