/* eslint-disable */
import React from 'react'
import { CircularProgress, Typography } from '@material-ui/core'

const Loader = React.memo(() => (
  <div className="align-loader">
    <CircularProgress color="primary" size="6rem" />
    <Typography>Loading ... Please wait....</Typography>
  </div>
))

export default Loader
