import React from "react";
import clsx from "clsx";
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import { InputBase, InputLabel, FormControl, Switch, FormHelperText, Icon, Button, Grid, Typography } from "@material-ui/core";
// import cloudIcon from '../../../assets/cloud-upload-fill.svg'
// import docIcon from "../../../assets/doc.svg";
// import LinearProgress from '@material-ui/core/LinearProgress';
// import closeIcon from '../../../assets/Close.svg';
import leftArrow from '../../../assets/left.svg';
// import  arrowOption from '../../../assets/arrow-ios-down.svg';
// import { ExpandMore, ExpandLess } from "@material-ui/icons";
import Nav from "../../../component/Nav.js"
const mapStateToProps = state => ({});
const mapDispatchToProps = state => ({});

const drawerWidth = 316;
/* const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#F3F3F3",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: ' #FFA800',
  },
}))(LinearProgress); */

const useStyles = theme => ({
  root: {
    display: "flex",
  },
  rootCard: {
    maxWidth: 345,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  appBarShift: {
    marginLeft: "150px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    position: "relative",
    top: "10rem",
  },
  inputStyle: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#E5E5E5',
    border: '1px solid #BFBFBF',
    fontSize: 16,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    padding: '0px',
    width: '100%',
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  selectOption: {
    minWidth: "208px",
    height: "50px",
    "&$iconOpen": {
      transform: "rotate(180deg)"
    },
    '&:before': {
      borderColor: 'var(--galaxy-blue)',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderColor: 'var(--galaxy-blue)',
    }
  },
  inputWidth: {
    minWidth: "413px",
    height: "50px",
  },
  textAreWidth: {
    minWidth: "603px",
    minHeight: "129px",
  },
  focused: {},
  iconOpen: {},
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#FFC805",
    color: ' #005A98',
    fontFamily: "OpenSans-Regular",
    fontWeight: "700",
    fontSize: "18px",
  },
});
const BootstrapInput = withStyles((theme) => ({
  minWidth: "413px",
  width: "100%"
}))(InputBase);



class AddNewCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      checkedA: true,
    }
  }

  handleDrawerOpen = () => {
    this.setState({ open: true })
  };
  handleDrawerClose = () => {
    this.setState({ open: false })
  };
  handleChange = (event) => {
    let name = event.target.name;
    let checked = event.target.checked
    this.setState(prevState => {
      return { [name]: checked };
    });
  };
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Nav handleDrawerOpen={this.handleDrawerOpen} handleDrawerClose={this.handleDrawerClose} open={this.state.open} />
        <main
          className={clsx(classes.content, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Typography variant="body2" pb={0} color="textSecondary" className="ListHeading " component="p">
            <Icon> <img className={classes.imageIcon} src={leftArrow} alt="Left arrow" /></Icon>
            Add New Category
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <div className="uploadDocDiv uploadDocColor">
                <Grid container>
                  <Grid item xs={12} sm={12} className="margin-value mr-l-4 mr-t-10">
                    <FormControl>
                      <InputLabel shrink htmlFor="categoryName">
                        Category Name
                      </InputLabel>
                      <BootstrapInput defaultValue="Enter Category Name" id="categoryName" className={clsx(classes.inputStyle, classes.inputWidth)} />
                      <FormHelperText>Category name can be max. 25 char.</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} className="margin-value mr-l-4" >
                    <InputLabel shrink={true} >
                      Activate Category
                    </InputLabel>
                    <Switch
                      checked={this.state.checkedA}
                      onChange={(event) => { this.handleChange(event) }}
                      name="checkedA"
                      className="main-switch"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Add New Category
                    </Button>
                  </Grid>

                </Grid>
              </div>
            </Grid>
          </Grid>
        </main>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(AddNewCategory));
