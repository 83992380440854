/** Category Actions  */

import {
  actionCreator,
  documentActionTypes,
  jsonApiHeader,
} from "../constants/apiConstants";

import { API_URL } from "../../../../actions/utilAction";
import axios from "axios";
import * as constant from "../../../../actions/constant";
import { toasterMessage } from "../../../../actions/util";

let access_token = localStorage.access_token;
// let access_token = sessionStorage.access_token;

export function getDocuments() {
  return (dispatch) => {
    axios
      .get(API_URL + constant.GET_DOCUMENT, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then(function (response) {
        if (response.data.status === 200) {
          // toasterMessage("success", response.data.message);
          dispatch(
            actionCreator(documentActionTypes.get_document.SUCCESS, {
              documentList: response.data.data,
              totalCount: response.data.data.length,
            })
          );
        }
      })
      .catch((error) => {
        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch(() => {
        toasterMessage("error", "Something bad happened");
      });
  };
}

export function addDocument(authObj, callback) {
  return (dispatch) => {
    axios
      .post(API_URL + constant.ADD_DOCUMENT, authObj, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then(function (response) {
        if (response.data.status === 200) {
          toasterMessage("success", response.data.message);
          return callback("success");
        }
      })
      .catch((error) => {
        // console.log("eroooo",error.response.data)
        toasterMessage("error", error.response.data.message);
        return callback("err");
      })
      .catch(() => {
        toasterMessage("error", "Something bad happened");
        return callback("err");
      });
  };
}

export function editDocuments(authObj, callback) {
  return (dispatch) => {
    axios
      .put(API_URL + constant.EDIT_DOCUMENT, authObj, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then(function (response) {
        if (response.data.status === 200) {
          toasterMessage("success", response.data.message);
          return callback("success");
        }
      })
      .catch((error) => {
        // console.log("eroooo",error.response.data)
        toasterMessage("error", error.response.data.message);
        return callback("err");
      })
      .catch(() => {
        toasterMessage("error", "Something bad happened");
        return callback("err");
      });
  };
}

export function getDoclist(obj) {
  return (dispatch) => {
    axios
      .post(API_URL + constant.GET_DOC, obj, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then(function (response) {
        if (response.data.status === 200) {
          // toasterMessage("success", response.data.message);
          dispatch(
            actionCreator(documentActionTypes.get_docs.SUCCESS, {
              docList: response.data.data,
              totalCountt: response.data.data.length,
            })
          );
        }
      })
      .catch((error) => {
        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch(() => {
        toasterMessage("error", "Something bad happened");
      });
  };
}

export function getDoclistbyuserId(obj) {
  return (dispatch) => {
    axios
      .post(API_URL + constant.GET_USER_DOC, obj, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then(function (response) {
        if (response.data.status === 200) {
          // toasterMessage("success", response.data.message);
          console.log("response.data", response.data.data);
          // return false
          dispatch(
            actionCreator(documentActionTypes.get_user_docs.SUCCESS, {
              userdocList: response.data.data,
            })
          );
        }
      })
      .catch((error) => {
        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch(() => {
        toasterMessage("error", "Something bad happened");
      });
  };
}

export function getDocFilteredData(obj) {
  console.log(obj, "frpm action");
  return (dispatch) => {
    axios
      .post(API_URL + constant.GET_FILTER_DOCUMENT, obj, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then(function (response) {
        if (response.data.status === 200) {
          // toasterMessage("success", response.data.message);
          dispatch(
            actionCreator(documentActionTypes.get_document.SUCCESS, {
              documentList: response.data.data,
              totalCount: response.data.data.length,
            })
          );
        }
      })
      .catch((error) => {
        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch(() => {
        toasterMessage("error", "Something bad happened");
      });
  };
}

export function getDocFilteredSharedData(obj) {
  console.log(obj, "frpm action");
  return (dispatch) => {
    axios
      .post(API_URL + constant.GET_FILTER_DOCUMENT, obj, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then(function (response) {
        if (response.data.status === 200) {
          // toasterMessage("success", response.data.message);
          dispatch(
            actionCreator(documentActionTypes.get_shared_docs.SUCCESS, {
              sharedList: response.data.data,
              totalCount: response.data.data.length,
            })
          );
        }
      })
      .catch((error) => {
        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch(() => {
        toasterMessage("error", "Something bad happened");
      });
  };
}
export function getsharedDocuments() {
  return (dispatch) => {
    axios
      .get(API_URL + constant.SHARED_WITH_ME, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then(function (response) {
        if (response.data.status === 200) {
          dispatch(
            actionCreator(documentActionTypes.get_shared_docs.SUCCESS, {
              sharedList: response.data.data,
            })
          );
        }
      })
      .catch((error) => {
        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch(() => {
        toasterMessage("error", "Something bad happened");
      });
  };
}

export function clearDocument() {
  return {
    type: "CLEAR_SHARED_DOCLIST",
    payload: {},
  };
}

export function getunApprovedDocuments() {
  return (dispatch) => {
    axios
      .get(API_URL + constant.UNAPPROVED_DOCUMENTS, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then(function (response) {
        if (response.data.status === 200) {
          dispatch(
            actionCreator(documentActionTypes.get_unapproved_docs.SUCCESS, {
              unapprovedList: response.data.data,
              totalCountt: response.data.data.length,
            })
          );
        }
      })
      .catch((error) => {
        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch(() => {
        toasterMessage("error", "Something bad happened");
      });
  };
}

export function getApprovedDocuments() {
  return (dispatch) => {
    axios
      .get(API_URL + constant.APPROVED_DOCUMENTS, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then(function (response) {
        if (response.data.status === 200) {
          dispatch(
            actionCreator(documentActionTypes.get_approved_docs.SUCCESS, {
              approvedList: response.data.data,
              totalCountt: response.data.data.length,
            })
          );
        }
      })
      .catch((error) => {
        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch(() => {
        toasterMessage("error", "Something bad happened");
      });
  };
}

export function applydateFilterUnApproved(start, end) {
  return {
    type: "UNAPPROVED_DATE_FILTER",
    payload: {
      start,
      end,
    },
  };
}

export function saveFilterRes(filterObj) {
  return (dispatch) => {
    dispatch(
      actionCreator(documentActionTypes.get_filter_Obj.SUCCESS, {
        filterObj: filterObj,
      })
    );
  };
}
