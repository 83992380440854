/** Organization Actions  */

import {
    actionCreator,
    organizationActionTypes,
    jsonApiHeader
} from '../constants/apiConstants';

import { API_URL } from '../../../../actions/utilAction';
import axios from 'axios';
import * as constant from '../../../../actions/constant';
import { toasterMessage } from "../../../../actions/util";

let access_token = localStorage.access_token;
// let access_token = sessionStorage.access_token;

export function getOrganizations() {
    return (dispatch) => {
        axios.get(API_URL + constant.GET_ORGANIZATION_LIST, {
            headers: jsonApiHeader(access_token, 'application/json')
        }).then(function (response) {
            if (response.data.status === 200) {
                // toasterMessage("success", response.data.message);
                dispatch(actionCreator(organizationActionTypes.get_organization.SUCCESS, { organizationList: response.data.data.totalOrganisation, totalCount: response.data.data.organisationList}));
            }
        }).catch((error) => {
            toasterMessage("error", error.response.data.error);
            if (error.response.status === 300) {
                toasterMessage("error", error.response.data.error);
            }
        }).catch(() => {
            toasterMessage('error', 'Something bad happened')
        })
    };
}

export function addOrganization(authObj) {
    // return false
    return (dispatch) => {
        axios.post(API_URL + constant.ADD_ORGANIZATION, authObj, {
            headers: jsonApiHeader(access_token, 'application/json')
        }).then(function (response) {
            if (response.data.status === 200) {
                toasterMessage("success", response.data.message);
                // let userObj = {
                //     page: 0
                // }
                axios.get(API_URL + constant.GET_ORGANIZATION_LIST, {
                    headers: jsonApiHeader(access_token, 'application/json')
                }).then(function (response) {
                    if (response.data.status === 200) {
                        dispatch(actionCreator(organizationActionTypes.get_organization.SUCCESS, { organizationList: response.data.data.totalOrganisation, 
                            totalCount: response.data.data.organisationList}));
                    }
                })
            }
        }).catch((error) => {
            toasterMessage("error", error.response.data.error);
            if (error.response.status === 300) {
                toasterMessage("error", error.response.data.error);
            }
        }).catch(() => {
            toasterMessage('error', 'Something bad happened')
        })
    };
}

export function editOrganization(authObj) {
    // return false
    return (dispatch) => {
        axios.put(API_URL + constant.EDIT_ORGANIZATION, authObj, {
            headers: jsonApiHeader(access_token, 'application/json')
        }).then(function (response) {
            if (response.data.status === 200) {
                // let userObj = {
                //     page: 0
                // }
                toasterMessage("success",response.data.message);
                axios.get(API_URL + constant.GET_ORGANIZATION_LIST, {
                    headers: jsonApiHeader(access_token, 'application/json')
                }).then(function (response) {
                    if (response.data.status === 200) {
                        dispatch(actionCreator(organizationActionTypes.get_organization.SUCCESS, { organizationList: response.data.data.totalOrganisation, 
                            totalCount: response.data.data.organisationList}));
                    }
                })
            } 
        }).catch((error) => {
            toasterMessage("error", error.response.data.error);
            if (error.response.status === 300) {
                toasterMessage("error", error.response.data.error);
            }
        }).catch(() => {
            toasterMessage('error', 'Something bad happened')
        })
    };
}

export function editStatus(authObj,callback) {

    // return false
    return (dispatch) => {
        axios.put(API_URL + constant.EDIT_ORGANIZATION, authObj, {
            headers: jsonApiHeader(access_token, 'application/json')
        }).then(function (response) {
            if (response.data.status === 200) {
                toasterMessage("success",response.data.message);
            return callback('success');
            } 
        }).catch((error) => {
            toasterMessage("error", error.response.data.error);
            if (error.response.status === 300) {
                toasterMessage("error", error.response.data.error);
            return callback(error);
            }
        }).catch(() => {
            toasterMessage('error', 'Something bad happened')
            return callback('err');
        })
    };
}
