import { userActionTypes } from './../constants/apiConstants';

const initialState = {
    userList: [],
    totalCount: 0,
    practitinerList:[],
    categoryList:[],
    userDetails:''

  
};

const userReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case userActionTypes.get_user.SUCCESS:
        return {
          ...state,
          userList: payload.userList,
          totalCount: payload.totalCount
        };
      case userActionTypes.get_prac.SUCCESS:
        return {
          ...state,
          practitinerList: payload.pracList,
        };
      case userActionTypes.get_cat.SUCCESS:
        return {
          ...state,
          categoryList: payload.categoryList,
        };
      case userActionTypes.get_user_details.SUCCESS:
        return {
          ...state,
          userDetails: payload.userDetails,
        };
      default:
        return state;
    }
  };
  
  export default userReducer;
  