import React from 'react';
import { Button, CssBaseline, TextField, Link, Paper, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import ForgetImg from '../../assets/forgetpassword.png';
import Logo from '../../assets/logo.png';
import * as constant from '../../actions/constant';
import { forgotPassword } from './actions/passwordAction';

const useStyles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${ForgetImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin:theme.spacing(12,12) ,
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#FFC805 !important",
    color: ' #005A98',
    fontFamily: "OpenSans-Regular",
    fontWeight: "700",
    fontSize: "18px",
    height:"60px",
  },
  link: {
    display: "flex",
    justifyContent: "center",
  }
});

class ForgetPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errorEmail: '',
      error: false,
      buttonDisabled:false
    }
    autoBind(this);
  }

  componentDidMount() {
    
  }

  static getDerivedStateFromProps(props, state) {
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.email !== '' && this.state.error === false) {
      this.setState({buttonDisabled:true})
      let userObj =
      {
        email: this.state.email,
      }
      // this.props.forgotPassword(userObj)
      this.props.forgotPassword(userObj, (result) => {
        let thiss=this
        if (result) {
          // this.setState({errorEmail:'Email not found'})
           setTimeout(function() {
            //your code to be executed after 1 second
            thiss.setState({buttonDisabled:false})
          }, 3000);
        }
    })
    }

    if (this.state.email === '' && this.state.error === false) {
      this.setState({ errorEmail: 'This field is required', error: true });
    }

  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <div className="mr-b-50">
              <img src={Logo} className={classes.logo} alt="logo" />
            </div>
            <Typography className="loginHeading" component="h1" variant="h5">
              Forgot Password
          </Typography>
            <Typography component="body2" variant="p" className="loginPara">
              Please enter your registered email address and we will send you a reset link by email Immediately.
          </Typography>
            <form className={classes.form} onSubmit={this.handleSubmit} noValidate>
              <TextField
                error={this.state.errorEmail !== ""}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email-Id"
                name="email"
                autoComplete="email"
                type='email'
                onChange={(event) => {
                  if (event.target.value.length !== 0 && (new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$").test(event.target.value) === true)) {
                    this.setState({ errorEmail: '', error: false, [event.target.name]: event.target.value });
                  }
                  else {
                    this.setState({ errorEmail: 'Please enter valid email', error: true });
                  }
                  if (event.target.value.length === 0) {
                    this.setState({ errorEmail: 'This field is required', error: true });
                  }
                }}
                helperText={this.state.errorEmail}
                autoFocus
              />
            
              <Button
                type="submit"
                fullWidth
                color="primary"
                className={classes.submit}
                disabled={this.state.buttonDisabled}
                disableTouchRipple="false"
              >
                SUBMIT
            </Button>
            <Link href={constant.LOGIN} variant="body2" className={classes.link}>
                Back to login
                </Link>
            </form>
          </div>
        </Grid>
        <Grid item xs={false} sm={4} md={6} className={classes.image} />
      </Grid>
    );
  }
}
function mapStateToProps(state) {
  return {
    userDetails: state.login.authData,
  };

}
export default connect(mapStateToProps, { forgotPassword })(withStyles(useStyles)(ForgetPassword));