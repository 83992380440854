import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core/";
import {
  InputBase,
  InputLabel,
  FormControl,
  Switch,
  FormHelperText,
  Button,
  Box,
  Grid,
} from "@material-ui/core";
import { tableIcons } from "./constants/iconConstant.js";
// import leftArrow from "../../../assets/left.svg";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import MaterialTable from "material-table";
import autoBind from "react-autobind";
import {
  addCategory,
  getCategories,
  editCategory,
  editStatus,
} from "./actions/categoryActions";
import NavAdmin from "../../../component/NavAdmin.js";
import Loader from "../../../component/Loader";
import disableBrowserBackButton from "disable-browser-back-navigation";

const drawerWidth = 316;
const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  rootCard: {
    maxWidth: 345,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  appBarShift: {
    marginLeft: "150px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    position: "relative",
    top: "10rem",
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  inputStyle: {
    "label + &": {
      marginTop: theme.spacing(4),
    },
    borderRadius: 4,
    position: "relative",
    border: "1px solid #BFBFBF",
    fontSize: 12,
    height: "34px",
    fontFamily: "OpenSans-Regular",
    color: "#005A98",
    fontWeight: "bold",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    padding: "0px",
    width: "100%",
    minWidth: "413px",
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#FFC805",
    color: " #005A98",
    fontFamily: "OpenSans-Regular",
    fontWeight: "700",
    fontSize: "18px",
  },
});
const BootstrapInput = withStyles((theme) => ({
  minWidth: "613px",
  width: "100%",
  marginTop: "25px",
}))(InputBase);

class ManageCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      modal: false,
      load: true,
      checkedA: false,
      checkedE: false,
      title: "Manage Category",
      modalStatus: "",
      categoryList: [],
      categoryDetail: "",
      categoryName: "",
      categoryId: "",
      errorAdd: "",
      errorEdit: "",
      error: false,
      isDisabled: false,
    };
    autoBind(this);
  }

  componentDidMount() {
    disableBrowserBackButton();
    this.props.getCategories();
    this.setState({ load: true });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.categoryList !== state.categoryList) {
      return {
        categoryList: props.categoryList,
        load: false,
      };
    }
    // Return null if the state hasn't changed
    return null;
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleAddChange = (event) => {
    let name = event.target.name;
    let checked = event.target.checked;
    this.setState((prevState) => {
      return { [name]: checked };
    });
  };
  handleEditChange = (event) => {
    const { name, checked } = event.target;
    this.setState((prevState) => ({
        categoryDetail: {
            ...prevState.categoryDetail,
            isActive: !prevState.categoryDetail.isActive
        },
        [name]: checked
    }));
  };
  handleChange = (event) => {
    let name = event.target.name;
    let checked = event.target.checked;
    this.setState((prevState) => {
      return { [name]: checked };
    });
  };

  handleStatus = (event, rowData) => {
    rowData.isActive = !rowData.isActive;
    let obj = {
      id: rowData._id,
      categoryName: rowData.categoryName,
      isActive: event.target.checked,
    };
    this.setState({ isDisabled: true });
    this.props.editStatus(obj, (result) => {
      if (result) {
        let thiss = this;
        setTimeout(function () {
          //your code to be executed after 1 second
          thiss.setState({ isDisabled: false });
        }, 3000);
      }
    });
  };
  none = () => {};
  addCategory = () => {
    if (
      this.state.categoryName.length !== 0 &&
      this.state.errorAdd === "" &&
      this.state.error === false
    ) {
      this.setState({ modal: false });
      let obj = {
        categoryName: this.state.categoryName,
        isActive: this.state.checkedA,
      };
      this.props.addCategory(obj);
    }
    // else if (this.state.categoryName.length >25) {
    //   this.setState({ errorAdd: 'Category name can be max. 25 char', error: true })
    // }
    else {
      this.setState({ errorAdd: "This field is required", error: true });
    }
  };
  editCategory = () => {
    if (
      this.state.categoryName.length !== 0 &&
      this.state.errorEdit === "" &&
      this.state.error === false
    ) {
      this.setState({ modal: false });
      // return false
      let obj = {
        id: this.state.categoryId,
        categoryName: this.state.categoryName,
        isActive: this.state.checkedE,
      };
      this.props.editCategory(obj);
    }
    // else if (this.state.categoryName.length >25) {
    //   console.log("this.state.categoryName.length",this.state.categoryName.length)
    //   this.setState({ errorAdd: 'Category name can be max. 25 char', error: true })
    // }
    else {
      this.setState({ errorEdit: "This field is required", error: true });
    }
  };
  handleModalOpen = (data, categoryDetails) => {
    this.setState({
      modal: true,
      modalStatus: data,
      categoryDetail: categoryDetails,
    });
  };
  handleEditModal = (data, categoryDetails) => {
    this.setState({
      modal: true,
      modalStatus: data,
      categoryDetail: categoryDetails,
      categoryName: categoryDetails.categoryName,
      categoryId: categoryDetails._id,
      error: false,
      checkedE: categoryDetails.isActive,
    });
  };
  handleAddModal = (data) => {
    this.setState({
      modal: true,
      modalStatus: data,
      categoryDetail: "",
      categoryName: "",
      error: false,
    });
  };
  handleModalClose = () => {
    this.setState({ modal: false, errorAdd: "", errorEdit: "" });
    this.props.getCategories();
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <NavAdmin
          handleDrawerOpen={this.handleDrawerOpen}
          handleDrawerClose={this.handleDrawerClose}
          open={this.state.open}
          title={this.state.title}
          activeValue={0}
        />
        <main
          className={clsx(classes.content, {
            [classes.appBarShift]: this.state.open,
          })}
        >

          <Box mb={7}>
            {/* <Typography variant="body2"  color="textSecondary" className="ListHeading " component="p">
    <Icon> <img className={classes.imageIcon} src={leftArrow}/></Icon> 
    Manage Category
            </Typography> */}
          </Box>
          <Button
            class="btn-doc heading1"
            style={{ marginBottom: "20px" }}
            // onClick={this.handleModalOpen}
            onClick={this.handleAddModal.bind(this, "add")}
            disableTouchRipple="false"
          >
            Add New Category
          </Button>
          {this.state.load === true ? (
            <Loader />
          ) : (
            <MaterialTable
              icons={tableIcons}
              title="Category List"
              columns={[
                {
                  title: "Id",
                  field: "categoryId",
                  cellStyle: { width: "20%" },
                },
                {
                  title: "Category Name",
                  field: "categoryName",
                  cellStyle: { width: "20%" },
                },
                {
                  title: "No. of Documents ",
                  field: "documentCount",
                  type: "numeric",
                  cellStyle: { width: "20%", textAlign: "center" },
                  headerStyle: {
                    textAlign: "center",
                  },
                },
              ]}
              data={this.state.categoryList}
              localization={{
                header: {
                  actions: "Status",
                },
              }}
              actions={[
                (rowData) => ({
                  icon: () => (
                    <Switch
                      checked={rowData.isActive}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                      name="checkedE"
                      className="main-switch mr-t-0"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  ),
                  tooltip: "Save User",
                  onClick: (event, rowData) => {
                    this.state.isDisabled === false
                      ? this.handleStatus(event, rowData)
                      : this.none();
                  },
                }),
                {
                  icon: () => (
                    <VisibilityOutlinedIcon style={{ color: "#005a98" }} />
                  ),
                  tooltip: "Show Document List",
                  // onClick: () => this.handleModalOpen()
                  onClick: (event, rowData) => {
                    // this.handleModalOpen('view', rowData)
                    this.props.history.push("/documentList/" + rowData._id);
                  },
                },
                {
                  icon: () => <EditOutlinedIcon style={{ color: "#005a98" }} />,
                  tooltip: "Edit Category List",
                  // onClick: () => this.handleModalOpen()
                  onClick: (event, rowData) => {
                    this.handleEditModal("edit", rowData);
                  },
                },
              ]}
              options={{
                actionsColumnIndex: -1,
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return {
                      backgroundColor: "#ebf5ff",
                      color: "#005a98",
                      fontSize: "14px",
                      fontWeight: "bold",
                      fontFamily: "OpenSans-Regular",
                    };
                  } else {
                    return {
                      color: "#005a98",
                      fontSize: "14px",
                      fontWeight: "bold",
                      fontFamily: "OpenSans-Regular",
                    };
                  }
                },
                actionsCellStyle: {
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                },
                headerStyle: {
                  backgroundColor: "#ffcf5c",
                  color: "#005a98",
                  fontSize: "16px",
                  fontWeight: "700",
                },
                actionStyle: { textAlign: "center" },
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [5, 10, 20, 30, 40, 50], // rows selection options
              }}
            />
          )}
          <Dialog
            open={this.state.modal}
            onClose={this.handleModalClose}
            aria-labelledby="form-dialog-title"
            className="dialog-main-div"
          >
            {this.state.modalStatus && this.state.modalStatus === "add" ? (
              <DialogTitle id="form-dialog-title" class="modal-header">
                Add New Category
              </DialogTitle>
            ) : (
              ""
            )}
            {this.state.modalStatus && this.state.modalStatus === "view" ? (
              <DialogTitle id="form-dialog-title" class="modal-header">
                View Category
              </DialogTitle>
            ) : (
              ""
            )}
            {this.state.modalStatus && this.state.modalStatus === "edit" ? (
              <DialogTitle id="form-dialog-title" class="modal-header">
                Edit Category
              </DialogTitle>
            ) : (
              ""
            )}
            <DialogContent className="dialog-content pd-70">
              <Grid container>
                <Grid item className="mr-b-40">
                  <FormControl>
                    <InputLabel
                      shrink
                      htmlFor="categoryName"
                      className="input-label label-font"
                    >
                      Category Name
                    </InputLabel>
                    {this.state.modalStatus &&
                    this.state.modalStatus === "view" ? (
                      <BootstrapInput
                        // disabled
                        readOnly
                        value={
                          this.state.categoryDetail
                            ? this.state.categoryDetail.categoryName
                            : ""
                        }
                        placeholder="Enter Category Name"
                        id="categoryName"
                        className={clsx(classes.inputStyle, classes.inputWidth)}
                      />
                    ) : (
                      <BootstrapInput
                        value={this.state.categoryName}
                        placeholder="Enter Category Name"
                        id="categoryName"
                        className={clsx(classes.inputStyle, classes.inputWidth)}
                        inputProps={{
                          maxLength: 25,
                        }}
                        onChange={(event) => {
                          this.setState({
                            errorAdd: "",
                            error: false,
                            categoryName: event.target.value,
                          });
                          if (this.state.modalStatus === "add") {
                            // if (event.target.value.length == 0) {
                            //   this.setState({ errorAdd: 'This field is required', error: true });
                            // }
                            // // else if (event.target.value.length != 0 && event.target.value.length > 25) {
                            // //   this.setState({ errorAdd: 'Category name can be max. 25 char', error: true });
                            // // }
                            // else {
                            //   this.setState({ errorAdd: '', error: false, categoryName: (event.target.value) });
                            // }
                            if (
                              event.target.value.length !== 0 &&
                              new RegExp("^[a-zA-Z0-9 ]*$").test(
                                event.target.value
                              ) === true
                            ) {
                              this.setState({
                                errorAdd: "",
                                error: false,
                                categoryName: event.target.value,
                              });
                            } else {
                              this.setState({
                                errorAdd: "Please enter valid category name",
                                error: true,
                              });
                            }
                            if (event.target.value.length === 0) {
                              this.setState({
                                errorAdd: "This field is required",
                                error: true,
                              });
                            }
                          }
                          if (this.state.modalStatus === "edit") {
                            if (
                              event.target.value.length !== 0 &&
                              new RegExp("^[a-zA-Z0-9 ]*$").test(
                                event.target.value
                              ) === true
                            ) {
                              this.setState({
                                errorEdit: "",
                                error: false,
                                categoryName: event.target.value,
                              });
                            } else {
                              this.setState({
                                errorEdit: "Please enter valid category name",
                                error: true,
                              });
                            }
                            if (event.target.value.length === 0) {
                              this.setState({
                                errorEdit: "This field is required",
                                error: true,
                              });
                            }
                          }
                        }}
                      />
                    )}
                    {this.state.modalStatus &&
                    this.state.modalStatus === "add" ? (
                      this.state.errorAdd === "" ? (
                        <FormHelperText>25 character max.</FormHelperText>
                      ) : (
                        <FormHelperText error>
                          {this.state.errorAdd}
                        </FormHelperText>
                      )
                    ) : (
                      ""
                    )}
                    {this.state.modalStatus &&
                    this.state.modalStatus === "edit" ? (
                      this.state.errorEdit === "" ? (
                        <FormHelperText>25 character max.</FormHelperText>
                      ) : (
                        <FormHelperText error>
                          {this.state.errorEdit}
                        </FormHelperText>
                      )
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <InputLabel shrink={true} className="input-label label-font">
                    Activate Category
                  </InputLabel>

                  {this.state.modalStatus &&
                  this.state.modalStatus === "add" ? (
                    <Switch
                      checked={this.state.checkedA}
                      onChange={(event) => {
                        this.handleAddChange(event);
                      }}
                      name="checkedA"
                      className="main-switch"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  ) : (
                    ""
                  )}

                  {this.state.modalStatus &&
                  this.state.modalStatus === "view" ? (
                    <Switch
                      // disabled
                      readOnly
                      checked={this.state.categoryDetail.isActive}
                      // checked={this.state.checkedA}
                      className="main-switch"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  ) : (
                    ""
                  )}

                  {this.state.modalStatus &&
                  this.state.modalStatus === "edit" ? (
                    <Switch
                      checked={this.state.categoryDetail.isActive}
                      onChange={(event) => {
                        this.handleEditChange(event);
                      }}
                      name="checkedE"
                      className="main-switch"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                class="btn-doc-close heading3"
                style={{ marginBottom: "20px", marginRight: "20px" }}
                onClick={this.handleModalClose}
                disableTouchRipple="false"
              >
                Close
              </Button>
              {this.state.modalStatus && this.state.modalStatus === "add" ? (
                <Button
                  class="btn-doc heading1"
                  style={{ marginBottom: "20px", marginRight: "20px" }}
                  onClick={this.addCategory}
                  disableTouchRipple="false"
                >
                  Submit
                </Button>
              ) : (
                ""
              )}
              {this.state.modalStatus && this.state.modalStatus === "edit" ? (
                <Button
                  class="btn-doc heading1"
                  style={{ marginBottom: "20px", marginRight: "20px" }}
                  onClick={this.editCategory}
                  disableTouchRipple="false"
                >
                  Submit
                </Button>
              ) : (
                ""
              )}
            </DialogActions>
          </Dialog>
        </main>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    categoryList: state.category.categoryList,
  };
}

export default connect(mapStateToProps, {
  getCategories,
  addCategory,
  editCategory,
  editStatus,
})(withStyles(useStyles)(ManageCategory));
