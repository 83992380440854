import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Icon,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import leftArrow from "../../../assets/left.svg";
import Nav from "../../../component/Nav.js";

/*import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core/";
import Rating from "@material-ui/lab/Rating";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";*/
import download from "../../../assets/download.png";
// import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
// import Frame from "../../../assets/Frame.svg";
// import pdf from "../../../assets/Pdf.png";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
// import CheckBoxIcon from "@material-ui/icons/CheckBox";
// import divider from "@material-ui/core";
import Loader from "../../../component/Loader";
import axios from "axios";
import { API_URL } from "../../../actions/utilAction";
import { jsonApiHeader } from "../../../actions/utilAction";
import { toasterMessage } from "../../../actions/util";
// import { Link } from "react-router-dom";
import moment from "moment";
import FileSaver from "file-saver";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (state) => ({});

const drawerWidth = 316;
/*const icon = (
  <CheckBoxOutlineBlankIcon fontSize="small" style={{ fill: "#005A98" }} />
);*/
// const checkedIcon = <CheckBoxIcon fontSize="small" />;
const access_token = localStorage.access_token;

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  rootCard: {
    maxWidth: 345,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  appBarShift: {
    marginLeft: "150px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    position: "relative",
    top: "10rem",
    padding: theme.spacing(2, 2),
    paddingRight: theme.spacing(5),
  },  
  inputStyle: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFFFFF",
    border: "1px solid rgba(255, 207, 92, 1)",
    fontSize: 16,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    padding: "0px",
    width: "100%",
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  inputWidth: {
    minWidth: "413px",
    height: "50px",
  },
  textAreWidth: {
    minWidth: "395px",
    minHeight: "160px",
    borderRadius: "10px",
  },
  alignIconData:{
    [theme.breakpoints.down('md')]: {
    marginLeft:"36px",
    marginRight:"4px",
    padding:"0px",
    },
    [theme.breakpoints.up('md')]: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    padding:"0px 40px",
    },
  },
  focused: {},
  iconOpen: {},
});
/* const BootstrapInput = withStyles((theme) => ({
  minWidth: "413px",
  width: "100%",
}))(InputBase); */

class RatingAndReviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      checkedA: true,
      modal: false,
      title: "My Document",
      documentId: "",
      documentData: "",
      loader: true,
      docName: "",
      docVersion: "",
      currentPage: 1,
      skipData: 10,
      totalDocCount: "",
    };
  }

  componentDidMount() {
    this.setState(
      {
        documentId: this.props.location.state.data.documentId,
        docVersion: this.props.location.state.data.versionName,
        docName: this.props.location.state.docName,
      },
      () => this.getHistory()
    );
    console.log(this.props.location.state);
    // "api/document/getDocumentHistory"
  }
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  handleChange = (event) => {
    let name = event.target.name;
    let checked = event.target.checked;
    this.setState((prevState) => {
      return { [name]: checked };
    });
  };
  handleModalOpen = (data) => {
    this.setState({ modal: true });
  };
  handleModalClose = (data) => {
    this.setState({ modal: false });
  };

  getHistory = () => {
    let postBody = {
      documentId: this.state.documentId,
      page: this.state.currentPage,
      limit: this.state.skipData,
    };
    axios
      .post(`${API_URL}/document/getDocumentHistory`, postBody, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then((res) => {
        if (res.data.status === 200) {
          this.setState(
            {
              loader: false,
              documentData: res.data.data.documentHistoryList,
              totalDocCount: res.data.data.totalCount,
            },
            () => console.log(this.state.documentData)
          );
        }
      })
      .catch((error) => {
        this.setState({ loader: false });

        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch(() => {
        this.setState({ loader: false });
        toasterMessage("error", "Something bad happened");
      });
  };
  goBack = () => {
    this.props.history.goBack();
  };

  downloadDocument = (data) => {
    this.setState({ loader: true });
    axios
      .get(`${API_URL}/document/downloadDoc/${data}`, {
        headers: jsonApiHeader(access_token, "blob"),
        responseType: "blob",
      })
      .then((res) => {
        this.setState({ fileDownloading: true, loader: false }, () => {
          FileSaver.saveAs(
            res.data,
            `${this.state.docName}V${this.state.docVersion}`
          );
        });

        console.log(res.data, "from here");
      })
      .catch((error) => {
        this.setState({ loader: false });
        // this.handleRejectModal()
        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch((error) => {
        this.setState({ loader: false });
        console.log(error);
        // this.handleRejectModal()
        toasterMessage("error", "Something bad happened");
      });
  };

  onPageChange = (page) => {
    let skip = (page - 1) * 10;
    this.setState(
      {
        currentPage: page,
        skipData: skip,
      },
      () => {
        this.getHistory();
      }
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Nav
          handleDrawerOpen={this.handleDrawerOpen}
          handleDrawerClose={this.handleDrawerClose}
          open={this.state.open}
          title={this.state.title}
        />
        <main
          className={clsx(classes.content, {
            [classes.appBarShift]: this.state.open,
          })}
        >

          {this.state.loader ? (
            <Loader />
          ) : (
            <>
              <Box mb={3}>
                <Typography
                  variant="body2"
                  pb={0}
                  color="textSecondary"
                  className="ListHeading "
                  component="p"
                >
                  {/* <Link to='/document-details'> */}
                  <Icon onClick={this.goBack}>
                    {}
                    <img
                      className={`${classes.imageIcon} arrow-position`}
                      src={leftArrow}
                      alt="Left Arrow"
                    />
                  </Icon>
                  {/* </Link> */}
                  History
                </Typography>
              </Box>
              <Grid container style={{ marginBottom: "31px" }}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body2" component="p">
                    <span
                      className="mr-r-16 filter-text head-filter"
                      style={{ color: "rgba(51, 51, 51, 1)" }}
                    >
                      {this.state.docName}
                    </span>
                    <span
                      className="label-heading mr-r-16"
                      style={{ color: "rgba(51, 51, 51, 1)" }}
                    >
                      Current version:
                    </span>{" "}
                    <span
                      className="version-font"
                      style={{ color: "rgba(51, 51, 51, 1)" }}
                    >
                      V{1.+this.state.docVersion/10}
                    </span>
                    {/* <span className="heading2">
                        Sep 01 2020 - Sep 30 2020
                               </span> */}
                  </Typography>
                </Grid>
              </Grid>
              {this.state.documentData.map((history) => {
                return (
                  <>
                    <Card
                      style={{ marginBottom: "8px" }}
                      className="card-rate-and-reviews card-reviews"
                    >
                      <CardContent className="card-rate-and-reviews card-reviews">
                        <Grid container>
                          <Grid item xs={4} sm={3} style={{ marginLeft: "40px" }}>
                            <Typography component="h5" variant="h5">
                              <span
                                className="filter-text head-filter"
                                style={{ fontWeight: "bold" }}
                              >
                                Date
                              </span>{" "}
                              <br />
                              <span className="history-sub-text">
                                {moment(history.created_at).format("DD-MM-YYYY")}
                              </span>{" "}
                            </Typography>
                          </Grid>
                          <divider
                            orientation="vertical"
                            style={{
                              border: "1px solid rgba(0, 90, 152, 0.1)",
                            }}
                          />
                          <Grid
                            item
                            xs={4} sm={3}
                            className="align-item-center"
                            style={{ padding: "0px 40px" }}
                          >
                            <Typography component="h5" variant="h5">
                              <span
                                className="filter-text head-filter"
                                style={{ fontWeight: "bold" }}
                              >
                                Version
                              </span>{" "}
                              <br />
                              <span className="history-sub-text">
                                {1.+history.versionName/10}
                              </span>{" "}
                            </Typography>
                          </Grid>
                          <divider
                            orientation="vertical"
                            style={{
                              border: "1px solid rgba(0, 90, 152, 0.1)",
                            }}
                          />
                          <Grid
                            item
                            xs={4} sm={3}
                            className={classes.alignIconData}
                          >
                            <Typography component="h5" variant="h5">
                              <span
                                className="filter-text head-filter"
                                style={{ fontWeight: "bold" }}
                              >
                                Status
                              </span>{" "}
                              <br />
                              <span
                                className="history-sub-text"
                                style={{
                                  color:
                                    history.status === "REVISION_REQUESTED"
                                      ? "#ff8b7b"
                                      : history.status === "PENDING_REVIEW"
                                      ? "#ff5551"
                                      : "#00c213",
                                }}
                              >
                                {history.status}
                              </span>{" "}
                            </Typography>
                          </Grid>
                          <divider
                            orientation="vertical"
                            style={{
                              border: "1px solid rgba(0, 90, 152, 0.1)",
                            }}
                          ></divider>

                          <Grid
                            onClick={(e) =>
                              this.downloadDocument(history.fileId)
                            }
                            item
                            xs={3} sm={1}
                            className="align-item-end"
                          >
                            {" "}
                            <img
                              className={`${classes.imageIcon} category-path`}
                              height="20px"
                              width="20px"
                              src={download}
                              alt="Download Icon"
                            />
                            {" "}

                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </>
                );
              })}
              <Pagination
                onChange={this.onPageChange}
                current={1}
                total={this.state.totalDocCount}
                pageSize={10}
                style={{
                  float: "right",
                  marginTop: "10px",
                  marginRight: "12.5px",
                }}
              />
            </>
          )}
        </main>
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(RatingAndReviews));

/*const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
];*/
