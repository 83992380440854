/** MESSAGE CONSTANT */
export const DELETE_RECORD = 'Do you want to delete this record?';
export const DELETE_RECORD_SUCCESS = 'Record deleted successfully';
export const ALREADY_EXIST = 'Data already exist';

/** API CONSTANT */
export const USER_LOGIN = '/user/login';
export const FORGOT_PASSWORD ='/user/forgotPassword';
export const GET_CATEGORY_LIST='/admin/category/getCategory';
export const ADD_CATEGORY='/admin/category/addCategory';
export const EDIT_CATEGORY='/admin/category/editCategory';
export const GET_ORGANIZATION_LIST='/admin/organisation/getOrganisation';
export const ADD_ORGANIZATION='/admin/organisation/addOrganisation';
export const EDIT_ORGANIZATION='/admin/organisation/editOrganisation';
export const ADD_USER='/admin/practitioner/addPractitioner';
export const USER_LIST = '/admin/practitioner/getPractitioner';
export const EDIT_USER='/admin/practitioner/editPractitioner';
export const PRAC_LIST = '/user/getPractitioner';
export const CATEGORY_LIST = '/user/getCategories';
export const ADD_DOCUMENT = '/document/addDocument';
export const EDIT_DOCUMENT='/document/editDocument'
export const GET_DOCUMENT = '/document/getDocument';
export const GET_FILTER_DOCUMENT = '/document/getFilterDocument';

export const GET_ORG_USER = '/admin/organisation/getPractitioner';
export const GET_DOC = '/admin/category/getDocument';
export const GET_USER_DOC = '/admin/practitioner/getPractitionerDoc';
export const GET_SHARED_DOC = '/document/shareDocument';

export const RESET_PASSWORD = '/user/resetPassword';
// export const CHANGE_PASSWORD = '/user/changePassword';
export const TOKEN_EXPIRED = '/user/tokenExpired';

export const GET_USER_DETAILS = '/user/getDetails';
export const EDIT_USER_DETAILS = '/user/editPractitioner';
export const SHARED_WITH_ME='/document/shareDocument';
export const UNAPPROVED_DOCUMENTS='/document/getUnApprovedDoc';
export const APPROVED_DOCUMENTS='/document/getApprovedDoc'


export const USERS_COUNT_LIST=''


/** ROUTE CONSTANT */
export const LOGIN = '/';
export const FORGOTPASSWORD = '/forgetPassword';
export const RESETPASSWORD = '/resetPassword';
export const DASHBOARD = '/MyDocuments';
export const LIST_CATEGORY = '/listCategory';

/* MESSAGING CONSTANT */
export const RECENT_USERS = '/message/recentUsers';
export const USER_MESSAGE = '/message/getMessage';
export const SEND_MESSAGE = '/message/postMessage';
export const GET_ALL_USERS = '/message/getUsers';
export const SEARCH_USERS = '/message/searchUsers';

