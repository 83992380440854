import { documentActionTypes } from './../constants/apiConstants';
import moment from 'moment'; 

const initialState = {
    documentList: [],
    totalCount: 0,
    docList: [],
    totalCountt: 0,
    userdocList: [],
    shareddocumentList:[],
    shareddocList: [],
    filterObj: '',
    unapprovedList: [],
    unapprovedTotalCount: 0,
    approvedList: [],
    approvedTotalCount: 0,
    myDocumentStartDate:'',
    myDocumentEndDate:''


};

const documentReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case documentActionTypes.get_document.SUCCESS:
        return {
          ...state,
          documentList: payload.documentList,
          totalCount: payload.totalCount
        };
      case documentActionTypes.get_docs.SUCCESS:
        return {
          ...state,
          docList: payload.docList,
          totalCountt: payload.totalCountt
        };
      case documentActionTypes.get_user_docs.SUCCESS:
        return {
          ...state,
          userdocList: payload.userdocList
        };
      case documentActionTypes.get_shared_docs.SUCCESS:
        return {
              ...state,
              shareddocList: payload.sharedList
        };
          
      // case documentActionTypes.get_shared_docs_filter.SUCCESS:
      //     return {
      //         ...state,
      //         shareddocumentList:[],
      //         shareddocumentList: payload.sharedList,
      //         totalCountt: payload.totalCountt
      //     };
      case documentActionTypes.get_filter_Obj.SUCCESS:
        console.log(payload.filterObj, 'from reducer');
        return {
          ...state,
          filterObj: payload.filterObj
        };
      case documentActionTypes.get_unapproved_docs.SUCCESS:
        return {
          ...state,
          unapprovedList: payload.unapprovedList,
          unapprovedTotalCount: payload.totalCountt
        };
      case documentActionTypes.get_approved_docs.SUCCESS:
        return {
          ...state,
          approvedList: payload.approvedList,
          approvedTotalCount: payload.totalCountt
        };
      case 'UNAPPROVED_DATE_FILTER':
        if (payload.start && payload.end) {
          let unapprovedList = state.unapprovedList;
          let fromDate = moment(payload.start).format('DD-MM-YYYY');
          let toDate = moment(payload.end).format('DD-MM-YYYY');
          console.log('hitted', fromDate, toDate);
          unapprovedList = unapprovedList.filter((emp) => {
            let date = moment(emp.currentDocument.created_at).format('DD-MM-YYYY');
            if (fromDate <= date && toDate >= date) {
              console.log(fromDate, date, toDate, 'from if');
              return emp;
            }
            return null; // Add a default return statement here
          });
          
          return {
            ...state,
            unapprovedList
          };
        }
        break;
        
      case 'CLEAR_SHARED_DOCLIST':
        return {
          ...state,
          shareddocList: []
        };
      default:
        return state;
    }
  };
  
  export default documentReducer;
  