import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core/";
import {
  InputBase,
  InputLabel,
  FormControl,
  Switch,
  FormHelperText,
  Button,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import { tableIcons } from "../organization/constants/iconConstant";
// import leftArrow from "../../../assets/left.svg";
// import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
// import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import MaterialTable from "material-table";
import autoBind from "react-autobind";
import NavAdmin from "../../../component/NavAdmin.js";
import Loader from "../../../component/Loader";
// import disableBrowserBackButton from "disable-browser-back-navigation";
import axios from "axios";
import { API_URL } from "../../../actions/utilAction";
import { jsonApiHeader } from "../../../actions/utilAction";
import { toasterMessage } from "../../../actions/util";
import { DateRangePicker, isInclusivelyBeforeDay } from "react-dates";
import moment from "moment";
const drawerWidth = 316;

const access_token = localStorage.access_token;

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  rootCard: {
    maxWidth: 345,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  appBarShift: {
    marginLeft: "150px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    position: "relative",
    top: "10rem",
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  inputStyle: {
    "label + &": {
      marginTop: theme.spacing(4),
    },
    borderRadius: 4,
    position: "relative",
    border: "1px solid #BFBFBF",
    fontSize: 12,
    height: "34px",
    fontFamily: "OpenSans-Regular",
    color: "#005A98",
    fontWeight: "bold",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    padding: "0px",
    width: "100%",
    minWidth: "413px",
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#FFC805",
    color: " #005A98",
    fontFamily: "OpenSans-Regular",
    fontWeight: "700",
    fontSize: "18px",
  },
});
const BootstrapInput = withStyles((theme) => ({
  minWidth: "613px",
  width: "100%",
  marginTop: "25px",
}))(InputBase);

class ReportedReviewManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      modal: false,
      load: false,
      checkedA: false,
      checkedE: false,
      title: "Manage Review",
      modalStatus: "",
      categoryList: [],
      categoryDetail: "",
      categoryName: "",
      categoryId: "",
      errorAdd: "",
      errorEdit: "",
      error: false,
      isDisabled: false,
      alldata: [],
      filteredAlldata: [],
    };
    autoBind(this);
  }

  componentDidMount() {
    this.getData();
    // disableBrowserBackButton();
    // this.props.getCategories();
    // this.setState({ load: false });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.categoryList !== state.categoryList) {
      return {
        categoryList: props.categoryList,
        load: false,
      };
    }
    // Return null if the state hasn't changed
    return null;
  }
  getData = () => {
    this.setState({ load: true });
    axios
      .get(`${API_URL}/flagRating/getFlagReview`, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then((res) => {
        if (res.data.status === 200) {
          this.setState(
            {
              alldata: res.data.data,
              filteredAlldata: res.data.data,
              load: false,
            },
            () => console.log(this.state.reasons, "from set")
          );
        }
      })
      .catch((error) => {
        this.setState({ load: false });
        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch(() => {
        this.setState({ load: false });
        toasterMessage("error", "Something bad happened");
      });
  };
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleAddChange = (event) => {
    let name = event.target.name;
    let checked = event.target.checked;
    this.setState((prevState) => {
      return { [name]: checked };
    });
  };
  handleEditChange = (event) => {
    const { name, checked } = event.target;
    
    this.setState((prevState) => {
      const updatedCategoryDetail = {
        ...prevState.categoryDetail,
        isActive: !prevState.categoryDetail.isActive,
      };
      
      return {
        categoryDetail: updatedCategoryDetail,
        [name]: checked,
      };
    });
  };  
  handleChange = (event, data) => {
    // let name = event.target.name;
    let checked = event.target.checked;
    console.log(checked, data, "from switch");

    let postBody = {
      flagId: data._id,
      isReportActive: checked,
    };
    this.setState({ load: true });
    let access_token = localStorage.access_token;
    axios
      .post(`${API_URL}/flagRating/changeFlagReview`, postBody, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({ load: false });
          toasterMessage("success", res.data.message);
          this.getData();
        }
      })
      .catch((error) => {
        this.setState({ load: false });
        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch(() => {
        this.setState({ load: false });
        toasterMessage("error", "Something bad happened");
      });
  };

  handleStatus = (event, rowData) => {
    rowData.isActive = !rowData.isActive;
    let obj = {
      id: rowData._id,
      categoryName: rowData.categoryName,
      isActive: event.target.checked,
    };
    this.setState({ isDisabled: true });
    this.props.editStatus(obj, (result) => {
      if (result) {
        let thiss = this;
        setTimeout(function () {
          //your code to be executed after 1 second
          thiss.setState({ isDisabled: false });
        }, 3000);
      }
    });
  };
  none = () => {};
  applyFilters = () => {
    if (this.state.startDate && this.state.endDate) {
      let filteredAlldata = this.state.filteredAlldata;
  
      let fromDate = moment(this.state.startDate).format("DD-MM-YYYY");
      let toDate = moment(this.state.endDate).format("DD-MM-YYYY");
      filteredAlldata = filteredAlldata.filter((emp) => {
        let date = moment(emp.created_at).format("DD-MM-YYYY");
        if (fromDate <= date && toDate >= date) {
          return emp;
        }
        return null; // Add this line to return null for non-matching elements
      });
      this.setState({ alldata: filteredAlldata });
    }
  };  
  resetFilters = () => {
    this.setState({ startDate: "", endDate: "" }, () => {
      this.getData();
    });
  };

  handleModalClose = () => {
    this.setState({ modal: false, errorAdd: "", errorEdit: "" });
    this.props.getCategories();
  };

  isOutsideRange = (day) => !isInclusivelyBeforeDay(day, moment());

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <NavAdmin
          handleDrawerOpen={this.handleDrawerOpen}
          handleDrawerClose={this.handleDrawerClose}
          open={this.state.open}
          title={this.state.title}
          activeValue={5}
        />
        <main
          className={clsx(classes.content, {
            [classes.appBarShift]: this.state.open,
          })}
        >

          <Box mb={7}>
            {/* <Typography variant="body2"  color="textSecondary" className="ListHeading " component="p">
    <Icon> <img className={classes.imageIcon} src={leftArrow}/></Icon> 
    Manage Category
            </Typography> */}
          </Box>
          {this.state.load === true ? (
            <Loader />
          ) : (
            <>
              <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={12}
                    className="calender"
                    style={{ position: "relative" }}
                  >
                     <Typography variant="body2" color="textSecondary" component="p">
                <span className="heading1 mr-r-20">Choose Date </span>
              <DateRangePicker
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                onDatesChange={({ startDate, endDate }) =>
                  this.setState({ startDate, endDate }, () => {
                    this.applyFilters();
                  })
                } // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(focusedInput) =>
                  this.setState({ focusedInput })
                } // PropTypes.func.isRequired,
                endDatePlaceholderText={"End Date"}
                style={{ position: "absolute", zIndex: "999" }}
                startDatePlaceholderText={"Start Date"}
                displayFormat={"MMM DD, YYYY"}
                isOutsideRange={this.isOutsideRange}
                minimumNights={0}
              />

              <Button
                class="btn-doc heading1"
                style={{ padding: "19px 44px", float: "right" }}
                onClick={(e) => this.resetFilters(e)}
              >
                Clear Filter
              
              </Button>
                    </Typography>
                  </Grid>
                  </Grid>

              <MaterialTable
                icons={tableIcons}
                title="Review Management"
                columns={[
                  {
                    title: "Id",
                    field: "flagRatingId",
                    cellStyle: { width: "20%" },
                  },
                  {
                    title: "Reporter's Email",
                    field: "reportedBy[email]",
                    cellStyle: { width: "20%" },
                  },
                  {
                    title: "Original Review",
                    field: "ratingId[comment]",
                    cellStyle: { width: "20%", textAlign: "center",wordBreak:"break-all" },
                    headerStyle: {
                      textAlign: "center",
                    },
                  },

                  {
                    title: "Report Reason",
                    field: "reportReasonId[flagReason]",
                    cellStyle: { width: "20%", textAlign: "center" },
                    headerStyle: {
                      textAlign: "center",
                    },
                  },
                  {
                    title: "Report Description",
                    field: "reasonComment",
                    cellStyle: { width: "20%", textAlign: "center" },
                    headerStyle: {
                      textAlign: "center",
                    },
                  },
                ]}
                data={this.state.alldata}
                localization={{
                  header: {
                    actions: "Status",
                  },
                }}
                actions={[
                  (rowData) => ({
                    icon: () => (
                      <Switch
                        checked={rowData.isReportActive}
                        onChange={(event) => {
                          this.handleChange(event, rowData);
                        }}
                        name="checkedE"
                        className="main-switch mr-t-0"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    ),
                    tooltip: "Approve",
                    // onClick: (event, rowData) => {
                    //   this.handleChange(event, rowData);
                    // },
                  }),
                ]}
                options={{
                  actionsColumnIndex: -1,
                  rowStyle: (x) => {
                    if (x.tableData.id % 2) {
                      return {
                        backgroundColor: "#ebf5ff",
                        color: "#005a98",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "OpenSans-Regular",
                      };
                    } else {
                      return {
                        color: "#005a98",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "OpenSans-Regular",
                      };
                    }
                  },
                  actionsCellStyle: {
                   
                    width: "100%",
                  },
                  headerStyle: {
                    backgroundColor: "#ffcf5c",
                    color: "#005a98",
                    fontSize: "16px",
                    fontWeight: "700",
                  },
                  actionStyle: { textAlign: "center" },
                  pageSize: 20,
                  emptyRowsWhenPaging: false,
                  pageSizeOptions: [5, 10, 20, 30, 40, 50], // rows selection options
                }}
              />
            </>
          )}
          <Dialog
            open={this.state.modal}
            onClose={this.handleModalClose}
            aria-labelledby="form-dialog-title"
            className="dialog-main-div"
          >
            {this.state.modalStatus && this.state.modalStatus === "add" ? (
              <DialogTitle id="form-dialog-title" class="modal-header">
                Add New Category
              </DialogTitle>
            ) : (
              ""
            )}
            {this.state.modalStatus && this.state.modalStatus === "view" ? (
              <DialogTitle id="form-dialog-title" class="modal-header">
                View Category
              </DialogTitle>
            ) : (
              ""
            )}
            {this.state.modalStatus && this.state.modalStatus === "edit" ? (
              <DialogTitle id="form-dialog-title" class="modal-header">
                Edit Category
              </DialogTitle>
            ) : (
              ""
            )}
            <DialogContent className="dialog-content pd-70">
              <Grid container>
                <Grid item className="mr-b-40">
                  <FormControl>
                    <InputLabel
                      shrink
                      htmlFor="categoryName"
                      className="input-label label-font"
                    >
                      Category Name
                    </InputLabel>
                    {this.state.modalStatus &&
                    this.state.modalStatus === "view" ? (
                      <BootstrapInput
                        // disabled
                        readOnly
                        value={
                          this.state.categoryDetail
                            ? this.state.categoryDetail.categoryName
                            : ""
                        }
                        placeholder="Enter Category Name"
                        id="categoryName"
                        className={clsx(classes.inputStyle, classes.inputWidth)}
                      />
                    ) : (
                      <BootstrapInput
                        value={this.state.categoryName}
                        placeholder="Enter Category Name"
                        id="categoryName"
                        className={clsx(classes.inputStyle, classes.inputWidth)}
                        inputProps={{
                          maxLength: 25,
                        }}
                        onChange={(event) => {
                          this.setState({
                            errorAdd: "",
                            error: false,
                            categoryName: event.target.value,
                          });
                          if (this.state.modalStatus === "add") {
                            // if (event.target.value.length == 0) {
                            //   this.setState({ errorAdd: 'This field is required', error: true });
                            // }
                            // // else if (event.target.value.length != 0 && event.target.value.length > 25) {
                            // //   this.setState({ errorAdd: 'Category name can be max. 25 char', error: true });
                            // // }
                            // else {
                            //   this.setState({ errorAdd: '', error: false, categoryName: (event.target.value) });
                            // }
                            if (
                              event.target.value.length !== 0 &&
                              new RegExp("^[a-zA-Z0-9 ]*$").test(
                                event.target.value
                              ) === true
                            ) {
                              this.setState({
                                errorAdd: "",
                                error: false,
                                categoryName: event.target.value,
                              });
                            } else {
                              this.setState({
                                errorAdd: "Please enter valid category name",
                                error: true,
                              });
                            }
                            if (event.target.value.length === 0) {
                              this.setState({
                                errorAdd: "This field is required",
                                error: true,
                              });
                            }
                          }
                          if (this.state.modalStatus === "edit") {
                            if (
                              event.target.value.length !== 0 &&
                              new RegExp("^[a-zA-Z0-9 ]*$").test(
                                event.target.value
                              ) === true
                            ) {
                              this.setState({
                                errorEdit: "",
                                error: false,
                                categoryName: event.target.value,
                              });
                            } else {
                              this.setState({
                                errorEdit: "Please enter valid category name",
                                error: true,
                              });
                            }
                            if (event.target.value.length === 0) {
                              this.setState({
                                errorEdit: "This field is required",
                                error: true,
                              });
                            }
                          }
                        }}
                      />
                    )}
                    {this.state.modalStatus &&
                    this.state.modalStatus === "add" ? (
                      this.state.errorAdd === "" ? (
                        <FormHelperText>25 character max.</FormHelperText>
                      ) : (
                        <FormHelperText error>
                          {this.state.errorAdd}
                        </FormHelperText>
                      )
                    ) : (
                      ""
                    )}
                    {this.state.modalStatus &&
                    this.state.modalStatus === "edit" ? (
                      this.state.errorEdit === "" ? (
                        <FormHelperText>25 character max.</FormHelperText>
                      ) : (
                        <FormHelperText error>
                          {this.state.errorEdit}
                        </FormHelperText>
                      )
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <InputLabel shrink={true} className="input-label label-font">
                    Activate Category
                  </InputLabel>

                  {this.state.modalStatus &&
                  this.state.modalStatus === "add" ? (
                    <Switch
                      checked={this.state.checkedA}
                      onChange={(event) => {
                        this.handleAddChange(event);
                      }}
                      name="checkedA"
                      className="main-switch"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  ) : (
                    ""
                  )}

                  {this.state.modalStatus &&
                  this.state.modalStatus === "view" ? (
                    <Switch
                      // disabled
                      readOnly
                      checked={this.state.categoryDetail.isActive}
                      // checked={this.state.checkedA}
                      className="main-switch"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  ) : (
                    ""
                  )}

                  {this.state.modalStatus &&
                  this.state.modalStatus === "edit" ? (
                    <Switch
                      checked={this.state.categoryDetail.isActive}
                      onChange={(event) => {
                        this.handleEditChange(event);
                      }}
                      name="checkedE"
                      className="main-switch"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </main>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    categoryList: state.category.categoryList,
  };
}

export default connect(
  mapStateToProps,
  {}
)(withStyles(useStyles)(ReportedReviewManagement));
