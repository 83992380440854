/*
     Login Api Constants
*/
import {
    actionCreator,
    createRequestActionTypes,
    jsonApiHeader
} from './../../../actions/utilAction';
export {
    actionCreator,
    jsonApiHeader
};

export const forgotActionTypes = {
    forgot_password: createRequestActionTypes('FORGOT_PASSWORD'),
};
