import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core/";
import {
  InputBase,
  InputLabel,
  FormControl,
  Switch,
  FormHelperText,
  Button,
  Box,
  Grid,
} from "@material-ui/core";
import { tableIcons } from "../organization/constants/iconConstant";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import MaterialTable from "material-table";
import autoBind from "react-autobind";
import { getOrganizations } from "../organization/actions/organizationAction";
import {
  addUser,
  getUsers,
  editStatus,
  getUserhistory,
} from "./actions/userAction";
import NavAdmin from "../../../component/NavAdmin.js";
import { SelectValue } from "../../practitioner/Documents/constants/dropDownStyled";
// import CircularProgress from "@material-ui/core/CircularProgress";
import Loader from "../../../component/Loader";
import CloseIcon from "@material-ui/icons/Close";

const drawerWidth = 316;
const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  rootCard: {
    maxWidth: 345,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  appBarShift: {
    marginLeft: "150px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    position: "relative",
    top: "10rem",
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  inputStyle: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    borderRadius: 4,
    position: "relative",
    border: "1px solid #BFBFBF",
    fontSize: 12,
    minWidth: "230px",
    height: "34px",
    fontFamily: "OpenSans-Regular",
    color: "#005A98",
    fontWeight: "bold",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    padding: "0px",
    width: "100%",
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#52d869",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#FFC805",
    color: " #005A98",
    fontFamily: "OpenSans-Regular",
    fontWeight: "700",
    fontSize: "18px",
  },
});
const BootstrapInput = withStyles((theme) => ({
  minWidth: "413px",
  width: "100%",
  marginTop: "25px",
}))(InputBase);

class ManageOrganization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      modal: false,
      checkedA: false,
      checkedE: false,
      title: "Manage User",
      modalStatus: "",
      organizationList: [],
      organizationDetail: "",
      organizationName: "",
      organizationId: "",
      errorAdd: "",
      error: false,
      preview: "",
      userEmail: "",
      userName: "",
      Designation: "",
      organization: "",
      errorDesignation: "",
      errorEmail: "",
      errorName: "",
      errorOrganization: "",
      errorPreview: "",
      organisationId: "",
      userList: [],
      load: true,
      imageName: "",
      isDisabled: false,
      isVisible: false,
    };
    autoBind(this);
  }

  componentDidMount() {
    // console.log("this.props.location.state", this.props.location.state)
    this.setState({ load: true });
    this.props.getUsers();
    this.props.getOrganizations();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.userList !== state.userList) {
      return {
        userList: props.userList,
        load: false,
      };
    }
    if (props.organizationList !== state.organizationList) {
      let filterOrg = props.organizationList.filter(function (el) {
        // console.log("el", el)
        return el.isActive !== false;
      });
      return {
        organizationList: filterOrg,
      };
    }
    // Return null if the state hasn't changed
    return null;
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleAddChange = (event) => {
    let name = event.target.name;
    let checked = event.target.checked;
    this.setState((prevState) => {
      return { [name]: checked };
    });
  };

  handleChange = (event) => {
    let name = event.target.name;
    let checked = event.target.checked;
    this.setState((prevState) => {
      return { [name]: checked };
    });
  };

  handleStatus = (event, rowData) => {
    rowData.isActive = !rowData.isActive;
    let obj = {
      id: rowData._id,
      isActive: event.target.checked,
    };
    this.setState({ isDisabled: true });
    // return false
    this.props.editStatus(obj, (result) => {
      // console.log("result-----------------",result)
      if (result !== "success") {
        // console.log("if")
        rowData.isActive = !rowData.isActive;
        this.setState({
          checkedE: !rowData.isActive,
        });
        let thiss = this;
        setTimeout(function () {
          //your code to be executed after 1 second
          thiss.setState({ isDisabled: false });
        }, 3000);
      } else {
        // console.log("else")
        let thiss = this;
        setTimeout(function () {
          //your code to be executed after 1 second
          thiss.setState({ isDisabled: false });
        }, 3000);
      }
    });
  };
  none = () => {};

  addUser = () => {
    // return false
    if (
      this.state.userEmail !== "" &&
      this.state.userName !== "" &&
      this.state.organisationId !== "" &&
      this.state.Designation !== "" &&
      this.state.errorPreview === "" &&
      this.state.error === false &&
      this.state.preview === ""
    ) {
      let obj = {
        isActive: this.state.checkedA,
        email: this.state.userEmail,
        userName: this.state.userName,
        organisationId: this.state.organisationId,
        designation: this.state.Designation,
      };
      this.props.addUser(obj);
      this.setState({ modal: false, isVisible: false });
    }
    if (
      this.state.userEmail !== "" &&
      this.state.userName !== "" &&
      this.state.organisationId !== "" &&
      this.state.Designation !== "" &&
      this.state.errorPreview === "" &&
      this.state.error === false &&
      this.state.preview !== ""
    ) {
      let obj = {
        isActive: this.state.checkedA,
        email: this.state.userEmail,
        userName: this.state.userName,
        organisationId: this.state.organisationId,
        designation: this.state.Designation,
        userImage: this.state.preview,
      };
      // console.log("add")
      // return false
      this.props.addUser(obj);
      this.setState({ modal: false, isVisible: false });
    }
    if (this.state.userEmail === "") {
      this.setState({ errorEmail: "This field is required", error: true });
    }
    if (this.state.userName === "") {
      this.setState({ errorName: "This field is required", error: true });
    }
    if (this.state.Designation === "") {
      this.setState({
        errorDesignation: "This field is required",
        error: true,
      });
    }
    if (this.state.organisationId === "") {
      this.setState({
        errorOrganization: "Please select min one organization",
        error: true,
      });
    }
    // if (this.state.preview === '') {
    //     this.setState({ errorPreview: "Please select image." });
    // }
  };

  handleModalOpen = (data, organizationDetails) => {
    this.setState({
      modal: true,
      modalStatus: data,
      organizationDetail: organizationDetails,
    });
  };

  handleAddModal = (data) => {
    this.setState({
      modal: true,
      isVisible: false,
      modalStatus: data,
      imageName: "",
      userEmail: "",
      userName: "",
      Designation: "",
      organisationId: "",
      preview: "",
      error: false,
    });
  };

  handleModalClose = () => {
    this.setState({
      modal: false,
      errorDesignation: "",
      errorEmail: "",
      errorName: "",
      errorOrganization: "",
      errorPreview: "",
    });
    this.props.getOrganizations();
  };

  selectValue(value) {
    this.setState({
      organisationId: value.target.value,
    });
  }

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  uploadImage = (e) => {
    // console.log("e.target.files[0].name",e.target.files[0].name)
    if (e.target.files.length) {
      let files = e.target.files[0];
      const fsize = files.size;
      const file = Math.round(fsize / 1024);
      if (file >= 5000) {
        this.setState({
          errorPreview:
            "The maximum allowed file size for 'Upload a Image' is '5 Mb'",
          imageName: e.target.files[0].name,
          isVisible: true,
        });
      } else {
        this.setState({
          errorPreview: "",
          imageName: e.target.files[0].name,
          isVisible: true,
        });
      }
      if (!files) {
        this.setState({ errorPreview: "Please select image." });
      }
      if (!files.name.match(/\.(jpeg|png)$/)) {
        this.setState({
          errorPreview:
            "Please upload a file in a valid format. We currently support: PNG, JPEG",
          isVisible: true,
        });
      }
      this.getBase64(files, (result) => {
        let idCardBase64 = result;
        // let b64 = btoa(unescape(encodeURIComponent(idCardBase64)));
        let splt = idCardBase64.split(",");
        // console.log("idCardBase64",splt[1])
        this.setState({
          preview: splt[1],
        });
      });
    }
  };
  removeImage = () => {
    this.setState({
      preview: "",
      imageName: "",
      errorPreview: "",
      isVisible: false,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <NavAdmin
          handleDrawerOpen={this.handleDrawerOpen}
          handleDrawerClose={this.handleDrawerClose}
          open={this.state.open}
          title={this.state.title}
          activeValue={2}
        />
        <main
          className={clsx(classes.content, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Box mb={7}></Box>
          <Button
            class="btn-doc heading1"
            style={{ marginBottom: "20px" }}
            // onClick={this.handleModalOpen}
            onClick={this.handleAddModal.bind(this, "add")}
            disableTouchRipple="false"
          >
            Add New User
          </Button>
          {this.state.load === true ? (
            <Loader />
          ) : (
            <MaterialTable
              icons={tableIcons}
              title="User List"
              columns={[
                { title: "Id", field: "userId", cellStyle: { width: "10%" } },
                { title: "Email", field: "email", cellStyle: { width: "20%" } },
                {
                  title: "Name",
                  field: "userName",
                  cellStyle: { width: "20%" },
                },
                {
                  title: "Organization",
                  field: "organisationId[organisationName]",
                  cellStyle: { width: "20%" },
                },
                {
                  title: "Designation",
                  field: "designation",
                  cellStyle: { width: "10%" },
                },
              ]}
              data={this.state.userList}
              localization={{
                header: {
                  actions: "Status",
                },
              }}
              actions={[
                (rowData) => ({
                  icon: () => (
                    <Switch
                      checked={rowData.isActive}
                      // disabled={this.state.isDisabled}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                      name="checkedE"
                      className="main-switch mr-t-0"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  ),
                  tooltip: "Save User",
                  onClick: (event, rowData) => {
                    this.state.isDisabled === false
                      ? this.handleStatus(event, rowData)
                      : this.none();
                  },
                }),
                {
                  icon: () => (
                    <VisibilityOutlinedIcon style={{ color: "#005a98" }} />
                  ),
                  tooltip: "Show User Detail",
                  // onClick: () => this.handleModalOpen()
                  onClick: (event, rowData) => {
                    // this.handleModalOpen.bind(this,'view')
                    // this.handleModalOpen('view', rowData)
                    this.props.history.push("/docList/" + rowData._id);
                  },
                },
              ]}
              options={{
                actionsColumnIndex: -1,
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return {
                      backgroundColor: "#ebf5ff",
                      color: "#005a98",
                      fontSize: "12px",
                      fontWeight: "bold",
                      fontFamily: "OpenSans-Regular",
                    };
                  } else {
                    return {
                      color: "#005a98",
                      fontSize: "12px",
                      fontWeight: "bold",
                      fontFamily: "OpenSans-Regular",
                    };
                  }
                },
                //         actionsCellStyle: { display: "flex", justifyContent: "center", width: "100%" },
                //         headerStyle: {
                //             backgroundColor: "#ffcf5c",
                //             color: "#005a98",
                //             fontSize: "16px",
                //             fontWeight: "700",
                //             color: "#005a98",
                //             fontFamily: "OpenSans-Regular",
                //         },
                //         actionStyle: { textAlign: "center" },
                //         pageSize: 20,
                //         pageSizeOptions: [5, 10, 20, 30, 40, 50],    // rows selection options

                //     }}
                // />}
                //         }
                //         }

                //     },
                
                headerStyle: {
                  backgroundColor: "#ffcf5c",
                  color: "#005a98",
                  fontSize: "16px",
                  fontWeight: "700",
                  fontFamily: "OpenSans-Regular",
                },             
                actionStyle: { padding:"0px 40px",color:"red",textAlign:"justify" },
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [5, 10, 20, 30, 40, 50], // rows selection options
              }}
            />
          )}

          <Dialog
            open={this.state.modal}
            onClose={this.handleModalClose}
            aria-labelledby="form-dialog-title"
            className="dialog-main-div"
          >
            {this.state.modalStatus && this.state.modalStatus === "add" ? (
              <DialogTitle id="form-dialog-title" class="modal-header">
                Add New User
              </DialogTitle>
            ) : (
              ""
            )}
            <DialogContent className="dialog-content pd-30">
              <Grid container>
                <Grid item xs={6}>
                  <FormControl>
                    <InputLabel
                      shrink
                      htmlFor="categoryName"
                      className="input-label label-font"
                    >
                      User's Email
                    </InputLabel>
                    <BootstrapInput
                      value={this.state.userEmail}
                      placeholder="Enter User's Email"
                      id="categoryName"
                      className={clsx(classes.inputStyle, classes.inputWidth)}
                      inputProps={{
                        maxLength: 50,
                      }}
                      onChange={(event) => {
                        this.setState({
                          errorEmail: "",
                          error: false,
                          userEmail: event.target.value,
                        });
                        if (
                          event.target.value.length !== 0 &&
                          new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$").test(
                            event.target.value
                          ) === true
                        ) {
                          this.setState({
                            errorEmail: "",
                            error: false,
                            [event.target.name]: event.target.value,
                          });
                        } else {
                          this.setState({
                            errorEmail: "Please enter valid email",
                            error: true,
                          });
                        }
                        if (event.target.value.length === 0) {
                          this.setState({
                            errorEmail: "This field is required",
                            error: true,
                          });
                        }
                      }}
                    />
                    <FormHelperText error style={{ marginBottom: "20px" }}>
                      {this.state.errorEmail}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <InputLabel
                      shrink
                      htmlFor="categoryName"
                      className="input-label label-font"
                    >
                      User's Name
                    </InputLabel>
                    <BootstrapInput
                      value={this.state.userName}
                      placeholder="Enter User's Name"
                      id="categoryName"
                      className={clsx(classes.inputStyle, classes.inputWidth)}
                      inputProps={{
                        maxLength: 25,
                      }}
                      onChange={(event) => {
                        this.setState({
                          errorName: "",
                          error: false,
                          userName: event.target.value,
                        });
                        // if (event.target.value.length == 0) {
                        //     this.setState({ errorName: 'This field is required', error: true });
                        // }
                        // else {
                        //     this.setState({ errorName: '', error: false, userName: (event.target.value) });
                        // }
                        if (
                          event.target.value.length !== 0 &&
                          // (new RegExp("^[a-zA-Z0-9]*$").test(event.target.value) == true)) {
                          new RegExp("^[a-zA-Z0-9 ]*$").test(
                            event.target.value
                          ) === true
                        ) {
                          this.setState({
                            errorName: "",
                            error: false,
                            [event.target.name]: event.target.value,
                          });
                        } else {
                          this.setState({
                            errorName: "Please enter valid name",
                            error: true,
                          });
                        }
                        if (event.target.value.length === 0) {
                          this.setState({
                            errorName: "This field is required",
                            error: true,
                          });
                        }
                      }}
                    />
                    <FormHelperText style={{ marginBottom: "22px" }} error>
                      {this.state.errorName}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6} className="mr-b-25">
                  <FormControl>
                    <InputLabel
                      shrink={true}
                      className="label-font input-label "
                    >
                      Select Organization
                    </InputLabel>
                    <SelectValue
                      onChange={(event) => {
                        // console.log("event",event.target.value)
                        if (event.target.value.length === 0) {
                          this.setState({
                            errorOrganization:
                              "Please select anyone organization",
                            error: true,
                          });
                        } else {
                          this.setState({
                            organisationId: event.target.value,
                            errorOrganization: "",
                          });
                        }
                      }}
                      style={{ height: "34px", minWidth: "220px" }}
                    >
                      <option value="" hidden>
                        Select Organization
                      </option>
                      {this.state.organizationList
                        ? this.state.organizationList.map((x, index) => (
                            <option value={x._id}>{x.organisationName}</option>
                          ))
                        : ""}
                    </SelectValue>
                  </FormControl>
                  <FormHelperText style={{ marginTop: "25px" }} error>
                    {this.state.errorOrganization}
                  </FormHelperText>
                </Grid>

                <Grid item xs={6}>
                  <FormControl>
                    <InputLabel
                      shrink
                      htmlFor="categoryName"
                      className="input-label label-font"
                    >
                      Designation
                    </InputLabel>
                    <BootstrapInput
                      value={this.state.Designation}
                      placeholder="Enter Designation"
                      id="categoryName"
                      className={clsx(classes.inputStyle, classes.inputWidth)}
                      inputProps={{
                        maxLength: 25,
                      }}
                      onChange={(event) => {
                        this.setState({
                          errorName: "",
                          error: false,
                          Designation: event.target.value,
                        });

                        if (event.target.value.length === 0) {
                          this.setState({
                            errorDesignation: "This field is required",
                            error: true,
                          });
                        } else {
                          this.setState({
                            errorDesignation: "",
                            error: false,
                            Designation: event.target.value,
                          });
                        }
                      }}
                    />
                    <FormHelperText style={{ marginBottom: "20px" }} error>
                      {this.state.errorDesignation}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={6}>
                  <InputLabel shrink={true} className="input-label label-font">
                    Activate User
                  </InputLabel>
                  {this.state.modalStatus &&
                  this.state.modalStatus === "add" ? (
                    <Switch
                      checked={this.state.checkedA}
                      onChange={(event) => {
                        this.handleAddChange(event);
                      }}
                      name="checkedA"
                      className="main-switch add-user-switch"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={6} sm={6}>
                  <InputLabel shrink={true} className="input-label label-font">
                    Upload Image
                  </InputLabel>
                  {/* <Box mt={2}>
                                            <Button class="btn-doc heading1">
                                                Upload Image
                                            </Button>
                                        </Box> */}
                  <input
                    type="file"
                    id="upload-button"
                    // accept=".png,.jpg,.jpeg"
                    style={{ color: "transparent" }}
                    // style={{ display: "none" }}
                    onChange={this.uploadImage}
                  />

                  <FormHelperText className="image-name text-image">
                    {this.state.imageName}{" "}
                    {this.state.isVisible === true ? (
                      <Button onClick={this.removeImage} className="btn-close"  disableTouchRipple="false">
                        <CloseIcon />
                      </Button>
                    ) : (
                      ""
                    )}
                  </FormHelperText>
                  {/* <FormHelperText error>{this.state.errorPreview}</FormHelperText> */}
                  <FormHelperText style={{ marginBottom: "20px" }} error>
                    {this.state.errorPreview}
                  </FormHelperText>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                class="btn-doc-close heading3"
                style={{ marginBottom: "20px", marginRight: "20px" }}
                onClick={this.handleModalClose}
                disableTouchRipple="false"
              >
                Close
              </Button>
              {this.state.modalStatus && this.state.modalStatus === "add" ? (
                <Button
                  class="btn-doc heading1"
                  style={{ marginBottom: "20px", marginRight: "20px" }}
                  onClick={this.addUser}
                  disableTouchRipple="false"
                >
                  Submit
                </Button>
              ) : (
                ""
              )}
            </DialogActions>
          </Dialog>
        </main>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    organizationList: state.organization.organizationList,
    userList: state.user.userList,
  };
}

export default connect(mapStateToProps, {
  getOrganizations,
  editStatus,
  addUser,
  getUsers,
  getUserhistory,
})(withStyles(useStyles)(ManageOrganization));
