import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  InputBase,
  TextareaAutosize,
  InputLabel,
  FormControl,
  Switch,
  TextField,
  FormHelperText,
  NativeSelect,
  Icon,
  Button,
  Box,
  Grid,
  Typography,
  MenuItem,
  Menu,
  Select,
} from "@material-ui/core";
import cloudIcon from "../../../assets/cloud-upload-fill.svg";
import docIcon from "../../../assets/doc.svg";
import LinearProgress from "@material-ui/core/LinearProgress";
import closeIcon from "../../../assets/Close.svg";
import leftArrow from "../../../assets/left.svg";
import downloadFrame from "../../../assets/Frame.png";
import Nav from "../../../component/Nav.js";
import { SelectValue } from "./constants/dropDownStyled";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core/";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { DropzoneArea } from "material-ui-dropzone";
import { Link } from "react-router-dom";
import {
  getcategoryPrac,
  getPractitioner,
} from "../../admin/user/actions/userAction";
import { getOrganizations } from "../../admin/organization/actions/organizationAction";
import { editDocuments } from "./actions/documentActions";
import Frame from "../../../assets/Frame.svg";
import { ja } from "date-fns/locale";

const icon = (
  <CheckBoxOutlineBlankIcon fontSize="small" style={{ fill: "#005A98" }} />
);
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const drawerWidth = 316;
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#F3F3F3",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: " #FFA800",
  },
}))(LinearProgress);

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  rootCard: {
    maxWidth: 345,
  },
  widthCategory: {
    [theme.breakpoints.up("md")]: {
      width: "290px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "230px",
    },
  },
  alignGrid: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "18px",
    },
  },
  uploadIconSize: {
    backgroundImage: require("../../../assets/cloud-upload-fill.svg"),
  },
  marginDropZone: {
    margin: "40px 114px",
  },
  alignCancel: {
    [theme.breakpoints.down("md")]: {
      paddingTop: "10px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "20px",
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  appBarShift: {
    marginLeft: "150px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    position: "relative",
    top: "10rem",
  },
  inputStyle: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFFFFF",
    border: "1px solid #BFBFBF",
    fontSize: 16,
    fontFamily: "OpenSans-Regular",
    color: "#005A98",
    fontWeight: "bold",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    padding: "0px",
    width: "100%",
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  selectOption: {
    minWidth: "208px",
    height: "50px",
  },
  inputWidth: {
    height: "50px",
  },
  textAreWidth: {
    [theme.breakpoints.down("md")]: {
      minHeight: "180px",
      minWidth: "250px",
      fontSize: "12px",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "129px",
      minWidth: "500px",
    },
  },
  alignSubmit: {
    [theme.breakpoints.down("md")]: {
      marginBottom: "20px",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  dropdownStyle: {
    border: "1px solid #FFC805",
    boxShadow: "0px 4px 24px rgba(0, 90, 152, 0.05)",
    borderRadius: "4px",
    backgroundColor: "#ffffff",
  },
  focused: {},
  iconOpen: {},
});
const BootstrapInput = withStyles((theme) => ({
  minWidth: "413px",
  width: "100%",
}))(InputBase);

class editDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isPrivate: false,
      title: "My Documents",
      documentName: "",
      errorCategory: "",
      categoryId: "",
      description: "",
      files: [],
      errorName: "",
      errorDescription: "",
      selectedUser: [],
      errorShare: "",
      errorDoc: "",
      buttonDisabled: false,
      prevDocData: "",
      sharedWith: props.location.state.data.sharedWith,
      sharedWithOrg: props.location.state.data.sharedWithOrganisation,
      showError: false,
      sharedDocs: [],
      selectedOraganisation: [],
      organisationList: [],
      selectedAllUsers: false,
      selectedAllOrganisation: false,
    };
  }

  componentDidMount() {
    console.log(this.props, "props from edi");
    this.props.getcategoryPrac();
    this.props.getPractitioner();
    this.props.getOrganizations();
    this.setData();
    this.checkForPrefilled();
  }

  setData = () => {
    let data = this.props.location.state.data;
    console.log(data.sharedWith, "shared with");
    let tempArr = [];
    // data.sharedWith.map((item) => {
    //   this.state.userList.map((users) => {
    //     if (item._id == users._id) {
    //       users.checked = true;
    //     }
    //   });
    // });

    this.setState(
      {
        documentName: data.documentName,
        isPrivate: data.isPrivate,
        description: data.description,
        categoryName: data.categoryId.categoryName,
        categoryId: data.categoryId._id,

        selectedOraganisation: data.sharedWithOrganisation.map(
          (item) => item._id
        ),
        // sharedDocs: data.sharedWithOrganisation
        // sharedWith: tempArr,
      },
      () => console.log(this.state.sharedWith, this.state, "from main")
    );
  };

  static getDerivedStateFromProps(props, state) {
    if (props.userList !== state.userList) {
      return {
        userList: props.userList,
      };
    }
    if (props.categoryList !== state.categoryList) {
      return {
        categoryList: props.categoryList,
      };
    }
    // Return null if the state hasn't changed
    return null;
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  handleChange = (event) => {
    if (event.target.checked == true) {
      this.setState({ errorShare: "" });
    }
    let name = event.target.name;
    if (name === "isPrivate") {
      this.setState({ selectedOraganisation: [], sharedDocs: [] });
    }
    let checked = event.target.checked;
    this.setState((prevState) => {
      return { [name]: checked };
    });
  };

  handleModalOpen = () => {
    this.setState({ showError: true });
    const {
      selectedUser,
      documentName,
      categoryId,
      errorShare,
      description,
      errorDescription,
      errorDoc,
      isPrivate,
      selectedOraganisation,
    } = this.state;
    if (isPrivate == false && (selectedUser.length == 0 || this.state.selectedAllUsers==false)) {
      this.setState({
        errorShare: "Please select min one user to share",
        error: true,
      });
    }
    if (
      documentName != "" &&
      categoryId != "" &&
      errorShare == "" &&
      description != "" &&
      errorDescription == "" &&
      errorDoc == ""
    ) {
      this.setState({
        buttonDisabled: true,
      });
      console.log("$");
      // let formData = new FormData();
      // formData.append("documentName", this.state.documentName);
      // formData.append("categoryId", this.state.categoryId);
      // formData.append("isPrivate", this.state.isPrivate);
      // formData.append(
      //   "sharedWith",
      //   this.state.isPrivate == false
      //     ? JSON.stringify(this.state.selectedUser)
      //     : JSON.stringify([])
      // );
      // formData.append("description", this.state.description);
      // return false
      if (!isPrivate && selectedOraganisation?.length < 1) return;
      let data = this.props.location.state.data;
      let body = {
        documentName: documentName,
        categoryId: categoryId,
        isPrivate: isPrivate,
        description: description,
        sharedWith: isPrivate == false ? selectedUser : [],
        sharedWithOrganisation: isPrivate == false ? selectedOraganisation : [],
        id: data._id,
        isEdit: false,
      };
      if (
        documentName !== data.documentName ||
        description !== data.description ||
        categoryId !== data.categoryId._id
      ) {
        body.isEdit = true;
      }
      this.props.editDocuments(body, (result) => {
        // console.log("result", result)
        if (result == "success") {
          this.setState({ modal: true });
        } else {
          this.setState({
            buttonDisabled: false,
          });
        }
      });
    }
    if (documentName == "") {
      this.setState({ errorName: "This field is required", error: true });
    }
    if (description == "") {
      this.setState({
        errorDescription: "This field is required",
        error: true,
      });
    }
    if (categoryId == "") {
      this.setState({
        errorCategory: "Please select anyone category",
        error: true,
      });
    }
  };

  handleModalClose = (data) => {
    this.setState({ modal: false });
  };

  onTagsChange = (event, values) => {
    let arr = [];
    values.map((x, index) => {
      arr.push(x._id);
    });
    console.log(arr, "here is pree");
    this.setState({ selectedUser: arr });
    // if (arr.length != 0 ) {
    //   this.setState({ errorShare: "" });
    // } else {
    //   this.setState({ errorShare: "Please select min one user to share" });
    // }
  };
  checkForPrefilled = () => {
    let arr = [];
    let orgarr = [];
    this.state.sharedWith.map((item) => {
      arr.push(item._id);
    });
    this.state.sharedWithOrg.map((item) => {
      orgarr.push(item._id);
    });
    this.setState({ selectedUser: arr, selectedOraganisation: orgarr });
  };

  /* function to handle shared with
      me selection
  */
  handleSharedWithDocs = (e, values) => {
    this.setState({
      selectedOraganisation: [...new Set(values.map((item) => item._id))],
      sharedDocs: [],
    });
  };

  handleSelectAllUser = (e) => {
    let arr = [];
    this.setState(
      {
        selectedAllUsers: !this.state.selectedAllUsers,
      },
      () => {
        if (this.state.selectedAllUsers) {
          this.props.userList.map((user) => {
            arr.push(user._id);
          });
          this.setState({ selectedUser: arr });
        } else {
          this.state.sharedWith.map((item) => {
            arr.push(item._id);
          });
          this.setState({ selectedUser: arr }, () => {});
        }
        // this.setState({ selectedUser: arr },()=> console.log(this.state.selectedAllUsers, "from here"));
      }
    );
  };

  handleSelectAllOrganisation = (e) => {
    let arr = [];
    this.setState(
      {
        selectedAllOrganisation: !this.state.selectedAllOrganisation,
      },
      () => {
        if (this.state.selectedAllOrganisation) {
          this.props.organizationList.map((user) => {
            arr.push(user._id);
          });
          this.setState({ selectedOraganisation: arr });
        } else {
          this.state.sharedWithOrg.map((item) => {
            arr.push(item._id);
          });
          this.setState({ selectedOraganisation: arr });
        }
        // this.setState({ selectedUser: arr },()=> console.log(this.state.selectedAllUsers, "from here"));
      }
    );
  };

  render() {
    console.log(this.props.userList, "userList");
    const { classes, organizationList } = this.props;
    const {
      sharedDocs,
      showError,
      selectedOraganisation,
      isPrivate,
    } = this.state;
    console.log(selectedOraganisation);
    const iconDoc = React.createElement(this.props.customIcon, {
      className: classes.uploadIconSize,
    });
    return (
      <div className={classes.root}>
        <Nav
          handleDrawerOpen={this.handleDrawerOpen}
          handleDrawerClose={this.handleDrawerClose}
          open={this.state.open}
          title={this.state.title}
        />
        <main
          className={classes.content}
          className={clsx(classes.content, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          {/* <Typography variant="body2" pb={0} color="textSecondary" className="ListHeading " component="p">
            <Link to={'/MyDocuments'}>   <Icon> <img className={classes.imageIcon} className="arrow-position" src={leftArrow} /></Icon></Link>
    Upload New Document
            </Typography>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <div className="uploadDocDiv uploadDocColor">
                <Grid container className={classes.marginDropZone}>
                  <DropzoneArea
                    onChange={this.handleImage.bind(this)}
                    dropzoneText={["Drag and drop a file here or click", <span style={{ fontSize: "14px" }}> <br />( .JPEG, .PDF, .XLS, .DOC, .PPT, .CSV)</span>, ""]}
                    className="uploadDoc dropzone-div"
                    customIcon={classes.cloudIcon}
                    acceptedFiles={['.xls,.csv,.jpeg,.docx,.ppt,.pdf,.pptx']}
                    // acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                    maxFileSize={50000000}
                    showFileNames
                    showAlerts={false}
                    filesLimit={1}
                    previewGridProps={{ container: { spacing: 1 }, item: { xs: 12 } }}
                  />
                  <FormHelperText className="display-block-center" error>{this.state.errorDoc}</FormHelperText>
                </Grid>

              </div>

            </Grid>
          </Grid> */}
          <div>
            <Grid container>
              <Grid item xs={10} sm={6} lg={6} className={classes.alignGrid}>
                <FormControl className="width-100 padding-right-100">
                  <InputLabel
                    className="label-font input-label "
                    shrink
                    htmlFor="bootstrap-input"
                  >
                    Name
                  </InputLabel>
                  <BootstrapInput
                    value={this.state.documentName}
                    placeholder="Enter Document Name"
                    id="categoryName"
                    className={clsx(classes.inputStyle, classes.inputWidth)}
                    inputProps={{
                      maxLength: 25,
                    }}
                    onChange={(event) => {
                      this.setState({
                        errorName: "",
                        error: false,
                        documentName: event.target.value,
                      });
                      if (event.target.value.length == 0) {
                        this.setState({
                          errorName: "This field is required",
                          error: true,
                        });
                      } else {
                        this.setState({
                          errorName: "",
                          error: false,
                          documentName: event.target.value,
                        });
                      }
                    }}
                  />
                  {this.state.errorName == "" ? (
                    <FormHelperText>25 character max.</FormHelperText>
                  ) : (
                    <FormHelperText error>
                      {this.state.errorName}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={10} sm={5} lg={5} className={classes.alignGrid}>
                <FormControl className="width-100 jj padding-right-100">
                  <InputLabel shrink={true} className="label-font input-label ">
                    Select Category
                  </InputLabel>
                  <SelectValue
                    defaultValue={
                      this.props.location.state.data.categoryId.categoryName
                    }
                    onChange={(event) => {
                      if (event.target.value.length == 0) {
                        this.setState({
                          errorCategory: "Please select anyone category",
                          error: true,
                        });
                      } else {
                        this.setState({
                          errorCategory: "",
                          error: false,
                          categoryId: event.target.value,
                        });
                      }
                    }}
                    // select={this.props.location.state.data.categoryId.categoryName}
                  >
                    <option hidden>Select Data Category</option>

                    {this.state.categoryList
                      ? this.state.categoryList.map((x, index) => (
                          <option
                            selected={
                              this.props.location.state.data.categoryId
                                .categoryName == x.categoryName
                            }
                            value={x._id}
                          >
                            {x.categoryName}
                          </option>
                        ))
                      : ""}
                  </SelectValue>
                  <FormHelperText error style={{ marginTop: "22px" }}>
                    {this.state.errorCategory}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={10} sm={1} lg={1} className={classes.alignGrid}>
                <InputLabel shrink={true} className="label-font input-label">
                  {/* Privacy */}
                  is Private?
                </InputLabel>
                <Switch
                  checked={this.state.isPrivate}
                  onChange={(event) => {
                    this.handleChange(event);
                  }}
                  name="isPrivate"
                  className="main-switch"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </Grid>
              <Grid container>
                <Grid item xs={10} sm={8} lg={8} className={classes.alignGrid}>
                  <FormControl className="width-100 padding-right-100">
                    <InputLabel
                      className="label-font input-label "
                      shrink={true}
                    >
                      Description
                    </InputLabel>
                    <TextareaAutosize
                      aria-label="maximum height"
                      // placeholder="Write Description"
                      // defaultValue=""
                      className={clsx(classes.inputStyle, classes.textAreWidth)}
                      value={this.state.description}
                      placeholder="Write Description"
                      id="description"
                      inputProps={{
                        maxLength: 300,
                      }}
                      onChange={(event) => {
                        this.setState({
                          errorDescription: "",
                          error: false,
                          description: event.target.value,
                        });
                        if (event.target.value.length == 0) {
                          this.setState({
                            errorDescription: "This field is required",
                            error: true,
                          });
                        } else if (event.target.value.length < 301) {
                          console.log("onchange", event.target.value);
                          this.setState({
                            errorDescription: "",
                            error: false,
                            description: event.target.value,
                          });
                        } else {
                          this.setState({
                            errorDescription:
                              "Description can be maximum 300 character long",
                            error: false,
                            description: this.state.description,
                          });
                        }
                      }}
                    />
                    <Grid container>
                      <Grid item xs={10} sm={6} lg={6}>
                        <FormHelperText error>
                          {this.state.errorDescription}
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={10} sm={6}>
                        <FormHelperText
                          className="align-count"
                          style={{ textAlign: "right" }}
                        >{`${this.state.description.length}/300`}</FormHelperText>
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>
                <Grid item xs={10} sm={2} lg={2}>
                  <InputLabel
                    className="label-font input-label"
                    shrink={true}
                    style={{ marginBottom: "12px" }}
                  >
                    Share with
                  </InputLabel>
                  {this.state.isPrivate == !true ? (
                    <div style={{ display: "flex" }}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={this.state.selectedAllUsers}
                        iconStyle={{
                          fill: "#FFC805",
                          outline: "2px solid red",
                        }}
                        onChange={(e) => {
                          this.handleSelectAllUser(e);
                        }}
                        style={{
                          position: "relative",
                          top: "-6px",
                          padding: "0px 7px 0px 0px",
                        }}
                      />
                      <InputLabel
                        className="label-font input-label"
                        shrink={true}
                        style={{ marginBottom: "12px" }}
                      >
                        Select All
                      </InputLabel>
                    </div>
                  ) : null}
                  {this.state.isPrivate == true ||
                  this.state.selectedAllUsers ? (
                    <React.Fragment>
                      <Autocomplete
                        // multiple
                        id="checkboxes-tags-demo"
                        disabled={true}
                        className="auto-check-user inputText fontValue"
                        options={top100Films}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={<Frame />}
                              style={{ border: "1px solid red" }}
                              className={classes.check}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={true}
                            />
                            {option.title}
                          </React.Fragment>
                        )}
                        className={classes.widthCategory}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            shrink={true}
                            placeholder="Select User"
                          />
                        )}
                      />
                    </React.Fragment>
                  ) : (
                    <Autocomplete
                      limitTags={5}
                      multiple
                      id="checkboxes-tags-demo"
                      className="auto-check-user heading-option"
                      options={this.state.userList}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.userName}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={this.state.selectedUser.includes(
                              option._id
                            )}
                            iconStyle={{
                              fill: "#FFC805",
                              outline: "2px solid red",
                            }}
                          />
                          <Grid item sm={6}>
                            <Box
                              component="div"
                              className="headig-option font-size-14"
                              textOverflow="ellipsis"
                            >
                              {option.userName}
                            </Box>
                          </Grid>
                          <Grid item sm={6}>
                            <Box
                              component="div"
                              className="headig-option font-size-12"
                              textOverflow="ellipsis"
                            >
                              {option.email}
                            </Box>
                          </Grid>
                        </React.Fragment>
                      )}
                      defaultValue={this.state.sharedWith}
                      className={classes.widthCategory}
                      onChange={this.onTagsChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          shrink={true}
                          placeholder="Select User"
                        />
                      )}
                    />
                  )}
                  <FormHelperText error>{this.state.errorShare}</FormHelperText>

                  {/* Shared with Organisation starts */}
                  <br />

                  <InputLabel
                    className="label-font input-label"
                    shrink={true}
                    style={{ marginBottom: "12px", width: 165 }}
                  >
                    Share with Organisation
                  </InputLabel>
                  {this.state.isPrivate !== true ? (
                    <div style={{ display: "flex" }}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={this.state.selectedAllOrganisation}
                        iconStyle={{
                          fill: "#FFC805",
                          outline: "2px solid red",
                        }}
                        onChange={(e) => {
                          this.handleSelectAllOrganisation(e);
                        }}
                        style={{
                          position: "relative",
                          top: "-6px",
                          padding: "0px 7px 0px 0px",
                        }}
                      />
                      <InputLabel
                        className="label-font input-label"
                        shrink={true}
                        style={{ marginBottom: "12px" }}
                      >
                        Select All
                      </InputLabel>
                    </div>
                  ) : null}

                  {this.state.selectedAllOrganisation ||
                  this.state.isPrivate == true ? (
                    <Autocomplete
                      // multiple
                      id="checkboxes-tags-demo"
                      disabled={true}
                      className="auto-check-user inputText fontValue"
                      options={top100Films}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.title}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={<Frame />}
                            style={{ border: "1px solid red" }}
                            className={classes.check}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={true}
                          />
                          {option.title}
                        </React.Fragment>
                      )}
                      className={classes.widthCategory}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          shrink={true}
                          placeholder="Select User"
                        />
                      )}
                    />
                  ) : (
                    <Autocomplete
                      limitTags={5}
                      multiple
                      id="checkboxes-tags-demo"
                      className="auto-check-user heading-option"
                      options={organizationList}
                      disabled={isPrivate}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option?.organisationName}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selectedOraganisation.includes(
                              option?._id
                            )}
                            iconStyle={{
                              fill: "#FFC805",
                              outline: "2px solid red",
                            }}
                          />
                          <Grid item sm={12}>
                            <Box
                              component="div"
                              className="headig-option font-size-14"
                              textOverflow="ellipsis"
                            >
                              {option?.organisationName}
                            </Box>
                          </Grid>
                        </React.Fragment>
                      )}
                      defaultValue={this.state.sharedWithOrg}
                      // defaultValue={!isPrivate ? sharedDocs : []}
                      style={{ width: "290px" }}
                      onChange={this.handleSharedWithDocs}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          shrink={true}
                          placeholder="Select Organisation"
                        />
                      )}
                    />
                  )}
                  <FormHelperText error>
                    {showError &&
                    selectedOraganisation.length < 1 &&
                    this.state.isPrivate == false
                      ? "Please select an organisation"
                      : ""}
                  </FormHelperText>

                  {/* Shared with Organisation ends */}
                </Grid>
              </Grid>
              <Grid
                container
                className="display-center"
                style={{ marginTop: "25px" }}
              >
                <Grid item xs={12} sm={6} className={classes.alignSubmit}>
                  <Button
                    type="submit"
                    className="main-btn submit-btn upload-submit border-radius-10"
                    onClick={this.handleModalOpen}
                    // disabled={this.state.buttonDisabled}
                    disableTouchRipple="false"
                  >
                    Submit Document
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.alignCancel}>
                  <Box>
                    <Link to={"/MyDocuments"}>
                      <Button
                        type="submit"
                        variant="contained"
                        class="btn-doc-close upload-btn heading3 border-radius-10"
                        disableTouchRipple="false"
                        // onClick={this.handleCancel}
                      >
                        Cancel
                      </Button>
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </main>
        <Dialog
          open={this.state.modal}
          onClose={this.handleModalClose}
          aria-labelledby="form-dialog-title"
          className="dialog-main-div"
        >
          <DialogContent className="dialog-content pd-70">
            <Grid container className="display-center">
              <Grid item className="mr-b-40" style={{ display: "contents" }}>
                <span className="upload-modal-heading font-open-sans">
                  Yayyyy!
                </span>
                <br />
                <span
                  className="upload-modal-heading-2 font-open-sans display-center"
                  style={{ textAlign: "center" }}
                >
                  Your document is submitted successfully
                </span>
              </Grid>
              <Grid item>
                <img
                  className={classes.imageIcon}
                  className="arrow-position"
                  src={downloadFrame}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Link to={"/MyDocuments"}>
              {" "}
              <Button
                class="btn-doc heading1"
                onClick={this.handleModalClose}
                style={{ marginBottom: "20px", marginRight: "20px" }}
                disableTouchRipple="false"
              >
                close
              </Button>
            </Link>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    categoryList: state.user.categoryList,
    organizationList: state.organization.organizationList,
    userList: state.user.practitinerList,
  };
}

export default connect(mapStateToProps, {
  getPractitioner,
  getcategoryPrac,
  editDocuments,
  getOrganizations,
})(withStyles(useStyles)(editDocument));

const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
];
