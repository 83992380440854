import React, { useState, useEffect } from "react";
import Video from "twilio-video";

import styles from "./room.module.scss";
import Participant from "../Participant";

const Room = ({ roomName, token, handleLogout, meetingAction, callType }) => {
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [disableAudio, setDisableAudio] = useState(false);
  const [disableVideo, setDisableVideo] = useState(false);

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };

    Video.connect(token, {
      name: roomName,
      networkQuality: {
        local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
        remote: 2, // RemoteParticipants' Network Quality verbosity [0 - 3]
      },
    }).then((room) => {
      setRoom(room);
      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.participants.forEach(participantConnected);
    });

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);

  const handleLocalParticipantAudio = () => {
    if (!disableAudio) {
      room.localParticipant.audioTracks.forEach((publication) => {
        // var localMedia = publication.localMedia;
        // localMedia.mute();
        publication.track.disable();
      });
    } else {
      room.localParticipant.audioTracks.forEach((publication) => {
        // console.log(publication,'from enables')
        publication.track.enable();
      });
    }
    setDisableAudio((disableAudio) => !disableAudio);
  };

  const handleLocalParticipantVideo = () => {
    if (disableVideo) {
      room.localParticipant.videoTracks.forEach((publication) => {
        publication.track.enable();
      });
    } else {
      room.localParticipant.videoTracks.forEach((publication) => {
        publication.track.disable();
      });
    }
    setDisableVideo((disableVideo) => !disableVideo);
  };

  const remoteParticipants = participants.map((participant) => (
    <Participant
      key={participant.sid}
      participant={participant}
      remoteParticipant
    />
  ));

  return (
    <div className={styles.room}>
      <div className={styles.remoteParticipants}>{remoteParticipants}</div>
      <div className={styles.localParticipants}>
        {room ? (
          <Participant
            key={room.localParticipant.sid}
            participant={room.localParticipant}
          />
        ) : (
          ""
        )}
      </div>
      <div className={styles.actionContainer}>
        <div className={styles.action}>
          <div className={styles.muteBtn} onClick={handleLocalParticipantAudio}>
            <i
              className={`fa ${
                disableAudio ? "fa-microphone-slash" : "fa-microphone"
              }   ${styles.muteParticipant}`}
            />
            {disableAudio ? "Unmute" : "Mute"}
          </div>
          <div
            style={{ marginRight: "5px" }}
            className={styles.videoBtn}
            onClick={handleLocalParticipantVideo}
          >
            <i
              className="fa fa-video-camera"
              style={disableVideo ? { color: "red" } : { color: "#fff" }}
            />
            {disableVideo ? "Resume Video" : "Stop Video"}
          </div>
        </div>
        <div style={{ marginLeft: "5px" }} className={styles.action}>
          <button
            style={{ marginLeft: "5px" }}
            onClick={handleLogout}
            className={styles.leaveBtn}
          >
            {meetingAction}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Room;
