import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Drawer, Icon, ListItem, ListItemIcon, ListItemText, Avatar, IconButton, AppBar, Grid, Toolbar, List, CssBaseline, Typography, Divider } from "@material-ui/core";
// import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
// import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
// import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from "react-router-dom";
import Logo from '../assets/logo.png';
import shortLogo from '../assets/shortLogo.png';
import docs from '../assets/google-docs 1.svg';
import docsYellow from '../assets/sideNav/google-docs 1.svg';
import arrowRight from "../assets/arrowhead-left (1).svg";
import shareYellow from '../assets/sideNav/share.svg';
import Hidden from '@material-ui/core/Hidden';
import arrowLeft from "../assets/arrowhead-left.svg";
import share from '../assets/share.svg';
import email from '../assets/email.svg';
import emailYellow from '../assets/emailYellow.png';
import unLock from '../assets/Unlock.svg';
import person from '../assets/person.svg';
// import setting from '../assets/settings.svg';
// import bell from '../assets/bell.svg';
import logOut from '../assets/log-out.svg';
import { useSelector } from 'react-redux';
// import { blueGrey } from "@material-ui/core/colors";

const drawerWidth = 316;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootCard: {
    maxWidth: 345,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  appBarShift: {
    marginLeft: "150px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    marginLeft: 20,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(12) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(15) + 1,
    },
  },
  media: {
    paddingTop: "100%",
    margin: "15px",
    borderRadius: "10px",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    minHeight: "125px !important",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  search: {
    position: 'relative',
    borderRadius: '20px',
    border: '1px solid #E1E1E1',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000000',
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  navButtonHide:{
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    position: "relative",
    top: "10rem",
  },
}));


export default function Nav({ handleDrawerOpen, handleDrawerClose, open, title,active, props }) {
  let authData = useSelector(state => state.login.authData);
  // console.log("authData nav",authData)
  // const userId = authData && authData._id ? authData._id : ''
  const buffer = authData && authData.userImage&&authData.userImage.data ? authData.userImage.data : ''; // e.g., <Buffer 89 50 4e ... >
  const buffer2 = authData && authData.userImage?authData.userImage: ''; 
  const b64 = buffer!==''?new Buffer(buffer).toString('base64'):buffer2;
  // let b64= btoa(unescape(encodeURIComponent(b64s)));
  const mimeType = 'image/png'

  const classes = useStyles();
  const theme = useTheme();
  const [isActive, setIsActive] = React.useState(active);
  const [mobileOpen,setMobileOpen]= React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  };
  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <List className="sideNav" >
          <Link to={'/MyDocuments'} className="text-decoration-none">
            <ListItem button key="My Documents" onClick={() => updateSelected(0)} selected={isActive === 0} className="nav-list-text">
              <ListItemIcon> 
                <Icon>
                  {isActive === 0 ? (
                    <img className={`${classes.imageIcon} category-path`} height="15" width="15" src={docsYellow} alt="Yellow Docs" />

                  ) : (
                    <img className={`${classes.imageIcon} category-path`} height="15" width="15" src={docs} alt="Docs" />

                  )}
                </Icon>
              </ListItemIcon>
              {open ?
                <ListItemText primary="My Documents" /> : " "}
              {isActive === 0 ? <i class="fa fa-circle add-active icon-active-alignment"></i> : ""}
            </ListItem>
          </Link>
          <Link to={'/shared-with-me'} className="text-decoration-none">
            <ListItem button key="Shared With Me" onClick={() => updateSelected(1)} selected={isActive === 1} className="nav-list-text">
              <ListItemIcon>
                <Icon>
                  {isActive === 1 ? (
                    <img className={`${classes.imageIcon} category-path jjj`} height="15" width="15" src={shareYellow} alt="Share Yellow" />
                  ) : (
                    <img className={`${classes.imageIcon} category-path`} height="15" width="15" src={share} alt="Share" />
                  )}
                </Icon>
              </ListItemIcon>
              {open ?
                <ListItemText primary="Shared With Me" /> : " "}
              {isActive === 1 ? <i class="fa fa-circle add-active icon-active-alignment"></i> : ""}
            </ListItem></Link>
          <Link to={'/change-password'} className="text-decoration-none">
              <ListItem button key="Chat" onClick={() => updateSelected(2)} selected={isActive === 2}  className="nav-list-text"><ListItemIcon>
              <Icon> <img className={`${classes.imageIcon} category-path`} height="15" width="15" src={unLock} alt="unlock" />
</Icon></ListItemIcon>
              {open ?
              <ListItemText primary="Change Password" />: "" }
              {isActive === 2?<i class="fa fa-circle add-active icon-active-alignment font-size-12"></i>:""}
              </ListItem></Link>
        </List>
        <Divider className="ruler" />
      <List className="sideNav">
          {/* <Link  className="text-decoration-none">
            <ListItem button key="Notification" onClick={() => updateSelected(3)} selected={isActive === 3} className="nav-list-text">
              <ListItemIcon> <Icon>
            {isActive === 3?<img className={classes.imageIcon} height="15" width="15" className="category-path" src={bell}/>:<img className={classes.imageIcon} height="15" width="15" className="category-path" src={bell}/>}</Icon></ListItemIcon>
              {open ?
              <ListItemText primary="Notification" />:" " }
                {isActive === 3?<i class="fa fa-circle add-active icon-active-alignment"></i>:""}
              </ListItem>
            </Link>
            <Link  className="text-decoration-none">
            <ListItem button key="Settings" onClick={() => updateSelected(4)} selected={isActive === 4} className="nav-list-text">
              <ListItemIcon> <Icon>
            {isActive === 4?<img className={classes.imageIcon} height="15" width="15" className="category-path" src={setting}/>:<img className={classes.imageIcon} height="15" width="15" className="category-path" src={setting}/>}</Icon></ListItemIcon>
              {open ?
              <ListItemText primary="Settings" />:" " }
                {isActive === 4?<i class="fa fa-circle add-active icon-active-alignment"></i>:""}
              </ListItem>
            </Link>
            <Link  className="text-decoration-none">
            <ListItem button key="Contact Admin" onClick={() => updateSelected(5)} selected={isActive === 5} className="nav-list-text">
              <ListItemIcon> <Icon>
            {isActive === 5?<img className={classes.imageIcon} height="15" width="15" className="category-path" src={person}/>:<img className={classes.imageIcon} height="15" width="15" className="category-path" src={person}/>}</Icon></ListItemIcon>
              {open ?
              <ListItemText primary="Contact Admin" />:" " }
                {isActive === 5?<i class="fa fa-circle add-active icon-active-alignment"></i>:""}
              </ListItem>
            </Link> */}
             <Link to={'/my-message'} className="text-decoration-none">
            <ListItem button key="Contact Admin" onClick={() => updateSelected(4)} selected={isActive === 4} className="nav-list-text">
              <ListItemIcon> 
                  <Icon>
                      {isActive === 4 ? (
                        <img
                          className={`${classes.imageIcon} category-path`}
                          height="15"
                          width="15"
                          src={emailYellow}
                          alt="Yellow Email"
                        />
                      ) : (
                        <img
                          className={`${classes.imageIcon} category-path`}
                          height="15"
                          width="15"
                          src={email}
                          alt="Email"
                        />
                      )}
                  </Icon>
                </ListItemIcon>
              {open ?
              <ListItemText primary="Chat" />:" " }
                {isActive === 4?<i class="fa fa-circle add-active icon-active-alignment"></i>:""}
              </ListItem>
            </Link> 
            <Link to={'/contactAdmin'} className="text-decoration-none">
            <ListItem button key="Contact Admin" onClick={() => updateSelected(3)} selected={isActive === 3} className="nav-list-text">
              <ListItemIcon> 
                <Icon>
                    {isActive === 3 ? (
                      <img
                        className={`${classes.imageIcon} category-path`}
                        height="15"
                        width="15"
                        src={person}
                        alt="Person Icon"
                      />
                    ) : (
                      <img
                        className={`${classes.imageIcon} category-path`}
                        height="15"
                        width="15"
                        src={person}
                        alt="Person Icon"
                      />
                    )}
                </Icon>
              </ListItemIcon>
              {open ?
              <ListItemText primary="Contact Admin" />:" " }
                {isActive === 3?<i class="fa fa-circle add-active icon-active-alignment"></i>:""}
              </ListItem>
            </Link> 
          <Link to={'/'} className="text-decoration-none">
            <ListItem button key="Logout" onClick={() => updateSelected(6)} selected={isActive === 6} className="nav-list-text">
              <ListItemIcon> 
                <Icon>
                  {isActive === 6 ? (
                    <img
                      className={`${classes.imageIcon} category-path`}
                      height="15"
                      width="15"
                      src={logOut}
                      alt="Logout"
                    />
                  ) : (
                    <img
                      className={`${classes.imageIcon} category-path`}
                      height="15"
                      width="15"
                      src={logOut}
                      alt="Logout"
                    />
                  )}
                </Icon>
              </ListItemIcon>
              {open ?
                <ListItemText primary="Logout" /> : " "}
              {isActive === 6 ? <i class="fa fa-circle add-active icon-active-alignment"></i> : ""}
            </ListItem>
          </Link>
        </List>
    </div>
  );

  function updateSelected(selectedIndex) {
    setIsActive(selectedIndex);
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className="height-125" >
          {open ? " " : <img src={shortLogo} className={`${classes.logo} pd-l-15`} alt="shortlogo" />
}
          <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerToggle}
              className={classes.navIconHide}
            >
              <MenuIcon style={{color:"#005a98"}}/>
            </IconButton>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            ml={20}
            className={clsx(classes.menuButton, classes.navButtonHide, {
              [classes.hide]: open,
            })}

          >
            
            <span className="arrowBox">
              <img src={arrowRight} className={classes.logo} alt="left arrow" />
            </span>
          </IconButton>
          <Grid container component="main" className={classes.root}>
            <Grid item xs={12} sm={4} style={{ paddingTop: "10px" }} >
              <Typography variant="h6" className="headingData" noWrap>
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5} >
              <Grid container >
                {/* <Grid item>
  <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
            </div>
  </Grid>
  <Grid item>
  <IconButton   className="notifi-btn"  aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={1} color="secondary">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.7196 13.404L20.5206 15.208C20.9896 15.677 21.1276 16.377 20.8726 16.991C20.6186 17.604 20.0266 18 19.3626 18H15.9996V18.341C15.9996 20.359 14.2056 22 11.9996 22C9.7936 22 7.9996 20.359 7.9996 18.341V18H4.6366C3.9726 18 3.3796 17.604 3.1246 16.99C2.8706 16.377 3.0096 15.678 3.4786 15.208L5.2796 13.404L5.2806 8.72699C5.2806 6.79299 6.1186 4.94899 7.5826 3.66699C9.0376 2.39099 10.9776 1.80399 12.8996 2.05999C16.2176 2.49899 18.7196 5.45599 18.7196 8.93699V13.404ZM5.5146 16L6.6946 14.818C7.0726 14.44 7.2806 13.938 7.2806 13.404V8.72699C7.2806 7.36999 7.8706 6.07299 8.9006 5.17099C9.9386 4.26099 11.2606 3.86099 12.6376 4.04199C14.9646 4.35099 16.7196 6.45499 16.7196 8.93699V13.404C16.7196 13.938 16.9276 14.44 17.3046 14.817L18.4856 16H5.5146ZM13.9996 18.341C13.9996 19.24 13.0836 20 11.9996 20C10.9156 20 9.9996 19.24 9.9996 18.341V18H13.9996V18.341Z" fill="#0D1C2E"/>
<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="19" height="20">
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.7196 13.404L20.5206 15.208C20.9896 15.677 21.1276 16.377 20.8726 16.991C20.6186 17.604 20.0266 18 19.3626 18H15.9996V18.341C15.9996 20.359 14.2056 22 11.9996 22C9.7936 22 7.9996 20.359 7.9996 18.341V18H4.6366C3.9726 18 3.3796 17.604 3.1246 16.99C2.8706 16.377 3.0096 15.678 3.4786 15.208L5.2796 13.404L5.2806 8.72699C5.2806 6.79299 6.1186 4.94899 7.5826 3.66699C9.0376 2.39099 10.9776 1.80399 12.8996 2.05999C16.2176 2.49899 18.7196 5.45599 18.7196 8.93699V13.404ZM5.5146 16L6.6946 14.818C7.0726 14.44 7.2806 13.938 7.2806 13.404V8.72699C7.2806 7.36999 7.8706 6.07299 8.9006 5.17099C9.9386 4.26099 11.2606 3.86099 12.6376 4.04199C14.9646 4.35099 16.7196 6.45499 16.7196 8.93699V13.404C16.7196 13.938 16.9276 14.44 17.3046 14.817L18.4856 16H5.5146ZM13.9996 18.341C13.9996 19.24 13.0836 20 11.9996 20C10.9156 20 9.9996 19.24 9.9996 18.341V18H13.9996V18.341Z" fill="white"/>
</mask>
<g mask="url(#mask0)">
</g>
</svg>

              </Badge>
            </IconButton>
  </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} >
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
              >
                <Link to={"/user-profile"} className="text-decoration-none"> <Avatar alt={ authData.userName}  src={`data:${mimeType};base64,${b64}`} /></Link>    {/* src={require('../assets/image 2.png')} />  */}
              </IconButton>
              <span className="name-lable">{authData ? authData.userName : 'Will Smith'}</span>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      <Hidden smDown>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.drawerOpen, classes.drawerClose, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >

        <div className={`${classes.toolbar} sideNav-main logo-main`}>
          {open ? <img src={Logo} className={classes.logo} alt="logo" /> : ""}
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <span className="arrowBox">
                <img src={arrowLeft} className={classes.logo} alt="left arrow" />
              </span>
            ) : (
                <span className="arrowBox">
                  <img src={arrowLeft} className={classes.logo} alt="left arrow" />
                </span>
              )}
          </IconButton>
        </div>
        <List className="sideNav" >
          <Link to={'/MyDocuments'} className="text-decoration-none">
            <ListItem button key="My Documents" onClick={() => updateSelected(0)} selected={isActive === 0} className="nav-list-text">
              <ListItemIcon> 
                <Icon>
                  {isActive === 0 ? (
                    <img
                    className={`${classes.imageIcon} category-path`}
                    height="15"
                    width="15"
                    src={docsYellow}
                    alt="Yellow document icon"
                  />                  
                  ) : (
                    <img
                      className={`${classes.imageIcon} category-path`}
                      height="15"
                      width="15"
                      src={docs}
                      alt="Document icon"
                    />
                  )}
              </Icon>
            </ListItemIcon>
              {open ?
                <ListItemText primary="My Documents" /> : " "}
              {isActive === 0 ? <i className="fa fa-circle add-active icon-active-alignment"></i> : ""}
            </ListItem>
          </Link>
          <Link to={'/shared-with-me'} className="text-decoration-none">
            <ListItem button key="Shared With Me" onClick={() => updateSelected(1)} selected={isActive === 1} className="nav-list-text">
              <ListItemIcon>
                <Icon>
                    {isActive === 1 ? (
                      <img
                        className={`${classes.imageIcon} category-path jjj`}
                        height="15"
                        width="15"
                        src={shareYellow}
                        alt="Share Icon Yellow"
                      />
                    ) : (
                      <img
                        className={`${classes.imageIcon} category-path`}
                        height="15"
                        width="15"
                        src={share}
                        alt="Share Icon"
                      />
                    )}
                </Icon>
              </ListItemIcon>
              {open ?
                <ListItemText primary="Shared With Me" /> : " "}
              {isActive === 1 ? <i class="fa fa-circle add-active icon-active-alignment"></i> : ""}
            </ListItem></Link>
          <Link to={'/change-password'} className="text-decoration-none">
              <ListItem button key="Chat" onClick={() => updateSelected(2)} selected={isActive === 2}  className="nav-list-text">
              <ListItemIcon>
                <Icon>
                  <img
                    className={`${classes.imageIcon} category-path`}
                    height="15"
                    width="15"
                    src={unLock}
                    alt="Unlock Icon"
                  />
                </Icon>
              </ListItemIcon>
              {open ?
              <ListItemText primary="Change Password" />: "" }
              {isActive === 2?<i class="fa fa-circle add-active icon-active-alignment font-size-12"></i>:""}
              </ListItem></Link>
        </List>
        <Divider className="ruler" />
        <List className="sideNav">
          {/* <Link  className="text-decoration-none">
            <ListItem button key="Notification" onClick={() => updateSelected(3)} selected={isActive === 3} className="nav-list-text">
              <ListItemIcon> <Icon>
            {isActive === 3?<img className={classes.imageIcon} height="15" width="15" className="category-path" src={bell}/>:<img className={classes.imageIcon} height="15" width="15" className="category-path" src={bell}/>}</Icon></ListItemIcon>
              {open ?
              <ListItemText primary="Notification" />:" " }
                {isActive === 3?<i class="fa fa-circle add-active icon-active-alignment"></i>:""}
              </ListItem>
            </Link>
            <Link  className="text-decoration-none">
            <ListItem button key="Settings" onClick={() => updateSelected(4)} selected={isActive === 4} className="nav-list-text">
              <ListItemIcon> <Icon>
            {isActive === 4?<img className={classes.imageIcon} height="15" width="15" className="category-path" src={setting}/>:<img className={classes.imageIcon} height="15" width="15" className="category-path" src={setting}/>}</Icon></ListItemIcon>
              {open ?
              <ListItemText primary="Settings" />:" " }
                {isActive === 4?<i class="fa fa-circle add-active icon-active-alignment"></i>:""}
              </ListItem>
            </Link>
            <Link  className="text-decoration-none">
            <ListItem button key="Contact Admin" onClick={() => updateSelected(5)} selected={isActive === 5} className="nav-list-text">
              <ListItemIcon> <Icon>
            {isActive === 5?<img className={classes.imageIcon} height="15" width="15" className="category-path" src={person}/>:<img className={classes.imageIcon} height="15" width="15" className="category-path" src={person}/>}</Icon></ListItemIcon>
              {open ?
              <ListItemText primary="Contact Admin" />:" " }
                {isActive === 5?<i class="fa fa-circle add-active icon-active-alignment"></i>:""}
              </ListItem>
            </Link> */}
             <Link to={'/my-message'} className="text-decoration-none">
            <ListItem button key="Contact Admin" onClick={() => updateSelected(4)} selected={isActive === 4} className="nav-list-text">
              <ListItemIcon> 
                  <Icon>
                    {isActive === 4 ? (
                      <img
                        className={`${classes.imageIcon} category-path`}
                        height="15"
                        width="15"
                        src={emailYellow}
                        alt="Email Icon Yellow"
                      />
                    ) : (
                      <img
                        className={`${classes.imageIcon} category-path`}
                        height="15"
                        width="15"
                        src={email}
                        alt="Email Icon"
                      />
                    )}
                </Icon>
              </ListItemIcon>
              {open ?
              <ListItemText primary="Chat" />:" " }
                {isActive === 4?<i class="fa fa-circle add-active icon-active-alignment"></i>:""}
              </ListItem>
            </Link> 
            <Link to={'/contactAdmin'} className="text-decoration-none">
            <ListItem button key="Contact Admin" onClick={() => updateSelected(3)} selected={isActive === 3} className="nav-list-text">
              <ListItemIcon> 
                <Icon>
                    {isActive === 3 ? (
                      <img
                        className={`${classes.imageIcon} category-path`}
                        height="15"
                        width="15"
                        src={person}
                        alt="Person Icon"
                      />
                    ) : (
                      <img
                        className={`${classes.imageIcon} category-path`}
                        height="15"
                        width="15"
                        src={person}
                        alt="Person Icon"
                      />
                    )}
                </Icon>
              </ListItemIcon>
              {open ?
              <ListItemText primary="Contact Admin" />:" " }
                {isActive === 3?<i class="fa fa-circle add-active icon-active-alignment"></i>:""}
              </ListItem>
            </Link> 
          <Link to={'/'} className="text-decoration-none">
            <ListItem button key="Logout" onClick={() => updateSelected(6)} selected={isActive === 6} className="nav-list-text">
              <ListItemIcon> 
                  <Icon>
                      {isActive === 6 ? (
                        <img
                          className={`${classes.imageIcon} category-path`}
                          height="15"
                          width="15"
                          src={logOut}
                          alt="Log Out Icon"
                        />
                      ) : (
                        <img
                          className={`${classes.imageIcon} category-path`}
                          height="15"
                          width="15"
                          src={logOut}
                          alt="Log Out Icon"
                        />
                      )}
                  </Icon>
                </ListItemIcon>
              {open ?
                <ListItemText primary="Logout" /> : " "}
              {isActive === 6 ? <i class="fa fa-circle add-active icon-active-alignment"></i> : ""}
            </ListItem>
          </Link>
        </List>
      </Drawer>
      </Hidden>
    </React.Fragment>
  );
}
