import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import "font-awesome/css/font-awesome.min.css";
import {
  Button,
  CardActions,
  CardContent,
  CardMedia,
  Avatar,
  Card,
  Grid,
  IconButton,
  Typography,
  Icon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  FormGroup,
  FormControlLabel,
  TextField,
  Checkbox,
  FormControl,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Rating from "@material-ui/lab/Rating";
import Nav from "../../../component/Nav.js";
import calender from "../../../assets/calendar.svg";
import mail from "../../../assets/email.png";
import download from "../../../assets/download.png";
// import options from "../../../assets/options-2.svg";
import Loader from "../../../component/Loader";
import { Link } from "react-router-dom";
import filter from "../../../assets/filterIcon.svg";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { getsharedDocuments, clearDocument } from "./actions/documentActions";
import FileSaver from "file-saver";
import axios from "axios";
import { API_URL } from "../../../actions/utilAction";
import { jsonApiHeader } from "../../../actions/utilAction";
import { toasterMessage } from "../../../actions/util";
import { getDocFilteredSharedData, saveFilterRes } from "./actions/documentActions";
import { getOrganizations } from "../../admin/organization/actions/organizationAction";
import { getcategoryPrac } from "../../admin/user/actions/userAction";

const drawerWidth = 316;
const icon = (
  <CheckBoxOutlineBlankIcon fontSize="small" style={{ fill: "#005A98" }} />
);
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  rootCard: {
    [theme.breakpoints.up("md")]: {
      maxWidth: 345,
    },
    boxShadow: "0px 7px 24px 0px rgba(0, 90, 152, 0.07)",
  },
  alignButton: {
    [theme.breakpoints.up("md")]: {
      position: "relative",
      bottom: "20px",
    },
    [theme.breakpoints.down("md")]: {
      margin: "20px 0px",
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  appBarShift: {
    marginLeft: "150px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  media: {
    paddingTop: "100%",
    margin: "15px",
    borderRadius: "10px",
    background: "#FFF8F8",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    minHeight: "125px !important",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2, 2),
    paddingRight: theme.spacing(5),
    position: "relative",
    top: "10rem",
  },
});

class MyDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      title: "Shared With Me",
      shareddocList: [],
      load: true,
      Status: "",
      rating: 0,
      filterModal: false,
      fileType: [],
      docStatus: false,
      pptStatus: false,
      csvStatus: false,
      pdfStatus: false,
      jpegStatus: false,
      xlsStatus: false,
    };
  }
  isOutsideRange = (day) => day.isAfter(moment.parseZone());
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  componentDidMount() {
    this.props.getsharedDocuments();
    this.props.getOrganizations();
    this.props.getcategoryPrac();
    this.setState({ load: true });
  }
  static getDerivedStateFromProps(props, state) {
    if (props.shareddocList !== state.shareddocList) {
      return {
        shareddocList: props.shareddocList,
        load: false,
      };
    }
    if (props.categoryList !== state.categoryList) {
      return {
        categoryList: props.categoryList,
      };
    }
    if (props.organisationList !== state.organisationList) {
      return {
        organisationList: props.organisationList,
      };
    }
    if (
      props.filterData !== undefined &&
      props.filterData !== state.filterData
    ) {
      // this.setState({startDate:props.filterData.startDate,endDate:props.filterData.endDate})
      return {
        shareddocList: props.filterData,
        // categoryName: props.filterData.categoryName,
        // organisation: props.filterData.organisation,
        // docStatus: props.filterData.docStatus,
        // pptStatus: props.filterData.pptStatus,
        // csvStatus: props.filterData.csvStatus,
        // pdfStatus: props.filterData.pdfStatus,
        // jpegStatus: props.filterData.jpegStatus
      };
    }
    // Return null if the state hasn't changed
    return null;
  }

  componentWillUnmount() {
    this.props.clearDocument();
  }

  viewDetails = (data) => {
    //  let data= this.state.documentList.filter
    this.props.history.push({
      pathname: "/shared-file-details",
      state: {
        data,
      },
    });
    // /document-details
  };

  handleModalClose = () => {
    this.setState({ filterModal: !this.state.filterModal });
  };
  cancel = () => {
    this.handleModalClose();
  };

  downloadDocument = (data) => {
    this.setState({ load: true });
    let access_token = localStorage.access_token;
    axios
      .get(
        `${API_URL}/document/downloadDoc/${data.currentAprrovDocument.fileId}`,
        {
          headers: jsonApiHeader(access_token, "blob"),
          responseType: "blob",
        }
      )
      .then((res) => {
        this.setState({ fileDownloading: true, load: false }, () => {
          FileSaver.saveAs(res.data, `${data.documentName}`);
        });

        console.log(res.data, "from here");
      })
      .catch((error) => {
        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch((error) => {
        console.log(error);
        toasterMessage("error", "Something bad happened");
      });
  };

  applyFilter = () => {
    let obj = {
      filter: {
        fileType: this.state.fileType || undefined,
        categoryId: this.state.categoryArray || undefined,
        fromDate: this.state.startDate1 || undefined,
        toDate: this.state.endDate1 || undefined,
        organisationId: this.state.organisationArray || undefined,
      },
      type: 1,
    };

    this.props.getDocFilteredSharedData(obj);
    this.setState({
      filterModal: false,
      load: true,
    });

    let filterObj = {
      categoryName: this.state.categoryName,
      fileType: this.state.fileType,
      categoryArray: this.state.categoryArray,
      docStatus: this.state.docStatus,
      pptStatus: this.state.pptStatus,
      csvStatus: this.state.csvStatus,
      pdfStatus: this.state.pdfStatus,
      jpegStatus: this.state.jpegStatus,
      xlsStatus: this.state.xlsStatus,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      startDate1: this.state.startDate1,
      endDate1: this.state.endDate1,
      organisation: this.state.organisation,
      organisationArray: this.state.organisationArray,
    };

    this.props.saveFilterRes(filterObj);
  };

  resetAll = () => {
    this.setState({
      // filterModal: false,
      docStatus: false,
      pptStatus: false,
      csvStatus: false,
      pdfStatus: false,
      jpegStatus: false,
      xlsStatus: false,
      fileType: [],
      categoryArray: [],
      startDate: "",
      endDate: "",
      startDate1: "",
      endDate1: "",
      categoryName: [],
      organisation: [],
      organisationArray: [],
      buttonDisabled: true,
    });
    this.props.getsharedDocuments();

    let filterObj = {
      pendingStatus: false,
      reviewStatus: false,
      approveStatus: false,
      docStatus: false,
      pptStatus: false,
      csvStatus: false,
      pdfStatus: false,
      jpegStatus: false,
      xlsStatus: false,
      isPublicstatus: false,
      isPrivatestatus: false,
      Status: [],
      fileType: [],
      categoryArray: [],
      isPrivate: [],
      startDate: "",
      endDate: "",
      startDate1: "",
      endDate1: "",
      categoryName: [],
      organisation: [],
      organisationArray: [],
    };

    this.props.saveFilterRes(filterObj);
  };

  onTagsChange = (event, values) => {
    let arr = [];
    let arr2 = [];
    values.map((x, index) => {
      arr.push(x._id);
      arr2.push({ categoryName: x.categoryName });
      return null; // Return a value (null in this case)
    });
    this.setState({
      categoryArray: arr,
      categoryName: arr2,
      buttonDisabled: false,
    });
  };
  

  onOranganisationChange = (event, values) => {
    // console.log("values",values)
    let arr = [];
    let arr2 = [];
    values.forEach((x, index) => {
      console.log(x, "here");
      arr.push(x._id);
      arr2.push({ organisationName: x.organisationName });
    });    
    this.setState({
      organisationArray: arr,
      organisation: arr2,
      buttonDisabled: false,
    });
  };

  render() {
    const { classes } = this.props;
    const mimeType = "image/png";
    console.log(this.state.organisatioList);
    return (
      <div className={classes.root}>
        <Nav
          handleDrawerOpen={this.handleDrawerOpen}
          handleDrawerClose={this.handleDrawerClose}
          open={this.state.open}
          title={this.state.title}
          active={1}
        />

          <main
            className={clsx(classes.content, {
              [classes.appBarShift]: this.state.open,
            })}
          >
          <Grid container>
            <Grid item xs={12} sm={12} lg={8}>
              <Typography variant="body2" color="textSecondary" component="p">
                <span className="heading1 mr-r-20">Choose Publish Date </span>

                <DateRangePicker
                  startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                  endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                  focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={(focusedInput) =>
                    this.setState({ focusedInput })
                  } // PropTypes.func.isRequired,
                  endDatePlaceholderText={"End Date"}
                  startDatePlaceholderText={"Start Date"}
                  displayFormat={"MMM DD, YYYY"}
                  isOutsideRange={this.isOutsideRange}
                  minimumNights={0}
                  onDatesChange={({ startDate, endDate }) => {
                    // console.log("startDate", endDate)
                    // console.log("endDate", endDate)
                    let startDate1 =
                      startDate !== "" && startDate !== null
                        ? startDate.format("YYYY-MM-DD")
                        : "";
                    let endDate1 =
                      endDate !== "" && endDate !== null
                        ? endDate.format("YYYY-MM-DD")
                        : "";
                    // console.log("startDate1", startDate1)
                    // console.log("endDate1", endDate1)
                    this.setState({
                      startDate,
                      endDate,
                      startDate1: startDate1,
                      endDate1: endDate1,
                      load: true,
                    });
                    if (
                      startDate !== "" &&
                      endDate !== "" &&
                      endDate !== null &&
                      startDate !== null
                    ) {
                      // console.log("inside")
                      let obj = {
                        filter: {
                          status: this.state.Status,
                          fileType: this.state.fileType,
                          categoryId: this.state.categoryArray,
                          isPrivate: this.state.isPrivate,
                          fromDate: startDate1,
                          toDate: endDate1,
                        },
                        type: 1,
                      };
                      if (this.state.Status && this.state.Status.length === 0) {
                        delete obj.filter.status;
                      }
                      if (
                        this.state.fileType &&
                        this.state.fileType.length === 0
                      ) {
                        delete obj.filter.fileType;
                      }
                      if (
                        this.state.categoryArray &&
                        this.state.categoryArray.length === 0
                      ) {
                        delete obj.filter.categoryId;
                      }
                      if (
                        this.state.isPrivate &&
                        this.state.isPrivate.length === 0
                      ) {
                        delete obj.filter.isPrivate;
                      }
                      if (startDate1 === "") {
                        delete obj.filter.fromDate;
                      }
                      if (endDate1 === "") {
                        delete obj.filter.toDate;
                      }
                      this.props.getDocFilteredSharedData(obj);
                    }
                  }}
                />
                <img
                  className={`${classes.imageIcon} align-calender`}
                  src={calender}
                  alt="Calendar Icon"
                />

              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={4} className={classes.alignButton}>
              <Button onClick={this.handleModalClose} className="detail-btn " disableTouchRipple="false">
                {/* onClick={this.handleModalOpen} */}
                <img className={classes.imageIcon} src={filter} alt="Filter Icon" />
              </Button>
              <Link to={"/upload/"}>
                <Button
                  class="btn-doc heading1"
                  style={{ padding: "19px 44px" }}
                  disableTouchRipple="false"
                >
                  Add Document
                </Button>
              </Link>
            </Grid>
          </Grid>
          {this.state.loader === true ? (
            <Loader />
          ) : (
            <Grid
              container
              spacing={4}
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              {this.state.shareddocList.length !== 0
                ? this.state.shareddocList.map((x, index) => (
                  <Grid item xs={12} sm={6} md={3}>
                    <Card className={classes.rootCard}>
                      {x.currentAprrovDocument &&
                        x.currentAprrovDocument.fileType &&
                        x.currentAprrovDocument.fileType ===
                        "application/pdf" ? (
                        <CardMedia
                          onClick={(e) => {
                            this.viewDetails(x);
                          }}
                          className={classes.media}
                          image={require("../../../assets/Pdf.png")}
                        />
                      ) : (
                        ""
                      )}
                      {x.currentAprrovDocument &&
                        x.currentAprrovDocument.fileType &&
                        x.currentAprrovDocument.fileType === ".pdf" ? (
                        <CardMedia
                          onClick={(e) => {
                            this.viewDetails(x);
                          }}
                          className={classes.media}
                          image={require("../../../assets/Pdf.png")}
                        />
                      ) : (
                        ""
                      )}
                      {x.currentAprrovDocument &&
                        x.currentAprrovDocument.fileType &&
                        x.currentAprrovDocument.fileType === "text/csv" ? (
                        <CardMedia
                          onClick={(e) => {
                            this.viewDetails(x);
                          }}
                          className={classes.media}
                          image={require("../../../assets/CSV.svg")}
                        />
                      ) : (
                        ""
                      )}
                      {x.currentAprrovDocument &&
                        x.currentAprrovDocument.fileType &&
                        x.currentAprrovDocument.fileType === ".csv" ? (
                        <CardMedia
                          onClick={(e) => {
                            this.viewDetails(x);
                          }}
                          className={classes.media}
                          image={require("../../../assets/CSV.svg")}
                        />
                      ) : (
                        ""
                      )}
                      {x.currentAprrovDocument &&
                        x.currentAprrovDocument.fileType &&
                        x.currentAprrovDocument.fileType === ".jpeg" ? (
                        <CardMedia
                          onClick={(e) => {
                            this.viewDetails(x);
                          }}
                          className={classes.media}
                          image={require("../../../assets/JPEG.svg")}
                        />
                      ) : (
                        ""
                      )}
                      {x.currentAprrovDocument &&
                        x.currentAprrovDocument.fileType &&
                        x.currentAprrovDocument.fileType === ".xls" ? (
                        <CardMedia
                          onClick={(e) => {
                            this.viewDetails(x);
                          }}
                          className={classes.media}
                          image={require("../../../assets/excel 1.svg")}
                        />
                      ) : (
                        ""
                      )}
                      {x.currentAprrovDocument &&
                        x.currentAprrovDocument.fileType &&
                        x.currentAprrovDocument.fileType === ".docx" ? (
                        <CardMedia
                          onClick={(e) => {
                            this.viewDetails(x);
                          }}
                          className={classes.media}
                          image={require("../../../assets/DOCX.svg")}
                        />
                      ) : (
                        ""
                      )}
                      {x.currentAprrovDocument &&
                        x.currentAprrovDocument.fileType &&
                        x.currentAprrovDocument.fileType === ".ppt" ? (
                        <CardMedia
                          onClick={(e) => {
                            this.viewDetails(x);
                          }}
                          className={classes.media}
                          image={require("../../../assets/PPT.svg")}
                        />
                      ) : (
                        ""
                      )}
                      {x.currentAprrovDocument &&
                        x.currentAprrovDocument.fileType &&
                        x.currentAprrovDocument.fileType === ".pptx" ? (
                        <CardMedia
                          onClick={(e) => {
                            this.viewDetails(x);
                          }}
                          className={classes.media}
                          image={require("../../../assets/PPT.svg")}
                        />
                      ) : (
                        ""
                      )}
                      <CardContent className="pd-t-b-0">
                        <Grid container>
                          <Grid item sm={11} xs={11}>
                            <Typography
                              variant="body2"
                              pb={0}
                              color="textSecondary"
                              className="ListHeading"
                              component="p"
                            >
                              {/* Tuberculosis - Cure */}
                              {x.documentName ? x.documentName : ""}
                            </Typography>
                          </Grid>
                          <Grid item sm={11} xs={11}>
                            <IconButton
                              style={{ padding: "5px 15px 0px 0px" }}
                              aria-haspopup="true"
                              disableTouchRipple
                            >
                              <Avatar
                                alt={x.createdBy.userName}
                                borderColor="primary.main"
                                className="shared-profile"
                                src={`data:${mimeType};base64,${x.createdBy && x.createdBy.userImage
                                    ? x.createdBy.userImage
                                    : ""
                                  }`}
                              />
                            </IconButton>
                            <span className="listName">
                              {/* Judith Mueller */}
                              {x.createdBy && x.createdBy.userName !== 0
                                ? x.createdBy.userName
                                : ""}
                            </span>
                          </Grid>
                          <Grid item sm={1} xs={1}>
                            <div className="align-icon" style={{ top: "7px" }}>
                              <Link to="/my-message">
                                <Icon>
                                <img
                                  className={classes.imageIcon}
                                  src={mail}
                                  alt="Mail Icon"
                                />

                                </Icon>
                              </Link>
                            </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions className="pd-l-r-16 display-center">
                        <Grid container>
                          <Grid item sm={1} xs={1}>
                            <Typography
                              variant="body2"
                              pb={0}
                              color="textSecondary"
                              className="listHeadingStar pd-0 margin-value mr-l-10"
                              component="p"
                            >
                              {x.getRatingShared.length &&
                                x.getRatingShared[0].rating > 0
                                ? `${Math.round(
                                  x.getRatingShared[0].rating
                                )}.0`
                                : "-"}
                            </Typography>
                          </Grid>
                          <Grid item sm={10} xs={10}>
                            <Rating
                              disabled
                              onChange={(e) => console.log(e)}
                              name="size-small"
                              customName="pristine"
                              className="pd-0 mr-l-30"
                              value={
                                x.getRatingShared.length
                                  ? `${Math.round(
                                    x.getRatingShared[0].rating
                                  )}.0`
                                  : 0
                              }
                              size="small"
                            />
                          </Grid>
                          <Grid item sm={1} xs={1}>
                          <img
                            style={{ cursor: "pointer" }}
                            onClick={() => this.downloadDocument(x)}
                            className={classes.imageIcon}
                            src={download}
                            alt="Download"
                          />

                          </Grid>
                        </Grid>
                      </CardActions>
                    </Card>
                  </Grid>
                ))
                : ""}
            </Grid>
          )}

          <Dialog
            open={this.state.filterModal}
            onClose={this.handleModalClose}
            aria-labelledby="form-dialog-title"
            className="dialog-main-div-filter"
          >
            <DialogTitle
              id="form-dialog-title"
              className="modal-header dialogTitleFilter"
            >
              Filter
              <IconButton
                onClick={this.handleModalClose}
                style={{ position: "absolute", right: "20px" }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className="dialog-content pd-filter">
              <Grid container>
                {/* <Grid item xs={12}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel
                      component="legend"
                      className="filter-text head-filter"
                    >
                      Status
                    </FormLabel>
                    <FormGroup className="display-inline align-filter-checkbox">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="PENDING_REVIEW"
                            style={{
                              color: "rgba(0, 90, 152, 1)",
                              paddingLeft: "0px",
                            }}
                          />
                        }
                        className="filter-text subhead-filter"
                        label="Pending review"
                        style={{ marginLeft: "0px" }}
                        // checked={this.state.pendingStatus}
                        // onChange={(e) => {
                        //   if (e.target.checked == true) {
                        //     this.state.Status.push(e.target.name);
                        //   } else {
                        //     this.state.Status.pop(e.target.name);
                        //   }
                        //   this.setState({ pendingStatus: e.target.checked });
                        // }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="REVIEW_REQUESTED"
                            style={{ color: "rgba(0, 90, 152, 1)" }}
                          />
                        }
                        className="filter-text subhead-filter"
                        label="Review requested"
                        // checked={this.state.reviewStatus}
                        // onChange={(e) => {
                        //   if (e.target.checked == true) {
                        //     this.state.Status.push(e.target.name);
                        //   } else {
                        //     this.state.Status.pop(e.target.name);
                        //   }
                        //   this.setState({ reviewStatus: e.target.checked });
                        // }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="APPROVED"
                            style={{ color: "rgba(0, 90, 152, 1)" }}
                          />
                        }
                        className="filter-text subhead-filter"
                        // checked={this.state.approveStatus}
                        label="Approved"
                        // onChange={(e) => {
                        //   if (e.target.checked == true) {
                        //     this.state.Status.push(e.target.name);
                        //   } else {
                        //     this.state.Status.pop(e.target.name);
                        //   }
                        //   this.setState({ approveStatus: e.target.checked });
                        // }}
                      />
                    </FormGroup>
                  </FormControl>
                  <Divider className="ruler-filter" />
                </Grid> */}
                <Grid item xs={7}>
                  <FormControl
                    component="fieldset"
                    className="head-filter"
                    style={{ marginTop: "30px" }}
                  >
                    <FormLabel
                      component="legend"
                      className="filter-text head-filter"
                    >
                      Document Type
                    </FormLabel>
                    <FormGroup className="display-inline filter-text head-filter align-filter-checkbox">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name=".docx"
                            style={{
                              color: "rgba(0, 90, 152, 1)",
                              paddingLeft: "0px",
                              paddingRight: "3px"
                            }}
                          />
                        }
                        className="filter-text subhead-filter"
                        label="DOCX"
                        style={{ marginLeft: "0px", marginRight: "23px" }}
                        checked={this.state.docStatus}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            this.state.fileType.push(e.target.name);
                          } else {
                            this.state.fileType.pop(e.target.name);
                          }
                          this.setState({ docStatus: e.target.checked });
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name=".pptx"
                            style={{
                              color: "rgba(0, 90, 152, 1)",
                              paddingLeft: "0px",
                              paddingRight: "3px"
                            }}
                          />
                        }
                        className="filter-text subhead-filter"
                        label="PPT"
                        style={{ marginLeft: "0px", marginRight: "23px" }}
                        checked={this.state.pptStatus}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            this.state.fileType.push(e.target.name);
                          } else {
                            this.state.fileType.pop(e.target.name);
                          }
                          this.setState({ pptStatus: e.target.checked });
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name=".csv"
                            style={{
                              color: "rgba(0, 90, 152, 1)",
                              paddingLeft: "0px",
                              paddingRight: "3px"
                            }}
                          />
                        }
                        className="filter-text subhead-filter"
                        label="CSV"
                        style={{ marginLeft: "0px", marginRight: "23px" }}
                        checked={this.state.csvStatus}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            this.state.fileType.push(e.target.name);
                          } else {
                            this.state.fileType.pop(e.target.name);
                          }
                          this.setState({ csvStatus: e.target.checked });
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name=".pdf"
                            style={{ color: "rgba(0, 90, 152, 1)", paddingRight: "3px" }}
                          />
                        }
                        className="filter-text subhead-filter"
                        label="PDF"
                        style={{ marginLeft: "0px", marginRight: "23px" }}
                        checked={this.state.pdfStatus}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            this.state.fileType.push(e.target.name);
                          } else {
                            this.state.fileType.pop(e.target.name);
                          }
                          this.setState({ pdfStatus: e.target.checked });
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name=".jpeg"
                            style={{ color: "rgba(0, 90, 152, 1)", paddingRight: "3px" }}
                          />
                        }
                        className="filter-text subhead-filter"
                        label="JPEG"
                        checked={this.state.jpegStatus}
                        style={{ marginLeft: "0px", marginRight: "23px" }}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            this.state.fileType.push(e.target.name);
                          } else {
                            this.state.fileType.pop(e.target.name);
                          }
                          this.setState({ jpegStatus: e.target.checked });
                        }}
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            name=".xls"
                            style={{ color: "rgba(0, 90, 152, 1)", paddingRight: "3px" }}
                          />
                        }
                        className="filter-text subhead-filter"
                        label="XLS"
                        style={{ marginLeft: "0px", marginRight: "23px" }}
                        checked={this.state.xlsStatus}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            this.state.fileType.push(e.target.name);
                          } else {
                            this.state.fileType.pop(e.target.name);
                          }
                          this.setState({ xlsStatus: e.target.checked });
                        }}
                      />
                    </FormGroup>
                  </FormControl>

                </Grid>

                {/* <Grid item xs={12}>
              <FormGroup className="display-inline head-filter">
              <FormLabel component="legend" className="filter-text head-filter">Organization Name</FormLabel>
          <FormControlLabel
            control={<Checkbox   name="Organization Name 1" />}
            className="filter-text subhead-filter"
            label="Organization Name 1"
          />
          <FormControlLabel
            control={<Checkbox   name="Organization Name 2" />}
            className="filter-text subhead-filter"
            label="Organization Name 2"
          />
          <FormControlLabel
            control={<Checkbox   name="Organization Name 3" />}
            className="filter-text subhead-filter"
            label="Organization Name 3"
          />
        </FormGroup>
        <Divider  className="ruler v"/>
              </Grid>
               */}
                {/* <Grid item xs={12}>
                  <FormGroup className="display-inline head-filter">
                    <FormLabel
                      component="legend"
                      className="filter-text head-filter"
                      style={{ marginTop: "30px" }}
                    >
                      Privacy Setting
                    </FormLabel>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isPrivate"
                          style={{
                            color: "rgba(0, 90, 152, 1)",
                            paddingLeft: "0px",
                          }}
                        />
                      }
                      className="filter-text subhead-filter align-filter-checkbox"
                      label="isPrivate"
                      style={{ marginLeft: "0px" }}
                      // checked={this.state.isPrivatestatus}
                      // onChange={(e) => {
                      //   if (e.target.checked == true) {
                      //     this.state.isPrivate.push(true);
                      //   } else {
                      //     this.state.isPrivate.pop(true);
                      //   }
                      //   this.setState({ isPrivatestatus: e.target.checked });
                      // }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isPublic"
                          style={{
                            color: "rgba(0, 90, 152, 1)",
                            paddingLeft: "0px",
                          }}
                        />
                      }
                      className="filter-text subhead-filter"
                      label="isPublic"
                      // checked={this.state.isPublicstatus}
                      // onChange={(e) => {
                      //   if (e.target.checked == true) {
                      //     this.state.isPrivate.push(false);
                      //   } else {
                      //     this.state.isPrivate.pop(false);
                      //   }
                      //   this.setState({ isPublicstatus: e.target.checked });
                      // }}
                    />
                  </FormGroup>
                  <Divider className="ruler-filter" />
                </Grid> */}

                <Grid item xs={3}>
                  <FormGroup className="display-inline align-filter-checkbox head-filter">
                    <FormLabel
                      component="legend"
                      className="filter-text head-filter"
                      style={{ marginTop: "15px" }}
                    >
                      Organisation Name
                    </FormLabel>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      className="auto-check-user heading-option"
                      options={this.state.organisationList}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.organisationName}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                            iconStyle={{
                              fill: "#FFC805",
                              outline: "2px solid red",
                            }}
                          />
                          <Grid item sm={6}>
                            <span
                              className="headig-option font-size-14"
                              style={{ float: "left" }}
                            >
                              {option.organisationName}
                            </span>
                          </Grid>
                        </React.Fragment>
                      )}
                      style={{ width: "290px", marginTop: "20px" }}
                      onChange={this.onOranganisationChange}
                      defaultValue={this.state.categoryName}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          shrink={true}
                          placeholder="Select Category"
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={6}>
                  <FormGroup className="display-inline align-filter-checkbox head-filter">
                    <FormLabel
                      component="legend"
                      className="filter-text head-filter"
                    >
                      Category Name
                    </FormLabel>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      className="auto-check-user heading-option"
                      options={this.state.categoryList}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.categoryName}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                            iconStyle={{
                              fill: "#FFC805",
                              outline: "2px solid red",
                            }}
                          />
                          <Grid item sm={6}>
                            <span
                              className="headig-option font-size-14"
                              style={{ float: "left" }}
                            >
                              {option.categoryName}
                            </span>
                          </Grid>
                        </React.Fragment>
                      )}
                      style={{ width: "290px", marginTop: "20px" }}
                      onChange={this.onTagsChange}
                      defaultValue={this.state.categoryName}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          shrink={true}
                          placeholder="Select Category"
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                class="btn-doc heading1"
                onClick={this.applyFilter}
                style={{ marginBottom: "20px", marginRight: "20px" }}
                disableTouchRipple="false"
              >
                Apply Filter
              </Button>
              <Button
                class="btn-doc heading1"
                onClick={this.resetAll}
                style={{ marginBottom: "20px", marginRight: "20px" }}
                disableTouchRipple="false"
              >
                Clear All
              </Button>
              {/* <Button
                class="btn-doc heading1"
                onClick={this.cancel}
                style={{ marginBottom: "20px", marginRight: "20px" }}
              >
                Cancel
              </Button> */}
            </DialogActions>
          </Dialog>
        </main>
      </div>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state",state)
  return {
    shareddocList: state.document.shareddocList,
    categoryList: state.user.categoryList,
    filterData: state.document.shareddocList,
    organisationList: state.organization.organizationList,
  };
}
export default connect(mapStateToProps, {
  getsharedDocuments,
  clearDocument,
  getDocFilteredSharedData,
  saveFilterRes,
  getcategoryPrac,
  getOrganizations,
})(withStyles(useStyles)(MyDocuments));
