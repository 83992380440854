import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ShowMoreText from "react-show-more-text";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
  InputBase,
  IconButton,
  Avatar,
  TextareaAutosize,
  InputLabel,
  FormControl,
  Switch,
  TextField,
  FormHelperText,
  NativeSelect,
  Icon,
  Button,
  Box,
  Grid,
  Typography,
  MenuItem,
  Menu,
  Modal,
  Select,
} from "@material-ui/core";
import leftArrow from "../../../assets/left.svg";
import Nav from "../../../component/Nav.js";
import { SelectValue } from "./constants/dropDownStyled";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core/";
import Rating from "@material-ui/lab/Rating";
import download from "../../../assets/download.png";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Frame from "../../../assets/Frame.svg";
import pdf from "../../../assets/Pdf.png";
import csv from "../../../assets/CSV.svg";
import jpeg from "../../../assets/JPEG.svg";
import excel from "../../../assets/excel 1.svg";
import doc from "../../../assets/DOCX.svg";
import ppt from "../../../assets/PPT.svg";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Loader from "../../../component/Loader";
import { TrafficOutlined } from "@material-ui/icons";
import FileSaver from "file-saver";
import axios from "axios";
import { API_URL } from "../../../actions/utilAction";
import { jsonApiHeader } from "../../../actions/utilAction";
import { toasterMessage } from "../../../actions/util";
import { Link } from "react-router-dom";
import moment from "moment";
import { DropzoneArea } from "material-ui-dropzone";
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (state) => ({});
const access_token = localStorage.access_token;

const drawerWidth = 316;
const icon = (
  <CheckBoxOutlineBlankIcon fontSize="small" style={{ fill: "#005A98" }} />
);
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  rootCard: {
    maxWidth: 345,
  },
  centerImg:{
    justifyContent:"center",
    display:"flex",
    margin:"0px 20px",
  },
  alignItem:{
    [theme.breakpoints.up('md')]: {
      marginLeft:"20px"
     },
     [theme.breakpoints.down('sm')]: {
      marginTop:"30px"
     },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  appBarShift: {
    marginLeft: "150px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  media: {
    padding: "35px 50px",
    margin: "15px",
    borderRadius: "10px",
    background: "#FFF8F8",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2, 2),
    paddingRight: theme.spacing(5),
    position: "relative",
    top: "10rem",
  },
  inputStyle: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFFFFF",
    border: "1px solid rgba(255, 207, 92, 1)",
    fontSize: 16,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    padding: "0px",
    width: "100%",
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  inputWidth: {
    minWidth: "413px",
    height: "50px",
  },
  textAreWidth: {
    minWidth: "395px",
    minHeight: "160px",
    borderRadius: "10px",
  },
  submitClick: {
    [theme.breakpoints.down("md")]: {
      padding: "0px 25px",
    },
  },
  focused: {},
  iconOpen: {},
});
const BootstrapInput = withStyles((theme) => ({
  minWidth: "413px",
  width: "100%",
}))(InputBase);

const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
];

class ViewDocumentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      checkedA: true,
      modal: false,
      title: "My Documents",
      data: "",
      docStatus: "",
      loader: true,
      errorDoc: "",
      files: [],
      fileUploadModal: false,
      buttonDisabled: false,
      expand: false,
    };
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  handleExpand = () =>{
    this.setState({expand: !this.state.expand})
  }
  handleChange = (event) => {
    let name = event.target.name;
    let checked = event.target.checked;
    this.setState((prevState) => {
      return { [name]: checked };
    });
  };
  handlefileUploadModal = () => {
    this.setState({ fileUploadModal: !this.state.fileUploadModal });
  };
  handleModalClose = (data) => {
    this.setState({ modal: false });
  };
  componentDidMount() {
    this.setState({
      data: this.props.location.state.data,
      docStatus: this.props.location.state.data.currentDocument.status,
      loader: false,
    });
  }

  downloadDocument = () => {
    // this.setState({ loader: true });
    let access_token = localStorage.access_token;
    axios
      .get(
        `${API_URL}/document/downloadDoc/${this.state.data.currentDocument.fileId}`,
        {
          headers: jsonApiHeader(access_token, "blob"),
          responseType: "blob",
        }
      )
      .then((res) => {
        this.setState({ fileDownloading: true, loader: false }, () => {
          FileSaver.saveAs(res.data, `${this.state.data.documentName}`);
        });

        console.log(res.data, "from here");
      })
      .catch((error) => {
        this.setState({ loader: false });
        // this.handleRejectModal()
        toasterMessage(
					"error",
					error.response?.data?.error || "Something bad happened"
				);
      });
  };

  getDocSize = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  viewHistory = () => {
    this.props.history.push({
      pathname: "/history",
      state: {
        data: this.state.data.currentDocument,
        docName: this.state.data.documentName,
      },
    });
  };
  viewRatings = () => {
    this.props.history.push({
      pathname: "/ratingAndReviews",
      state: {
        data: this.state.data.currentDocument,
        docName: this.state.data.documentName,
      },
    });
  };

  handleImage(files) {
    let file_size = files[0] && files[0].size ? files[0].size : 0;
    if (file_size > 5316476) {
      this.setState({
        files: files,
        errorDoc: "Max file size allowed is 5MB",
      });
    } else {
      this.setState({
        files: files,
        errorDoc: "",
      });
    }
  }
  uploadNewVersion = () => {
    this.setState({
      buttonDisabled: true,
    });
    let formData = new FormData();
    formData.append("documentId", this.state.data.currentDocument.documentId);
    formData.append("file", this.state.files[0]);
    // document/versionUpdate
    axios
      .post(`${API_URL}/document/versionUpdate`, formData, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({
            loader: false,
            buttonDisabled: false,
          });
          this.handlefileUploadModal();
          toasterMessage("success", "Version Uploaded Successfully");
        }
      })
      .catch((error) => {
        this.setState({ loader: false });

        this.handlefileUploadModal();
        
        toasterMessage("error", error.response.data?.message || "Something bad happened");
        if (error.response.status === 300) {
        toasterMessage("error", error.response.data?.message || "Something bad happened");
        }
      })
      .catch(() => {
        this.setState({ loader: false });
        toasterMessage("error", "Something bad happened");
      });
  };

  editDocument = () => {
    this.props.history.push({
      pathname: "/edit",
      state: {
        data: this.state.data,
      },
    });
  };

  render() {
    console.log(this.state.data, "from render");
    const { classes } = this.props;

    return (
      <div>
        {/* {this.state.data && !this.state.loader ? ( */}
        <>
          <div className={classes.root}>
            <Nav
              handleDrawerOpen={this.handleDrawerOpen}
              handleDrawerClose={this.handleDrawerClose}
              open={this.state.open}
              title={this.state.title}
              active={0}
            />
            <main
              className={classes.content}
              className={clsx(classes.content, {
                [classes.appBarShift]: this.state.open,

          
              })}
            >
              {this.state.data && !this.state.loader ? (
                <>
                  <Box mb={3}>
                    <Typography
                      variant="h5"
                      pb={0}
                      color="textSecondary"
                      className="ListHeading "
                      component="h5"
                    >
                      <Icon onClick={(e) => this.props.history.goBack()}>
                        <img
                          className={classes.imageIcon}
                          className="arrow-position"
                          src={leftArrow}
                        />
                      </Icon>

                      {this.state.data.documentName}
                    </Typography>

                    <Button
                      onClick={(e) => this.editDocument()}
                      class="btn-doc heading1"
                      style={{ padding: "19px 44px" }}
                      align="right"
                      style={{
                        float: "right",
                        position: "relative",
                        bottom: "40px",
                      }}
                      disableTouchRipple="false"
                    >
                      Edit Document
                    </Button>
                  </Box>

                  <Grid container>
                    <Grid item xs={12} sm={12} lg={12}>
                      <div
                        style={{ height: "auto", paddingBottom: "30px" }}
                        className="shared-details view-details-color"
                      >
                        <Grid container className={classes.centerImg}>
                          <Grid item  align-items-xs-center justify-xs-flex-end  lg={4}>
                            <div className="img-side display-center">
                              {/* {this.state.data.currentDocument.fileType == ".pdf"?
                            <img className={classes.media} style={{ margin: "auto" }} src={pdf}/>

                            
                         :this.state.data.currentDocument.fileType == ".csv"?
                         <img className={classes.media} style={{ margin: "auto" }} src={csv}/>:null
                         
                         }  */}
                              <img
                                className={classes.media}
                                style={{ margin: "auto" }}
                                src={
                                  this.state.data.currentDocument.fileType ==
                                  ".pdf"
                                    ? pdf
                                    : this.state.data.currentDocument
                                        .fileType == "application/pdf"
                                    ? pdf
                                    : this.state.data.currentDocument
                                        .fileType == ".csv"
                                    ? csv
                                    : this.state.data.currentDocument
                                        .fileType == "text/csv"
                                    ? csv
                                    : this.state.data.currentDocument
                                        .fileType == ".jpeg"
                                    ? jpeg
                                    : this.state.data.currentDocument
                                        .fileType == ".xls"
                                    ? excel
                                    : this.state.data.currentDocument
                                        .fileType == ".xlsx"
                                    ? excel
                                    : this.state.data.currentDocument
                                        .fileType == ".jpeg"
                                    ? jpeg
                                    : this.state.data.currentDocument
                                        .fileType == ".docx"
                                    ? doc
                                    : this.state.data.currentDocument
                                        .fileType == ".doc"
                                    ? doc
                                    : this.state.data.currentDocument
                                        .fileType == ".ppt"
                                    ? ppt
                                    : this.state.data.currentDocument
                                        .fileType == ".pptx"
                                    ? ppt
                                    : null
                                }
                              />
                            </div>
                            <Grid container>
                              <Grid item sm={12} xs={12} lg={12}>
                                <Typography
                                  variant="p"
                                  pb={0}
                                  color="textSecondary"
                                  className="listHeadingStar pd-0 margin-value mr-l-30"
                                  component="p"
                                >
                                  {this.state.data.getRatingShared.length &&  this.state.data.getRatingShared[0]
                                          .rating>0
                                    ? `${Math.round(
                                        this.state.data.getRatingShared[0]
                                          .rating
                                      )}.0`
                                    : "--"}
                                  <Rating
                                    disabled={true}
                                    name="size-small"
                                    name="pristine"
                                    className="pd-0 mr-l-30 rating-align"
                                    value={
                                      this.state.data.getRatingShared.length
                                        ? Math.round(
                                            this.state.data.getRatingShared[0]
                                              .rating
                                          )
                                        : 0
                                    }
                                    size="small"
                                  />
                                  <span
                                    className="seeAll-text"
                                    onClick={(e) => this.viewRatings()}
                                    style={{
                                      marginLeft: "80px",
                                    }}
                                  >
                                    see all
                                  </span>{" "}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} lg={8}>
                            <div
                              className="detail-side"
                              className={classes.detailSide}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                lg={12}
                                className="pd-t-40"
                              >
                                <Typography
                                  variant="h5"
                                  pb={0}
                                  color="textSecondary"
                                  className="ListHeading "
                                  component="h5"
                                >
                                  {this.state.data.documentName}
                                </Typography>
                              </Grid>
                              <Grid container className="pd-t-25">
                                <Grid item xs={4} sm={3} lg={3}>
                                  <span className="label-heading">
                                    Category:
                                  </span>
                                  <br />
                                  <span className="label-data">
                                    {this.state.data
                                      ? this.state.data.categoryId.categoryName
                                      : null}
                                  </span>
                                </Grid>
                                <Grid item xs={3} sm={4} lg={4}>
                                  <span className="label-heading">
                                    Version:
                                  </span>
                                  <br />
                                  <span className="label-data">
                                    V
                                    {1 +
                                      this.state.data.currentDocument
                                        .versionName /
                                        10}
                                  </span>
                                </Grid>
                                <Grid item xs={3} sm={3} lg={3}>
                                  <span className="label-heading">
                                    File Type:
                                  </span>
                                  <br />
                                  <span className="label-data">
                                    {this.state.data.currentDocument.fileType
                                      .substring(1)
                                      .toUpperCase()}
                                  </span>
                                </Grid>
                                <Grid item xs={2} sm={2} lg={2}>
                                  <span className="label-heading">
                                    File Size:
                                  </span>
                                  <br />
                                  <span className="label-data">
                                    {this.getDocSize(
                                      this.state.data.currentDocument
                                        .fileSizeBytes
                                    )}
                                  </span>
                                </Grid>
                              </Grid>
                              <Grid container className="pd-t-40">
                                <Grid item xs={3} sm={3} lg={3}>
                                  <InputLabel
                                    shrink={true}
                                    className="label-font input-label"
                                  >
                                    {/* Privacy */}
                                    Privacy
                                  </InputLabel>
                                  <Switch
                                    disabled={TrafficOutlined}
                                    checked={this.state.data.isPrivate}
                                    onChange={(event) => {
                                      this.handleChange(event);
                                    }}
                                    name="isPrivate"
                                    className="main-switch"
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4} lg={4}>
                                  <span className="label-heading">Status</span>
                                  <br />
                                  <span
                                    className={"label-data"}
                                    style={{
                                      color:
                                        this.state.docStatus ==
                                        "REVISION_REQUESTED"
                                          ? "#ff8b7b"
                                          : this.state.docStatus ==
                                            "PENDING_REVIEW"
                                          ? "#ff5551"
                                          : "#00c213",
                                    }}
                                  >
                                    {this.state.data
                                      ? this.state.docStatus
                                      : null}
                                    <br />
                                    {this.state.docStatus ==
                                    "REVISION_REQUESTED"
                                      ? `On ${moment(
                                          this.state.data.currentDocument
                                            .createdAt
                                        ).format("DD-MM-YYYY")}`
                                      : null}
                                  </span>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <span className="label-heading">
                                    Description
                                  </span>
                                  <br />
                                  <span className="label-data">
                                   <ShowMoreText
                                      lines={1}
                                      more={"Show More"}
                                      less={"Show Less"}
                                      onClick={this.handleExpand}
                                      expanded={this.state.expand}
                                      width={350}
                                    >
                                    {this.state.data.description}
                                    </ShowMoreText> 
                                    
                                  </span>
                                </Grid>
                              </Grid>
                              {this.state.docStatus == "REVISION_REQUESTED" ? (
                                <Grid container className="pd-t-40">
                                  <Grid item xs={12} sm={12}>
                                    <span className="label-heading">
                                      Response Posted By Admin
                                    </span>
                                    <br />
                                    <span className="label-data">
                                      {
                                        this.state.data.currentDocument
                                          .revisionCommentByAdmin
                                      }
                                    </span>
                                  </Grid>
                                </Grid>
                              ) : null}

                              <Grid container className="pd-t-40">
                                {this.state.data.isPrivate == false ? (
                                  <Grid item xs={12} sm={12} lg={12}>
                                    <InputLabel
                                      className="label-font input-label"
                                      shrink={true}
                                      style={{ marginBottom: "12px" }}
                                    >
                                      Shared with
                                    </InputLabel>
                                    <Autocomplete
                                      // disabled={true}
                                      limitTags={4}
                                      multiple
                                      id="checkboxes-tags-demo"
                                      className="auto-check-user inputText fontValue"
                                      options={this.state.data.sharedWith}
                                      style={{ padding: "0px" }}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) =>
                                        option.userName
                                      }
                                      renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            checked={true}
                                            iconStyle={{
                                              fill: "#FFC805",
                                              outline: "2px solid red",
                                            }}
                                          />
                                          {option.userName}
                                        </React.Fragment>
                                      )}
                                      defaultValue={this.state.data.sharedWith}
                                      style={{ width: "80%" }}
                                      renderInput={(params) => (
                                        console.log(params, "params"),
                                        (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            shrink={true}
                                            placeholder={
                                              this.state.data.sharedWith.length
                                                ? "Shared With ..."
                                                : "Not Shared"
                                            }
                                          />
                                        )
                                      )}
                                    />
                                  </Grid>
                                ) : null}
                              </Grid>




                              <Grid container className="pd-t-40">
                                {this.state.data.isPrivate == false ? (
                                  <Grid item xs={12} sm={12} lg={12}>
                                    <InputLabel
                                      className="label-font input-label"
                                      shrink={true}
                                      style={{ marginBottom: "12px" }}
                                    >
                                      Shared with Organisation
                                    </InputLabel>
                                    <Autocomplete
                                      // disabled={true}
                                      multiple
                                      limitTags={5}
                                      id="checkboxes-tags-demo"
                                      className="auto-check-user inputText fontValue"
                                      options={this.state.data.sharedWithOrganisation}
                                      style={{ padding: "0px" }}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) =>
                                        option.organisationName
                                      }
                                      renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            checked={true}
                                            iconStyle={{
                                              fill: "#FFC805",
                                              outline: "2px solid red",
                                            }}
                                          />
                                          {option.organisationName}
                                        </React.Fragment>
                                      )}
                                      defaultValue={this.state.data.sharedWithOrganisation}
                                      style={{ width: "80%" }}
                                      renderInput={(params) => (
                                        console.log(params, "params"),
                                        (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            shrink={true}
                                            placeholder={
                                              this.state.data.sharedWithOrganisation.length
                                                ? "Shared With ..."
                                                : "Not Shared"
                                            }
                                          />
                                        )
                                      )}
                                    />
                                  </Grid>
                                ) : null}
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container className=" display-center" style={{textAlign:"center"}}>
                    <Grid item xs={12} sm={12} lg={3} md={3} className="mr-t-10 mr-2">
                      <Button
                        onClick={(e) => this.downloadDocument()}
                        type="submit"
                        className="main-btn submit-btn btn-shared text-transform-inherit"
                        disableTouchRipple="false"
                      >
                        Download
                      </Button>
                   </Grid>
                    <Grid item  xs={12} sm={12} lg={3} md={12} className="mr-t-10">
                      <Box  className={classes.alignItem}>
                        <Button
                          type="submit"
                          className=" shared-btn-rate btn-shared text-transform-inherit"
                          onClick={(e) => this.handlefileUploadModal()}
                          disableTouchRipple="false"
                        >
                          Upload New Version
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3} md={12} className="mr-t-10">
                      <Box className={classes.alignItem}  >
                        <Button
                          onClick={this.viewHistory}
                          type="submit"
                          className=" shared-btn-rate btn-shared text-transform-inherit"
                          disableTouchRipple="false"
                        >
                          View History
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>

                  {/* <Modal
                    open={this.state.fileUploadModal}
                    onClose={this.state.handlefileUploadModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  >
                   <div>vishal</div>
                  </Modal> */}

                  <Dialog
                    open={this.state.fileUploadModal}
                    onClose={this.handlefileUploadModal}
                    aria-labelledby="form-dialog-title"
                    className="dialog-main-div"
                  >
                    <DialogContent className="dialog-content pd-70">
                      <Grid container className="display-center">
                        <Grid
                          item
                          className="mr-b-40"
                          style={{ display: "contents" }}
                        >
                          <Typography
                            variant="h5"
                            pb={0}
                            color="textSecondary"
                            className="ListHeading "
                            component="h5"
                          >
                            Upload New Version
                          </Typography>
                          <Grid container>
                            <Grid item xs={12} sm={12}>
                              <div
                                className="uploadDocDiv uploadDocColor"
                                style={{ margin: "20px 0px", height: "340px" }}
                              >
                                <Grid
                                  container
                                  className={classes.marginDropZone}
                                  className="dropzone-modal"
                                >
                                  <DropzoneArea
                                    onChange={this.handleImage.bind(this)}
                                    dropzoneText={[
                                      "Drag and drop a file here or click",
                                      <span style={{ fontSize: "14px" }}>
                                        {" "}
                                        <br />( .JPEG, .PDF, .XLS, .DOC, .PPT,
                                        .CSV)
                                      </span>,
                                      "",
                                    ]}
                                    acceptedFiles={[
                                      ".xls,.xlsx,.csv,.jpeg,.doc,.docx,.ppt,.pdf,.pptx",
                                    ]}
                                    // acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                    maxFileSize={50000000}
                                    showFileNames
                                    showAlerts={false}
                                    filesLimit={1}
                                    previewGridProps={{
                                      container: { spacing: 1 },
                                      item: { xs: 12 },
                                    }}
                                  />
                                  <FormHelperText
                                    className="display-block-center"
                                    error
                                  >
                                    {this.state.errorDoc}
                                  </FormHelperText>
                                </Grid>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item></Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                      <button
                        type="submit"
                        className="btn-doc heading1"
                        onClick={(e) => this.uploadNewVersion(e)}
                        disabled={this.state.buttonDisabled}
                        style={{ marginBottom: "20px", marginRight: "20px" }}
                        disableTouchRipple="false"
                      >
                        Submit
                      </button>{" "}
                      <Button
                        class="btn-doc heading1"
                        onClick={this.handlefileUploadModal}
                        style={{ marginBottom: "20px", marginRight: "20px" }}
                        disableTouchRipple="false"
                      >
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              ) : (
                <Loader />
              )}
            </main>
          </div>
        </>
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(ViewDocumentDetails));
