import {
    actionCreator,
    userActionTypes,
    jsonApiHeader
} from '../constants/apiConstants';

import { API_URL } from '../../../../actions/utilAction';
import axios from 'axios';
import * as constant from '../../../../actions/constant';
import { toasterMessage } from "../../../../actions/util";
// import history from '../../../../history.js';

let access_token = localStorage.access_token;
// let access_token = sessionStorage.access_token;

export function getUsers() {
    return (dispatch) => {
        axios.get(API_URL + constant.USER_LIST, {
            headers: jsonApiHeader(access_token, 'application/json')
        }).then(function (response) {
            if (response.data.status === 200) {
                // toasterMessage("success", response.data.message);
                dispatch(actionCreator(userActionTypes.get_user.SUCCESS,
                    { userList: response.data.data.user, totalCount: response.data.data.totalUser }));
            }
        }).catch((error) => {
            toasterMessage("error", error.response.data.error);
            if (error.response.status === 300) {
                toasterMessage("error", error.response.data.error);
            }
        }).catch(() => {
            toasterMessage('error', 'Something bad happened')
        })
    };
}
export function getUserhistory(userArr) {
    return (dispatch) => {
        axios.post(API_URL + constant.GET_ORG_USER, userArr, {
            headers: jsonApiHeader(access_token, 'application/json')
        }).then(function (response) {
            if (response.data.status === 200) {
                // toasterMessage("success", response.data.message);
                dispatch(actionCreator(userActionTypes.get_user.SUCCESS,
                    { userList: response.data.data, totalCount: response.data.data.length }));
            }
        }).catch((error) => {
            toasterMessage("error", error.response.data.error);
            if (error.response.status === 300) {
                toasterMessage("error", error.response.data.error);
            }
        }).catch(() => {
            toasterMessage('error', 'Something bad happened')
        })
    }
}
export function addUser(authObj) {
    // return false
    return (dispatch) => {
        axios.post(API_URL + constant.ADD_USER, authObj, {
            headers: jsonApiHeader(access_token, 'application/json')
        }).then(function (response) {
            if (response.data.status === 200) {
                toasterMessage("success", response.data.message)
                axios.get(API_URL + constant.USER_LIST, {
                    headers: jsonApiHeader(access_token, 'application/json')
                }).then(function (response) {
                    if (response.data.status === 200) {
                        // toasterMessage("success", response.data.message);
                        dispatch(actionCreator(userActionTypes.get_user.SUCCESS,
                            { userList: response.data.data.user, totalCount: response.data.data.totalUser }));
                    }
                })
            }
        }).catch((error) => {
            toasterMessage("error", error.response.data.error);
            if (error.response.status === 300) {
                toasterMessage("error", error.response.data.error);
            }
        }).catch(() => {
            toasterMessage('error', 'Something bad happened')
        })
    };
}

export function editStatus(authObj, callback) {
    return (dispatch) => {
        axios.put(API_URL + constant.EDIT_USER, authObj, {
            headers: jsonApiHeader(access_token, 'application/json')
        }).then(function (response) {
            if (response.data.status === 200) {
                toasterMessage("success", response.data.message);
                return callback('success');
            }
        }).catch((error) => {
            toasterMessage("error", error.response.data.error);
            return callback(error);
        }).catch(() => {
            toasterMessage('error', 'Something bad happened')
            return callback('err');
        })
    };
}

export function getcategoryPrac() {
    return (dispatch) => {
        axios.get(API_URL + constant.CATEGORY_LIST, {
            headers: jsonApiHeader(access_token, 'application/json')
        }).then(function (response) {
            if (response.data.status === 200) {
                // toasterMessage("success", response.data.message);
                dispatch(actionCreator(userActionTypes.get_cat.SUCCESS,
                    { categoryList: response.data.data }));
            }
        }).catch((error) => {
            toasterMessage("error", error.response.data.error);
            if (error.response.status === 300) {
                toasterMessage("error", error.response.data.error);
            }
        }).catch(() => {
            toasterMessage('error', 'Something bad happened')
        })
    };
}
export function getPractitioner() {
    return (dispatch) => {
        axios.get(API_URL + constant.PRAC_LIST, {
            headers: jsonApiHeader(access_token, 'application/json')
        }).then(function (response) {
            if (response.data.status === 200) {
                // toasterMessage("success", response.data.message);
                dispatch(actionCreator(userActionTypes.get_prac.SUCCESS,
                    { pracList: response.data.data }));
            }
        }).catch((error) => {
            toasterMessage("error", error.response.data.error);
            if (error.response.status === 300) {
                toasterMessage("error", error.response.data.error);
            }
        }).catch(() => {
            toasterMessage('error', 'Something bad happened')
        })
    };
}

export function getUserDetails() {
    return (dispatch) => {
        axios.get(API_URL + constant.GET_USER_DETAILS, {
            headers: jsonApiHeader(access_token, 'application/json')
        }).then(function (response) {
            if (response.data.status === 200) {
                // toasterMessage("success", response.data.message);
                dispatch(actionCreator(userActionTypes.get_user_details.SUCCESS,
                    { userDetails: response.data.data }));
            }
        }).catch((error) => {
            toasterMessage("error", error.response.data.error);
            if (error.response.status === 300) {
                toasterMessage("error", error.response.data.error);
            }
        }).catch(() => {
            toasterMessage('error', 'Something bad happened')
        })
    };
}

// export function editUser(authObj) {
//     // return false
//     return (dispatch) => {
//         axios.put(API_URL + constant.EDIT_USER_DETAILS, authObj, {
//             headers: jsonApiHeader(access_token, 'application/json')
//         }).then(function (response) {
//             if (response.data.status === 200) {
//                 toasterMessage("success", response.data.message)
//                 axios.get(API_URL + constant.GET_USER_DETAILS, {
//                     headers: jsonApiHeader(access_token, 'application/json')
//                 }).then(function (response) {
//                     if (response.data.status === 200) {
//                         // toasterMessage("success", response.data.message);
//                         dispatch(actionCreator(userActionTypes.get_user_details.SUCCESS,
//                             { userDetails: response.data.data }));
//                         history.push('/myDocuments');
//                     }
//                 }).catch((error) => {
//                     toasterMessage("error", error.response.data.error);
//                     if (error.response.status === 300) {
//                         toasterMessage("error", error.response.data.error);
//                     }
//                 }).catch(() => {
//                     toasterMessage('error', 'Something bad happened')
//                 })
//             }
//         });
//     }
// }