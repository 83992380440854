import React, { useState, useEffect, useRef } from "react";

import styles from "./participant.module.scss";

const Participant = ({ participant, remoteParticipant }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [network, setNetwork] = useState(null);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    const participantNetworkQuality = (networkQuality) => {
      setNetwork(networkQuality);
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);
    participant.on("networkQualityLevelChanged", participantNetworkQuality);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <div
      className={`${styles.participant} ${
        remoteParticipant ? styles.remoteParticipant : ""
      }`}
    >
      <h3 className="shadow-lg">{participant.identity}</h3>
      <div className={styles.signal}>
        {network === 1 ? <span className={styles.signal1}></span> : null}
        {network === 2 ? (
          <>
            <span className={`${styles.signal1} ${styles.badNetwork}`}></span>
            <span className={`${styles.signal2} ${styles.badNetwork}`}></span>
          </>
        ) : null}
        {network === 3 ? (
          <>
            <span className={`${styles.signal1} ${styles.badNetwork}`}></span>
            <span className={`${styles.signal2} ${styles.badNetwork}`}></span>
            <span className={`${styles.signal3} ${styles.badNetwork}`}></span>
          </>
        ) : null}
        {network === 4 ? (
          <>
            <span className={`${styles.signal1} ${styles.okNetwork}`}></span>
            <span className={`${styles.signal2} ${styles.okNetwork}`}></span>
            <span className={`${styles.signal3} ${styles.okNetwork}`}></span>
            <span className={`${styles.signal4} ${styles.okNetwork}`}></span>
          </>
        ) : null}
        {network === 5 ? (
          <>
            <span className={`${styles.signal1} ${styles.fineNetwork}`}></span>
            <span className={`${styles.signal2} ${styles.fineNetwork}`}></span>
            <span className={`${styles.signal3} ${styles.fineNetwork}`}></span>
            <span className={`${styles.signal4} ${styles.fineNetwork}`}></span>
            <span className={`${styles.signal5} ${styles.fineNetwork}`}></span>
          </>
        ) : null}
      </div>
      <video ref={videoRef} autoPlay={true} />
      <audio ref={audioRef} autoPlay={true} />
    </div>
  );
};

export default Participant;
