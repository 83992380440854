import { organizationActionTypes } from './../constants/apiConstants';

const initialState = {
    organizationList: [],
    totalCount: 0,
  
};

const organizationReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case organizationActionTypes.get_organization.SUCCESS:
            return {
                ...state,
                organizationList: payload.organizationList,
                totalCount: payload.totalCount
            };
     
        default:
            return state;
    }
};

export default organizationReducer;
