import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "font-awesome/css/font-awesome.min.css";
import {
  Button,
  CardActions,
  CardContent,
  FormLabel,
  FormGroup,
  FormControlLabel,
  TextField,
  Hidden,
  Divider,
  Checkbox,
  CardMedia,
  FormControl,
  InputLabel,
  FormHelperText,
  Card,
  Grid,
  Paper,
  IconButton,
  Typography,
  Box,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { SelectValue } from "./constants/dropDownStyled.js";
import { Link } from "react-router-dom";
import Nav from "../../../component/Nav.js";
import unLock from "../../../assets/Unlock.svg";
import lock from "../../../assets/lock.svg";
import download from "../../../assets/download.svg";
import share from "../../../assets/share.svg";
import calender from "../../../assets/calendar.svg";
import {
  getDocuments,
  getDocFilteredData,
  saveFilterRes,
} from "./actions/documentActions.js";
import Loader from "../../../component/Loader.js";
import momentPropTypes from "react-moment-proptypes";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
} from "@material-ui/core/";
import { getUserDetails } from "../../admin/user/actions/userAction.js";
import CloseIcon from "@material-ui/icons/Close";
import { getcategoryPrac } from "../../admin/user/actions/userAction.js";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import ResponsiveDateRangePicker from "./constants/ResponsiveDateRangePicker.js";
import "react-dates/initialize";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import pdf from "../../../assets/Pdf.png";
import csv from "../../../assets/CSV.svg";
import jpeg from "../../../assets/JPEG.svg";
import excel from "../../../assets/excel 1.svg";
import doc from "../../../assets/DOCX.svg";
import ppt from "../../../assets/PPT.svg";
import { DateRangePicker, isInclusivelyBeforeDay } from "react-dates";
import filter from "../../../assets/filterIcon.svg";
import "react-dates/lib/css/_datepicker.css";
import disableBrowserBackButton from "disable-browser-back-navigation";
import { API_URL } from "../../../actions/utilAction.js";
import { jsonApiHeader } from "../../../actions/utilAction.js";
import FileSaver from "file-saver";
import axios from "axios";
import { toasterMessage } from "../../../actions/util.js";

moment.locale("pt-br");

const icon = (
  <CheckBoxOutlineBlankIcon fontSize="small" style={{ fill: "#005A98" }} />
);
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const drawerWidth = 316;

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  rootCard: {
    [theme.breakpoints.up("md")]: {
      maxWidth: 345,
    },
    boxShadow: "0px 7px 24px 0px rgba(0, 90, 152, 0.07)",
  },
  marginButton:{
    [theme.breakpoints.down('md')]: {
    marginBottom:"10px",
    }
  },
  detailBtn:{
    padding: "16px 0px 16px 0px",
  background: "#ffc805 !important",
  marginRight: "20px",
  borderRadius: "10px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  alignFilterButton:{
    [theme.breakpoints.down("md")]: {
      display:"contents",
    },
  },
  widthCategory:{
    [theme.breakpoints.up('md')]: {
     width:"290px"
    },
    [theme.breakpoints.down('sm')]: {
     width:"180px"
    },
  },
  appBarShift: {
    marginLeft: "150px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  media: {
    paddingTop: "100%",
    margin: "15px",
    borderRadius: "10px",
    background: "#FFF8F8",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  alignButton: {
    [theme.breakpoints.up("md")]: {
      position: "relative",
      bottom: "20px",
    },
    [theme.breakpoints.down("md")]: {
      margin: "20px 0px",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    minHeight: "125px !important",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2, 2),
    paddingRight: theme.spacing(5),
    position: "relative",
    top: "10rem",
  },
});

class MyDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      load: true,
      documentList: [],
      status: "",
      fileType: "",
      categoryId: "",
      fromDate: "",
      toDate: "",
      title: "My Documents ",
      modal: false,
      categoryList: [],
      organisationList: [],
      categoryName: [],
      Status: [],
      fileType: [],
      categoryArray: [],
      isPrivate: [],
      pendingStatus: false,
      reviewStatus: false,
      approveStatus: false,
      docStatus: false,
      pptStatus: false,
      csvStatus: false,
      pdfStatus: false,
      jpegStatus: false,
      xlsStatus: false,
      isPublicstatus: false,
      isPrivatestatus: false,
      startDate: "",
      endDate: "",
      focusedInput: "",
      startDate1: "",
      endDate1: "",
      filterObj: "",
      organisation: [],
      buttonDisabled: true,
    };
  }

  componentDidMount() {
    // disableBrowserBackButton();
    this.props.getDocuments();
    this.props.getcategoryPrac();
    this.checkPassword();

    this.setState({
      load: true,
    });
    if (this.props.filterObj.startDate && this.props.filterObj.endDate) {
      this.setState({
        // startDate: moment(this.props.filterObj.startDate),
        // endDate: moment(this.props.filterObj.endDate),
        organisation: this.props.filterObj.organisation,
      });
    }
  }

  checkPassword = () => {
    const passwordChanged = localStorage.getItem('passwordChanged');
    if (passwordChanged) {
      try {
        const passwordChangedParsed = JSON.parse(passwordChanged);
        console.log(passwordChangedParsed == false);
        if (passwordChangedParsed == false) {
          console.log(passwordChanged, "from if details");
          toasterMessage("error", "Please change your Temporary password");
          this.props.history.push({
            pathname: "/change-password",
          });
        }
      } catch (e) {
        console.error('Error parsing passwordChanged from localStorage:', e);
      }
    }
  };
  
  static getDerivedStateFromProps(props, state) {
    if (props.documentList !== state.documentList) {
      return {
        documentList: props.documentList,
        load: false,
      };
    }
    if (props.categoryList !== state.categoryList) {
      return {
        categoryList: props.categoryList,
      };
    }

    if (props.organisationList != state.organisatioList) {
      return {
        organisationList: props.organisationList,
      };
    }

    if (props.filterObj != undefined && props.filterObj !== state.filterObj) {
      console.log("filterObj state", props.filterObj);
      // this.setState({startDate:props.filterObj.startDate,endDate:props.filterObj.endDate})
      return {
        filterObj: props.filterObj,
        pendingStatus: props.filterObj.pendingStatus,
        categoryName: props.filterObj.categoryName,
        organisation: props.filterObj.organisation,
        isPrivate: props.filterObj.isPrivate,
        reviewStatus: props.filterObj.reviewStatus,
        approveStatus: props.filterObj.approveStatus,
        docStatus: props.filterObj.docStatus,
        pptStatus: props.filterObj.pptStatus,
        csvStatus: props.filterObj.csvStatus,
        pdfStatus: props.filterObj.pdfStatus,
        jpegStatus: props.filterObj.jpegStatus,
        xlsStatus: props.filterPbj.xlsStatus,
        isPublicstatus: props.filterObj.isPublicstatus,
        isPrivatestatus: props.filterObj.isPrivatestatus,
      };
    }
    // Return null if the state hasn't changed
    return null;
  }
  // componentDidUpdate(prevprops,nextProps){
  //   // console.log(prevprops,nextProps,'did')
  // if(prevprops.filterObj!=this.props.filterObj){
  //   console.log(prevprops,nextProps,'inside if')
  //   this.setState({startDate:this.props.filterObj.startDate})

  // }
  // }

  handleModalOpen = (data) => {
    this.setState({ modal: true });
  };

  handleModalClose = (data) => {
    this.setState({ modal: false });
  };

  applyFilter = () => {
    this.setState({
      modal: false,
      load: true,
    });
    let obj = {
      filter: {
        status: this.state.Status,
        fileType: this.state.fileType,
        categoryId: this.state.categoryArray,
        isPrivate: this.state.isPrivate,
        fromDate: this.state.startDate1,
        toDate: this.state.endDate1,
        organisationId: this.state.organisationArray,
      },
      type: 2,
    };

    // console.log("status",this.state.Status)
    if (this.state.Status && this.state.Status.length == 0) {
      // console.log("status")
      delete obj.filter.status;
    }
    if (this.state.fileType && this.state.fileType.length == 0) {
      delete obj.filter.fileType;
    }
    if (this.state.categoryArray && this.state.categoryArray.length == 0) {
      delete obj.filter.categoryId;
    }
    if (this.state.isPrivate && this.state.isPrivate.length == 0) {
      delete obj.filter.isPrivate;
    }
    if (this.state.startDate1 == "") {
      delete obj.filter.fromDate;
    }
    if (this.state.endDate1 == "") {
      delete obj.filter.toDate;
    }
    if (
      this.state.organisationArray &&
      this.state.organisationArray.lenght == 0
    ) {
      delete obj.filter.organisationId;
    }
    this.props.getDocFilteredData(obj);

    let filterObj = {
      categoryName: this.state.categoryName,
      Status: this.state.Status,
      fileType: this.state.fileType,
      categoryArray: this.state.categoryArray,
      isPrivate: this.state.isPrivate,
      pendingStatus: this.state.pendingStatus,
      reviewStatus: this.state.reviewStatus,
      approveStatus: this.state.approveStatus,
      docStatus: this.state.docStatus,
      pptStatus: this.state.pptStatus,
      csvStatus: this.state.csvStatus,
      pdfStatus: this.state.pdfStatus,
      jpegStatus: this.state.jpegStatus,
      xlsStatus: this.state.xlsStatus,
      isPublicstatus: this.state.isPublicstatus,
      isPrivatestatus: this.state.isPrivatestatus,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      startDate1: this.state.startDate1,
      endDate1: this.state.endDate1,
      organisation: this.state.organisation,
      organisationArray: this.state.organisationArray,
    };

    this.props.saveFilterRes(filterObj);
  };
  saveFilter = () => {
    console.log(this.state.endDate, "from");
    let filterObj = {
      categoryName: this.state.categoryName,
      Status: this.state.Status,
      fileType: this.state.fileType,
      categoryArray: this.state.categoryArray,
      isPrivate: this.state.isPrivate,
      pendingStatus: this.state.pendingStatus,
      reviewStatus: this.state.reviewStatus,
      approveStatus: this.state.approveStatus,
      docStatus: this.state.docStatus,
      pptStatus: this.state.pptStatus,
      csvStatus: this.state.csvStatus,
      pdfStatus: this.state.pdfStatus,
      jpegStatus: this.state.jpegStatus,
      xlsStatus: this.state.xlsStatus,
      isPublicstatus: this.state.isPublicstatus,
      isPrivatestatus: this.state.isPrivatestatus,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      startDate1: this.state.startDate1,
      endDate1: this.state.endDate1,
      organisation: this.state.organisation,
      organisationArray: this.state.organisationArray,
    };
    this.props.saveFilterRes(filterObj);
  };
  resetAll = () => {
    this.setState({
      modal: false,
      pendingStatus: false,
      reviewStatus: false,
      approveStatus: false,
      docStatus: false,
      pptStatus: false,
      csvStatus: false,
      pdfStatus: false,
      jpegStatus: false,
      xlsStatus: false,
      isPublicstatus: false,
      isPrivatestatus: false,
      Status: [],
      fileType: [],
      categoryArray: [],
      isPrivate: [],
      startDate: "",
      endDate: "",
      startDate1: "",
      endDate1: "",
      categoryName: [],
      organisation: [],
      organisationArray: [],
      buttonDisabled: true,
    });
    this.props.getDocuments();

    let filterObj = {
      pendingStatus: false,
      reviewStatus: false,
      approveStatus: false,
      docStatus: false,
      pptStatus: false,
      csvStatus: false,
      pdfStatus: false,
      jpegStatus: false,
      xlsStatus: false,
      isPublicstatus: false,
      isPrivatestatus: false,
      Status: [],
      fileType: [],
      categoryArray: [],
      isPrivate: [],
      startDate: "",
      endDate: "",
      startDate1: "",
      endDate1: "",
      categoryName: [],
      organisation: [],
      organisationArray: [],
    };

    this.props.saveFilterRes(filterObj);
  };

  cancel = () => {
    this.setState({
      modal: false,
      pendingStatus: this.state.filterObj.pendingStatus,
      categoryName: this.state.filterObj.categoryName,
      organisation: this.state.organisation,
      isPrivate: this.state.filterObj.isPrivate,
      reviewStatus: this.state.filterObj.reviewStatus,
      approveStatus: this.state.filterObj.approveStatus,
      docStatus: this.state.filterObj.docStatus,
      pptStatus: this.state.filterObj.pptStatus,
      csvStatus: this.state.filterObj.csvStatus,
      pdfStatus: this.state.filterObj.pdfStatus,
      jpegStatus: this.state.filterObj.jpegStatus,
      xlsStatus: this.state.filterObj.xlsStatus,
      isPublicstatus: this.state.filterObj.isPublicstatus,
      isPrivatestatus: this.state.filterObj.isPrivatestatus,
      buttonDisabled: true,
    });
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  onTagsChange = (event, values) => {
    // console.log("values",values)
    let arr = [];
    let arr2 = [];
    values.map((x, index) => {
      arr.push(x._id);
      arr2.push({ categoryName: x.categoryName });
    });
    this.setState({
      categoryArray: arr,
      categoryName: arr2,
      buttonDisabled: false,
    });
  };

  onCategoryChange = (event, values) => {
    // console.log("values",values)
    let arr = [];
    let arr2 = [];
    values.map((x, index) => {
      console.log(x, "here");
      arr.push(x._id);
      arr2.push({ organisationName: x.organisationName });
    });
    this.setState({
      organisationArray: arr,
      organisation: arr2,
      buttonDisabled: false,
    });
  };

  // isOutsideRange = (day) => day.isAfter(moment.parseZone());
  isOutsideRange = (day) => !isInclusivelyBeforeDay(day, moment());
  viewDetails = (data) => {
    //  let data= this.state.documentList.filter
    console.log(this.props);
    this.props.history.push({
      pathname: "/document-details",
      state: {
        data,
      },
    });
    // /document-details
  };
  downloadDocument = (data) => {
    console.log(data, "from here");
    // alert('hii')
    // this.setState({ load: true });
    let access_token = localStorage.access_token;
    axios
      .get(`${API_URL}/document/downloadDoc/${data.currentDocument.fileId}`, {
        headers: jsonApiHeader(access_token, "blob"),
        responseType: "blob",
      })
      .then((res) => {
        this.setState({ fileDownloading: true, load: false }, () => {
          FileSaver.saveAs(res.data, `${data.documentName}`);
        });

        console.log(res.data, "from here");
      })
      .catch((error) => {
        // this.handleRejectModal()
        this.setState({ fileDownloading: false, load: false })
        toasterMessage(
					"error",
					error.response?.data?.error || "Something bad happened"
				);
      })
      
  };

  editDocument = (data) => {
    this.props.history.push({
      pathname: "/edit",
      state: {
        data: data,
      },
    });
  };

  render() {
    console.log(this.props, "here are props");
    const { classes } = this.props;
    // const endDateString =
    //   this.state.endDate && this.state.endDate.format("MMMM Do YYYY");
    // const startDateString =
    //   this.state.startDate && this.state.startDate.format("MMMM Do YYYY");
    return (
      <div className={classes.root}>
        <Nav
          handleDrawerOpen={this.handleDrawerOpen}
          handleDrawerClose={this.handleDrawerClose}
          open={this.state.open}
          title={this.state.title}
          active={0}
        />

        <main
            className={clsx(classes.content, classes.appBarShift, {
                [classes.appBarShift]: this.state.open,
            })}
        >
          <Grid container>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography variant="body2" color="textSecondary" component="p">
                <span
                  className="heading1 mr-r-20"
                  style={{
                    lineHeight: "2.2",
                    top: "-3px",
                    position: "relative",
                  }}
                >
                  Current Version Upload Date{" "}
                </span>

                <DateRangePicker
                  startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                  endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                  focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={(focusedInput) =>
                    this.setState({ focusedInput })
                  } // PropTypes.func.isRequired,
                  endDatePlaceholderText={"End Date"}
                  startDatePlaceholderText={"Start Date"}
                  displayFormat={"MMM DD, YYYY"}
                  isOutsideRange={this.isOutsideRange}
                  minimumNights={0}
                  onDatesChange={({ startDate, endDate }) => {
                    // console.log("startDate", endDate);
                    // console.log("endDate", endDate);

                    let startDate1 =
                      startDate != "" && startDate != null
                        ? startDate.format("YYYY-MM-DD")
                        : "";
                    let endDate1 =
                      endDate != "" && endDate != null
                        ? endDate.format("YYYY-MM-DD")
                        : "";
                    // console.log(endDate,'before')
                    // console.log("startDate1", startDate1)
                    // console.log("endDate1", endDate1)
                    this.setState(
                      {
                        startDate,
                        endDate,
                        startDate1: startDate1,
                        endDate1: endDate1,
                      },
                      () => this.saveFilter()
                    );

                    if (
                      startDate != "" &&
                      endDate != "" &&
                      endDate != null &&
                      startDate != null
                      // this.state.startDate &&
                      // this.state.endDate
                    ) {
                      // console.log("inside")
                      let obj = {
                        filter: {
                          status: this.state.Status,
                          fileType: this.state.fileType,
                          categoryId: this.state.categoryArray,
                          isPrivate: this.state.isPrivate,
                          fromDate: startDate1,
                          toDate: endDate1,
                          organisationId: this.state.organisationArray,
                        },
                        type: 2,
                      };
                      if (this.state.Status.length == 0) {
                        delete obj.filter.status;
                      }
                      if (this.state.fileType.length == 0) {
                        delete obj.filter.fileType;
                      }
                      if (this.state.categoryArray.length == 0) {
                        delete obj.filter.categoryId;
                      }
                      if (this.state.isPrivate.length == 0) {
                        delete obj.filter.isPrivate;
                      }
                      if (startDate1 == "") {
                        delete obj.filter.fromDate;
                      }
                      if (endDate1 == "") {
                        delete obj.filter.toDate;
                      }

                      this.setState({ load: true });
                      this.props.getDocFilteredData(obj);
                    }
                  }}
                />
                <Hidden mdDown>
                    <img
                        className={`${classes.imageIcon} align-calender`}
                        src={calender}
                    />
                </Hidden>
              </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                lg={6}
                xl={6}
                className={`${classes.alignButton} align-add-btn align-btn`}
            >
              <Button
                onClick={this.handleModalOpen}
                className={clsx(classes.marginButton,classes.detailBtn)}
                disableTouchRipple="false"
              >
                {/* onClick={this.handleModalOpen} */}
                <img className={classes.imageIcon} src={filter} />
              </Button>
              <Link to={"/upload/"}>
                <Button
                  class="btn-doc heading1"
                  style={{ padding: "19px 44px", marginRight: "20px" }}
                  disableTouchRipple="false"
                  className={classes.marginButton}
                >
                  Add Document
                </Button>
              </Link>

              <Link to={"/upload-multiple-documents"}>
                <Button
                  class="btn-doc heading1"
                  style={{ padding: "19px 30px", marginTop: "2px " }}
                  disableTouchRipple="false"
                  className={classes.marginButton}
                >
                  Add Multiple Documents
                </Button>
              </Link>
            </Grid>
          </Grid>
          {this.state.load == true ? (
            <Loader />
          ) : (
            <Grid
              container
              spacing={4}
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              {this.state.documentList.length != 0
                ? this.state.documentList.map((x, index) => (
                    <Grid item xs={12} sm={6} md={3}>
                      <Card
                        key={x.currentDocument}
                        className={classes.rootCard}
                      >
                        {/* <Grid
                      onClick={(e) => {
                        this.viewDetails(x);
                      }}
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      <Card className={classes.rootCard}>
                    > */}
                        {/* image/jpeg */}
                        {x.currentDocument &&
                        x.currentDocument.fileType &&
                        x.currentDocument.fileType == "application/pdf" ? (
                          <CardMedia
                            className={classes.media}
                            image={require("../../../assets/Pdf.png")}
                            // title="img"
                          />
                        ) : (
                          ""
                        )}
                        {x.currentDocument &&
                        x.currentDocument.fileType &&
                        x.currentDocument.fileType == ".pdf" ? (
                          <CardMedia
                            onClick={(e) => {
                              this.viewDetails(x);
                            }}
                            className={classes.media}
                            image={require("../../../assets/Pdf.png")}
                            // title="img"
                          />
                        ) : (
                          ""
                        )}
                        {x.currentDocument &&
                        x.currentDocument.fileType &&
                        x.currentDocument.fileType == "text/csv" ? (
                          <CardMedia
                            onClick={(e) => {
                              this.viewDetails(x);
                            }}
                            className={classes.media}
                            image={require("../../../assets/CSV.svg")}
                            // title="img"
                          />
                        ) : (
                          ""
                        )}
                        {x.currentDocument &&
                        x.currentDocument.fileType &&
                        x.currentDocument.fileType == ".csv" ? (
                          <CardMedia
                            onClick={(e) => {
                              this.viewDetails(x);
                            }}
                            className={classes.media}
                            image={require("../../../assets/CSV.svg")}
                            // title="img"
                          />
                        ) : (
                          ""
                        )}
                        {x.currentDocument &&
                        x.currentDocument.fileType &&
                        x.currentDocument.fileType == ".jpeg" ? (
                          <CardMedia
                            onClick={(e) => {
                              this.viewDetails(x);
                            }}
                            className={classes.media}
                            image={require("../../../assets/JPEG.svg")}
                            // title="img"
                          />
                        ) : (
                          ""
                        )}
                           {x.currentDocument &&
                        x.currentDocument.fileType &&
                        x.currentDocument.fileType == ".jpg" ? (
                          <CardMedia
                            onClick={(e) => {
                              this.viewDetails(x);
                            }}
                            className={classes.media}
                            image={require("../../../assets/JPEG.svg")}
                            // title="img"
                          />
                        ) : (
                          ""
                        )}
                        {x.currentDocument &&
                        x.currentDocument.fileType &&
                        (x.currentDocument.fileType == ".xls" || x.currentDocument.fileType == ".xlsx") ? (
                          <CardMedia
                            onClick={(e) => {
                              this.viewDetails(x);
                            }}
                            className={classes.media}
                            image={require("../../../assets/excel 1.svg")}
                            // title="img"
                          />
                        ) : (
                          ""
                        )}
                        {x.currentDocument &&
                        x.currentDocument.fileType &&
                        (x.currentDocument.fileType == ".docx" || x.currentDocument.fileType == ".doc" ) ? (
                          <CardMedia
                            onClick={(e) => {
                              this.viewDetails(x);
                            }}
                            className={classes.media}
                            image={require("../../../assets/DOCX.svg")}
                            // title="img"
                          />
                        ) : (
                          ""
                        )}
                        {x.currentDocument &&
                        x.currentDocument.fileType &&
                        x.currentDocument.fileType == ".ppt" ? (
                          <CardMedia
                            onClick={(e) => {
                              this.viewDetails(x);
                            }}
                            className={classes.media}
                            image={require("../../../assets/PPT.svg")}
                            // title="img"
                          />
                        ) : (
                          ""
                        )}
                        {x.currentDocument &&
                        x.currentDocument.fileType &&
                        x.currentDocument.fileType == ".pptx" ? (
                          <CardMedia
                            onClick={(e) => {
                              this.viewDetails(x);
                            }}
                            className={classes.media}
                            image={require("../../../assets/PPT.svg")}
                            // title="img"
                          />
                        ) : (
                          ""
                        )}
                        <CardContent className="pd-t-b-0">
                          <Grid container>
                            <Grid item sm={11} xs={11}>
                              <Box
                                component="div"
                                className="ListHeading "
                                style={{ width: "100%", maxWidth: "180px" }}
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                {/* Tuberculosis - Cure.. */}
                                {x.documentName}
                              </Box>
                            </Grid>
                            {x.isPrivate == true ? (
                                <Grid item sm={1} xs={1}>
                                    <div className="align-icon">
                                        <img
                                            className={`${classes.imageIcon} arrow-position cursor-pointer`}
                                            src={lock}
                                        />
                                    </div>
                                </Grid>
                            ) : (
                                <Grid item sm={1} xs={1}>
                                    <div className="align-icon">
                                        <img
                                            className={`${classes.imageIcon} arrow-position cursor-pointer`}
                                            src={unLock}
                                        />
                                    </div>
                                </Grid>
                            )}
                          </Grid>
                        </CardContent>
                        <CardActions className="pd-l-r-16 ">
                          <Grid container>
                            <Grid item sm={1} xs={1}>
                              <Typography
                                variant="body2"
                                pb={0}
                                color="textSecondary"
                                className="ListHeading pd-0"
                                component="p"
                              >
                                {/* 4.0 */}
                                {x.getRatingShared.length &&
                                x.getRatingShared[0].rating > 0
                                  ? `${Math.round(
                                      x.getRatingShared[0].rating
                                    )}.0`
                                  : "--"}
                              </Typography>
                            </Grid>
                            <Grid item sm={9} xs={9}>
                              <IconButton
                                className="pd-0"
                                aria-label="add to favorites"
                              >
                                <Box
                                  component="fieldset"
                                  className="pd-0"
                                  borderColor="transparent"
                                >
                                  <Rating
                                    disabled={true}
                                    name="size-small"
                                    size="small"
                                    className="pd-0 align-rating"
                                    value={
                                      x.getRatingShared.length
                                        ? `${Math.round(
                                            x.getRatingShared[0].rating
                                          )}.0`
                                        : 0
                                    }
                                    // value={x.getRatingShared[0].rating}
                                  />
                                </Box>
                              </IconButton>
                            </Grid>
                            <Grid
                              onClick={() => this.downloadDocument(x)}
                              item
                              sm={1}
                              xs={1}
                            >
                            <div className="align-icon">
                                <img
                                    className={`${classes.imageIcon} arrow-position cursor-pointer`}
                                    height="14"
                                    width="14"
                                    src={download}
                                />
                            </div>
                            </Grid>
                            <Grid onClick={() => this.editDocument(x)} item sm={1}>
                                <div className="align-icon">
                                    <img
                                        className={`${classes.imageIcon} arrow-position cursor-pointer`}
                                        height="14"
                                        width="14"
                                        src={share}
                                    />
                                </div>
                            </Grid>
                          </Grid>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))
                : ""}
            </Grid>
          )}
          <Dialog
            open={this.state.modal}
            onClose={this.handleModalClose}
            aria-labelledby="form-dialog-title"
            className="dialog-main-div-filter"
          >
            <DialogTitle
              id="form-dialog-title"
              className="modal-header dialogTitleFilter"
              style={{ color: "#000000" }}
            >
              Filter
              <IconButton
                onClick={this.handleModalClose}
                style={{ position: "absolute", right: "20px", top: "26px" }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className="dialog-content pd-filter">
              <Grid container>
                <Grid item lg={6} xs={12}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel
                      component="legend"
                      className="filter-text head-filter"
                    >
                      Status
                    </FormLabel>
                    <FormGroup className="display-inline align-filter-checkbox">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="PENDING_REVIEW"
                            style={{
                              color: "rgba(0, 90, 152, 1)",
                              paddingLeft: "0px",
                              paddingRight: "3px",
                            }}
                          />
                        }
                        className="filter-text subhead-filter"
                        label="Pending review"
                        style={{ marginLeft: "0px", marginRight: "23px" }}
                        checked={this.state.pendingStatus}
                        onChange={(e) => {
                          if (e.target.checked == true) {
                            this.state.Status.push(e.target.name);
                          } else {
                            this.state.Status.pop(e.target.name);
                          }
                          this.setState({
                            pendingStatus: e.target.checked,
                            buttonDisabled: false,
                          });
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="REVISION_REQUESTED"
                            style={{
                              color: "rgba(0, 90, 152, 1)",
                              paddingRight: "3px",
                            }}
                          />
                        }
                        className="filter-text subhead-filter"
                        label="Revision Requested"
                        checked={this.state.reviewStatus}
                        onChange={(e) => {
                          if (e.target.checked == true) {
                            this.state.Status.push(e.target.name);
                          } else {
                            this.state.Status.pop(e.target.name);
                          }
                          this.setState({
                            reviewStatus: e.target.checked,
                            buttonDisabled: false,
                          });
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="APPROVED"
                            style={{
                              color: "rgba(0, 90, 152, 1)",
                              paddingRight: "3px",
                            }}
                          />
                        }
                        className="filter-text subhead-filter"
                        checked={this.state.approveStatus}
                        label="Approved"
                        onChange={(e) => {
                          if (e.target.checked == true) {
                            this.state.Status.push(e.target.name);
                          } else {
                            this.state.Status.pop(e.target.name);
                          }
                          this.setState(
                            {
                              approveStatus: e.target.checked,
                              buttonDisabled: false,
                            },
                            () =>
                              console.log(
                                this.state.approveStatus,
                                this.state.Status,
                                "approve"
                              )
                          );
                        }}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <FormControl component="fieldset" className="head-filter">
                    <FormLabel
                      component="legend"
                      className="filter-text head-filter"
                    >
                      Document Type
                    </FormLabel>
                    <FormGroup className="display-inline filter-text head-filter align-filter-checkbox">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name=".docx"
                            style={{
                              color: "rgba(0, 90, 152, 1)",
                              paddingLeft: "0px",
                              paddingRight: "3px",
                            }}
                          />
                        }
                        className="filter-text subhead-filter"
                        label="DOCX"
                        style={{ marginLeft: "0px", marginRight: "23px" }}
                        checked={this.state.docStatus}
                        onChange={(e) => {
                          if (e.target.checked == true) {
                            this.state.fileType.push(e.target.name);
                          } else {
                            this.state.fileType.pop(e.target.name);
                          }
                          this.setState({
                            docStatus: e.target.checked,
                            buttonDisabled: false,
                          });
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name=".pptx"
                            style={{
                              color: "rgba(0, 90, 152, 1)",
                              paddingLeft: "0px",
                              paddingRight: "3px",
                            }}
                          />
                        }
                        className="filter-text subhead-filter"
                        label="PPT"
                        checked={this.state.pptStatus}
                        style={{ marginLeft: "0px", marginRight: "23px" }}
                        onChange={(e) => {
                          if (e.target.checked == true) {
                            this.state.fileType.push(e.target.name);
                          } else {
                            this.state.fileType.pop(e.target.name);
                          }
                          this.setState({
                            pptStatus: e.target.checked,
                            buttonDisabled: false,
                          });
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name=".csv"
                            style={{
                              color: "rgba(0, 90, 152, 1)",
                              paddingLeft: "0px",
                              paddingRight: "3px",
                            }}
                          />
                        }
                        className="filter-text subhead-filter"
                        label="CSV"
                        style={{ marginLeft: "0px", marginRight: "23px" }}
                        checked={this.state.csvStatus}
                        onChange={(e) => {
                          if (e.target.checked == true) {
                            this.state.fileType.push(e.target.name);
                          } else {
                            this.state.fileType.pop(e.target.name);
                          }
                          this.setState({
                            csvStatus: e.target.checked,
                            buttonDisabled: false,
                          });
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name=".pdf"
                            style={{
                              color: "rgba(0, 90, 152, 1)",
                              paddingRight: "3px",
                              paddingLeft: "0px",
                            }}
                          />
                        }
                        className="filter-text subhead-filter"
                        label="PDF"
                        style={{ marginLeft: "0px", marginRight: "23px" }}
                        checked={this.state.pdfStatus}
                        onChange={(e) => {
                          if (e.target.checked == true) {
                            this.state.fileType.push(e.target.name);
                          } else {
                            this.state.fileType.pop(e.target.name);
                          }
                          this.setState({
                            pdfStatus: e.target.checked,
                            buttonDisabled: false,
                          });
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name=".jpeg"
                            style={{
                              color: "rgba(0, 90, 152, 1)",
                              paddingRight: "3px",
                              paddingLeft: "0px",
                            }}
                          />
                        }
                        className="filter-text subhead-filter"
                        label="JPEG"
                        checked={this.state.jpegStatus}
                        style={{ marginLeft: "0px", marginRight: "23px" }}
                        onChange={(e) => {
                          if (e.target.checked == true) {
                            this.state.fileType.push(e.target.name);
                          } else {
                            this.state.fileType.pop(e.target.name);
                          }
                          this.setState({
                            jpegStatus: e.target.checked,
                            buttonDisabled: false,
                          });
                        }}
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            name=".xls"
                            style={{ color: "rgba(0, 90, 152, 1)" }}
                          />
                        }
                        className="filter-text subhead-filter"
                        label="XLS"
                        checked={this.state.xlsStatus}
                        onChange={(e) => {
                          if (e.target.checked == true) {
                            this.state.fileType.push(e.target.name);
                          } else {
                            this.state.fileType.pop(e.target.name);
                          }
                          this.setState({
                            xlsStatus: e.target.checked,
                            buttonDisabled: false,
                          });
                        }}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>

                {/* <Grid item xs={12}>
              <FormGroup className="display-inline head-filter">
              <FormLabel component="legend" className="filter-text head-filter">Organization Name</FormLabel>
          <FormControlLabel
            control={<Checkbox   name="Organization Name 1" />}
            className="filter-text subhead-filter"
            label="Organization Name 1"
          />
          <FormControlLabel
            control={<Checkbox   name="Organization Name 2" />}
            className="filter-text subhead-filter"
            label="Organization Name 2"
          />
          <FormControlLabel
            control={<Checkbox   name="Organization Name 3" />}
            className="filter-text subhead-filter"
            label="Organization Name 3"
          />
        </FormGroup>
        <Divider  className="ruler v"/>
              </Grid>
               */}
                <Grid item lg={6} xs={12}>
                  <FormGroup className="display-inline head-filter">
                    <FormLabel
                      component="legend"
                      className="filter-text head-filter"
                      style={{ marginTop: "30px" }}
                    >
                      Privacy Setting
                    </FormLabel>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isPrivate"
                          style={{
                            color: "rgba(0, 90, 152, 1)",
                            paddingLeft: "0px",
                            paddingRight: "3px",
                          }}
                        />
                      }
                      className="filter-text subhead-filter align-filter-checkbox"
                      label="Private"
                      style={{ marginLeft: "0px", marginRight: "23px" }}
                      checked={this.state.isPrivatestatus}
                      onChange={(e) => {
                        if (e.target.checked == true) {
                          this.state.isPrivate.push(true);
                        } else {
                          this.state.isPrivate.pop(true);
                        }
                        this.setState({
                          isPrivatestatus: e.target.checked,
                          buttonDisabled: false,
                        });
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isPublic"
                          style={{
                            color: "rgba(0, 90, 152, 1)",
                            paddingLeft: "0px",
                            paddingRight: "3px",
                          }}
                        />
                      }
                      className="filter-text subhead-filter"
                      label="Public"
                      checked={this.state.isPublicstatus}
                      style={{ marginLeft: "0px", marginRight: "23px" }}
                      onChange={(e) => {
                        if (e.target.checked == true) {
                          this.state.isPrivate.push(false);
                        } else {
                          this.state.isPrivate.pop(false);
                        }
                        this.setState({
                          isPublicstatus: e.target.checked,
                          buttonDisabled: false,
                        });
                      }}
                    />
                  </FormGroup>
                  <Divider
                    className="ruler-filter"
                    orientation="vertical"
                    flexItem
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  {/* <Grid item lg={6}>
                    <FormGroup className="display-inline align-filter-checkbox head-filter">
                      <FormLabel
                        component="legend"
                        className="filter-text head-filter"
                        style={{ marginTop: "30px" }}
                      >
                        Organisation Name
                      </FormLabel>
                      <Autocomplete
                       limitTags={5}
                        multiple
                        id="checkboxes-tags-demo"
                        className="auto-check-user heading-option"
                        options={this.state.organisationList}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.organisationName}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              checked={selected}
                              iconStyle={{
                                fill: "#FFC805",
                                outline: "2px solid red",
                              }}
                            />
                            <Grid item sm={6}>
                              <span
                                className="headig-option font-size-14"
                                style={{ float: "left" }}
                              >
                                {option.organisationName}
                              </span>
                            </Grid>
                          </React.Fragment>
                        )}
                        style={{ width: "290px", marginTop: "20px" }}
                        onChange={this.onCategoryChange}
                        defaultValue={this.state.organisation}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            shrink={true}
                            placeholder="Select Category"
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid> */}
                  <Grid item lg={6} xs={12}>
                    <FormGroup className="display-inline align-filter-checkbox head-filter">
                      <FormLabel
                        component="legend"
                        className="filter-text head-filter"
                        style={{ marginTop: "16px" }}
                      >
                        Category Name
                      </FormLabel>
                      {/* {this.state.categoryList
                      ? this.state.categoryList.map((x, index) =>
                        <FormControlLabel
                          control={<Checkbox name={x._id}/>}
                          className="filter-text subhead-filter"
                          label={x.categoryName}
                          // {this.state.categoryArraychecked.map((x,index)=>)}
                          onChange={e => {
                            if (e.target.checked == true) {
                              this.state.categoryArray.push(e.target.name)
                            }
                            else {
                              this.state.categoryArray.pop(e.target.name)
                            }
                            console.log(e.target.name, e.target.checked, this.state.categoryArray);
                            // this.setState({ [x.categoryName]: e.target.checked });
                          }}
                        />) : ''} */}

                      <Autocomplete
                        limitTags={5}
                        multiple
                        id="checkboxes-tags-demo"
                        className="auto-check-user heading-option"
                        options={this.state.categoryList}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.categoryName}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              checked={selected}
                              iconStyle={{
                                fill: "#FFC805",
                                outline: "2px solid red",
                              }}
                            />
                            <Grid item sm={6} xs={12}>
                              <span
                                className="headig-option font-size-14"
                                style={{ float: "left" }}
                              >
                                {option.categoryName}
                              </span>
                            </Grid>
                          </React.Fragment>
                        )}
                        style={{  marginTop: "20px" }}
                        onChange={this.onTagsChange}
                        defaultValue={this.state.categoryName}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            shrink={true}
                            placeholder="Select Category"
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ padding: "0px 25px 20px 0px" }} className={classes.alignFilterButton}>
              <Button
                disabled={this.state.buttonDisabled}
                class="btn-doc heading1"
                onClick={this.applyFilter}
                style={{
                  marginRight: "23px",
                  background: "#ffc805",
                  padding: "14px 44px",
                  borderRadius: "6px",
                  border: "none",
                  marginLeft:"10px",
                  lineHeight: 1,
                  marginBottom: "20px",
                   marginRight: "20px",
                }}
                disableTouchRipple="false"
              >
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: " 700",
                    color: "#005a98",
                    textTransform: "capitalize",
                  }}
                >
                  Apply Filter
                </span>
              </Button>
              <Button
                class="btn-doc heading1"
                onClick={this.resetAll}
                style={{ marginBottom: "20px", marginRight: "23px" }}
                disableTouchRipple="false"
                style={{marginBottom: "20px",
                  marginRight: "20px", padding: "14px 44px" }}
              >
                Clear All
              </Button>
              {/* <Button
                class="btn-doc heading1"
                onClick={this.cancel}
                style={{ marginBottom: "20px", marginRight: "20px" }}
              >
                Cancel
              </Button> */}
            </DialogActions>
          </Dialog>
        </main>
      </div>
    );
  }
}

function mapStateToProps(state) {
  console.log("state------------", state);
  return {
    documentList: state.document.documentList,
    categoryList: state.user.categoryList,
    filterObj: state.document.filterObj,
    organisationList: state.organization.organizationList,
  };
}

export default connect(mapStateToProps, {
  getDocuments,
  getcategoryPrac,
  getDocFilteredData,
  getUserDetails,
  saveFilterRes,
})(withStyles(useStyles)(MyDocuments));
