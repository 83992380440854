import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {Drawer, ListItem, Icon, ListItemIcon, ListItemText,  Avatar,   IconButton, AppBar, Grid, Toolbar, List, CssBaseline, Typography, Divider}  from "@material-ui/core";
import { Link } from "react-router-dom";
/* import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined"; */
import category  from "../assets/category.svg";
import organization  from "../assets/management.svg";
import arrowLeft from "../assets/arrowhead-left.svg";
import arrowRight from "../assets/arrowhead-left (1).svg";
import users from "../assets/users.svg"
import categoryYellow  from "../assets/sideNav/category.svg";
import organizationYellow  from "../assets/sideNav/management.svg";
import usersYellow from "../assets/sideNav/users.svg"
import approved from "../assets/document 1.svg"
import unapproved from "../assets/document 2.svg"
import approvedYellow from "../assets/approvedYellow.svg"
import unapprovedYellow from "../assets/unapprovedYellow.svg"
/* import SearchIcon from '@material-ui/icons/Search';
import person from '../assets/person.svg';
import setting from '../assets/settings.svg';
import bell from '../assets/bell.svg';*/
import logOut from '../assets/log-out.svg'; 
import reportReview from '../assets/ManageReportedReveiw.svg';
import reportReviewYellow from '../assets/manageReportReviewYellow.svg';


import Logo from '../assets/logo.png';
import shortLogo from '../assets/shortLogo.png';
import { useSelector } from 'react-redux';

const drawerWidth = 316;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootCard:{
    maxWidth: 345,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow:"none",
  },
  appBarShift: {
    marginLeft: "150px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    marginLeft:20,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(12) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(15) + 1,
    },
  },
  media: {
    paddingTop: "100%",
    margin: "15px",
    borderRadius: "10px",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    minHeight: "125px !important",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  search: {
    position: 'relative',
    borderRadius: '20px',
    border: '1px solid #E1E1E1', 
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color:'#000000',
  },
  
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    position:"relative",
    top:"10rem",
  },
}));


export default function Nav({handleDrawerOpen, handleDrawerClose, open, title,activeValue}) {
  let authData = useSelector(state => state.login.authData);
  const buffer = authData&&authData.userImage?authData.userImage.data:''; // e.g., <Buffer 89 50 4e ... >
  const b64 = new Buffer(buffer).toString('base64');
  // console.log("b64",b64)
  const mimeType = 'image/png'

  const classes = useStyles();
  const theme = useTheme();
  const [isActive, setIsActive]=React.useState(activeValue);
  function updateSelected(selectedIndex) {
    setIsActive(selectedIndex);
  }
  return (
      <React.Fragment>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className="height-125" >
        { open? " " : <img src={shortLogo} className={`${classes.logo} pd-l-15`} alt="shortlogo" />
}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            ml={20}
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
           
          >
           <span className="arrowBox">
           <img src={arrowRight} className={classes.logo} alt="left arrow" /> 
              </span>
          </IconButton>
                    <Grid container component="main" className={classes.root}>
      <Grid item xs={12} sm={4} style={{paddingTop:"10px"}} >
          <Typography variant="h6" className="headingData" noWrap>
            {title}
          </Typography>
</Grid>
<Grid item xs={12} sm={6} >
<Grid container >
  {/* <Grid item>
  <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
            </div>
  </Grid>
  <Grid item>
  <IconButton   className="notifi-btn"  aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={1} color="secondary">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.7196 13.404L20.5206 15.208C20.9896 15.677 21.1276 16.377 20.8726 16.991C20.6186 17.604 20.0266 18 19.3626 18H15.9996V18.341C15.9996 20.359 14.2056 22 11.9996 22C9.7936 22 7.9996 20.359 7.9996 18.341V18H4.6366C3.9726 18 3.3796 17.604 3.1246 16.99C2.8706 16.377 3.0096 15.678 3.4786 15.208L5.2796 13.404L5.2806 8.72699C5.2806 6.79299 6.1186 4.94899 7.5826 3.66699C9.0376 2.39099 10.9776 1.80399 12.8996 2.05999C16.2176 2.49899 18.7196 5.45599 18.7196 8.93699V13.404ZM5.5146 16L6.6946 14.818C7.0726 14.44 7.2806 13.938 7.2806 13.404V8.72699C7.2806 7.36999 7.8706 6.07299 8.9006 5.17099C9.9386 4.26099 11.2606 3.86099 12.6376 4.04199C14.9646 4.35099 16.7196 6.45499 16.7196 8.93699V13.404C16.7196 13.938 16.9276 14.44 17.3046 14.817L18.4856 16H5.5146ZM13.9996 18.341C13.9996 19.24 13.0836 20 11.9996 20C10.9156 20 9.9996 19.24 9.9996 18.341V18H13.9996V18.341Z" fill="#0D1C2E"/>
<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="19" height="20">
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.7196 13.404L20.5206 15.208C20.9896 15.677 21.1276 16.377 20.8726 16.991C20.6186 17.604 20.0266 18 19.3626 18H15.9996V18.341C15.9996 20.359 14.2056 22 11.9996 22C9.7936 22 7.9996 20.359 7.9996 18.341V18H4.6366C3.9726 18 3.3796 17.604 3.1246 16.99C2.8706 16.377 3.0096 15.678 3.4786 15.208L5.2796 13.404L5.2806 8.72699C5.2806 6.79299 6.1186 4.94899 7.5826 3.66699C9.0376 2.39099 10.9776 1.80399 12.8996 2.05999C16.2176 2.49899 18.7196 5.45599 18.7196 8.93699V13.404ZM5.5146 16L6.6946 14.818C7.0726 14.44 7.2806 13.938 7.2806 13.404V8.72699C7.2806 7.36999 7.8706 6.07299 8.9006 5.17099C9.9386 4.26099 11.2606 3.86099 12.6376 4.04199C14.9646 4.35099 16.7196 6.45499 16.7196 8.93699V13.404C16.7196 13.938 16.9276 14.44 17.3046 14.817L18.4856 16H5.5146ZM13.9996 18.341C13.9996 19.24 13.0836 20 11.9996 20C10.9156 20 9.9996 19.24 9.9996 18.341V18H13.9996V18.341Z" fill="white"/>
</mask>
<g mask="url(#mask0)">
</g>
</svg>

              </Badge>
            </IconButton>
  </Grid> */}
      </Grid>
          </Grid>
            <Grid item xs={12} sm={2} > 
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit"
            >
              {/* <Avatar alt="Cindy Baker" src={require('../assets/image 2.png')} />  */}
              <Avatar alt="Cindy Baker" borderColor="primary.main" src={`data:${mimeType};base64,${b64}`} />

            </IconButton>
            <span className="name-lable">{authData?authData.userName:'Will Smith'}</span>
            </Grid>
            </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.drawerOpen, classes.drawerClose, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        position="sidenav" 
        width="sidenav-width"
      >


<div className={`${classes.toolbar} sideNav-main logo-main`}>
       {  open? <img src={Logo} className={classes.logo} alt="logo" /> : ""}
            <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (  
            <span className="arrowBox">
             <img src={arrowLeft} className={classes.logo} alt="Left arrow" />
            </span>
            ) : (
              <span className="arrowBox">
                  <img src={arrowLeft} className={classes.logo} alt="Left arrow" />
              </span>
            )}
          </IconButton>
        </div>
        <List  className="sideNav" >
            <Link to={'/listCategory'} onClick={() => updateSelected(0)} className="text-decoration-none">
            <ListItem button key="Manage Category"  selected={isActive === 0} className="nav-list-text">
            <ListItemIcon> 
              <Icon>
                {isActive === 0 ? (
                  <img
                    className={`${classes.imageIcon} category-path`}
                    height="15"
                    width="15"
                    src={categoryYellow}
                    alt=""
                  />
                ) : (
                  <img
                    className={`${classes.imageIcon} category-path`}
                    height="15"
                    width="15"
                    src={category}
                    alt=""
                  />
                )}
              </Icon>
            </ListItemIcon>
              {open ?
              <ListItemText primary="Manage Category" />:" " }
                {isActive === 0?<i className="fa fa-circle add-active  font-size-12  icon-active-alignment"></i>:""}
              </ListItem>
            </Link>
           <Link to={'/listOrganization'} onClick={() => updateSelected(1)} className="text-decoration-none">
              <ListItem button key="Manage Organization"  selected={isActive === 1} className="nav-list-text">
              <ListItemIcon> 
                <Icon>
                      {isActive === 1 ? (
                        <img
                          className={`${classes.imageIcon} category-path`}
                          height="15"
                          width="15"
                          src={organizationYellow}
                          alt="Organization Yellow"
                        />
                      ) : (
                        <img
                          className={`${classes.imageIcon} category-path`}
                          height="15"
                          width="15"
                          src={organization}
                          alt="Organization"
                        />
                      )}
                    </Icon>
               </ListItemIcon> 
               {open ?
              <ListItemText primary="Manage Organization" />: " " }
               {isActive === 1?<i className="fa fa-circle add-active  font-size-12  icon-active-alignment"></i>:""}
              </ListItem></Link>
              <Link to={'/listUser'}  onClick={() => updateSelected(2)} className="text-decoration-none">
              <ListItem button key="Manage Users" selected={isActive === 2}  className="nav-list-text"><ListItemIcon>
                <Icon>
                  {isActive === 2 ? (
                    <img
                      className={`${classes.imageIcon} category-path`}
                      height="15"
                      width="15"
                      src={usersYellow}
                      alt=""
                    />
                  ) : (
                    <img
                      className={`${classes.imageIcon} category-path`}
                      height="15"
                      width="15"
                      src={users}
                      alt=""
                    />
                  )}
                </Icon>
              </ListItemIcon>
              {open ?
              <ListItemText primary="Manage Users" />: "" }
              {isActive === 2?<i className="fa fa-circle add-active font-size-12 icon-active-alignment"></i>:""}
              </ListItem></Link>
              <Link to={'/approvedDocument'} onClick={() => updateSelected(3)} className="text-decoration-none"> 
              <ListItem button key="Manage Approved Document" selected={isActive === 3}  className="nav-list-text"><ListItemIcon>
                <Icon>
                    {isActive === 3 ? (
                      <img
                        className={`${classes.imageIcon} category-path`}
                        height="15"
                        width="15"
                        src={approvedYellow}
                        alt="Approved Yellow"
                      />
                    ) : (
                      <img
                        className={`${classes.imageIcon} category-path`}
                        height="15"
                        width="15"
                        src={approved}
                        alt="Approved"
                      />
                    )}
                </Icon>
              </ListItemIcon>
              {open ?
              <ListItemText primary="Manage Approved Document" />: "" }
              {isActive === 3?<i className="fa fa-circle add-active font-size-12 icon-active-alignment"></i>:""}
              </ListItem></Link>
              <Link to={'/unApprovedDocument'} onClick={() => updateSelected(4)} className="text-decoration-none"> 
              <ListItem button key="Manage Unapproved Document" selected={isActive === 4}  className="nav-list-text"><ListItemIcon>
                <Icon>
                    {isActive === 4 ? (
                      <img
                        className={`${classes.imageIcon} category-path`}
                        height="15"
                        width="15"
                        src={unapprovedYellow}
                        alt=""
                      />
                    ) : (
                      <img
                        className={`${classes.imageIcon} category-path`}
                        height="15"
                        width="15"
                        src={unapproved}
                        alt=""
                      />
                    )}
                </Icon>
              </ListItemIcon>
              {open ?
              <ListItemText primary="Manage Unapproved Document" />: "" }
              {isActive === 4?<i className="fa fa-circle add-active font-size-12 icon-active-alignment"></i>:""}
              </ListItem></Link>
              <Link to={'/reviewManagement'} onClick={() => updateSelected(5)} className="text-decoration-none"> 
              <ListItem button key="Manage Unapproved Document" selected={isActive === 5}  className="nav-list-text"><ListItemIcon>
                <Icon>
                    {isActive === 5 ? (
                      <img
                        className={`${classes.imageIcon} category-path`}
                        height="15"
                        width="15"
                        src={reportReviewYellow}
                        alt="Report Review (Yellow)"
                      />
                    ) : (
                      <img
                        className={`${classes.imageIcon} category-path`}
                        height="15"
                        width="15"
                        src={reportReview}
                        alt="Report Review"
                      />
                    )}
                </Icon>
              </ListItemIcon>
              {open ?
              <ListItemText primary="Manage Reported Review" />: "" }
              {isActive === 5?<i className="fa fa-circle add-active font-size-12 icon-active-alignment"></i>:""}
              </ListItem></Link>





        </List>
        <Divider  className="ruler"/>
        <List className="sideNav">
          {/* <Link to="" className="text-decoration-none">
            <ListItem button key="Notification" onClick={() => updateSelected(3)} selected={isActive === 3} className="nav-list-text">
              <ListItemIcon> <Icon>
            {isActive === 3?<img className={classes.imageIcon} height="15" width="15" className="category-path" src={bell}/>:<img className={classes.imageIcon} height="15" width="15" className="category-path" src={bell}/>}</Icon></ListItemIcon>
              {open ?
              <ListItemText primary="Notification" />:" " }
                {isActive === 3?<i className="fa fa-circle add-active icon-active-alignment"></i>:""}
              </ListItem>
            </Link>
            <Link to="" className="text-decoration-none">
            <ListItem button key="Settings" onClick={() => updateSelected(4)} selected={isActive === 4} className="nav-list-text">
              <ListItemIcon> <Icon>
            {isActive === 4?<img className={classes.imageIcon} height="15" width="15" className="category-path" src={setting}/>:<img className={classes.imageIcon} height="15" width="15" className="category-path" src={setting}/>}</Icon></ListItemIcon>
              {open ?
              <ListItemText primary="Settings" />:" " }
                {isActive === 4?<i className="fa fa-circle add-active icon-active-alignment"></i>:""}
              </ListItem>
            </Link>
            <Link to="" className="text-decoration-none">
            <ListItem button key="Contact Admin" onClick={() => updateSelected(5)} selected={isActive === 5} className="nav-list-text">
              <ListItemIcon> <Icon>
            {isActive === 5?<img className={classes.imageIcon} height="15" width="15" className="category-path" src={person}/>:<img className={classes.imageIcon} height="15" width="15" className="category-path" src={person}/>}</Icon></ListItemIcon>
              {open ?
              <ListItemText primary="Contact Admin" />:" " }
                {isActive === 5?<i className="fa fa-circle add-active icon-active-alignment"></i>:""}
              </ListItem>
            </Link> */}
            <Link to="/" className="text-decoration-none">
  <ListItem button key="Logout" onClick={() => updateSelected(6)} selected={isActive === 6} className="nav-list-text">
    <ListItemIcon>
      <Icon>
        {isActive === 6 ? (
          <img className={`${classes.imageIcon} category-path`} height="15" width="15" src={logOut} alt="Logout" />
        ) : (
          <img className={`${classes.imageIcon} category-path`} height="15" width="15" src={logOut} alt="Logout" />
        )}
      </Icon>
    </ListItemIcon>
    {open ? <ListItemText primary="Logout" /> : null}
    {isActive === 6 ? <i className="fa fa-circle add-active icon-active-alignment"></i> : null}
  </ListItem>
</Link>

        </List>
      </Drawer>
      </React.Fragment>
  );
}
