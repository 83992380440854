import { categoryActionTypes } from './../constants/apiConstants';

const initialState = {
    categoryList: [],
    totalCount: 0,
  
};

const categoryReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case categoryActionTypes.get_category.SUCCESS:
            return {
                ...state,
                categoryList: payload.categoryList,
                totalCount: payload.totalCount
            };
        // Other cases...
        default:
            return state;
    }
};

export default categoryReducer;
