/*
     Login Api Constants
*/
import {
    actionCreator,
    createRequestActionTypes,
    jsonApiHeader
} from './../../../../actions/utilAction';
export {
    actionCreator,
    jsonApiHeader
};

export const categoryActionTypes = {
    get_category: createRequestActionTypes('GET_CATEGORY'),
};
