import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Avatar,
  TextareaAutosize,
  Icon,
  Button,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import leftArrow from "../../../assets/left.svg";
import Nav from "../../../component/Nav.js";
// import { SelectValue } from "./constants/dropDownStyled";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core/";
import Rating from "@material-ui/lab/Rating";
// import download from "../../../assets/download.png";
import pdf from "../../../assets/Pdf.png";
import csv from "../../../assets/CSV.svg";
import jpeg from "../../../assets/JPEG.svg";
import excel from "../../../assets/excel 1.svg";
import doc from "../../../assets/DOCX.svg";
import ppt from "../../../assets/PPT.svg";
import FileSaver from "file-saver";
import axios from "axios";
import { API_URL } from "../../../actions/utilAction";
import { jsonApiHeader } from "../../../actions/utilAction";
import { toasterMessage } from "../../../actions/util";
import { Link } from "react-router-dom";
import Loader from "../../../component/Loader";
import swal from "sweetalert";

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (state) => ({});

const drawerWidth = 316;

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  rootCard: {
    maxWidth: 345,
  },
  centerImg:{
    justifyContent:"center",
    display:"flex",
    margin:"0px 20px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  alignButton:{
    [theme.breakpoints.up('md')]: {
     marginLeft:"20px"
    },
    [theme.breakpoints.down('sm')]: {
     marginTop:"30px"
    },
  },
  appBarShift: {
    marginLeft: "150px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2, 2),
    paddingRight: theme.spacing(5),
    position: "relative",
    top: "10rem",
  },
  inputStyle: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFFFFF",
    border: "1px solid rgba(255, 207, 92, 1)",
    fontSize: 16,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    padding: "0px",
    width: "100%",
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  inputWidth: {
    minWidth: "413px",
    height: "50px",
  },
  textAreWidth: {
    minWidth: "395px",
    minHeight: "160px",
    borderRadius: "10px",
  },
  focused: {},
  iconOpen: {},
});
/* const BootstrapInput = withStyles((theme) => ({
  minWidth: "413px",
  width: "100%",
}))(InputBase); */

class SharedFileDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      checkedA: true,
      modal: false,
      title: "Shared With Me ",
      data: "",
      docStatus: "",
      loader: true,
      rating: 0,
      reviewComment: "",
      buttonDisabled: false,
    };
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  handleChange = (event) => {
    let name = event.target.name;
    let checked = event.target.checked;
    this.setState((prevState) => {
      return { [name]: checked };
    });
  };

  handleModalOpen = (data) => {
    this.setState({ modal: !this.state.modal });
  };
  handleModalClose = (data) => {
    this.setState({ modal: false });
  };

  componentDidMount() {
    this.setState(
      {
        data: this.props.location.state.data,
        loader: false,
        // docStatus: this.props.location.state.data.currentAprrovDocument.status
      },
      () => {
        console.log(this.state.data);
        if (this.state.data.userPerRating.length) {
          this.setState({ buttonDisabled: true });
        }
      }
    );
  }

  downloadDocument = () => {
    this.setState({ loader: true });
    let access_token = localStorage.access_token;
    axios
      .get(
        `${API_URL}/document/downloadDoc/${this.state.data.currentAprrovDocument.fileId}`,
        {
          headers: jsonApiHeader(access_token, "blob"),
          responseType: "blob",
        }
      )
      .then((res) => {
        this.setState({ fileDownloading: true, loader: false }, () => {
          FileSaver.saveAs(res.data, `${this.state.data.documentName}`);
        });

        console.log(res.data, "from here");
      })
      .catch((error) => {
        this.setState({ loader: false });
        // this.handleRejectModal()
        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch((error) => {
        this.setState({ loader: false });
        console.log(error);
        // this.handleRejectModal()
        toasterMessage("error", "Something bad happened");
      });
  };

  getDocSize = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  submitReview = () => {
    if (!this.state.reviewComment) {
      return toasterMessage("error", "Please Add Comment ");
    } else if (this.state.rating === 0) {
      return toasterMessage("error", "Please Add Ratings");
    }

    this.setState({ loader: true });
    let access_token = localStorage.access_token;
    let postBody = {
      documentId: this.state.data.currentAprrovDocument.documentId,
      documentVersionId: this.state.data.currentAprrovDocument.documentLogId,
      rating: this.state.rating,
      comment: this.state.reviewComment,
    };
    axios
      .post(`${API_URL}/ratingReview/addReview`, postBody, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then((res) => {
        this.handleModalOpen();
        toasterMessage("success", res.data.message);
        this.props.history.push({
          pathname: "/shared-with-me",
        });
        this.setState(
          { fileDownloading: true, loader: false, buttonDisabled: true },
          () => {
            // FileSaver.saveAs(res.data, `${this.state.data.documentName}`);
          }
        );

        console.log(res.data, "from here");
      })
      .catch((error) => {
        this.handleModalOpen();
        this.setState({ loader: false });
        toasterMessage("error", "Yoou have already Submitted Review");
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch((error) => {
        this.handleModalOpen();
        this.setState({ loader: false });
        console.log(error);
        // this.handleRejectModal()
        toasterMessage("error", "Something bad happened");
      });
  };
  viewRatings = () => {
    console.log(this.state.data);
    this.props.history.push({
      pathname: "/ratingAndReviews",
      state: {
        data: this.state.data.currentAprrovDocument,
        docName: this.state.data.documentName,
      },
    });
  };
  render() {
    const { classes } = this.props;
    const mimeType = "image/png";

    return (
      <div>
        {/* {!this.state.loader && this.state.data ? ( */}
        <>
          <div className={classes.root}>
            <Nav
              handleDrawerOpen={this.handleDrawerOpen}
              handleDrawerClose={this.handleDrawerClose}
              open={this.state.open}
              title={this.state.title}
              active={1}
            />
            <main
              className={clsx(classes.content, {
                [classes.appBarShift]: this.state.open,
              })}
            >

              {!this.state.loader && this.state.data ? (
                <>
                  <Box mb={3}>
                    <Typography
                      variant="body2"
                      pb={0}
                      color="textSecondary"
                      className="ListHeading "
                      component="p"
                    >
                      <Link to="/shared-with-me">
                        <Icon>
                        <img
                          className={`${classes.imageIcon} arrow-position`}
                          src={leftArrow}
                          alt="Left Arrow"
                        />

                        </Icon>
                      </Link>
                      {this.state.data.documentName}
                    </Typography>
                  </Box>
                  <Grid container>
                    <Grid item xs={12} sm={12} lg={12}>
                      <div className="shared-details shared-details-color">
                        <Grid container className={classes.centerImg}>
                          <Grid item xs={12} sm={12} lg={4}>
                            <div className="img-side display-center">
                              <img
                                className={classes.media}
                                style={{ margin: "auto" }}
                                src={
                                  this.state.data.currentAprrovDocument
                                    .fileType === ".pdf"
                                    ? pdf
                                    : this.state.data.currentAprrovDocument
                                        .fileType === "application/pdf"
                                    ? pdf
                                    : this.state.data.currentAprrovDocument
                                        .fileType === ".csv"
                                    ? csv
                                    : this.state.data.currentAprrovDocument
                                        .fileType === "text/csv"
                                    ? csv
                                    : this.state.data.currentAprrovDocument
                                        .fileType === ".jpeg"
                                    ? jpeg
                                    : this.state.data.currentAprrovDocument
                                        .fileType === ".xls"
                                    ? excel
                                    : this.state.data.currentAprrovDocument
                                        .fileType === ".jpeg"
                                    ? jpeg
                                    : this.state.data.currentAprrovDocument
                                        .fileType === ".docx"
                                    ? doc
                                    : this.state.data.currentAprrovDocument
                                        .fileType === ".ppt"
                                    ? ppt
                                    : this.state.data.currentAprrovDocument
                                        .fileType === ".pptx"
                                    ? ppt
                                    : null
                                
                                }
                                alt=""
                              />
                            </div>
                            <Grid container>
                              <Grid item xs={12} sm={12} lg={12}>
                              <Typography
                                  variant="body2"
                                  pb={0}
                                  color="textSecondary"
                                  className="listHeadingStar pd-0 margin-value mr-l-30"
                                  component="p"
                                >
                                  {this.state.data.getRatingShared.length
                                    ? `${Math.round(
                                        this.state.data.getRatingShared[0]
                                          .rating
                                      )}.0`
                                    : 0}
                               
                               <Rating
                                  disabled
                                  name="size-small"
                                  className="pd-0 mr-l-30  rating-align"
                                  value={
                                    this.state.data.getRatingShared.length
                                      ? `${Math.round(
                                          this.state.data.getRatingShared[0].rating
                                        )}`
                                      : 0
                                  }
                                  size="small"
                                />


                                <span
                                  className="seeAll-text"
                                  onClick={(e) => this.viewRatings()}
                                  style={{
                                    marginLeft: "80px",
                                  }}
                                >
                                  see all
                                </span> </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={8} lg={8}>
                            <div className="detail-side">
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                lg={12}
                                className="pd-t-40"
                              >
                                <Typography
                                  variant="body2"
                                  pb={0}
                                  color="textSecondary"
                                  className="ListHeading "
                                  component="p"
                                >
                                  {this.state.data.documentName}
                                </Typography>
                              </Grid>

                              <Grid container className="pd-t-25">
                                <Grid item xs={6} sm={4} lg={4}>
                                  <span>Author:</span>
                                  <br />
                                  <IconButton>
                                    <Avatar
                                      alt="Judith Mueller"
                                      borderColor="primary.main"
                                      s
                                      className="shared-profile"
                                      src={`data:${mimeType};base64,${this.state.data.createdBy.userImage}`}
                                      // src={this.state.data.createdBy.userImage}
                                    />
                                    <span className="text-title">
                                      {this.state.data.createdBy.userName}
                                    </span>{" "}
                                  </IconButton>
                                </Grid>
                                <Grid item xs={6} sm={4} lg={4}>
                                  <span className="label-heading">
                                    Category:
                                  </span>
                                  <br />
                                  <span className="label-data">
                                    {this.state.data.categoryId.categoryName}
                                  </span>
                                </Grid>
                                <Grid item xs={6} sm={4} lg={4}>
                                  <span className="label-heading">
                                    Version:
                                  </span>
                                  <br />
                                  <span className="label-data">
                                    V
                                    {1.+
                                      this.state.data.currentAprrovDocument
                                        .versionName/10
                                    }
                                  </span>
                                </Grid>
                              </Grid>
                              <Grid container className="pd-t-40">
                                <Grid item xs={6} sm={4} lg={4}>
                                  <span className="label-heading">
                                    File Type:
                                  </span>
                                  <br />
                                  <span className="label-data">
                                    {this.state.data.currentAprrovDocument.fileType
                                      .substring(1)
                                      .toUpperCase()}
                                  </span>
                                </Grid>
                                <Grid item xs={6} sm={4} lg={4}>
                                  <span className="label-heading">
                                    File Size:
                                  </span>
                                  <br />
                                  <span className="label-data">
                                    {this.getDocSize(
                                      this.state.data.currentAprrovDocument
                                        .fileSizeBytes
                                    )}
                                  </span>
                                </Grid>
                                {this.state.data.userPerRating.length ? (
                                  <Grid item xs={6} sm={4} lg={4}>
                                    <span>Your Rating:</span>
                                    <br />
                                    <Rating
                                      disabled
                                      name="size-small"
                                      className="pd-0"
                                      value={Math.round(this.state.data.userPerRating[0].rating)}
                                      size="small"
                                    />

                                  </Grid>
                                ) : null}
                              </Grid>
                              <Grid container className="pd-t-40">
                                <Grid
                                  item
                                  xs={6}
                                  sm={12}
                                  lg={12}
                                  style={{ paddingBottom: "30px" }}
                                >
                                  <span className="label-heading">
                                    Description:
                                  </span>
                                  <br />
                                  <span className="label-data">
                                    {this.state.data.description}
                                  </span>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container className="mr-t-50 display-center">
                    <Grid item>
                      <Button
                        onClick={(e) => this.downloadDocument()}
                        type="submit"
                        className="main-btn submit-btn btn-shared text-transform-inherit"
                        disableTouchRipple="false"
                      >
                        Download
                      </Button>
                    </Grid>
                    <Grid item>
                      <Box className={classes.alignButton}>
                        {" "}
                        <Button
                          disabled={this.state.buttonDisabled}
                          type="submit"
                          className=" shared-btn-rate btn-shared text-transform-inherit"
                          onClick={this.handleModalOpen}
                          disableTouchRipple="false"
                        >
                          Rate Document
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Loader />
              )}
            </main>

            <Dialog
              open={this.state.modal}
              onClose={this.handleModalClose}
              aria-labelledby="form-dialog-title"
              className="dialog-main-div"
            >
              <DialogTitle id="form-dialog-title" class="modal-header">
                Rate Document
              </DialogTitle>
              <DialogContent className="dialog-content pd-70">
                <Grid container className="display-center">
                  <Grid item className="mr-b-40">
                    <Rating
                      value={this.state.rating}
                      onChange={(e, v) => {
                        console.log(v, "from console");
                        this.setState({ rating: v }, () =>
                          console.log(this.state.rating)
                        );
                      }}
                      name="simple-controlled"
                      className="pd-0"
                      size="large"
                    />
                    {/* 
<Rating
          name="simple-controlled"
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        /> */}
                  </Grid>
                  <Grid item>
                  <TextareaAutosize
                    className={clsx(classes.inputStyle, classes.textAreWidth)}
                    rowsMax={8}
                    aria-label="maximum height"
                    placeholder="Write Comment"
                    value={this.state.reviewComment}
                    onChange={(e) => {
                      if (e.target.value.length < 300) {
                        this.setState({
                          reviewComment: e.target.value,
                        });
                      } else {
                        swal("Maximum 300 characters Allowed");
                      }
                    }}
                  />

                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className="display-center">
                <Button
                  onClick={(e) => this.submitReview()}
                  class="btn-doc heading1"
                  style={{ marginBottom: "20px", marginRiht: "20px" }}
                  disableTouchRipple="false"
                >

                  Submit
                </Button>
                <Button
                  class="btn-doc-close heading3"
                  style={{ marginBottom: "20px", marginRight: "20px" }}
                  onClick={this.handleModalClose}
                  disableTouchRipple="false"
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(SharedFileDetails));
