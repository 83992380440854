import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import Nav from "../../../component/Nav.js";
import SendData from "../../../assets/send 1.png";
import CachedIcon from "@material-ui/icons/Cached";
import SearchIcon from "@material-ui/icons/Search";
import { Scrollbars } from "react-custom-scrollbars";
import leftArrow from "../../../assets/left.svg";
import { makeStyles } from "@material-ui/core/styles";
import { loginAction } from "../../../modules/login/actions/loginActions";
import {
  CardActions,
  Button,
  Hidden,
  Grid,
  Fab,
  Dialog,
  DialogActions,
  DialogContent,
  CardContent,
  ListItemAvatar,
  Card,
  InputBase,
  IconButton,
  Divider,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  TextareaAutosize,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import {
  getRecentUsers,
  getMessageById,
  sendMessage,
  getAllUsers,
  searchUser,
} from "./actions/chatAction";
import moment from "moment";
import Loader from "../../../component/Loader";
import { toasterMessage } from "../../../actions/util";
import { onComplete } from "./constants/apiConstants";
import axios from "axios";
import { API_URL } from "../../../actions/utilAction";
import { jsonApiHeader } from "../../../actions/utilAction";
import videoCalling from "./videoCalling";
import Video from "twilio-video";
// import Calling from './calling'
import Room from "./Room/index";
import io from "socket.io-client";
import CallIcon from "@material-ui/icons/Call";
import CallEndIcon from "@material-ui/icons/CallEnd";
import VideocamIcon from "@material-ui/icons/Videocam";

// const socket = io('http://n1.iworklab.com:3229');
// socket.on('calling', () => {
//     console.log(`I'm connected with the back-end`);
// });
const socket = io("https://www.api.muellerhealthfoundation.org");
const drawerWidth = 316;
const HEIGHT = "40vh";
const access_token = localStorage.access_token;
const useStyles = (theme) => ({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: "100%",
    height: "40vh",
    boxShadow: "none",
  },
  avatarRadius: {
    borderRadius: "5px",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  messageArea: {
    height: "40vh",
    transform: `rotateX(180deg)`,
  },
  insideText: {
    transform: `rotateX(-180deg)`,
  },
  scrollBar: {
    right: "2px",
    bottom: "2px",
    top: "30px",
  },

  root: {
    display: "flex",
  },
  rootCard: {
    maxWidth: "345px",
    boxShadow: "0px 7px 24px 0px rgba(0, 90, 152, 0.07)",
  },
  fab: {
    position: "absolute",
    bottom: "-130px",
    right: theme.spacing(2),
    backgroundColor: "#ffc805",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  messageSection: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "49px",
      position: "relative",
      bottom: "70px",
    },
  },
  displayShow: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  displayHide: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  textBox: {
    "& p": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    width: "50%",
  },
  appBarShift: {
    marginLeft: "150px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  iconButton: {
    padding: "10px",
    marginLeft: "20px",
  },
  search: {
    position: "relative",
    borderRadius: "6px",
    border: "1px solid rgba(0, 90, 152, 0.2)",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    marginBottom: "20px",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  media: {
    paddingTop: "100%",
    margin: "15px",
    borderRadius: "10px",
    background: "#FFF8F8",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  chatCard: {
    maxWidth: "379px",
    maxHeight: "86px",
    marginTop: "15px",
    border: "1px solid rgba(0, 90, 152, 0.2)",
    boxShadow: "0px 7px 14px 0px rgba(0, 90, 152, 0.07)",
  },
  heading: {
    background: "rgba(235, 245, 255, 1)",
    height: "75px",
    display: "flex",
    justifyContent: "center",
  },
  chatItem: {
    backgroundColor: "rgba(245, 250, 255, 1)",
    borderRadius: "10px",
    maxWidth: "fit-content",
    margin: "0px 5px",
    display: "inline-block",
    "&::after": {
      content: '""',
      position: "absolute",
      display: "block",
      width: "0",
      zIndex: "1",
      borderStyle: "solid",
      borderColor: "transparent rgba(245, 250, 255, 1)",
      marginTop: "-20px",
    },
  },
  chatItemLeft: {
    "&::after": {
      top: "56%",
      left: "70px",
      borderWidth: "8px 8px 8px 0",
    },
    "& p": {
      justifyContent: "flex-end",
    },
  },
  chatItemRight: {
    "&::after": {
      top: "56%",
      right: "70px",
      borderWidth: "8px 0 8px 8px",
    },
    "& p": {
      justifyContent: "flex-start",
    },
  },
  gridright: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  gridLeft: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },

  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    minHeight: "125px !important",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  text: {
    "& span": {
      fontFamily: "OpenSans-Regular",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "500",
      color: "rgba(0, 0, 0, 1)",
      letterSpacing: "2px",
      padding: "9px 18px 0px 15px",
      // wordBreak: "break-all",
    },
    "& p": {
      fontFamily: "OpenSans-Regular",
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: "700",
      color: "rgba(51, 51, 51, 1)",
      display: "flex",
      padding: "5px 19px 10px 15px",
      // wordBreak: "break-all",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2, 2),
    paddingRight: theme.spacing(5),
    position: "relative",
    top: "10rem",
  },
  tabContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  msgSend: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchContainer: {
    paddingBottom: "20px",
    display: "flex",
    justifyContent: "space-between",
  },
});

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.emptyState();
    this.onComplete = onComplete.bind(this);

    this.myVideoRef = React.createRef();
    this.remoteVidRef = React.createRef();
  }

  emptyState = () => {
    return {
      open: false,
      title: "Chat",
      load: true,
      text: "",
      recentUsers: [],
      allUsers: [],
      searchUsers: [],
      chatInfo: {},
      selectedUser: {},
      activeList: "recentUsers",
      page: 1,
      limit: 10,
      search: "",
      field: "",
      videoCalling: false,
      room: null,
      token: "",
      roomName: "",
      participants: [],
      showWelcomPage: true,
      toggleMessage: true,
      toogleChat: true,
      VideoModal: false,
      incomingCall: false,
      tokenData: "",
      userImage: "",
      callType: "video",
    };
  };

  scrollToBottom = () => {
    this.listContainer.scrollTop = this.listContainer.scrollHeight;
  };

  componentDidMount() {
    let { page, limit } = this.state;
    this.setState({ field: "recentUsers", loading: true });
    this.props.getRecentUsers({ page, limit }, this.onComplete);
    this.scrollToBottom();
    console.log(socket, "here is socket");
    socket.on("connect", (data) => {
      console.log(`I'm connected with the back-end`, data);
    });
    socket.on("message", (user) => {
      console.log(this.props.loggedInUser._id, "hitted", user);
      if (user.receiverId._id == this.props.loggedInUser._id) {
        this.setState(
          {
            incomingCall: true,
            incomingCallName: user.receiverId.userNameCalling,
            tokenData: user,
            userImage: user.receiverId.userImage,
          }
          // () => {
          //   this.addUser(user.receiverId, user.token);
          // }
        );
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.loadingMsg && this.state.sendMsg) {
      this.scrollToBottom();
      this.setState({ sendMsg: false });
    }
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  // function to refresh particular chat
  handleRefresh = () => {
    if (!this.state.selectedUser || !this.state.selectedUser?._id) {
      return toasterMessage("error", "Please select a user first");
    }
    this.setState({ field: "chatInfo", loadingMsg: true });
    this.props.getMessageById(this.state.selectedUser?._id, this.onComplete);
  };

  /* function to get specific user chat 
    with logged in user
  */
  getUserChat = (user) => {
    this.setState({
      selectedUser: user,
      field: "chatInfo",
      showWelcomPage: false,
      loadingMsg: true,
      toggleMessage: !this.state.toggleMessage,
    });
    this.props.getMessageById(user._id, this.onComplete);
  };

  /*
    function to change state
  */
  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleToggleList = (e, key, value) => {
    e.stopPropagation();
    let { limit, allUsers } = this.state;
    if (value === "recentUsers") {
      this.setState({
        [key]: value,
        page: 1,
        field: value,
        loading: true,
        totalUsers: 0,
        toggleMessage: !this.state.toggleMessage,
      });
      this.props.getRecentUsers({ page: 1, limit }, this.onComplete);
    } else {
      this.setState({ [key]: value, page: 0, field: value, loading: true });
      allUsers?.length < 1 &&
        this.props.getAllUsers({ page: 0, limit }, this.onComplete);
    }
  };

  handleSend = (e) => {
    e.preventDefault();
    this.setState({ showError: true });
    const { text } = this.state;
    if (!text) return;
    let payload = {
      to: this.state.selectedUser._id,
      message: text,
    };
    this.props.sendMessage(payload, this.onComplete);
    this.setState({
      field: "chatInfo",
      sendMsg: true,
      loadingMsg: true,
      showError: false,
    });
  };

  handlePagination = (step) => {
    let { page, limit } = this.state;
    page = page + step;
    this.setState((prev) => ({ page, field: prev.activeList }));
    this.state.activeList === "recentUsers"
      ? this.props.getRecentUsers({ page, limit }, this.onComplete)
      : this.props.getAllUsers({ page, limit }, this.onComplete);
  };

  handleSearch = (key, value) => {
    let { search, limit, page, activeList, timeout } = this.state;
    if (!value) {
      this.props.getRecentUsers({ page, limit }, this.onComplete);
      this.setState({ search: value });
      return;
    }
    this.setState({ [key]: value });
    clearTimeout(timeout);
    const time = setTimeout(() => {
      let payload = {
        type: activeList === "allUsers" ? 1 : 2,
        limit,
        page,
        query: value,
      };
      this.props.searchUser(payload, this.onComplete);
    }, 1500);
    this.setState({ field: activeList, timeout: time });
  };
  hideComponent = () => {
    this.setState({ toggleMessage: !this.state.toggleMessage });
  };
  calNext = () => {
    let {
      limit,
      page,
      totalUsers,
      activeList,
      allUsers,
      recentUsers,
    } = this.state;
    let activeUser = activeList === "recentUsers" ? recentUsers : allUsers;
    return (page - 1) * limit + activeUser?.length < totalUsers;
  };

  getSessionToken = async (id, roomName) => {
    try {
      let data = {
        identity: id,
        room: roomName,
      };
      const result = await axios({
        method: "POST",
        url: "https://peridot-spider-4849.twil.io/create-token",
        data,
      });

      this.setState(
        { token: result.data, VideoModal: true, incomingCall: false },
        () => {
          console.log(this.state.token, "here irrrr");
          // this.videoCall();
        }
      );
      // console.log(result.data, "aagya mai");
    } catch (err) {
      console.log("Something went wrong");
    }
  };

  initiateVideo = (event, user) => {
    let roomName = Math.random().toString(36).substring(7);
    this.setState({ roomName: roomName }, () => {
      this.getSessionToken(this.props.loggedInUser.userName, roomName);
    });
    this.startCall(user, roomName);
  };

  addUser = (userDetails, roomName) => {
    console.log(userDetails, "user details");
    this.getSessionToken(this.props.loggedInUser.userName, roomName);
  };

  handleCloseVideoModal = () => {
    this.setState((prevState, props) => ({
      VideoModal: !prevState.VideoModal,
    }));
  };

  handleLogout = () => {
    this.setState({ token: "" });
    setTimeout(() => {
      this.setState({ VideoModal: false });
    }, 1000);
  };

  startCall = (data, roomName) => {
    console.log(data, "gtt");
    data.userNameCalling = this.props.loggedInUser.userName;
    socket.emit("calling", { token: roomName, receiverId: data });
  };

  componentWillUnmount() {
    socket.close();
  }

  render() {
    const { classes, loggedInUser } = this.props;
    const {
      text,
      activeList,
      page,
      search,
      selectedUser,
      recentUsers,
      allUsers,
      chatInfo,
      loading,
      showError,
      loadingMsg,
      sendMsg,
      showWelcomPage,
      toggleMessage,
    } = this.state;
    return (
      <div className={classes.root}>
        <Nav
          handleDrawerOpen={this.handleDrawerOpen}
          handleDrawerClose={this.handleDrawerClose}
          open={this.state.open}
          title={this.state.title}
          active={4}
        />
        <main
          className={classes.content}
          className={clsx(classes.content, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <div>
            <Dialog
              open={this.state.VideoModal}
              // onClose={this.handleCloseVideoModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              className="videoModal"
            >
              {this.state.token ? (
                <Room
                  roomName={this.state.roomName}
                  token={this.state.token}
                  handleLogout={this.handleLogout}
                  meetingAction={"End Call"}
                  callType={this.state.callType}
                />
              ) : null}

              {/* <div ref={this.myVideoRef} className="LocalParticipant"></div>
              <div ref={this.remoteVidRef} className="LocalParticipant"></div>
              <div className="align-chat-button">
                <Fab
                  aria-label="backbutton"
                  className={classes.fab}
                  onClick={this.hideComponent}
                >
                  <img
                    className={classes.imageIcon}
                    className="arrow-changepassword"
                    src={leftArrow}
                  />
                </Fab>
                <Fab
                  aria-label="backbutton"
                  className={classes.fab}
                  onClick={this.hideComponent}
                >
                  <img
                    className={classes.imageIcon}
                    className="arrow-changepassword"
                    src={leftArrow}
                  />
                </Fab>
              </div> */}
            </Dialog>
          </div>

          <Grid container>
            <Grid item item xs={12} sm={3}>
              <div className={classes.search}>
                <InputBase
                  placeholder="Search…"
                  type="text"
                  style={{ width: "75%" }}
                  inputProps={{ "aria-label": "Search by name or email" }}
                  value={search}
                  onChange={(e) => this.handleSearch("search", e.target.value)}
                />
                <IconButton className={classes.iconButton} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            component={Paper}
            className={clsx(classes.chatSection, classes.chatHide)}
          >
            <>
              <Grid
                item
                xs={12}
                sm={3}
                className={{
                  [classes.displayHide]: !toggleMessage,
                }}
              >
                <div
                  className={classes.tabContainer}
                  style={{ marginBottom: "20px" }}
                >
                  <Button
                    class="btn-doc heading1"
                    className={classes.tab}
                    disableTouchRipple={true}
                    style={{
                      padding: "10px",
                      margin: "0 2px",
                      width: "45%",
                      background:
                        activeList !== "recentUsers" ? "lightgrey" : "",
                    }}
                    onClick={(e) =>
                      this.handleToggleList(e, "activeList", "recentUsers")
                    }
                  >
                    Chats
                  </Button>
                  <Button
                    class="btn-doc heading1"
                    className={classes.tab}
                    disableTouchRipple={true}
                    style={{
                      padding: "10px",
                      margin: "0 2px",
                      width: "45%",
                      background: activeList !== "allUsers" ? "lightgrey" : "",
                    }}
                    onClick={(e) =>
                      this.handleToggleList(e, "activeList", "allUsers")
                    }
                  >
                    All users
                  </Button>
                </div>
                <Scrollbars
                  style={{ height: HEIGHT }}
                  renderTrackVertical={(props) => (
                    <div className={classes.scrollBar} {...props} />
                  )}
                  renderTrackHorizontal={(props) => (
                    <div style={{ display: "none" }} {...props} />
                  )}
                >
                  <List>
                    {loading ? (
                      <Loader />
                    ) : activeList === "recentUsers" ? (
                      recentUsers?.map((user) => (
                        <Card
                          key={user._id}
                          className={classes.chatCard}
                          onClick={() => this.getUserChat(user)}
                        >
                          <CardContent
                            className="p-0"
                            style={{ padding: "0px" }}
                          >
                            <ListItem button key={user.userName}>
                              <ListItemIcon>
                                <Avatar
                                  alt={user.userName}
                                  variant="square"
                                  src={`data:image/png;base64,${user.userImage}`}
                                  className={classes.avatarRadius}
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={user.userName}
                                secondary={user.message}
                                className={classes.textBox}
                              ></ListItemText>
                              <ListItemText
                                secondary={moment(user.created_at).format(
                                  "DD-MM-YY hh:mm a"
                                )}
                                align="right"
                              ></ListItemText>
                            </ListItem>
                          </CardContent>
                        </Card>
                      ))
                    ) : (
                      allUsers?.map((user) => (
                        <Card
                          key={user?._id}
                          className={classes.chatCard}
                          onClick={() => this.getUserChat(user)}
                        >
                          <CardContent
                            className="p-0"
                            style={{ padding: "0px" }}
                          >
                            <ListItem button key={user?.userName}>
                              <ListItemIcon>
                                <Avatar
                                  alt={user?.userName}
                                  variant="square"
                                  src={`data:image/png;base64,${user?.userImage}`}
                                  className={classes.avatarRadius}
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={user?.userName}
                              ></ListItemText>
                              {/* <ListItemText
                                secondary={moment(user?.created_at).format(
                                  "DD-MM-YY hh:mm a"
                                )}
                                align="right"
                              ></ListItemText> */}
                            </ListItem>
                          </CardContent>
                        </Card>
                      ))
                    )}
                  </List>
                </Scrollbars>
                <div
                  className={classes.tabContainer}
                  style={{ marginTop: "35px" }}
                >
                  {page > 1 ? (
                    <Button
                      class="btn-doc heading1"
                      disableTouchRipple={true}
                      className={classes.tab}
                      style={{ padding: "10px", margin: "0 2px", width: "50%" }}
                      onClick={() => this.handlePagination(-1)}
                    >
                      Prev
                    </Button>
                  ) : null}
                  {this.calNext() ? (
                    <Button
                      class="btn-doc heading1"
                      disableTouchRipple={true}
                      className={classes.tab}
                      style={{ padding: "10px", margin: "0 2px", width: "50%" }}
                      onClick={() => this.handlePagination(1)}
                    >
                      Next
                    </Button>
                  ) : null}
                </div>
              </Grid>
            </>
            <Grid
              item
              sm={9}
              xs={12}
              className={clsx(classes.messageSection, {
                [classes.displayShow]: toggleMessage,
              })}
            >
              <Card
                style={{
                  boxShadow: "0px 7px 20px 0px rgba(22, 101, 216, 0.05)",
                }}
              >
                <List
                  className={classes.heading}
                  disableTouchRipple={false}
                  onClick={this.handleRefresh}
                >
                  <ListItem button key="RemySharp">
                    <ListItemText
                      primary={selectedUser.userName}
                      classes={{
                        primary: "report-modal chatHeading font-open-sans ",
                      }}
                      className="report-modal"
                    ></ListItemText>
                    {selectedUser.userName ? (
                      <>
                        <ListItemIcon
                          onClick={(e) => {
                            this.initiateVideo(e, selectedUser);
                          }}
                        >
                          {/* <span
                            onClick={() => this.setState({ callType: "audio" })}
                          >
                            Audio
                          </span> */}
                          <VideocamIcon
                            onClick={() => this.setState({ callType: "video" })}
                            style={{ color: "#333" }}
                          />
                        </ListItemIcon>
                        {/* <ListItemIcon
                          onClick={(e) => {
                            this.addUser(e, selectedUser);
                          }}
                        >
                          Add User
                        </ListItemIcon> */}

                        <ListItemIcon>
                          <CachedIcon />
                        </ListItemIcon>
                      </>
                    ) : null}
                  </ListItem>
                </List>
                <CardContent>
                  <Scrollbars
                    className={classes.messageArea}
                    style={{ height: HEIGHT }}
                    onScroll={this.handleScroll}
                    renderTrackVertical={(props) => (
                      <div className={classes.scrollBar} {...props} />
                    )}
                    renderTrackHorizontal={(props) => (
                      <div style={{ display: "none" }} {...props} />
                    )}
                  >
                    <div className={classes.insideText}>
                      {loadingMsg && <Loader />}
                    </div>
                    <List
                      className={clsx(
                        classes.insideText,
                        loadingMsg ? classes.hide : ""
                      )}
                      ref={(element) => {
                        this.listContainer = element;
                      }}
                    >
                      {selectedUser?._id &&
                        (chatInfo?.data
                          ? chatInfo.data.map((msg) =>
                              msg.createdBy === loggedInUser._id ? (
                                <ListItem key={msg._id}>
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={12}
                                      className={classes.gridright}
                                    >
                                      <ListItemText
                                        align={"right"}
                                        primary={msg.message}
                                        secondary={moment(
                                          msg.created_at
                                        ).format("DD-MM-YY hh:mm a")}
                                        className={clsx(
                                          classes.chatItem,
                                          classes.text,
                                          classes.chatItemRight
                                        )}
                                      ></ListItemText>
                                      <ListItemAvatar align="right">
                                        <Avatar
                                          variant="square"
                                          alt={selectedUser?.username}
                                          className={classes.avatarRadius}
                                          src={`data:image/png;base64,${loggedInUser.userImage}`}
                                        ></Avatar>
                                      </ListItemAvatar>
                                    </Grid>
                                  </Grid>
                                </ListItem>
                              ) : (
                                <ListItem key={msg._id}>
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={12}
                                      className={classes.gridLeft}
                                    >
                                      <ListItemAvatar align="left">
                                        <Avatar
                                          alt={selectedUser?.username}
                                          variant="square"
                                          className={classes.avatarRadius}
                                          src={`data:image/png;base64,${chatInfo.image.userImage}`}
                                        ></Avatar>
                                      </ListItemAvatar>
                                      <ListItemText
                                        align="left"
                                        className={clsx(
                                          classes.chatItem,
                                          classes.text,
                                          classes.chatItemLeft
                                        )}
                                        primary={msg.message}
                                        secondary={moment(
                                          msg.created_at
                                        ).format("DD-MM-YY hh:mm a")}
                                      ></ListItemText>
                                    </Grid>
                                  </Grid>
                                </ListItem>
                              )
                            )
                          : !loadingMsg && "No Messages present")}
                    </List>
                  </Scrollbars>
                </CardContent>
                <CardActions className="send-background">
                  <Divider />
                  <Grid
                    container
                    className="p-4"
                    style={{ padding: "10px 20px" }}
                  >
                    <form onSubmit={this.handleSend} style={{ width: "100%" }}>
                      <div className="msgSend">
                        <TextareaAutosize
                          rowsMax={4}
                          inputProps={{ maxLength: 300 }}
                          id="outlined-basic-email"
                          placeholder="Type Your Message..."
                          className="msg-type"
                          onBlur={() => this.setState({ showError: false })}
                          onChange={(e) =>
                            this.handleChange("text", e.target.value)
                          }
                          value={text}
                          style={{
                            paddingLeft: "0 !important",
                            paddingTop: "0 !important",
                          }}
                        />
                        <button onClick={this.handleSend}>
                          <img
                            height="25px"
                            className={classes.imageIcon}
                            className="cursor-pointer"
                            src={SendData}
                          />
                        </button>
                      </div>
                    </form>
                    <div style={{ color: "red" }}>
                      {showError && !text ? "Please Enter a message" : ""}
                    </div>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          <Hidden mdUp>
            <Fab
              aria-label="backbutton"
              className={classes.fab}
              onClick={this.hideComponent}
            >
              <img
                className={classes.imageIcon}
                className="arrow-changepassword"
                src={leftArrow}
              />
            </Fab>
          </Hidden>
          {/* Video incoming call Modal */}
          <Dialog
            open={this.state.incomingCall}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                backgroundColor: "#333",
                boxShadow: "none",
                alignItems: "center",
              },
            }}
          >
            <DialogContent style={{ padding: "40px 80px 40px 72px" }}>
              <Avatar
                alt="Remy Sharp"
                src={`data:image/png;base64,${this.state.userImage}`}
                // src="/static/images/avatar/1.jpg"
                style={{
                  height: "150px",
                  width: "150px",
                  marginBottom: "20px",
                }}
              />
              <span
                style={{
                  justifyContent: "center",
                  display: "flex",
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#fff",
                }}
              >
                {this.state.incomingCallName}
              </span>
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "16px",
                  color: "#fff",
                }}
              >
                Incoming calling...
              </span>
            </DialogContent>
            <DialogActions>
              {/* <Button
                color="primary"
                style={{
                  background: "green",
                  borderRadius: "50%",
                  height: "60px",
                }}
              >
                <VideocamIcon style={{ color: "#fff" }} />
              </Button> */}
              <Button
                onClick={() =>
                  this.addUser(
                    this.state.tokenData.receiverId,
                    this.state.tokenData.token
                  )
                }
                color="primary"
                style={{
                  background: "green",
                  borderRadius: "50%",
                  height: "60px",
                }}
                disableTouchRipple={true}
              >
                <CallIcon style={{ color: "#fff" }} />
              </Button>
              <Button
                onClick={() => this.setState({ incomingCall: false })}
                color="primary"
                style={{
                  background: "red",
                  borderRadius: "50%",
                  height: "60px",
                }}
                disableTouchRipple={true}
              >
                <CallEndIcon style={{ color: "#fff" }} />
              </Button>
            </DialogActions>
          </Dialog>

          {/* Video incoming call Modal */}
        </main>
      </div>
    );
  }
}

function mapStateToProps({ login }) {
  return {
    loggedInUser: login.authData,
  };
}

export default connect(mapStateToProps, {
  getRecentUsers,
  getMessageById,
  sendMessage,
  getAllUsers,
  searchUser,
  loginAction,
})(withStyles(useStyles)(Chat));
