/*
     Organization Api Constants
*/
import {
    actionCreator,
    createRequestActionTypes,
    jsonApiHeader
} from './../../../../actions/utilAction';
export {
    actionCreator,
    jsonApiHeader
};

export const organizationActionTypes = {
    get_organization: createRequestActionTypes('GET_ORGANIZATION'),
};
