import React from 'react';
import { Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Paper, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { connect } from 'react-redux';
import LoginImg from '../../assets/loginMain.png';
import Logo from '../../assets/logo.png';
import autoBind from 'react-autobind';
import { loginAction } from './actions/loginActions';
import * as constant from '../../actions/constant';
import jwt from 'jsonwebtoken';
import disableBrowserBackButton from 'disable-browser-back-navigation';


const useStyles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${LoginImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin:theme.spacing(12,12) ,
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#FFC805 !important",
    color: ' #005A98',
    fontFamily: "OpenSans-Regular",
    fontWeight: "700",
    fontSize: "18px",
    height:'60px',
  },
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      errorEmail: '',
      errorPassword: '',
      error: false,
      checked: false,
      showPassword: false,
      buttonDisabled: false
    }
    autoBind(this);
  }

  componentDidMount() {
    disableBrowserBackButton();
    const isChecked = localStorage.isChecked ? localStorage.isChecked : '';
    const buffer = localStorage.buffer ? localStorage.buffer : '';
    // if(isChecked && isChecked == 'true'){
      localStorage.removeItem('access_token');
      // const decoded = jwt.verify(buffer, 'DEMO@xyz');
      try {
      const decoded = jwt.verify(buffer, 'DEMO@xyz');
      console.log(decoded,'here is')
        this.setState({
        username: decoded.email,
        password: decoded.password,
        checked: isChecked
      })
      } catch(err) {
        // err
        // console.log("error",err)
      }
    // }
    // else{
    //     localStorage.clear();
    //     window.sessionStorage.clear()
    // }
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.username !== '' && this.state.password !== '' && this.state.error === false) {
      this.setState({buttonDisabled:true})
      let userObj =
      {
        email: this.state.username,
        password: this.state.password,
      }
      let isChecked = this.state.checked
      // return false
      // this.props.loginAction(userObj, isChecked);
      this.props.loginAction(userObj,isChecked, (result) => {
        let thiss=this
        if (result) {
          if(result==='Enter valid Email'){
          this.setState({errorEmail:'Enter valid email'})}
          // if(result=='Password incorrect'){
          //   this.setState({errorPassword:'Password incorrect '})}
           setTimeout(function() {
            //your code to be executed after 1 second
            thiss.setState({buttonDisabled:false})
          }, 3000);
        }
    })
    }
    if (this.state.username === '' && this.state.error === false) {
      this.setState({ errorEmail: 'This field is required', error: true });
    }
    if (this.state.password === '' && this.state.error === false) {
      this.setState({ errorPassword: 'This field is required', error: true });
    }

  }
  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }
  handleCheck() {
    this.setState({ checked: !this.state.checked });
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <div className="mr-b-50">
              <img src={Logo} className={classes.logo} alt="logo" />
            </div>
            <Typography className="loginHeading" component="h1" variant="h5">
              Log In
          </Typography>
            <Typography component="body2" variant="p" className="loginPara">
              Better cures. Better Health. Better lives.<br />
            Help us win the fight against  infectious diseases
          </Typography>
            <form className={classes.form} onSubmit={this.handleSubmit} noValidate>
              <TextField
                error={this.state.errorEmail !== ""}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                defaultValue={this.state.username ? this.state.username : ''}
                value={this.state.username}
                type='email'
                onChange={(event) => {
                  this.setState({ errorEmail: '', error: false, [event.target.name]: event.target.value });
                  if (event.target.value.length !== 0 && (new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$").test(event.target.value) === true)) {
                    this.setState({ errorEmail: '', error: false, [event.target.name]: event.target.value });
                  }
                  else {
                    this.setState({ errorEmail: 'Please enter valid email', error: true });
                  }
                  if (event.target.value.length === 0) {
                    this.setState({ errorEmail: 'This field is required', error: true });
                  }
                }}
                helperText={this.state.errorEmail}
                autoFocus
              />
              <TextField
                error={this.state.errorPassword !== ""}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={this.state.showPassword ? "text" : "password"}
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleClickShowPassword}
                      >
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                onChange={(event) => {
                  // if (event.target.value.length != 0 && new RegExp("^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$").test(event.target.value) == true) {
                  this.setState({ errorPassword: '', error: false, [event.target.name]: event.target.value });
                  if (event.target.value.length !== 0) {
                    this.setState({ errorPassword: '', error: false, [event.target.name]: event.target.value });
                  }
                  else {
                    this.setState({ errorPassword: 'This field is required', error: true });
                  }
                }}
                helperText={this.state.errorPassword}
                id="password"
                autoComplete="current-password"
                defaultValue={this.state.password ? this.state.password : ''}
                value={this.state.password}
              />
              <Grid container>
                <Grid item xs>
                  <FormControlLabel
                    control={<Checkbox
                      value="remember"
                      color="primary"
                      onChange={this.handleCheck}
                      checked={this.state.checked}
                      style={{padding: "0px 9px 0px 0px"}}
                    />}
                    style={{padding: "0px 0px 0px 12px"}}
                    label="Remember me" className="rememberMe"
                  />
                </Grid>

                <Grid item>
                  <Link href={constant.FORGOTPASSWORD} variant="body2">
                    Forgot password?
                </Link>
                </Grid>

              </Grid>

              <Button
                type="submit"
                fullWidth
                className={classes.submit}
                disabled={this.state.buttonDisabled}
                disableTouchRipple="false"
              >
                LOGIN
            </Button>
            </form>
          </div>
        </Grid>
        <Grid item xs={false} sm={4} md={6} className={classes.image} />
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    authData: state.login.authData
  };
}

export default connect(mapStateToProps, { loginAction })(withStyles(useStyles)(Login));