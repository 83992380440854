/*
     Organization Api Constants
*/
import {
    actionCreator,
    createRequestActionTypes,
    jsonApiHeader
} from './../../../../actions/utilAction';
export {
    actionCreator,
    jsonApiHeader
};

export const userActionTypes = {
    get_user: createRequestActionTypes('GET_USER'),
    get_prac: createRequestActionTypes('GET_PRAC'),
    get_cat: createRequestActionTypes('GET_CATEGORY'),
    get_user_details: createRequestActionTypes('GET_USER_DETAILS')
};
