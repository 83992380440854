import React, { Component } from "react";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { Switch } from "react-router-dom";
// import Dashboard from '../modules/admin/dashboard/Dashboard';
import NoMatch from "../component/NoMatch";
// import Base from '../component/components/Base';
// import { refreshAuthDetails } from '../modules/login/actions/loginActions';
import MyDocuments from "../modules/practitioner/Documents/MyDocuments";
import Login from "../modules/login/Login";
import UploadNewDocument from "../modules/practitioner/Documents/UploadNewDocument";
import editDocument from "../modules/practitioner/Documents/editDocument";
import ResetPassword from "../modules/password/ResetPassword";
import ForgetPassword from "../modules/password/ForgetPassword";
// import changePassword from "../modules/password/changePassword";
import linkExpired from "../component/linkExpired";
import addCategory from "../modules/admin/category/AddNewCategory";
import listCategory from "../modules/admin/category/ManageCategory";
import listOrganization from "../modules/admin/organization/ManageOrganization";
import listUser from "../modules/admin/user/ManageUsers";
import userList from "../modules/admin/user/listUser";
import SharedWithMe from "../modules/practitioner/Documents/SharedWithMe";
import SharedFileDetails from "../modules/practitioner/Documents/SharedFileDetails";
import documentList from "../modules/practitioner/Documents/documentList";
import userdocList from "../modules/practitioner/Documents/userDocList";
import userProfile from "../modules/practitioner/Profile/userProfile";
import RatingAndReviews from "../modules/practitioner/RatingAndReviews/RatingAndReviews";
import ViewDocumentDetails from "../modules/practitioner/Documents/ViewDocumentDetails";
import History from "../modules/practitioner/Documents/History";
import UnApprovedDocument from "../modules/admin/unApprovedDocument/UnApprovedDocument";
import uploadMultipleFiles from "../modules/practitioner/Documents/uploadMultipleFiles";
import ApprovedDocument from "../modules/admin/approvedDocument/ApprovedDocument";
import ContactAdmin from "../modules/practitioner/contactAdmin/ContactAdmin";
import ReportedReviewManagement from "../modules/admin/reportedReviewManagement/ReportedReviewManagement";
// import CreateNFT from "../modules/nft/CreateNFT"; 
import { connect } from "react-redux";
// import history from "../history";
import Chat from "../modules/practitioner/chat/MyChat";

class RoutesComponent extends Component {
  componentDidMount() {
    // let access_token = window.sessionStorage.getItem("access_token");
    // // localStorage.getItem('access_token');
    // if (access_token && access_token != null) {
    //     this.props.refreshAuthDetails();
    // } else {
    //     let split = this.props.location.pathname.split('/')
    //     let value = split[2];
    //     if (window.location.pathname !== "/" &&
    //         window.location.pathname !== "/forgetPassword" &&
    //         window.location.pathname !== "/resetPassword/") {
    //         history.push('/')
    //     }
    // }
  }

  render() {
    let props = this.props;
    let split = props.location.pathname.split("/");
    let value = split[2];
    const listofPages = [
      "/",
      "/forgetPassword",
      "/resetPassword/" + value,
      "/expired",
    ];
    const token = localStorage.access_token ? true : false;
    // const token = sessionStorage.access_token?true :false;
    if (listofPages.indexOf(props.location.pathname) > -1) {
      return (
        <Switch>
          <PublicRoute exact path="/" component={Login} />
          <PublicRoute
            exact
            path="/resetPassword/:resetId"
            component={ResetPassword}
          />
          <PublicRoute
            exact
            path="/forgetPassword"
            component={ForgetPassword}
          />
          <PublicRoute exact path="/expired" component={linkExpired} />
        </Switch>
      );
    }
    if (props.userInfo.userType === 1) {
      return (
        <Switch>
          <PrivateRoute
            exact
            path="/addCategory"
            component={addCategory}
            user={token}
          />
          <PrivateRoute
            exact
            path="/listCategory"
            component={listCategory}
            user={token}
          />
          <PrivateRoute
            exact
            path="/listOrganization"
            component={listOrganization}
            user={token}
          />
          <PrivateRoute
            exact
            path="/listUser"
            component={listUser}
            user={token}
          />
          <PrivateRoute
            path="/documentList/:docId"
            component={documentList}
            user={token}
          />
          <PrivateRoute
            path="/docList/:userId"
            component={userdocList}
            user={token}
          />
          <PrivateRoute
            path="/userlist/:orgId"
            component={userList}
            user={token}
          />
          <PrivateRoute
            path="/unApprovedDocument"
            component={UnApprovedDocument}
            user={token}
          />
          <PrivateRoute
            path="/approvedDocument"
            component={ApprovedDocument}
            user={token}
          />

          <PrivateRoute
            path="/reviewManagement"
            component={ReportedReviewManagement}
            user={token}
          />

          <PublicRoute path="*" component={NoMatch} />
        </Switch>
      );
    }
    if (props.userInfo.userType === 2) {
      return (
        <Switch>
          <PrivateRoute
            exact
            path="/MyDocuments"
            component={MyDocuments}
            user={token}
          />
          <PrivateRoute
            exact
            path="/upload"
            component={UploadNewDocument}
            user={token}
          />
          <PrivateRoute
            exact
            path="/edit"
            component={editDocument}
            user={token}
          />
          <PrivateRoute
            exact
            path="/shared-with-me"
            component={SharedWithMe}
            user={token}
          />
          <PrivateRoute
            exact
            path="/my-message"
            component={Chat}
            user={token}
          />
          <PrivateRoute
            exact
            path="/shared-file-details"
            component={SharedFileDetails}
            user={token}
          />
           <PrivateRoute
            exact
            path="/upload-multiple-documents"
            component={uploadMultipleFiles}
            user={token}
          />
          <PrivateRoute
            exact
            path="/user-profile"
            component={userProfile}
            user={token}
          />
          <PrivateRoute
            exact
            path="/document-details"
            component={ViewDocumentDetails}
            user={token}
          />
          <PrivateRoute
            exact
            path="/history"
            component={History}
            user={token}
          />
          <PrivateRoute
            exact
            path="/ratingAndReviews"
            component={RatingAndReviews}
            user={token}
          />
          <PrivateRoute
            exact
            path="/change-password"
            component={ForgetPassword}
            user={token}
          />
          <PrivateRoute
            path="/contactAdmin"
            component={ContactAdmin}
            user={token}
          />
          {/*<PrivateRoute
            exact
            path="/create-nft"
            component={CreateNFT}
            user={token}
          />*/}
          <PublicRoute path="*" component={NoMatch} />
        </Switch>
      );
    }  
  }
}
function mapStateToProps(state) {
  return {
    userInfo: state.login.authData,
  };
}

export default connect(mapStateToProps, {})(RoutesComponent);

