import React from "react";
import clsx from "clsx";
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core/';
import { InputBase, TextareaAutosize, InputLabel, FormControl, Switch, TextField, FormHelperText, NativeSelect, Icon, Button, Box, Grid, Typography, } from "@material-ui/core";
import { tableIcons } from "./constants/iconConstant.js";
import leftArrow from '../../../assets/left.svg';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MaterialTable from 'material-table';
import autoBind from 'react-autobind';
import { getOrganizations, addOrganization, editOrganization, editStatus } from './actions/organizationAction';
import NavAdmin from "../../../component/NavAdmin.js";
import history from '../../../history.js';
import Loader from '../../../component/Loader';

const drawerWidth = 316;
const useStyles = theme => ({
  root: {
    display: "flex",
  },
  rootCard: {
    maxWidth: 345,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  appBarShift: {
    marginLeft: "150px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    position: "relative",
    top: "10rem",
  },
  switchBase: {
    padding: 1,
    marginTop: "0px",
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  inputStyle: {
    'label + &': {
      marginTop: theme.spacing(4),
    },
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #BFBFBF',
    fontSize: 12,
    height:"34px",
    fontFamily: "OpenSans-Regular",
color: "#005A98",
fontWeight:"bold",
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    padding: '0px',
    width: '100%',
    minWidth: '413px',
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#FFC805",
    color: ' #005A98',
    fontFamily: "OpenSans-Regular",
    fontWeight: "700",
    fontSize: "18px",
  },
});
const BootstrapInput = withStyles((theme) => ({
  minWidth: "413px",
  width: "100%",
  marginTop: "25px",
}))(InputBase);

class ManageOrganization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      modal: false,
      checkedA: false,
      checkedE: false,
      title: "Manage Organization",
      modalStatus: '',
      organizationList: [],
      organizationDetail: '',
      organizationName: '',
      organizationEmail: '', // Added this line
      organizationId: '',
      errorAdd: '',
      errorEdit: '',
      error: false,
      load: true,
      isDisabled: false
    }
    autoBind(this);
  }

  componentDidMount() {
    this.props.getOrganizations();
    this.setState({ load: true })

  }

  static getDerivedStateFromProps(props, state) {
    if (props.organizationList !== state.organizationList) {
      return {
        organizationList: props.organizationList,
        load: false
      };
    }
    // Return null if the state hasn't changed
    return null;

  }

  handleDrawerOpen = () => {
    this.setState({ open: true })
  };
  handleDrawerClose = () => {
    this.setState({ open: false })
  };

  handleAddChange = (event) => {
    let name = event.target.name;
    let checked = event.target.checked
    this.setState(prevState => {
      return { [name]: checked };
    });
  }

  handleEditChange = (event) => {
    this.state.organizationDetail.isActive = !this.state.organizationDetail.isActive;
    let name = event.target.name;
    let checked = event.target.checked;
    this.setState(prevState => {
      return {
        [name]: checked
      };
    });
  }

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState(prevState => {
      return { [name]: value };
    });
  }

  handleStatus = (event, rowData) => {
    rowData.isActive = !rowData.isActive
    let obj = {
      "id": rowData._id,
      "organisationName": rowData.organisationName,
      "isActive": event.target.checked
    }
    this.setState({ isDisabled: true })
    this.props.editStatus(obj, (result) => {
      if (result) {
        let thiss = this
        setTimeout(function () {
          //your code to be executed after 1 second
          thiss.setState({ isDisabled: false })
        }, 3000);
      }
    })
  }

  none = () => {

  }

  addOrganization = () => {
    if (this.state.organizationName.length != 0 && this.state.organizationEmail.length != 0 && this.state.errorAdd == '' && this.state.error == false) {
      this.setState({ modal: false })
      let obj = {
        "organisationName": this.state.organizationName,
        "organisationEmail": this.state.organizationEmail, // Added this line
        "isActive": this.state.checkedA
      }
      this.props.addOrganization(obj)
    } else {
      this.setState({ errorAdd: 'This field is required', error: true })
    }
  }

  editOrganization = () => {
    if (this.state.organizationName.length != 0 && this.state.errorEdit == '' && this.state.error == false) {
      this.setState({ modal: false })
      // return false
      let obj = {
        "id": this.state.organizationId,
        "organisationName": this.state.organizationName,
        "isActive": this.state.checkedE
      }
      this.props.editOrganization(obj)
    }
    // else if (this.state.organizationName.length >25) {
    //   this.setState({ errorEdit: 'Organization name can be max. 25 char', error: true })
    // }
    else {
      this.setState({ errorEdit: 'This field is required', error: true })
    }
  }

  handleModalOpen = (data, organizationDetails) => {
    this.setState({ modal: true, modalStatus: data, organizationDetail: organizationDetails });
  };

  handleEditModal = (data, organizationDetails) => {
    this.setState({
      modal: true, modalStatus: data, organizationDetail: organizationDetails, organizationName: organizationDetails.organisationName,
      organizationId: organizationDetails._id, error: false, checkedE: organizationDetails.isActive
    });
  }
  handleAddModal = (data) => {
    this.setState({ modal: true, modalStatus: data, organizationDetail: '', organizationName: '', error: false });
  }
  handleModalClose = () => {
    this.setState({ modal: false, errorAdd: '', errorEdit: '', organizationEmail: '' }); // Added organizationEmail reset
    this.props.getOrganizations();
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <NavAdmin handleDrawerOpen={this.handleDrawerOpen} handleDrawerClose={this.handleDrawerClose} open={this.state.open} title={this.state.title} activeValue={1} />
        <main className={classes.content}
          className={clsx(classes.content, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Box mb={7}>
          </Box>
          <Button class="btn-doc heading1"  disableTouchRipple="false" style={{ marginBottom: "20px" }}
            // onClick={this.handleModalOpen}
            onClick={
              this.handleAddModal.bind(this, 'add')
            }
           
          >Add New Organization</Button>
          {this.state.load == true ? <Loader /> : <MaterialTable
            icons={tableIcons}
            title="Organization List"
            columns={[
              { title: 'Id', field: 'organisationId', cellStyle: { width: "20%" } },
              { title: 'Organization Name', field: 'organisationName', cellStyle: { width: "20%" } },
              {
                title: 'No. of Users ', field: 'userCount.length', type: 'numeric', cellStyle: { width: "20%", textAlign: "center" }, headerStyle: {
                 paddingLeft:"40px"
                }
              },
            ]}
            data={
              this.state.organizationList
            }
            localization={{
              header: {
                actions: 'Status'
              },
            }}
            actions={[
              rowData => ({
                icon: () =>
                  <Switch
                    checked={rowData.isActive}
                    onChange={(event) => { this.handleChange(event) }}
                    name="checkedE"
                    className="main-switch mr-t-0"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />,
                tooltip: 'Save User',
                onClick: (event, rowData) => { this.state.isDisabled == false ? this.handleStatus(event, rowData) : this.none() },
              }
              ),
              {
                icon: () => <VisibilityOutlinedIcon style={{color:"#005a98"}} />,
                tooltip: 'Show User List',
                // onClick: () => this.handleModalOpen()
                onClick: (event, rowData) => {
                  // this.handleModalOpen.bind(this,'view')
                  // this.handleModalOpen('view', rowData)
                  this.props.history.push("/userlist/" + rowData._id)
                }
              },
              {
                icon: () => <EditOutlinedIcon style={{color:"#005a98"}}/>,
                tooltip: 'Edit Organization List',
                // onClick: () => this.handleModalOpen()
                onClick: (event, rowData) => { this.handleEditModal('edit', rowData) }
              },
            ]}
            options={{
              actionsColumnIndex: -1,
              rowStyle: x => {
                if (x.tableData.id % 2) {
                  return {
                    backgroundColor: "#ebf5ff",
                    color: "#005a98",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "OpenSans-Regular",
                  }
                }
                else {
                  return {
                    color: "#005a98",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "OpenSans-Regular",
                  }
                }
              },
              
              headerStyle: {
                backgroundColor: "#ffcf5c",
                color: "#005a98",
                fontSize: "16px",
                fontWeight: "700",
                color: "#005a98",
              },
              pageSize: 20,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [5, 10, 20, 30, 40, 50],    // rows selection options

            }}
          />}
          <Dialog open={this.state.modal} onClose={this.handleModalClose} aria-labelledby="form-dialog-title" className="dialog-main-div">
            {this.state.modalStatus && this.state.modalStatus === 'add' ? <DialogTitle id="form-dialog-title" className="modal-header">Add New Organization</DialogTitle> : ''}
            {this.state.modalStatus && this.state.modalStatus === 'view' ? <DialogTitle id="form-dialog-title" className="modal-header">View Organization</DialogTitle> : ''}
            {this.state.modalStatus && this.state.modalStatus === 'edit' ? <DialogTitle id="form-dialog-title" className="modal-header">Edit Organization</DialogTitle> : ''}
            <DialogContent className="dialog-content pd-70">
            <Grid container>
              <Grid item className="mr-b-40">
                <FormControl>
                  <InputLabel shrink htmlFor="organizationName" className="input-label label-font">
                    Organization Name
                  </InputLabel>
                  {this.state.modalStatus && this.state.modalStatus === 'view' ? (
                    <BootstrapInput
                      readOnly
                      value={this.state.organizationDetail ? this.state.organizationDetail.organisationName : ''}
                      placeholder="Enter Organization Name"
                      id="organizationName"
                      className={clsx(classes.inputStyle, classes.inputWidth)}
                    />
                  ) : (
                    <BootstrapInput
                      value={this.state.organizationName}
                      placeholder="Enter Organization Name"
                      id="organizationName"
                      name="organizationName"
                      className={clsx(classes.inputStyle, classes.inputWidth)}
                      inputProps={{ maxLength: 25 }}
                      onChange={this.handleChange}
                    />
                  )}
                  {this.state.modalStatus && this.state.modalStatus === 'add' && (
                    this.state.errorAdd === '' ? (
                      <FormHelperText>25 character max.</FormHelperText>
                    ) : (
                      <FormHelperText error>{this.state.errorAdd}</FormHelperText>
                    )
                  )}
                  {this.state.modalStatus && this.state.modalStatus === 'edit' && (
                    this.state.errorEdit === '' ? (
                      <FormHelperText>25 character max.</FormHelperText>
                    ) : (
                      <FormHelperText error>{this.state.errorEdit}</FormHelperText>
                    )
                  )}
                </FormControl>
              </Grid>

              <Grid item className="mr-b-40">
                <FormControl>
                  <InputLabel shrink htmlFor="organizationEmail" className="input-label label-font">
                    Organization Email
                  </InputLabel>
                  {this.state.modalStatus && this.state.modalStatus === 'view' ? (
                    <BootstrapInput
                      readOnly
                      value={this.state.organizationDetail ? this.state.organizationDetail.organisationEmail : ''}
                      placeholder="Enter Organization Email"
                      id="organizationEmail"
                      className={clsx(classes.inputStyle, classes.inputWidth)}
                    />
                  ) : (
                    <BootstrapInput
                      value={this.state.organizationEmail}
                      placeholder="Enter Organization Email"
                      id="organizationEmail"
                      name="organizationEmail"
                      className={clsx(classes.inputStyle, classes.inputWidth)}
                      onChange={this.handleChange}
                    />
                  )}
                  {this.state.modalStatus && this.state.modalStatus === 'add' && (
                    this.state.errorAdd === '' ? (
                      <FormHelperText>Enter a valid email address.</FormHelperText>
                    ) : (
                      <FormHelperText error>{this.state.errorAdd}</FormHelperText>
                    )
                  )}
                  {this.state.modalStatus && this.state.modalStatus === 'edit' && (
                    this.state.errorEdit === '' ? (
                      <FormHelperText>Enter a valid email address.</FormHelperText>
                    ) : (
                      <FormHelperText error>{this.state.errorEdit}</FormHelperText>
                    )
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12}>
                <InputLabel shrink className="input-label label-font">
                  Activate Organization
                </InputLabel>
                {this.state.modalStatus && this.state.modalStatus === 'add' ? (
                  <Switch
                    checked={this.state.checkedA}
                    onChange={this.handleAddChange}
                    name="checkedA"
                    className="main-switch"
                    style={{ marginTop: "0px" }}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                ) : null}
                {this.state.modalStatus && this.state.modalStatus === 'view' ? (
                  <Switch
                    readOnly
                    checked={this.state.organizationDetail.isActive}
                    className="main-switch"
                    style={{ marginTop: "0px" }}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                ) : null}
                {this.state.modalStatus && this.state.modalStatus === 'edit' ? (
                  <Switch
                    checked={this.state.organizationDetail.isActive}
                    onChange={this.handleEditChange}
                    name="checkedE"
                    className="main-switch"
                    style={{ marginTop: "0px" }}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                ) : null}
              </Grid>
            </Grid>
          </DialogContent>

            <DialogActions>
              <Button class="btn-doc-close heading3" style={{ marginBottom: "20px", marginRight: "20px" }}  disableTouchRipple="false" onClick={this.handleModalClose}>
                Close
        </Button>
              {this.state.modalStatus && this.state.modalStatus === 'add' ? <Button class="btn-doc heading1"  disableTouchRipple="false" style={{ marginBottom: "20px", marginRight: "20px" }}
                onClick={this.addOrganization}>
                Submit</Button> : ''}
              {this.state.modalStatus && this.state.modalStatus === 'edit' ? <Button class="btn-doc heading1"  disableTouchRipple="false" style={{ marginBottom: "20px", marginRight: "20px" }}
                onClick={this.editOrganization}
              >
                Submit</Button> : ''}
            </DialogActions>
          </Dialog>

        </main>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    organizationList: state.organization.organizationList,
  };
}

export default connect(mapStateToProps, { getOrganizations, addOrganization, editOrganization, editStatus })(withStyles(useStyles)(ManageOrganization));

