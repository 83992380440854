import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core/";
import {
  TextareaAutosize,
  Button,
  Box,
  Grid,
} from "@material-ui/core";
import { tableIcons } from "../../admin/organization/constants/iconConstant";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import GetAppIcon from "@material-ui/icons/GetApp";
import MaterialTable from "material-table";
import autoBind from "react-autobind";
import { getDoclist } from "./actions/documentActions";
import NavAdmin from "../../../component/NavAdmin.js";
import Loader from "../../../component/Loader";
import axios from "axios";
import FileSaver from "file-saver";
import { API_URL } from "../../../actions/utilAction";
import { jsonApiHeader } from "../../../actions/utilAction";
import { toasterMessage } from "../../../actions/util";

const drawerWidth = 316;
const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  rootCard: {
    maxWidth: 345,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  appBarShift: {
    marginLeft: "150px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    position: "relative",
    top: "10rem",
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  inputStyle: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    borderRadius: 4,
    position: "relative",
    border: "1px solid #BFBFBF",
    fontSize: 16,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    padding: "0px",
    width: "100%",
    marginBottom: "20px",
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#FFC805",
    color: " #005A98",
    fontFamily: "OpenSans-Regular",
    fontWeight: "700",
    fontSize: "18px",
  },
});
/* const BootstrapInput = withStyles((theme) => ({
  minWidth: "413px",
  width: "100%",
  marginTop: "25px",
  marginBottom: "20px",
}))(InputBase);
 */
class ManageDoc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      modal: false,
      checkedA: false,
      checkedE: false,
      title: "Document List",
      modalStatus: "",
      docList: [],
      load: true,
      fileId: "",
      rejectModal: false,
      rejectComment: "",
    };
    autoBind(this);
  }

  componentDidMount() {
    this.setState({ load: true });
    let obj = {
      mongooseId: this.props.match.params.docId,
    };
    this.props.getDoclist(obj);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.docList !== state.docList) {
      return {
        docList: props.docList,
        load: false,
      };
    }
    // Return null if the state hasn't changed
    return null;
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  approveFileApiCall = () => {
    let postBody = {
      documentId: this.state.fileId,
    };
    this.setState({ load: true });
    let access_token = localStorage.access_token;
    axios
      .post(`${API_URL}/document/approveDoc`, postBody, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({ load: false });
          toasterMessage("success", res.data.message);
          let obj = {
            mongooseId: this.props.match.params.userId,
          };
          this.props.getDoclistbyuserId(obj);
          //   this.props.getunApprovedDocuments();
        }
      })
      .catch((error) => {
        this.setState({ load: false });
        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch(() => {
        this.setState({ load: false });
        toasterMessage("error", "Something bad happened");
      });
  };

  downloadDocument = (data) => {
    this.setState({ load: true });
    let access_token = localStorage.access_token;
    axios
      .get(`${API_URL}/document/downloadDoc/${data.currentDocument.fileId}`, {
        headers: jsonApiHeader(access_token, "blob"),
        responseType: "blob",
      })
      .then((res) => {
        this.setState({ fileDownloading: true, load: false }, () => {
          FileSaver.saveAs(res.data, `${data.documentName}`);
        });

        console.log(res.data, "from here");
      })
      .catch((error) => {
        // this.handleRejectModal()
        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch((error) => {
        console.log(error);
        // this.handleRejectModal()
        toasterMessage("error", "Something bad happened");
      });
  };

  handleRejectModal = () => {
    this.setState({ rejectModal: !this.state.rejectModal, rejectComment: "" });
  };
  rejectFile = (data) => {
    console.log(data);
    this.setState({ fileId: data._id });
    this.handleRejectModal();
  };

  rejectFileApiCall = () => {
    if (!this.state.rejectComment.trim()) {
      toasterMessage("error", "Comment is required");
      // alert('Comment is required')
      return;
    }
    if (this.state.rejectComment.length > 300) {
      toasterMessage(
        "error",
        "Comment is too long only 300 characters allowed"
      );
      // alert('Comment is required')
      return;
    }
    this.setState({ loader: true });
    let postBody = {
      id: this.state.fileId,
      comment: this.state.rejectComment,
      type: 1,
    };
    let access_token = localStorage.access_token;
    axios
      .post(`${API_URL}/document/reqRevisionDoc`, postBody, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then((res) => {
        if (res.data.status === 200) {
          toasterMessage("success", res.data.message);
          let obj = {
            mongooseId: this.props.match.params.userId,
          };
          this.props.getDoclistbyuserId(obj);
          this.setState({ load: false });
          this.handleRejectModal();
        }
      })
      .catch((error) => {
        this.handleRejectModal();
        this.setState({ load: false });
        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch(() => {
        this.handleRejectModal();
        toasterMessage("error", "Something bad happened");
        this.setState({ load: false });
      });

    console.log(this.state.fileId, this.state.rejectComment, "from save");
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <NavAdmin
          handleDrawerOpen={this.handleDrawerOpen}
          handleDrawerClose={this.handleDrawerClose}
          open={this.state.open}
          title={this.state.title}
        />
        <main
          className={clsx(classes.content, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Box mb={7}></Box>
          {this.state.load === true ? (
            <Loader />
          ) : (
            <MaterialTable
              icons={tableIcons}
              title="Document List"
              columns={[
                {
                  title: "Id",
                  field: "documentId",
                  cellStyle: { width: "10%" },
                },
                {
                  title: "Email",
                  field: "createdBy[email]",
                  cellStyle: { width: "20%" },
                },
                {
                  title: "Document name",
                  field: "documentName",
                  cellStyle: { width: "20%" },
                },
                {
                  title: "Category",
                  field: "categoryId[categoryName]",
                  cellStyle: { width: "20%" },
                },
                {
                  title: "Status",
                  field: "currentDocument[status]",
                  cellStyle: { width: "10%" },
                },
              ]}
              data={
                this.state.docList
                // [{email:'trisha@yopmail.com'}]
              }
              localization={{
                header: {
                  actions: "Action",
                },
              }}
              actions={[
                {
                  icon: () => <CheckBoxIcon />,
                  tooltip: "Approve",
                  // onClick: () => this.handleModalOpen()
                  onClick: (event, rowData) => {
                    this.setState({ fileId: rowData._id }, () => {
                      this.approveFileApiCall();
                    });
                    // this.handleModalOpen.bind(this,'view')
                    // this.handleModalOpen('view', rowData)
                  },
                },
                {
                  icon: () => <CancelPresentationIcon />,
                  tooltip: "Reject",
                  // onClick: () => this.handleModalOpen()
                  onClick: (event, rowData) => {
                    this.rejectFile(rowData);
                    // this.handleModalOpen.bind(this,'view')
                    // this.handleModalOpen('view', rowData)
                  },
                },
                {
                  icon: () => <GetAppIcon />,
                  tooltip: "Download",
                  // onClick: () => this.handleModalOpen()
                  onClick: (event, rowData) => {
                    this.downloadDocument(rowData);
                    // this.handleModalOpen.bind(this,'view')
                    // this.handleModalOpen('view', rowData)
                  },
                },
              ]}
              options={{
                actionsColumnIndex: -1,
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return {
                      backgroundColor: "#ebf5ff",
                      color: "#005a98",
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily: "OpenSans-Regular",
                    };
                  } else {
                    return {
                      color: "#005a98",
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily: "OpenSans-Regular",
                    };
                  }
                },
                actionsCellStyle: {
                  width: "100%",
                  marginBottom: "-1px",
                  paddingLeft: "3rem",
                },
                headerStyle: {
                  backgroundColor: "#ffcf5c",
                  color: "#005a98",
                  fontSize: "16px",
                  fontWeight: "700",
                },
                actionStyle: { display: "flex", justifyContent: "center" },
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [5, 10, 20, 30, 40, 50], // rows selection options
              }}
            />
          )}

          <Dialog
            open={this.state.rejectModal}
            onClose={this.handleRejectModal}
            aria-labelledby="form-dialog-title"
            className="dialog-main-div"
          >
            <DialogTitle id="form-dialog-title" class="modal-header">
              Are you sure you want to request a revision?
            </DialogTitle>
            <DialogContent
              className="dialog-content"
              style={{ padding: "60px" }}
            >
              <Grid container>
                <Grid item>
                  <span
                    className="label-heading"
                    style={{ marginBottom: "10px" }}
                  >
                    Share additional information/message with the Author :
                    <span style={{ color: "red" }}> (Max 300 Characters)</span>
                  </span>
                  <br />
                  <TextareaAutosize
                    className={clsx(classes.inputStyle, classes.textAreWidth)}
                    rowsMax={15}
                    rowsMin={8}
                    inputProps={{
                      maxLength: 300,
                    }}
                    aria-label="maximum height"
                    placeholder="Write Description"
                    value={this.state.rejectComment}
                    onChange={(e) => {
                      if (e.target.value.trim().length > 300) {
                        return toasterMessage("error", "Message Limit exceeded!!!");
                      } else {
                        this.setState({
                          rejectComment: e.target.value,
                        });
                      }
                    }}
                  />

                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              style={{ padding: "0px 60px", justifyContent: "flex-start" }}
            >
              <Button
                class="btn-doc heading1"
                style={{ marginBottom: "20px", marginRight: "20px" }}
                onClick={this.rejectFileApiCall}
                disableTouchRipple="false"
              >
                Submit
              </Button>
              <Button
                class="btn-doc-close heading3"
                style={{ marginBottom: "20px", marginRight: "20px" }}
                onClick={this.handleRejectModal}
                disableTouchRipple="false"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </main>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    docList: state.document.docList,
  };
}

export default connect(mapStateToProps, { getDoclist })(
  withStyles(useStyles)(ManageDoc)
);
