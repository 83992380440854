/** Login Actions  */

import {
    jsonApiHeader
} from '../constants/apiConstant';
import history from '../../../history.js';
import { API_URL } from '../../../actions/utilAction';
import axios from 'axios';
import { toasterMessage } from "../../../actions/util";
import * as constant from '../../../actions/constant';


let access_token = localStorage.access_token;
// let access_token = sessionStorage.access_token;


export function forgotPassword(authObj,callback) {

    return (dispatch) => {
        axios.post(API_URL + constant.FORGOT_PASSWORD, authObj, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(function (response) {
                if (response.data.status === 200) {
                    history.push(constant.LOGIN);
                toasterMessage("success", response.data.message);
                } 
            }).catch((error) => {
                toasterMessage("error", error.response.data.error);
                if (error.response.status === 300) {
                toasterMessage("error", error.response.data.error);
                }
            return callback('err');
            }).catch(() => {
                    toasterMessage('error', 'Something bad happened')
                })
    };
}

export function resetPassword(authObj) {
    return (dispatch) => {
        axios.post(API_URL + constant.RESET_PASSWORD, authObj, {
            headers: jsonApiHeader(access_token, 'application/json')
        }).then(function (response) {
            if (response.data.status === 200) {
                history.push(constant.LOGIN);
                toasterMessage("success", response.data.message);
            // return callback( response.data.message);
            }
        }).catch((error) => {
            toasterMessage("error", error.response.data.error);
        }).catch(() => {
            toasterMessage('error', 'Something bad happened')
            // return callback('err');
        })
    };
}

// export function changePassword(authObj) {
//     return (dispatch) => {
//         axios.post(API_URL + constant.CHANGE_PASSWORD, authObj, {
//             headers: jsonApiHeader(access_token, 'application/json')
//         }).then(function (response) {
//             if (response.data.status === 200) {
//                 history.push('/MyDocuments');
//                 toasterMessage("success", response.data.message);
//             }
//         }).catch((error) => {
//             toasterMessage("error", error.response.data.error);
//         }).catch(() => {
//             toasterMessage('error', 'Something bad happened')
//             // return callback('err');
//         })
//     };
// }

export function tokenExist(authObj,callback) {
    return (dispatch) => {
        axios.post(API_URL + constant.TOKEN_EXPIRED, authObj, {
            headers: jsonApiHeader(access_token, 'application/json')
        }).then(function (response) {
            // console.log("response",response)
            if (response.data.status === 200) {
                // toasterMessage("success", response.data.message);
            return callback( response.data.data.isExpired);
            }
        }).catch((error) => {
            // toasterMessage("error", error.response.data.error);
            return callback( 'err');
        }).catch(() => {
            // toasterMessage('error', 'Something bad happened')
            return callback('err');
        })
    };
}