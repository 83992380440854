import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  InputBase,
  TextareaAutosize,
  InputLabel,
  FormControl,
  Switch,
  TextField,
  FormHelperText,
  NativeSelect,
  Icon,
  Button,
  Box,
  Grid,
  Typography,
  MenuItem,
  Menu,
  Select,
  Hidden,
} from "@material-ui/core";
import cloudIcon from "../../../assets/cloud-upload-fill.svg";
import docIcon from "../../../assets/doc.svg";
import LinearProgress from "@material-ui/core/LinearProgress";
import closeIcon from "../../../assets/Close.svg";
import leftArrow from "../../../assets/left.svg";
import downloadFrame from "../../../assets/Frame.png";
import Nav from "../../../component/Nav.js";
import { SelectValue } from "./constants/dropDownStyled";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core/";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { DropzoneArea } from "material-ui-dropzone";
import { Link } from "react-router-dom";
import {
  getcategoryPrac,
  getPractitioner,
} from "../../admin/user/actions/userAction";
import { addDocument } from "./actions/documentActions";
import Frame from "../../../assets/Frame.svg";
import { getOrganizations } from "../../admin/organization/actions/organizationAction";
const icon = (
  <CheckBoxOutlineBlankIcon fontSize="small" style={{ fill: "#005A98" }} />
);
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const drawerWidth = 316;
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#F3F3F3",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: " #FFA800",
  },
}))(LinearProgress);

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  rootCard: {
    maxWidth: 345,
  },
  marginGridDesc: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "10px",
    },
  },
  marginGrid: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "40px 165px 0px 75px",
    },
  },
  marginBottomButton: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: "30px",
    },
  },
  uploadIconSize: {
    backgroundImage: require("../../../assets/cloud-upload-fill.svg"),
  },
  marginDropZone: {
    margin: "40px 114px",
  },
  alignGrid: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "18px",
    },
  },
  marginBottomGrid: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: "15px",
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  appBarShift: {
    marginLeft: "150px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    position: "relative",
    top: "10rem",
  },
  inputStyle: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFFFFF",
    border: "1px solid #BFBFBF",
    fontSize: 16,
    fontFamily: "OpenSans-Regular",
    color: "#005A98",
    fontWeight: "bold",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    padding: "0px",
    width: "100%",
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  selectOption: {
    minWidth: "208px",
    height: "50px",
  },
  inputWidth: {
    height: "50px",
  },
  textAreWidth: {
    minHeight: "129px",
    minWidth: "500px",
  },
  dropdownStyle: {
    border: "1px solid #FFC805",
    boxShadow: "0px 4px 24px rgba(0, 90, 152, 0.05)",
    borderRadius: "4px",
    backgroundColor: "#ffffff",
  },
  focused: {},
  iconOpen: {},
});
const BootstrapInput = withStyles((theme) => ({
  minWidth: "413px",
  width: "100%",
}))(InputBase);

class UploadNewDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isPrivate: false,
      title: "My Documents ",
      documentName: "",
      errorCategory: "",
      categoryId: "",
      description: "",
      files: [],
      errorName: "",
      errorDescription: "",
      selectedUser: [],
      errorShare: "",
      errorDoc: "",
      buttonDisabled: false,
      showError: false,
      selectedOraganisation: [],
      organisationList: [],
      selectedAllUsers: false,
      selectedAllOrganisation: false,
    };
  }
  componentDidMount() {
    this.props.getcategoryPrac();
    this.props.getPractitioner();
    this.props.getOrganizations();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.userList !== state.userList) {
      let temp = [...props.userList];
      temp.unshift({
        _id: "0",
        isActive: true,
        email: "",
        userName: "All",
      });
      return {
        userList: temp,
      };
    }
    console.log(props.categoryList, "props.categoryList");
    if (props.categoryList !== state.categoryList) {
      return {
        categoryList: props.categoryList,
      };
    }
    // Return null if the state hasn't changed
    return null;
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  handleChange = (event) => {
    if (event.target.checked == true) {
      this.setState({ errorShare: "" });
    }
    let name = event.target.name;
    let checked = event.target.checked;
    this.setState((prevState) => {
      return { [name]: checked };
    });
  };

  handleModalOpen = () => {
    this.setState({
        errorShare: "",
        error: false,
        showError: false
      });
    if (!this.state.isPrivate && !this.state.selectedOraganisation?.length && !this.state.selectedUser.length) {
    this.setState({
        errorShare: "Please select min one user to share",
        error: true,
        showError: true
      });

      return;
    }
    
    if (
      this.state.documentName != "" &&
      this.state.categoryId != "" &&
      this.state.errorShare == "" &&
      this.state.description != "" &&
      this.state.errorDescription == "" &&
      this.state.errorDoc == "" &&
      this.state.files.length != 0
    ) {
      this.setState({
        buttonDisabled: true,
      });
      console.log("$");
      let formData = new FormData();
      formData.append("documentName", this.state.documentName);
      formData.append("categoryId", this.state.categoryId);
      formData.append("isPrivate", this.state.isPrivate);
      formData.append(
        "sharedWith",
        this.state.isPrivate == false
          ? JSON.stringify(this.state.selectedUser)
          : JSON.stringify([])
      );
      formData.append(
        "sharedWithOrganisation",
        this.state.isPrivate == false
          ? JSON.stringify(this.state.selectedOraganisation)
          : JSON.stringify([])
      );
      formData.append("description", this.state.description);
      formData.append("file", this.state.files[0]);
      // return false
      this.props.addDocument(formData, (result) => {
        // console.log("result", result)
        if (result == "success") {
          this.setState({ modal: true });
        } else {
          this.setState({
            buttonDisabled: false,
          });
        }
      });
    }
    if (this.state.documentName == "") {
      this.setState({ errorName: "This field is required", error: true });
    }
    if (this.state.files.length == 0) {
      this.setState({ errorDoc: "Please upload one document", error: true });
    }
    if (this.state.description == "") {
      this.setState({
        errorDescription: "This field is required",
        error: true,
      });
    }
    if (this.state.categoryId == "") {
      this.setState({
        errorCategory: "Please select a category",
        error: true,
      });
    }
  };

  handleModalClose = (data) => {
    this.setState({ modal: false });
  };

  handleImage(files) {
    let file_size = files[0] && files[0].size ? files[0].size : 0;
    if (file_size > 5316476) {
      this.setState({
        files: files,
        errorDoc: "Max file size allowed is 5MB",
      });
    } else {
      this.setState({
        files: files,
        errorDoc: "",
      });
    }
  }

  onTagsChange = (event, values) => {
    let arr = [];
    values.map((x, index) => {
      console.log(x, "this is selected");

      // if (x._id == 0) {
      //   this.props.userList.map((user) => {
      //     arr.push(user._id);
      //   });
      // } else {
      //   arr.push(x._id);
      // }
      arr.push(x._id);
      console.log(arr, "from arr");
    });

    this.setState({ selectedUser: arr });
    if (arr.length != 0) {
      this.setState({ errorShare: "" });
    } else {
      this.setState({ errorShare: "Please select min one user to share" });
    }
  };

  /* function to handle shared with
      me selection
    */
  handleSharedWithDocs = (e, values) => {
    this.setState({ selectedOraganisation: values.map((item) => item._id) });
  };

  handleSelectAllUser = (e) => {
    let arr = [...this.state.selectedUser];
    this.setState(
      {
        selectedAllUsers: !this.state.selectedAllUsers,
      },
      () => {
        if (this.state.selectedAllUsers) {
          this.props.userList.map((user) => {
            arr.push(user._id);
          });
          this.setState({ selectedUser: arr });
        } else {
          arr = [];
          this.setState({ selectedUser: arr });
        }
        // this.setState({ selectedUser: arr },()=> console.log(this.state.selectedAllUsers, "from here"));
      }
    );
  };

  handleSelectAllOrganisation = (e) => {
    let arr = [...this.state.selectedOraganisation];
    this.setState(
      {
        selectedAllOrganisation: !this.state.selectedAllOrganisation,
      },
      () => {
        if (this.state.selectedAllOrganisation) {
          this.props.organizationList.map((user) => {
            arr.push(user._id);
          });
          this.setState({ selectedOraganisation: arr });
        } else {
          arr = [];
          this.setState({ selectedOraganisation: arr });
        }
        // this.setState({ selectedUser: arr },()=> console.log(this.state.selectedAllUsers, "from here"));
      }
    );
  };

  render() {
    console.log("this.state.categoryList", this.props.categoryList);
    const { classes, organizationList } = this.props;
    const { sharedDocs, showError, selectedOraganisation } = this.state;
    const iconDoc = React.createElement(this.props.customIcon, {
      className: classes.uploadIconSize,
    });
    return (
      <div className={classes.root}>
        <Nav
          handleDrawerOpen={this.handleDrawerOpen}
          handleDrawerClose={this.handleDrawerClose}
          open={this.state.open}
          title={this.state.title}
        />
        <main
          className={classes.content}
          className={clsx(classes.content, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Typography
            variant="body2"
            pb={0}
            color="textSecondary"
            className="ListHeading "
            component="p"
          >
            <Link to={"/MyDocuments"}>
              {" "}
              <Icon>
                {" "}
                <img
                  className={classes.imageIcon}
                  className="arrow-position"
                  src={leftArrow}
                />
              </Icon>
            </Link>
            Upload New Document
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Hidden mdDown>
                <div className="uploadDocDiv uploadDocColor">
                  <Grid container className={classes.marginDropZone}>
                    <DropzoneArea
                      onChange={this.handleImage.bind(this)}
                      dropzoneText={[
                        "Drag and drop a file here or click",
                        <span style={{ fontSize: "14px" }}>
                          {" "}
                          <br />( .JPEG, .PDF, .XLS, .DOCX, .DOC .PPT, .CSV)
                        </span>,
                        "",
                      ]}
                      className="uploadDoc dropzone-div"
                      customIcon={classes.cloudIcon}
                      acceptedFiles={[".xls,.xlsx,.csv,.jpeg,.doc,.docx,.ppt,.pdf,.pptx"]}
                      // acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                      maxFileSize={50000000}
                      showFileNames
                      showAlerts={false}
                      filesLimit={1}
                      previewGridProps={{
                        container: { spacing: 1 },
                        item: { xs: 12 },
                      }}
                    />
                    <FormHelperText className="display-block-center" error>
                      {this.state.errorDoc}
                    </FormHelperText>
                  </Grid>
                </div>
              </Hidden>

              <Hidden mdUp>
                <Grid container>
                  <Grid item xs={12}>
                    <DropzoneArea
                      onChange={this.handleImage.bind(this)}
                      dropzoneText={[
                        "Drag and drop a file here or click",
                        <span style={{ fontSize: "14px" }}>
                          {" "}
                          <br />( .JPEG, .PDF, .XLS, .DOC, .PPT, .CSV)
                        </span>,
                        "",
                      ]}
                      className="uploadDoc dropzone-div"
                      customIcon={classes.cloudIcon}
                      acceptedFiles={[".xls,.xlsx,.csv,.jpeg,.doc,.docx,.ppt,.pdf,.pptx"]}
                      // acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                      maxFileSize={50000000}
                      showFileNames
                      showAlerts={false}
                      filesLimit={1}
                      previewGridProps={{
                        container: { spacing: 1 },
                        item: { xs: 12 },
                      }}
                    />
                    <FormHelperText className="display-block-center" error>
                      {this.state.errorDoc}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
          <div className={clsx(classes.marginGrid, classes.marginBottomGrid)}>
            <Grid container>
              <Grid item xs={12} sm={6} lg={6} className={classes.alignGrid}>
                <FormControl className="width-100 padding-right-100">
                  <InputLabel
                    className="label-font input-label "
                    shrink
                    htmlFor="bootstrap-input"
                  >
                    Name
                  </InputLabel>
                  <BootstrapInput
                    value={this.state.documentName}
                    placeholder="Enter Document Name"
                    id="categoryName"
                    className={clsx(classes.inputStyle, classes.inputWidth)}
                    inputProps={{
                      maxLength: 25,
                    }}
                    onChange={(event) => {
                      this.setState({
                        errorName: "",
                        error: false,
                        documentName: event.target.value,
                      });
                      if (event.target.value.length == 0) {
                        this.setState({
                          errorName: "This field is required",
                          error: true,
                        });
                      } else {
                        this.setState({
                          errorName: "",
                          error: false,
                          documentName: event.target.value,
                        });
                      }
                    }}
                  />
                  {this.state.errorName == "" ? (
                    <FormHelperText>25 character max.</FormHelperText>
                  ) : (
                    <FormHelperText error>
                      {this.state.errorName}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5} lg={5} className={classes.alignGrid}>
                <FormControl className="width-100 padding-right-100">
                  <InputLabel shrink={true} className="label-font input-label ">
                    Select Category
                  </InputLabel>
                  <SelectValue
                    onChange={(event) => {
                      if (event.target.value.length == 0) {
                        this.setState({
                          errorCategory: "Please select a category",
                          error: true,
                        });
                      } else {
                        this.setState({
                          errorCategory: "",
                          error: false,
                          categoryId: event.target.value,
                        });
                      }
                    }}
                  >
                    <option value="" hidden>
                      Select Data Category
                    </option>
                    {this.props.categoryList
                      ? this.props.categoryList.map((x, index) => (
                          <option value={x._id}>{x.categoryName}</option>
                        ))
                      : ""}
                  </SelectValue>
                  <FormHelperText error style={{ marginTop: "22px" }}>
                    {this.state.errorCategory}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={1} lg={1} className={classes.alignGrid}>
                <InputLabel shrink={true} className="label-font input-label">
                  {/* Privacy */}
                  is Private?
                </InputLabel>
                <Switch
                  checked={this.state.isPrivate}
                  onChange={(event) => {
                    this.handleChange(event);
                  }}
                  name="isPrivate"
                  className="main-switch"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </Grid>
              <Grid container className={classes.marginBottomButton}>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  lg={8}
                  className={clsx(classes.alignGrid, classes.marginGridDesc)}
                >
                  <FormControl className="width-100 padding-right-100">
                    <InputLabel
                      className="label-font input-label "
                      shrink={true}
                    >
                      Description
                    </InputLabel>
                    <TextareaAutosize
                      aria-label="maximum height"
                      // placeholder="Write Description"
                      // defaultValue=""
                      className={clsx(classes.inputStyle, classes.textAreWidth)}
                      value={this.state.description}
                      placeholder="Write Description"
                      id="description"
                      inputProps={{
                        maxLength: 300,
                      }}
                      onChange={(event) => {
                        this.setState({
                          errorDescription: "",
                          error: false,
                          description: event.target.value,
                        });
                        if (event.target.value.length == 0) {
                          this.setState({
                            errorDescription: "This field is required",
                            error: true,
                          });
                        } else if (event.target.value.length < 301) {
                          console.log("onchange", event.target.value);
                          this.setState({
                            errorDescription: "",
                            error: false,
                            description: event.target.value,
                          });
                        } else {
                          this.setState({
                            errorDescription:
                              "Description can be maximum 300 character long",
                            error: false,
                            description: this.state.description,
                          });
                        }
                      }}
                    />
                    <Grid container>
                      <Grid item xs={6} sm={6}>
                        <FormHelperText error>
                          {this.state.errorDescription}
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <FormHelperText
                          className="align-count"
                          style={{ textAlign: "right" }}
                        >{`${this.state.description.length}/300`}</FormHelperText>
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={2}
                  className={clsx(classes.alignGrid)}
                >
                  <InputLabel
                    className="label-font input-label"
                    shrink={true}
                    style={{ marginBottom: "12px" }}
                  >
                    Share with
                  </InputLabel>
                  {this.state.isPrivate == !true ? (
                    <div style={{ display: "flex" }}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={this.state.selectedAllUsers}
                        iconStyle={{
                          fill: "#FFC805",
                          outline: "2px solid red",
                        }}
                        onChange={(e) => {
                          this.handleSelectAllUser(e);
                        }}
                        style={{
                          position: "relative",
                          top: "-6px",
                          padding: "0px 7px 0px 0px",
                        }}
                      />
                      <InputLabel
                        className="label-font input-label"
                        shrink={true}
                        style={{ marginBottom: "12px" }}
                      >
                        Select All
                      </InputLabel>
                    </div>
                  ) : null}

                  {this.state.isPrivate == true ||
                  this.state.selectedAllUsers ? (
                    <Autocomplete
                      limitTags={5}
                      readOnly
                      disabled
                      multiple
                      id="checkboxes-tags-demo"
                      className="auto-check-user inputText fontValue"
                      options={top100Films}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.title}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={<Frame />}
                            style={{ border: "1px solid red" }}
                            className={classes.check}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.title}
                        </React.Fragment>
                      )}
                      style={{ width: "290px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          shrink={true}
                          placeholder="Select User"
                        />
                      )}
                    />
                  ) : (
                    <>
                      <Autocomplete
                        limitTags={5}
                        multiple
                        id="checkboxes-tags-demo"
                        className="auto-check-user heading-option"
                        options={this.state.userList}
                        disableCloseOnSelect
                        limitTags={5}
                        getOptionLabel={(option) => option.userName}
                        renderOption={(option, { selected }) => (
                          console.log(option, "from seletected"),
                          (
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                checked={selected}
                                iconStyle={{
                                  fill: "#FFC805",
                                  outline: "2px solid red",
                                }}
                              />
                              <Grid item sm={6}>
                                <Box
                                  component="div"
                                  className="headig-option font-size-14"
                                  textOverflow="ellipsis"
                                >
                                  {option.userName}
                                </Box>
                              </Grid>
                              <Grid item sm={6}>
                                <Box
                                  component="div"
                                  className="headig-option font-size-12"
                                  textOverflow="ellipsis"
                                >
                                  {option.email}
                                </Box>
                              </Grid>
                            </React.Fragment>
                          )
                        )}
                        style={{ width: "290px" }}
                        onChange={this.onTagsChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            shrink={true}
                            placeholder="Select User"
                          />
                        )}
                      />
                    </>
                  )}
                  <FormHelperText error>{this.state.errorShare}</FormHelperText>

                  {/* Shared with Organisation starts */}
                  <br />

                  <InputLabel
                    className="label-font input-label"
                    shrink={true}
                    style={{ marginBottom: "12px", width: 165 }}
                  >
                    Share with Organisation
                  </InputLabel>
                  {this.state.isPrivate !== true ? (
                    <div style={{ display: "flex" }}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={this.state.selectedAllOrganisation}
                        iconStyle={{
                          fill: "#FFC805",
                          outline: "2px solid red",
                        }}
                        onChange={(e) => {
                          this.handleSelectAllOrganisation(e);
                        }}
                        style={{
                          position: "relative",
                          top: "-6px",
                          padding: "0px 7px 0px 0px",
                        }}
                      />
                      <InputLabel
                        className="label-font input-label"
                        shrink={true}
                        style={{ marginBottom: "12px" }}
                      >
                        Select All
                      </InputLabel>
                    </div>
                  ) : null}

                  {this.state.selectedAllOrganisation ||
                  this.state.isPrivate == true ? (
                    <Autocomplete
                      limitTags={5}
                      readOnly
                      disabled
                      id="checkboxes-tags-demo"
                      className="auto-check-user inputText fontValue"
                      options={top100Films}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.title}
                      // renderOption={(option, { selected }) => (
                      //   <React.Fragment>
                      //     <Checkbox
                      //       icon={<Frame />}
                      //       style={{ border: "1px solid red" }}
                      //       className={classes.check}
                      //       checkedIcon={checkedIcon}
                      //       style={{ marginRight: 8 }}
                      //       checked={selected}
                      //     />
                      //     {/* {option.title} */}
                      //   </React.Fragment>
                      // )}
                      style={{ width: "290px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          shrink={true}
                          placeholder="Select Organisation"
                        />
                      )}
                    />
                  ) : (
                    <Autocomplete
                      limitTags={5}
                      multiple
                      id="checkboxes-tags-demo"
                      className="auto-check-user heading-option"
                      options={organizationList}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option?.organisationName}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selectedOraganisation.includes(
                              option?._id
                            )}
                            iconStyle={{
                              fill: "#FFC805",
                              outline: "2px solid red",
                            }}
                          />
                          <Grid item sm={12}>
                            <Box
                              component="div"
                              className="headig-option font-size-14"
                              textOverflow="ellipsis"
                            >
                              {option?.organisationName}
                            </Box>
                          </Grid>
                        </React.Fragment>
                      )}
                      defaultValue={sharedDocs}
                      style={{ width: "290px" }}
                      onChange={this.handleSharedWithDocs}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          shrink={true}
                          placeholder="Select Organisation"
                        />
                      )}
                    />
                  )}

                  <FormHelperText error>
                    {showError && selectedOraganisation.length < 1
                      ? "Please select an organisation"
                      : ""}
                  </FormHelperText>

                  {/* Shared with Organisation ends */}
                </Grid>
              </Grid>
              <Grid container className="display-center">
                <Grid item>
                  <Button
                    type="submit"
                    className="main-btn submit-btn upload-submit border-radius-10"
                    onClick={this.handleModalOpen}
                    disabled={this.state.buttonDisabled}
                    disableTouchRipple="false"
                  >
                    Submit Document
                  </Button>
                </Grid>
                <Grid item>
                  <Box ml={4}>
                    <Link to={"/MyDocuments"}>
                      {" "}
                      <Button
                        type="submit"
                        variant="contained"
                        class="btn-doc-close upload-btn heading3 border-radius-10"
                        disableTouchRipple="false"
                        // onClick={this.handleCancel}
                      >
                        Cancel
                      </Button>
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </main>
        <Dialog
          open={this.state.modal}
          onClose={this.handleModalClose}
          aria-labelledby="form-dialog-title"
          className="dialog-main-div"
        >
          <DialogContent className="dialog-content pd-70">
            <Grid container className="display-center">
              <Grid item className="mr-b-40" style={{ display: "contents" }}>
                <span className="upload-modal-heading font-open-sans">
                  Yayyyy!
                </span>
                <br />
                <span
                  className="upload-modal-heading-2 font-open-sans display-center"
                  style={{ textAlign: "center" }}
                >
                  Your document is submitted successfully
                </span>
              </Grid>
              <Grid item>
                <img
                  className={classes.imageIcon}
                  className="arrow-position"
                  src={downloadFrame}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Link to={"/MyDocuments"}>
              {" "}
              <Button
                class="btn-doc heading1"
                onClick={this.handleModalClose}
                disableTouchRipple="false"
                style={{ marginBottom: "20px", marginRight: "20px" }}
              >
                Close
              </Button>
            </Link>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
function mapStateToProps(state) {
  console.log(state, "from state to props");
  return {
    categoryList: state.category.categoryList,
    organizationList: state.organization.organizationList,
    userList: state.user.practitinerList,
  };
}

export default connect(mapStateToProps, {
  getPractitioner,
  getcategoryPrac,
  addDocument,
  getOrganizations,
})(withStyles(useStyles)(UploadNewDocuments));

const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
];
