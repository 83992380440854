import React from "react";
import clsx from "clsx";
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import { ListItemText, Icon, Button, Box, Grid, Typography, List } from "@material-ui/core";
// import cloudIcon from '../../../assets/cloud-upload-fill.svg'
import docIcon from "../../../assets/doc.svg";
import LinearProgress from '@material-ui/core/LinearProgress';
import closeIcon from '../../../assets/Close.svg';
// import leftArrow from '../../../assets/left.svg';
import downloadFrame from '../../../assets/Frame.png';
import Nav from "../../../component/Nav.js"
// import { SelectValue } from "./constants/dropDownStyled";
import { Dialog, DialogActions,Card, CardContent, CardMedia, DialogContent, Divider } from '@material-ui/core/';
// import Checkbox from '@material-ui/core/Checkbox';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
// import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
// import { DropzoneArea } from 'material-ui-dropzone'
import { Link } from "react-router-dom";
import { getcategoryPrac, getPractitioner } from '../../admin/user/actions/userAction';
import { addDocument } from './actions/documentActions';
// import Frame from '../../../assets/Frame.svg'

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" style={{ fill: "#005A98" }} />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

const drawerWidth = 316;
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#F3F3F3",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: ' #FFA800',
  },
}))(LinearProgress);

const useStyles = theme => ({
  root: {
    display: "flex",
  },
  rootCard: {
    maxWidth: 345,
  },
  uploadIconSize: {
    backgroundImage: require("../../../assets/cloud-upload-fill.svg"),
  },
  marginDropZone: {
    margin: "40px 114px"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  appBarShift: {
    marginLeft: "150px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    position: "relative",
    top: "10rem",
  },
  inputStyle: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#FFFFFF',
    border: '1px solid #BFBFBF',
    fontSize: 16,
    fontFamily: "OpenSans-Regular",
    color: "#005A98",
    fontWeight: "bold",
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    padding: '0px',
    width: '100%',
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  selectOption: {
    minWidth: "208px",
    height: "50px",
  },
  inputWidth: {
    height: "50px",
  },
  textAreWidth: {
    minHeight: "129px",
    minWidth: "500px",
  },
  dropdownStyle:
  {
    border: "1px solid #FFC805",
    boxShadow: "0px 4px 24px rgba(0, 90, 152, 0.05)",
    borderRadius: "4px",
    backgroundColor: "#ffffff",
  },
  focused: {},
  iconOpen: {},
});
/* const BootstrapInput = withStyles((theme) => ({
  minWidth: "413px",
  width: "100%"
}))(InputBase);
 */

class UploadNewDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isPrivate: false,
      title: "My Documents ",
      documentName: '',
      errorCategory: '',
      categoryId: '',
      description: '',
      files: [],
      errorName: '',
      errorDescription: '',
      selectedUser: [],
      errorShare: '',
      errorDoc: '',
      buttonDisabled: false
    }
  }
  componentDidMount() {
    this.props.getcategoryPrac();
    this.props.getPractitioner();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.userList !== state.userList) {
      return {
        userList: props.userList,
      };
    }
    if (props.categoryList !== state.categoryList) {
      return {
        categoryList: props.categoryList,
      };
    }
    // Return null if the state hasn't changed
    return null;

  }

  handleDrawerOpen = () => {
    this.setState({ open: true })
  };
  handleDrawerClose = () => {
    this.setState({ open: false })
  };
  handleChange = (event) => {
    if (event.target.checked === true) {
      this.setState({ errorShare: '' })
    }
    let name = event.target.name;
    let checked = event.target.checked
    this.setState(prevState => {
      return { [name]: checked };
    });
  };

  handleModalOpen = () => {
    if (this.state.isPrivate === false && this.state.selectedUser.length === 0) {
      this.setState({ errorShare: 'Please select min one user to share', error: true })
    }
    if (this.state.documentName !== '' && this.state.categoryId !== '' && this.state.errorShare === ''
      && this.state.description !== '' && this.state.errorDescription === '' && this.state.errorDoc === '' && this.state.files.length !== 0) {
      this.setState({
        buttonDisabled: true
      })
      console.log("$")
      let formData = new FormData();
      formData.append("documentName", this.state.documentName)
      formData.append("categoryId", this.state.categoryId)
      formData.append("isPrivate", this.state.isPrivate)
      formData.append("sharedWith", this.state.isPrivate === false ? JSON.stringify(this.state.selectedUser) : JSON.stringify([]))
      formData.append("description", this.state.description)
      formData.append("file", this.state.files[0])
      // return false
      this.props.addDocument(formData, (result) => {
        // console.log("result", result)
        if (result === 'success') {
          this.setState({ modal: true });
        }
        else {
          this.setState({
            buttonDisabled: false
          })
        }
      })
    }
    if (this.state.documentName === '') {
      this.setState({ errorName: 'This field is required', error: true })
    }
    if (this.state.files.length === 0) {
      this.setState({ errorDoc: 'Please upload one document', error: true })
    }
    if (this.state.description === '') {
      this.setState({ errorDescription: 'This field is required', error: true })
    }
    if (this.state.categoryId === '') {
      this.setState({ errorCategory: 'Please select anyone category', error: true })
    }

  };

  handleModalClose = (data) => {
    this.setState({ modal: false });
  };


  handleImage(files) {
    let file_size = files[0] && files[0].size ? files[0].size : 0
    if (file_size > 5316476) {
      this.setState({
        files: files,
        errorDoc: 'Max file size allowed is 5MB'
      });
    }
    else {
      this.setState({
        files: files,
        errorDoc: ''
      });
    }
  }


  onTagsChange = (event, values) => {
    let arr = [];
    values.map((x, index) => {
      arr.push(x._id);
      return null; // Add a return statement here
    });
    this.setState({ selectedUser: arr });
    if (arr.length !== 0) {
      this.setState({ errorShare: '' });
    } else {
      this.setState({ errorShare: 'Please select at least one user to share' });
    }
  };
  

  render() {
    const { classes } = this.props;
    // const iconDoc = React.createElement(this.props.customIcon, { className: classes.uploadIconSize });
    return (
      <div className={classes.root}>
        <Nav handleDrawerOpen={this.handleDrawerOpen} handleDrawerClose={this.handleDrawerClose} open={this.state.open} title={this.state.title} />
        <main
          className={clsx(classes.content, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Typography variant="body2" pb={0} color="textSecondary" className="ListHeading " component="p">
          Key Highlights For Uploading Multiple Documents
            </Typography>
            <Divider className="ruler-multiple" />
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <List style={{display:"flex"}}>
                        <ListItemText>Step 1. <br/> Download Sample file format</ListItemText>
                        <ListItemText>Step 2. <br/> Upload Document detail(CSV file) </ListItemText>
                        <ListItemText>Step 3. <br/> Upload Your Documents</ListItemText>

                    </List>
                </Grid>
            </Grid>
            <Typography variant="body2" pb={0} color="textSecondary" className="ListHeading " component="p">
            Your documents are here!
            </Typography>
         
              <div className="uploadDocDiv uploadDocColor upload-alignment" style={{display:"block"}}>
              <div className="uploadProgress">
                    <Grid container>
                    <Grid item xs={12} sm={1}>
                    <Icon>
                    <img
                      className={clsx(classes.imageIcon, "align-doc-icon")}
                      src={docIcon}
                      alt="Document Icon"
                    />
                    </Icon>
                    </Grid>
                    <Grid item xs={12} sm={11}>
                      <span className="doc-title">My Document 1.pdf</span>
                      <Box className="float-right" mr={2} mt={2}>
                        <span className="mr-r-5 percentage-color">5/8 Uploaded </span>
                        <img className={classes.imageIcon} src={closeIcon} alt="Close" />
                      </Box>
                    <BorderLinearProgress variant="determinate"  className="align-progress" value={50} />
                    </Grid>
                    </Grid>
                      </div>
                      <Grid
                        container
                        spacing={4}
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                <Grid item xs={12} sm={6} md={3}>
                  <Card className={classes.rootCard}>
                    <CardMedia className={classes.media}>
                      <img
                        className={classes.media}
                        style={{ margin: "auto", padding: "35px 40px" }}
                        src={require("../../../assets/Pdf.png")}
                        alt="PDF Document"
                      />
                    </CardMedia>
                    <CardContent className="pd-t-b-0">
                      <Typography
                        variant="body2"
                        pb={0}
                        color="textSecondary"
                        className="ListHeading"
                        component="p"
                      >
                        My Documents
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
            </Grid>
        </div>
          <div className="uploadDocDiv">
              <Grid container className="display-center">
                <Grid item>
                  <Button
                    type="submit"
                    className="main-btn submit-btn upload-submit border-radius-10"
                    onClick={this.handleModalOpen}
                    disabled={this.state.buttonDisabled}
                    disableTouchRipple="false"
                  >
                    Submit Document
            </Button>
                </Grid>
                <Grid item>
                  <Box ml={4}>
                    <Link to={'/MyDocuments'}>  <Button
                      type="submit"
                      variant="contained"
                      class="btn-doc-close upload-btn heading3 border-radius-10"
                      disableTouchRipple="false"
                    // onClick={this.handleCancel}
                    >
                      Cancel
            </Button>
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            
          </div>
        </main>
        <Dialog open={this.state.modal} onClose={this.handleModalClose} aria-labelledby="form-dialog-title" className="dialog-main-div">
          <DialogContent className="dialog-content pd-70">
            <Grid container className="display-center">
              <Grid item className="mr-b-40" style={{ display: "contents" }}>
                <span className="upload-modal-heading font-open-sans"  >Yayyyy!</span><br /><span className="upload-modal-heading-2 font-open-sans display-center" style={{ textAlign: "center" }}>Your document is submitted successfully</span>
              </Grid>
              <Grid item>
              <img
                className={clsx(classes.imageIcon, "arrow-position")}
                src={downloadFrame}
                alt="Download Frame"
              />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Link to={'/MyDocuments'}>  <Button class="btn-doc heading1" onClick={this.handleModalClose}  disableTouchRipple="false" style={{ marginBottom: "20px", marginRight: "20px" }} >
              Close
        </Button></Link>
          </DialogActions>
        </Dialog>

      </div >
    );
  }
}
function mapStateToProps(state) {
  return {
    categoryList: state.user.categoryList,
    userList: state.user.practitinerList
  };
}

export default connect(mapStateToProps, { getPractitioner, getcategoryPrac, addDocument })(withStyles(useStyles)(UploadNewDocuments));

/* const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },

]; */