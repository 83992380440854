import React from "react";


class VideoCalling extends React.Component {
  render() {
    return (
      <div>
        <h1 style={{color:'black'}} >


          
        </h1>
      </div>
    );
  }
}

export default VideoCalling;
