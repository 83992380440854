import React from 'react';
import { connect } from 'react-redux';
import { Button, CssBaseline, TextField, Paper, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ResetImg from '../../assets/reset.png';
import Logo from '../../assets/logo.png';
import autoBind from 'react-autobind';
// import * as constant from '../../actions/constant';
import { resetPassword, tokenExist } from './actions/passwordAction';
import Loader from '../../component/Loader.js';
import history from '../../history.js';


const useStyles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${ResetImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: "96px 165px",
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#FFC805 !important",
    color: ' #005A98',
    fontFamily: "OpenSans-Regular",
    fontWeight: "700",
    fontSize: "18px",
    height: "60px",
  },
});


class RestPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorconfPassword: '',
      errorPassword: '',
      newpassword: '',
      confirmpassword: '',
      showPassword: false,
      confirmShowPassword: false,
      resetToken: '',
      isExpired:false,
      load:true
    }
    autoBind(this);
  }
  componentDidMount() {

    let tokenExist = {
      token: this.props.match.params.resetId
    }
    this.props.tokenExist(tokenExist, (result) => {
      this.setState({load:false})
      // console.log("result", result)
      if(result===true){
      history.push('/expired');
      }
      if(result==='err'){
        history.push('/expired');
        }
    })
    this.setState({
      resetToken: this.props.match.params.resetId
    })
    // const resetToken = localStorage.resetToken ? localStorage.resetToken : '';

    // if (resetToken != '' && resetToken === this.props.match.params.resetId) {
    //   // console.log("expired")
    //   history.push('/expired');
    // }

  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }
  handleClickShowConfirmPassword() {
    this.setState({ confirmShowPassword: !this.state.confirmShowPassword });
  }
  handleSubmit(event) {
    event.preventDefault();
    if (this.state.newpassword !== '' && this.state.confirmpassword !== '' && this.state.error === false) {
      let obj = {
        token: this.state.resetToken,
        password: this.state.newpassword
      }
      this.props.resetPassword(obj)
      // localStorage.setItem('resetToken', this.state.resetToken);
    }
    if (this.state.newpassword === '') {
      this.setState({ errorPassword: 'This field is required', error: true })
    }
    if (this.state.confirmpassword === '') {
      this.setState({ errorconfPassword: 'This field is required', error: true })
    }
  }

  render() {
    const { classes } = this.props;
    return (
      this.state.load === true ? <Loader /> :
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <div className="mr-b-50">
              <img src={Logo} className={classes.logo} alt="logo" />
            </div>
            <Typography className="loginHeading" component="h1" variant="h5">
              Reset Password
          </Typography>
            <Typography component="body2" variant="p" className="loginPara">
            Please Enter Your New Password.
          </Typography>
            <form className={classes.form} onSubmit={this.handleSubmit} noValidate>
              <TextField
                error={this.state.errorPassword !== ""}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleClickShowPassword}
                      >
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                id="newpassword"
                label="New Password"
                type={this.state.showPassword ? "text" : "password"}
                name="newpassword"
                autoComplete="newpassword"
                onChange={(event) => {
                  if (event.target.value.length !== 0 && event.target.value.length > 5 &&
                    // new RegExp("^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$").test(event.target.value) == true) 
                    new RegExp("^(?=.*[0-9])(?=.*[a-zA-Z])").test(event.target.value) === true) {
                    this.setState({ errorPassword: '', error: false, [event.target.name]: event.target.value });
                  } else {
                    this.setState({ errorPassword: 'Password should be minimum 6 character long with atleast one number and one alphabet', error: true });
                  }
                  if (event.target.value.length === 0) {
                    this.setState({ errorPassword: 'This field is required', error: true });
                  }
                }}
                helperText={this.state.errorPassword}
                autoFocus
              />
              <TextField
                error={this.state.errorconfPassword !== ""}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowConfirmPassword}
                        onMouseDown={this.handleClickShowConfirmPassword}
                      >
                        {this.state.confirmShowPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                name="confirmpassword"
                label="Confirm Password"
                type={this.state.confirmShowPassword ? "text" : "password"}
                id="confirmpassword"
                autoComplete="Confirm Password"
                onChange={(event) => {
                  if (event.target.value.length !== 0) {
                    if (this.state.newpassword === event.target.value) {
                      this.setState({ errorconfPassword: '', error: false, [event.target.name]: event.target.value });
                    } else {
                      this.setState({ errorconfPassword: 'Passwords do not match', error: true });
                    }
                  }
                  else {
                    this.setState({ errorconfPassword: 'This field is required', error: true });
                  }
                }}
                helperText={this.state.errorconfPassword}
              />
              <Button
                type="submit"
                fullWidth
                color="primary"
                className={classes.submit}
                disableTouchRipple="false"
              >
                RESET PASSWORD
            </Button>
            </form>
          </div>
        </Grid>
        <Grid item xs={false} sm={4} md={6} className={classes.image} />
      </Grid>
    );
  }
}
function mapStateToProps(state) {
  return {
  };

}
export default connect(mapStateToProps, { resetPassword, tokenExist })(withStyles(useStyles)(RestPassword));