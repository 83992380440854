const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";

/** Production */

export const API_URL = 'https://d2mwyez4wwzsys.cloudfront.net/api';
export const PROFILE_IMG_PATH = '';




export function actionCreator(actionType, data) {
  return {
    type: actionType,
    payload: data,
  };
}

export function createRequestActionTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((requestTypes, type) => {
    requestTypes[type] = `${base}_${type}`;
    return requestTypes;
  }, {});
}

export const jsonApiHeader = (accessToken, ContentType) => {
  return {
    "Content-Type": ContentType,
    Authorization: localStorage.access_token
      ? `${localStorage.access_token}`
      : "",
    // Authorization: sessionStorage.access_token
    // ? `${sessionStorage.access_token}`
    // : "",
    //  'x-auth-token':localStorage.access_token?`${localStorage.access_token}`
    //  : "",

  };
};

// http://n1.iworklab.com:3226/api
