import styled from "styled-components";
export const SelectValue = styled.select`
width: 100%;
height: 50px;
position: relative;
top: 24px;
right: 12px;
background: white;
color: #333333;
padding-left: 5px;
font-size: 14px;
margin-left: 10px;
font-family: OpenSans-Regular;
font-style: normal;
font-weight: bold;
font-size: 12px;
border: 1px solid #BFBFBF;
border-radius: 4px;
        &:focus{
            border: 1px solid #BFBFBF;
        border-radius: 4px;
        outline:none;
        }
        &::icon{
            border: 1px solid red;
        border-radius: 4px;
        outline:none;
        }
  option {
    color: #333333;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
   
    padding: 0px 2px 1px;
    border: 1px solid #FFC805;
    box-shadow: 0px 4px 24px rgba(0, 90, 152, 0.05);
  }
`;