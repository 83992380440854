export { jsonApiHeader } from './../../../../actions/utilAction';

export function onComplete(bool, data) {
  if (bool) {
    let { field } = this.state;
    switch (field) {
      case "recentUsers":
        return this.setState(() => ({
          [field]: data.data,
          totalUsers: data.count,
          loading: false,
        }));
      case "allUsers":
        return this.setState(() => ({
          [field]: data.data,
          totalUsers: data.count,
          loading: false,
        }));
      case "chatInfo":
        return this.setState(() => ({
          [field]: { image: data.image, data: data.data.reverse() },
          text: '',
          loadingMsg: false,
          sendMsg: false,
        }))
      default:
        return this.setState({ loading: false, loadingMsg: false });
    }
  } else {
    this.setState(prev => ({ loading: false, loadingMsg: false, sendMsg: false, chatInfo: { ...prev.chatInfo, data: prev.chatInfo?.data ? [...prev.chatInfo?.data.splice(-1, 1)] : [] } }));
  }
}