import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'; // SAYING use redux form reducers as reducers
import loginReducer from '../modules/login/reducers/loginReducer';
import categoryReducer from '../modules/admin/category/reducers/categoryReducer';
import organizationReducer from '../modules/admin/organization/reducers/organizationReducer';
import userReducer from '../modules/admin/user/reducers/userReducer';
import documentReducer from '../modules/practitioner/Documents/reducers/documentReducer';

const allReducers = combineReducers({
  form: formReducer,
  login: loginReducer,
  category:categoryReducer,
  organization:organizationReducer,
  user:userReducer,
  document:documentReducer,
});

export default allReducers;
