import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core/";
import {
  TextareaAutosize,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import { tableIcons } from "../organization/constants/iconConstant";
// import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import MaterialTable from "material-table";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import calender from "../../../assets/calendar.svg";
import autoBind from "react-autobind";
import NavAdmin from "../../../component/NavAdmin.js";
import "react-dates/initialize";
import { DateRangePicker, isInclusivelyBeforeDay } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import {
getunApprovedDocuments,
  applydateFilterUnApproved,
} from "../../practitioner/Documents/actions/documentActions";
import axios from "axios";
// import CircularProgress from "@material-ui/core/CircularProgress";
import { API_URL } from "../../../actions/utilAction";
import { jsonApiHeader } from "../../../actions/utilAction";
import { toasterMessage } from "../../../actions/util";
import FileSaver from "file-saver";
import Loader from "../../../component/Loader";

moment.locale("pt-br");

const drawerWidth = 316;
const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  rootCard: {
    maxWidth: 345,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  appBarShift: {
    marginLeft: "150px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    position: "relative",
    top: "10rem",
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  inputStyle: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    borderRadius: 4,
    position: "relative",
    border: "1px solid rgba(255, 207, 92, 1)", // Modified border value
    fontSize: 16,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    padding: "0px",
    width: "100%",
    marginBottom: "20px",
    marginTop: "20px",
  },  
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#FFC805",
    color: " #005A98",
    fontFamily: "OpenSans-Regular",
    fontWeight: "700",
    fontSize: "18px",
  },
});
/* const BootstrapInput = withStyles((theme) => ({
  minWidth: "413px",
  width: "100%",
  marginTop: "25px",
  marginBottom: "20px",
}))(InputBase); */

class UnApprovedDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      open: false,
      modal: false,
      checkedA: false,
      checkedE: false,
      title: "Manage Unapproved Document",
      modalStatus: "",
      organizationList: [],
      organizationDetail: "",
      organizationName: "",
      organizationId: "",
      errorAdd: "",
      error: false,
      preview: "",
      userEmail: "",
      userName: "",
      Designation: "",
      organization: "",
      errorDesignation: "",
      errorEmail: "",
      errorName: "",
      errorOrganization: "",
      errorPreview: "",
      organisationId: "",
      userList: [],
      isDisabled: false,
      unApprovedDocumentsList: [],
      filteredUnApproved: [],
      rejectModal: false,
      fileId: "",
      rejectComment: "",
      errorRejectComment: "",
      fileDownloading: "",
      loader: true,
      startDate:'',
      endDate:''
    };
    autoBind(this);
  }

  componentDidMount() {
    console.log(this.props, "here are");
    // this.setState({ loader: true })
    this.props.getunApprovedDocuments();
    // this.setState({load:true})

    // this.props.getUserhistory(obj)

    // this.props.getOrganizations();
  }
  componentDidUpdate(prevProp) {
    if (
      prevProp.unApprovedDocumentsList !== this.props.unApprovedDocumentsList
    ) {
      console.log(this.props.unApprovedDocumentsList, "from didupdate");
      this.setState({
        unApprovedDocumentsList: this.props.unApprovedDocumentsList,
        filteredUnApproved: this.props.unApprovedDocumentsList,
        loader: false,
      });
    }
  }
  // static getDerivedStateFromProps(props, state) {
  //     if (props.unApprovedDocumentsList) {
  //         return {
  //             loader: false
  //         };
  //     }
  //     // props.unApprovedDocumentsList
  //     // Return null if the state hasn't changed
  //     return null;

  // }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleAddChange = (event) => {
    let name = event.target.name;
    let checked = event.target.checked;
    this.setState((prevState) => {
      return { [name]: checked };
    });
  };

  handleChange = (event) => {
    let name = event.target.name;
    let checked = event.target.checked;
    this.setState((prevState) => {
      return { [name]: checked };
    });
  };

  handleModalOpen = (data, organizationDetails) => {
    this.setState({
      modal: true,
      modalStatus: data,
      organizationDetail: organizationDetails,
    });
  };

  handleAddModal = (data) => {
    this.setState({
      modal: true,
      modalStatus: data,
      organizationDetail: "",
      organizationName: "",
      error: false,
    });
  };

  handleModalClose = () => {
    this.setState({
      modal: false,
      errorDesignation: "",
      errorEmail: "",
      errorName: "",
      errorOrganization: "",
      errorPreview: "",
    });
    this.props.getOrganizations();
  };

  handleRejectModal = () => {
    this.setState({ rejectModal: !this.state.rejectModal, rejectComment: "" });
  };
  rejectFile = (data) => {
    console.log(data);
    this.setState({ fileId: data._id });
    this.handleRejectModal();
  };

  approveFileApiCall = () => {
    let postBody = {
      documentId: this.state.fileId,
    };
    this.setState({ loader: true });
    let access_token = localStorage.access_token;
    axios
      .post(`${API_URL}/document/approveDoc`, postBody, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({ loader: false });
          toasterMessage("success", res.data.message);
          this.props.getunApprovedDocuments();
        }
      })
      .catch((error) => {
        this.setState({ loader: false });
        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch(() => {
        this.setState({ loader: false });
        toasterMessage("error", "Something bad happened");
      });
  };

  rejectFileApiCall = () => {
    if (!this.state.rejectComment.trim()) {
      toasterMessage("error", "Comment is required");
      // alert('Comment is required')
      return;
    }
    if (this.state.rejectComment.length > 300) {
      toasterMessage(
        "error",
        "Comment is too long only 300 characters allowed"
      );
      // alert('Comment is required')
      return;
    }
    this.setState({ loader: true });
    let postBody = {
      id: this.state.fileId,
      comment: this.state.rejectComment,
      type: 1,
    };
    let access_token = localStorage.access_token;
    axios
      .post(`${API_URL}/document/reqRevisionDoc`, postBody, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then((res) => {
        if (res.data.status === 200) {
          toasterMessage("success", "Document Rejected");
          this.props.getunApprovedDocuments();
          this.setState({ loader: false });
          this.handleRejectModal();
        }
      })
      .catch((error) => {
        this.handleRejectModal();
        this.setState({ loader: false });
        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch(() => {
        this.handleRejectModal();
        toasterMessage("error", "Something bad happened");
        this.setState({ loader: false });
      });

    console.log(this.state.fileId, this.state.rejectComment, "from save");
  };

  downloadDocument = (data) => {
    this.setState({ loader: true });
    let access_token = localStorage.access_token;
    axios
      .get(`${API_URL}/document/downloadDoc/${data.currentDocument.fileId}`, {
        headers: jsonApiHeader(access_token, "blob"),
        responseType: "blob",
      })
      .then((res) => {
        this.setState({ fileDownloading: true, loader: false }, () => {
          FileSaver.saveAs(res.data, `${data.documentName}`);
        });

        console.log(res.data, "from here");
      })
      .catch((error) => {
        // this.handleRejectModal()
        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch((error) => {
        console.log(error);
        // this.handleRejectModal()
        toasterMessage("error", "Something bad happened");
      });
  };
  applyFilters = () => {
    if (this.state.startDate && this.state.endDate) {
      let unapprovedList = this.state.filteredUnApproved;

      let fromDate = moment(this.state.startDate).format("DD-MM-YYYY");
      let toDate = moment(this.state.endDate).format("DD-MM-YYYY");
      unapprovedList = unapprovedList.filter((emp) => {
        let date = moment(emp.currentDocument.created_at).format("DD-MM-YYYY");
        if (fromDate <= date && toDate >= date) {
          return emp;
        }
        return false;
      });
      this.setState({ unApprovedDocumentsList: unapprovedList });
    }
  };
  resetFilters = () => {
    this.setState({ startDate: '', endDate: '' }, () => {
      this.props.getunApprovedDocuments();
    });
  };
  // isOutsideRange = (day) => day.isAfter(moment.parseZone());
  isOutsideRange = (day) => {
    return !isInclusivelyBeforeDay(day, moment());
  };

  render() {
    // console.log(this.props, 'from render')
    const { classes } = this.props;
    return (
      <>
        {/* {this.state.loader ? (
          <Loader />
        ) : ( */}
        <div className={classes.root}>
          <NavAdmin
            handleDrawerOpen={this.handleDrawerOpen}
            handleDrawerClose={this.handleDrawerClose}
            open={this.state.open}
            title={this.state.title}
            activeValue={4}
          />
          <main
            className={clsx(classes.content, {
              [classes.appBarShift]: this.state.open,
            })}
          >

            {this.state.loader ? (
              <Loader />
            ) : (
              <>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={12}
                    className="calender"
                    style={{ position: "relative" }}
                  >
                     <Typography variant="body2" color="textSecondary" component="p">
                <span className="heading1 mr-r-20">Choose Date </span>
                    <DateRangePicker
                      startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                      endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                      onDatesChange={({ startDate, endDate }) =>
                        this.setState({ startDate, endDate }, () => {
                          this.applyFilters();
                        })
                      } // PropTypes.func.isRequired,
                      focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={(focusedInput) =>
                        this.setState({ focusedInput })
                      } // PropTypes.func.isRequired,
                      endDatePlaceholderText={"End Date"}
                      style={{ position: "absolute", zIndex: "999" }}
                      startDatePlaceholderText={"Start Date"}
                      displayFormat={"MMM DD, YYYY"}
                      isOutsideRange={this.isOutsideRange}
                      minimumNights={0}
                    />
                    <img
                      className={`${classes.imageIcon} align-calender`}
                      src={calender}
                      alt="Calendar"
                    />

                      <Button
                      class="btn-doc heading1"
                      disableTouchRipple="false"
                      style={{ padding: "19px 44px",float:"right" }}
                      onClick={(e) => this.resetFilters(e)}
                    >
                      Clear Filter
                    </Button>
                    </Typography>
                  </Grid>
                  <Grid>
                  
                  </Grid>
                </Grid>
                {/* <Button class="btn-doc heading1" style={{ marginBottom: "20px" }}
      // onClick={this.handleModalOpen}
                onClick={
                    this.handleAddModal.bind(this, 'add')
                }
            >Add New User</Button> */}
                <MaterialTable
                  icons={tableIcons}
                  title="UN-APPROVED DOCUMENT MANAGEMENT"
                  
                  columns={[
                    {
                      title: "Id",
                      field: "documentId",
                      cellStyle: { width: "10%" },
                    },
                    {
                      title: "Email",
                      field: "createdBy[email]",
                      cellStyle: { width: "20%" },
                    },
                    {
                      title: "Document Name",
                      field: "documentName",
                      cellStyle: { width: "20%" },
                    },
                    {
                      title: "Category",
                      field: "categoryId[categoryName]",
                      cellStyle: { width: "20%" },
                    },
                    {
                      title: "Status",
                      field: "currentDocument[status]",
                      cellStyle: { width: "12%" },
                    },
                  ]}
                  data={this.state.unApprovedDocumentsList}
                  localization={{
                    header: {
                      actions: "Actions",
                    },
                  }}
                  actions={[
                    {
                      icon: () => (
                        <CheckOutlinedIcon style={{ color: "#005a98" }} />
                      ),
                      tooltip: "Approve",
                      // onClick: () => this.handleModalOpen()
                      onClick: (event, rowData) => {
                        this.setState({ fileId: rowData._id }, () => {
                          this.approveFileApiCall();
                        });
                      },
                    },
                    {
                      icon: () => (
                        <ClearOutlinedIcon style={{ color: "#005a98" }} />
                      ),
                      tooltip: "Reject",
                      // onClick: () => this.handleRejectModal()
                      onClick: (event, rowData) => {
                        this.rejectFile(rowData);
                        // this.handleModalOpen.bind(this,'view')
                        // this.handleModalOpen('view', rowData)
                      },
                    },
                    {
                      icon: () => <GetAppIcon style={{ color: "#005a98" }} />,
                      tooltip: "Download",
                      // onClick: () => this.handleModalOpen()
                      onClick: (event, rowData) => {
                        this.downloadDocument(rowData);
                        // this.handleModalOpen.bind(this,'view')
                        // this.handleModalOpen('view', rowData)
                      },
                    },
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                    rowStyle: x => {
                      if (x.tableData.id % 2) {
                        return {
                          backgroundColor: "#ebf5ff",
                          color: "#005a98",
                          fontSize: "14px",
                          fontWeight: "bold",
                          fontFamily: "OpenSans-Regular",
                        }
                      }
                      else {
                        return {
                          color: "#005a98",
                          fontSize: "14px",
                          fontWeight: "bold",
                          fontFamily: "OpenSans-Regular",
                        }
                      }
                    },
                    actionsCellStyle: {
                      display: 'flex',
                      justifyContent: 'center',
                      padding: 16,
                      width: '100%'
                    },
                    headerStyle: {
                      backgroundColor: "#ffcf5c",
                      color: "#005a98",
                      fontSize: "16px",
                      fontWeight: "700",
                    },
                    actionStyle: { paddingLeft:"56px",textAlign:"left",color:"red" },
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30, 40, 50], // rows selection options
                  }}
                />

                <Dialog
                  open={this.state.rejectModal}
                  onClose={this.handleRejectModal}
                  aria-labelledby="form-dialog-title"
                  className="dialog-main-div"
                >
                  <DialogTitle id="form-dialog-title" class="modal-header">
                    Are you sure you want to request a revision?
                  </DialogTitle>
                  <DialogContent className="dialog-content" style={{padding:"60px"}}>
                    <Grid container>
                      <Grid item>
                        <span
                          className="label-heading"
                          style={{ marginBottom: "10px" }}
                        >
                          Share additional information/message with the Author :
                          <span style={{ color: "red" }}>
                            {" "}
                            (Max 300 Characters)
                          </span>
                        </span>
                        <br />
                        <TextareaAutosize
                          className={clsx(classes.inputStyle, classes.textAreWidth)}
                          rowsMax={15}
                          rowsMin={8}
                          inputProps={{
                            maxLength: 300,
                          }}
                          aria-label="maximum height"
                          placeholder="Write Description"
                          value={this.state.rejectComment}
                          onChange={(e) => {
                            if (e.target.value.trim().length > 300) {
                              return toasterMessage(
                                "error",
                                "Message Limit exceeded!!!"
                              );
                            } else {
                              this.setState({
                                rejectComment: e.target.value,
                              });
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions style={{padding:"0px 60px",justifyContent:"flex-start"}}>
                    <Button
                      class="btn-doc heading1"
                      style={{ marginBottom: "20px", marginRight: "20px" }}
                      onClick={this.rejectFileApiCall}
                      disableTouchRipple="false"
                    >
                      Submit
                    </Button>
                    <Button
                      class="btn-doc-close heading3"
                      style={{ marginBottom: "20px", marginRight: "20px" }}
                      onClick={this.handleRejectModal}
                      disableTouchRipple="false"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </main>
        </div>
        {/* )} */}
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    unApprovedDocumentsList: state.document.unapprovedList,
  };
}

export default connect(mapStateToProps, {
  getunApprovedDocuments,
  applydateFilterUnApproved,
})(withStyles(useStyles)(UnApprovedDocument));
