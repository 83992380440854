import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  InputBase,
  TextareaAutosize,
  InputLabel,
  FormControl,
  Divider,
  FormHelperText,
  Icon,
  Button,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import leftArrow from "../../../assets/left.svg";
import Nav from "../../../component/Nav.js";
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core/";
import Rating from "@material-ui/lab/Rating";
// import { getUserDetails } from '../../admin/user/actions/userAction';
import { getUserDetails, editUser } from "../../login/actions/loginActions";
import { SelectValue } from "../../practitioner/Documents/constants/dropDownStyled";
import { getOrganizations } from "../../admin/organization/actions/organizationAction";
// import { editUser } from '../../admin/user/actions/userAction';
// import download from "../../../assets/download.png";
// import pdf from "../../../assets/Pdf.png";
// import { EditOutlined } from "@material-ui/icons";

const drawerWidth = 316;

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  rootCard: {
    maxWidth: 345,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  appBarShift: {
    marginLeft: "150px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2, 2),
    paddingRight: theme.spacing(5),
    position: "relative",
    top: "10rem",
  },
  inputStyle: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    borderRadius: 4,
    position: "relative",
    border: "1px solid rgba(255, 207, 92, 1)",
    fontSize: 14,
    fontFamily: "OpenSans-Regular",
    color: "#005A98",
    fontWeight: "bold",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    padding: "0px",
    width: "100%",
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  inputWidth: {
    height: "34px",
  },
  textAreWidth: {
    minWidth: "395px",
    minHeight: "160px",
    borderRadius: "10px",
  },
  focused: {},
  iconOpen: {},
});
const BootstrapInput = withStyles((theme) => ({
  minWidth: "213px",
  width: "100%",
}))(InputBase);

class userProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      checkedA: true,
      modal: false,
      buttonDisabled: false,
      title: "My Account/Profile",
      userDetail: "",
      errorName: "",
      errorDesignation: "",
      errorOrganization: "",
      organizationList: [],
      organisationName: "",
      organisationId: "",
      Designation: "",
      userName: "",
      userImage: "",
      errorPreview: "",
      mobileNo: "",
      experience: "",
      country: "",
      city: "",
      Email: "",
    };
    this.editProfile = this.editProfile.bind(this);
    this.handleChangee = this.handleChangee.bind(this);
  }

  componentDidMount() {
    this.props.getUserDetails();
    this.props.getOrganizations();
    // this.setState({ load: true })
  }
  static getDerivedStateFromProps(props, state) {
    if (props.userDetails !== state.userDetail) {
      console.log("props.userDetails", props.userDetails);
      // let Image = props.userDetail != '' && props.userDetails.userImage && props.userDetails.userImage.data ?props.userDetails.userImage.data : '';
      // const b64 = new Buffer(Image).toString('base64');
      return {
        userDetail: props.userDetails,
        organisationName:
          props.userDetails && props.userDetails.organisationId
            ? props.userDetails.organisationId.organisationName
            : "",
        organisationId:
          props.userDetails && props.userDetails.organisationId
            ? props.userDetails.organisationId._id
            : "",
        userName:
          props.userDetails && props.userDetails.userName
            ? props.userDetails.userName
            : "",
        Designation:
          props.userDetails && props.userDetails.designation
            ? props.userDetails.designation
            : "",
        Email:
          props.userDetails && props.userDetails.email
            ? props.userDetails.email
            : "",
        mobileNo:
          props.userDetail !== "" && props.userDetails.mobileNumber
            ? props.userDetails.mobileNumber
            : "",
        experience:
          props.userDetail !== "" && props.userDetails.experience
            ? props.userDetails.experience
            : "",
        country:
          props.userDetail !== "" && props.userDetails.country
            ? props.userDetails.country
            : "",
        city:
          props.userDetail !== "" && props.userDetails.city
            ? props.userDetails.city
            : "",
        userImage:
          props.userDetail !== "" && props.userDetails.userImage
            ? props.userDetails.userImage
            : "",
        // load: false
      };
    }

    if (props.organizationList !== state.organizationList) {
      let filterOrg = props.organizationList.filter(function (el) {
        return el.isActive !== false;
      });
      return {
        organizationList: filterOrg,
      };
    }
    // Return null if the state hasn't changed
    return null;
  }
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  handleChange = (event) => {
    let name = event.target.name;
    let checked = event.target.checked;
    this.setState((prevState) => {
      return { [name]: checked };
    });
  };
  handleModalOpen = (data) => {
    this.setState({ modal: true });
  };
  handleModalClose = (data) => {
    this.setState({ modal: false });
  };
  selectValue(value) {
    this.setState({
      organisationId: value.target.value,
    });
  }
  editProfile() {
    // console.log("edit",this.state.Email,this.state.userName,this.state.organisationId,this.state.Designation,this.state.errorPreview ,this.state.userImage)
    if (
      this.state.Email !== "" &&
      this.state.userName !== "" &&
      this.state.organisationId !== "" &&
      this.state.Designation !== "" &&
      this.state.errorPreview === "" &&
      this.state.userImage !== ""
    ) {
      this.setState({ buttonDisabled: true });
      let obj = {
        userImage: this.state.userImage,
        userName: this.state.userName,
        designation: this.state.Designation,
        organisationId: this.state.organisationId,
        mobileNumber: this.state.mobileNo,
        experience: this.state.experience,
        country: this.state.country,
        city: this.state.city,
      };

      // if (this.state.mobileNo == '') {
      //   delete obj.mobileNumber
      // }
      // if (this.state.experience == '') {
      //   delete obj.experience
      // }
      // if (this.state.country == '') {
      //   delete obj.country
      // }
      // if (this.state.city == '') {
      //   delete obj.city
      // }
      this.props.editUser(obj, (result) => {
        console.log("result", result);
        if (result) {
          this.setState({ buttonDisabled: false });
        }
      });
    }
    if (this.state.userName === "") {
      this.setState({ errorName: "This field is required", error: true });
    }
    if (this.state.Designation === "") {
      this.setState({
        errorDesignation: "This field is required",
        error: true,
      });
    }
    if (this.state.organisationId === "") {
      this.setState({
        errorOrganization: "Please select min one organization",
        error: true,
      });
    }
    if (this.state.preview === "") {
      this.setState({ errorPreview: "Please select image." });
    }
    // return false
  }
  handleChangee(e) {
    if (e.target.files.length) {
      let files = e.target.files[0];
      const fsize = files.size;
      const file = Math.round(fsize / 1024);
      if (file >= 5000) {
        this.setState({
          errorPreview:
            "The maximum allowed file size for 'Upload a Image' is '5 Mb'",
          imageName: e.target.files[0].name,
          isVisible: true,
        });
      } else {
        this.setState({
          errorPreview: "",
          imageName: e.target.files[0].name,
          isVisible: true,
        });
      }
      if (!files) {
        this.setState({ errorPreview: "Please select image." });
      }
      if (!files.name.match(/\.(jpeg|png)$/)) {
        this.setState({
          errorPreview:
            "Please upload a file in a valid format. We currently support: PNG, JPEG",
          isVisible: true,
        });
      }
      // console.log("files",files)
      this.getBase64(files, (result) => {
        let idCardBase64 = result;
        let splt = idCardBase64.split(",");
        // console.log("spl",splt[1])
        this.setState({
          userImage: splt[1],
        });
      });
    }
  }
  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  render() {
    const { classes } = this.props;
    const buffer = this.state.userImage;
    // const buffer = this.state.userDetail.length!=0&& this.state.userDetail.userImage&&this.state.userDetail.userImage.data?this.state.userDetail.userImage.data:''
    // console.log("buffer",buffer)
    // const b64 = new Buffer(buffer).toString("base64");
    const mimeType = "image/png";
    return (
      <div className={classes.root}>
        <Nav
          handleDrawerOpen={this.handleDrawerOpen}
          handleDrawerClose={this.handleDrawerClose}
          open={this.state.open}
          title={this.state.title}
        />
        <main
          className={clsx(classes.content, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Box mb={3}>
            <Typography
              variant="body2"
              pb={0}
              color="textSecondary"
              className="ListHeading "
              component="p"
            >
              <Link to={"/MyDocuments"}>
              <Icon>
              <img
                className={clsx(classes.imageIcon, 'arrow-position')}
                src={leftArrow}
                alt="Left Arrow"
              />

              </Icon>
              </Link>
              User Profile
            </Typography>
          </Box>
          <div className="shared-details-color">
            <Grid container style={{ padding: "25px 0px" }}>
              <Grid item xs={12} sm={12} lg={4}>
                {/* <img className={classes.imageIcon} className="img-side-profile"  src={require('../../../assets/profile.jpg')}/> */}
                <img
                  className={clsx(classes.imageIcon, 'img-side-profile')}
                  style={{ border: "2px solid  #005a98" }}
                  src={`data:${mimeType};base64,${buffer}`}
                  alt="User Profile"
                />



                <FormHelperText
                  style={{
                    marginBottom: "20px",
                    marginTop: "50px",
                    textAlign: "center",
                  }}
                  error
                >
                  {this.state.errorPreview}
                </FormHelperText>
                <div style={{ textAlign: "center" }}>
                  <input
                    id="myInputEdit"
                    type="file"
                    onChange={this.handleChangee}
                  />
                </div>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item xs={12} sm={12} lg={7} style={{ marginLeft: "40px" }}>
                <div className="detail-side">
                  <Grid container className="pd-t-25">
                    <Grid item xs={12} sm={12} lg={4}>
                      <FormControl className="width-100 padding-right-25">
                        <InputLabel
                          className="label-font input-label "
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          Name
                        </InputLabel>
                        <BootstrapInput
                          placeholder="Enter Full Name"
                          id="name"
                          defaultValue={
                            this.state.userDetail !== ""
                              ? this.state.userDetail.userName
                              : ""
                          }
                          className={clsx(
                            classes.inputStyle,
                            classes.inputWidth
                          )}
                          inputProps={{
                            maxLength: 25,
                          }}
                          onChange={(event) => {
                            this.setState({
                              errorName: "",
                              error: false,
                              userName: event.target.value,
                            });
                            if (event.target.value.length !== 0) {
                              this.setState({
                                errorName: "",
                                error: false,
                                userName: event.target.value,
                              });
                            } else {
                              this.setState({
                                errorName: "Please enter valid User name",
                                error: true,
                              });
                            }
                            if (event.target.value.length === 0) {
                              this.setState({
                                errorName: "This field is required",
                                error: true,
                              });
                            }
                          }}
                        />
                        {this.state.errorName !== "" ? (
                          <FormHelperText
                            style={{ marginBottom: "22px" }}
                            error
                          >
                            {this.state.errorName}
                          </FormHelperText>
                        ) : (
                          <FormHelperText>25 character max.</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4} sm={12}>
                      <FormControl className="width-100 padding-right-25">
                        <InputLabel
                          className="label-font input-label "
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          Designation
                        </InputLabel>
                        <BootstrapInput
                          placeholder="Enter  Designation"
                          id="designation"
                          defaultValue={
                            this.state.userDetail !== ""
                              ? this.state.userDetail.designation
                              : ""
                          }
                          className={clsx(
                            classes.inputStyle,
                            classes.inputWidth
                          )}
                          inputProps={{
                            maxLength: 25,
                          }}
                          onChange={(event) => {
                            this.setState({
                              errorDesignation: "",
                              error: false,
                              Designation: event.target.value,
                            });

                            if (event.target.value.length === 0) {
                              this.setState({
                                errorDesignation: "This field is required",
                                error: true,
                              });
                            } else {
                              this.setState({
                                errorDesignation: "",
                                error: false,
                                Designation: event.target.value,
                              });
                            }
                          }}
                        />
                        {this.state.errorDesignation !== "" ? (
                          <FormHelperText
                            style={{ marginBottom: "20px" }}
                            error
                          >
                            {this.state.errorDesignation}{" "}
                          </FormHelperText>
                        ) : (
                          <FormHelperText>25 character max.</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    {/* <Grid item xs={12} lg={4} sm={12}>
                      <FormControl className="width-100 padding-right-25">
                        <InputLabel className="label-font input-label " shrink htmlFor="bootstrap-input">
                          Organization
                  </InputLabel>
                        <BootstrapInput
                          placeholder="Enter  Organization" id="organization"
                          className={clsx(classes.inputStyle, classes.inputWidth)}
                          inputProps={{
                            maxLength: 25
                          }}

                        />
                        <FormHelperText>25 character max.</FormHelperText>
                      </FormControl>
                    </Grid> */}
                    {/* <Grid item xs={6} className="mr-b-25" > */}
                    <Grid item xs={12} lg={4} sm={12}>
                      <FormControl>
                        <InputLabel
                          shrink={true}
                          className="label-font input-label "
                        >
                          Select Organization
                        </InputLabel>
                        <SelectValue
                          defaultValue={this.state.userDetail.organisationId}
                          style={{border: "1px solid rgba(255, 207, 92, 1)",
    background: "bottom",height: "34px", minWidth: "220px", color:"#005A98" }}
                          onChange={(event) => {
                            // console.log("event", event.target.value)
                            if (event.target.value.length === 0) {
                              this.setState({
                                errorOrganization:
                                  "Please select anyone organization",
                                error: true,
                              });
                            } else {
                              let filterOrg = this.state.organizationList.filter(
                                function (el) {
                                  return el._id === event.target.value;
                                }
                              );
                              // console.log("filterOrg",filterOrg)
                              if (filterOrg.length !== 0) {
                                this.setState({
                                  organisationName:
                                    filterOrg[0].organisationName,
                                });
                              }
                              this.setState({
                                organisationId: event.target.value,
                                errorOrganization: "",
                              });
                            }
                          }}
                        >
                          <option
                            value={this.state.userDetail.organisationId._id}
                            hidden
                          >
                            {/* Select Organization */}
                            {this.state.organisationName}
                          </option>
                          {this.state.organizationList
                            ? this.state.organizationList.map((x, index) => (
                                <option value={x._id}>
                                  {x.organisationName}
                                </option>
                              ))
                            : ""}
                        </SelectValue>
                      </FormControl>
                      <FormHelperText style={{ marginTop: "25px" }} error>
                        {this.state.errorOrganization}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                  <Grid container className="pd-t-40">
                    <Grid item xs={12} lg={4} sm={12}>
                      <FormControl className="width-100 padding-right-25">
                        <InputLabel
                          className="label-font input-label "
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          Email
                        </InputLabel>
                        <BootstrapInput
                          placeholder="Enter Email"
                          id="email"
                          defaultValue={
                            this.state.userDetail !== ""
                              ? this.state.userDetail.email
                              : ""
                          }
                          readOnly={true}
                          className={clsx(
                            classes.inputStyle,
                            classes.inputWidth
                          )}
                          inputProps={{
                            maxLength: 25,
                          }}
                        />
                        <FormHelperText>25 character max.</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4} sm={12}>
                      <FormControl className="width-100 padding-right-25">
                        <InputLabel
                          className="label-font input-label "
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          Mobile No.
                        </InputLabel>
                        <BootstrapInput
                          placeholder="Enter  Mobile No."
                          id="mobileNo."
                          className={clsx(
                            classes.inputStyle,
                            classes.inputWidth
                          )}
                          defaultValue={
                            this.state.mobileNo !== "" ? this.state.mobileNo : ""
                          }
                          inputProps={{
                            maxLength: 25,
                          }}
                          value={this.state.mobileNo}
                          type="number"
                          onChange={(event) => {
                           if (event.target.value.length < 13) {
                              this.setState({ mobileNo: event.target.value });
                            } else {
                              return swal("Maximum 13 digits allowed");
                            }

                            // if (event.target.value.length == 0) {
                            //   this.setState({ errorDesignation: 'This field is required', error: true });
                            // }
                            // else {
                            //   this.setState({
                            //     errorDesignation: '',
                            //     error: false,
                            //     mobileNo: (event.target.value)
                            //   });
                            // }
                          }}
                        />
                        <FormHelperText>25 character max.</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4} sm={12}>
                      <FormControl className="width-100 padding-right-25">
                        <InputLabel
                          className="label-font input-label "
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          Experience
                        </InputLabel>
                        <BootstrapInput
                          placeholder="Enter Experience"
                          id="experience"
                          className={clsx(
                            classes.inputStyle,
                            classes.inputWidth
                          )}
                          defaultValue={
                            this.state.experience !== ""
                              ? this.state.experience
                              : ""
                          }
                          inputProps={{
                            maxLength: 25,
                          }}
                          onChange={(event) => {
                            this.setState({ experience: event.target.value });

                            // if (event.target.value.length == 0) {
                            //   this.setState({ errorDesignation: 'This field is required', error: true });
                            // }
                            // else {
                            //   this.setState({
                            //     errorDesignation: '',
                            //     error: false,
                            //     experience: (event.target.value)
                            //   });
                            // }
                          }}
                        />
                        <FormHelperText>25 character max.</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container className="pd-t-40">
                    <Grid item xs={6} lg={6} sm={6}>
                      <FormControl className="width-100 padding-right-25">
                        <InputLabel
                          className="label-font input-label "
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          Country
                        </InputLabel>
                        <BootstrapInput
                          placeholder="Enter Country"
                          id="country"
                          className={clsx(
                            classes.inputStyle,
                            classes.inputWidth
                          )}
                          defaultValue={
                            this.state.country !== "" ? this.state.country : ""
                          }
                          inputProps={{
                            maxLength: 25,
                          }}
                          onChange={(event) => {
                            this.setState({ country: event.target.value });

                            // if (event.target.value.length == 0) {
                            //   this.setState({ errorDesignation: 'This field is required', error: true });
                            // }
                            // else {
                            //   this.setState({
                            //     errorDesignation: '',
                            //     error: false,
                            //     country: (event.target.value)
                            //   });
                            // }
                          }}
                        />
                        <FormHelperText>25 character max.</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} lg={6} sm={6}>
                      <FormControl className="width-100 padding-right-25">
                        <InputLabel
                          className="label-font input-label "
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          City
                        </InputLabel>
                        <BootstrapInput
                          placeholder="Enter City"
                          id="city"
                          className={clsx(
                            classes.inputStyle,
                            classes.inputWidth
                          )}
                          defaultValue={
                            this.state.city !== "" ? this.state.city : ""
                          }
                          inputProps={{
                            maxLength: 25,
                          }}
                          type="text"
                          onChange={(event) => {
                            this.setState({ city: event.target.value });

                            // if (event.target.value.length == 0) {
                            //   this.setState({ errorDesignation: 'This field is required', error: true });
                            // }
                            // else {
                            //   this.setState({
                            //     errorDesignation: '',
                            //     error: false,
                            //     city: (event.target.value)
                            //   });
                            // }
                          }}
                        />
                        <FormHelperText>25 character max.</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
          <Grid container className="mr-t-50 display-center">
            <Grid item>
              <Button
                type="submit"
                className="main-btn submit-btn btn-shared text-transform-inherit"
                onClick={this.editProfile}
                disabled={this.state.buttonDisabled}
                disableTouchRipple="false"
              >
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Box ml={4}>
                <Link to={"/MyDocuments"} className="text-decoration-none">
                  <Button
                    type="submit"
                    className=" shared-btn-rate btn-shared text-transform-inherit"
                    disableTouchRipple="false"
                    // onClick={this.handleModalOpen}
                  >
                    Cancel
                  </Button>
                </Link>
              </Box>
            </Grid>
          </Grid>

          <Dialog
            open={this.state.modal}
            onClose={this.handleModalClose}
            aria-labelledby="form-dialog-title"
            className="dialog-main-div"
          >
            <DialogTitle id="form-dialog-title" class="modal-header">
              Rate Document
            </DialogTitle>
            <DialogContent className="dialog-content pd-70">
              <Grid container className="display-center">
                <Grid item className="mr-b-40">
                  <Rating
                    name="size-large"
                    customName="pristine"
                    className="pd-0"
                    value={4}
                    size="large"
                  />
                </Grid>
                <Grid item>
                <TextareaAutosize
                  className={clsx(classes.inputStyle, classes.textAreWidth)}
                  rowsMax={8}
                  aria-label="maximum height"
                  placeholder="Write Description"
                  defaultValue=""
                />

                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                class="btn-doc heading1"
                style={{ marginBottom: "20px", marginRight: "20px" }}
                disableTouchRipple="false"
              >
                Submit
              </Button>
              <Button
                class="btn-doc-close heading3"
                style={{ marginBottom: "20px", marginRight: "20px" }}
                onClick={this.handleModalClose}
                disableTouchRipple="false"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </main>
      </div>
    );
  }
}

function mapStateToProps(state) {
  // console.log("state",state)
  return {
    userDetails: state.login.authData,
    organizationList: state.organization.organizationList,
  };
}
export default connect(mapStateToProps, {
  getUserDetails,
  editUser,
  getOrganizations,
})(withStyles(useStyles)(userProfile));
