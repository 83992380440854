import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  TextareaAutosize,
  Button,
  Grid,
} from "@material-ui/core";
import Nav from "../../../component/Nav.js";
import axios from "axios";
// import CircularProgress from "@material-ui/core/CircularProgress";
import { API_URL } from "../../../actions/utilAction";
import { jsonApiHeader } from "../../../actions/utilAction";
import { toasterMessage } from "../../../actions/util";
import Loader from "../../../component/Loader";
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (state) => ({});

const drawerWidth = 316;

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  rootCard: {
    maxWidth: 345,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  appBarShift: {
    marginLeft: "150px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2, 2),
    paddingRight: theme.spacing(5),
    position: "relative",
    top: "10rem",
  },
  inputStyle: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFFFFF",
    border: "1px solid rgba(255, 207, 92, 1)",
    fontSize: 16,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    padding: "0px",
    width: "100%",
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  inputWidth: {
    minWidth: "413px",
    height: "50px",
  },
  textAreWidth: {
    minWidth: "395px",
    minHeight: "160px",
    borderRadius: "10px",
  },
  focused: {},
  iconOpen: {},
});
/* const BootstrapInput = withStyles((theme) => ({
  minWidth: "413px",
  width: "100%",
}))(InputBase);
 */
class ContactAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      checkedA: true,
      modal: false,
      title: "Contact Admin",
      message: "",
      loader: false,
    };
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  handleChange = (event) => {
    let name = event.target.name;
    let checked = event.target.checked;
    this.setState((prevState) => {
      return { [name]: checked };
    });
  };
  handleModalOpen = (data) => {
    this.setState({ modal: true });
  };
  handleModalClose = (data) => {
    this.setState({ modal: false });
  };

  sendMessage = () => {
    let postBody = {
      message: this.state.message,
    };
    if (this.state.message) {
      this.setState({ loader: true });
      let access_token = localStorage.access_token;
      axios
        .post(`${API_URL}/user/contactAdmin`, postBody, {
          headers: jsonApiHeader(access_token, "application/json"),
        })
        .then((res) => {
          if (res.data.status === 200) {
            toasterMessage("success", res.data.message);
            this.setState({ message: "", loader: false });
          }
        })
        .catch((error) => {
          this.setState({ message: "", loader: false });
          // this.handleRejectModal()
          toasterMessage("error", error.response.data.error);
          if (error.response.status === 300) {
            toasterMessage("error", error.response.data.error);
          }
        })
        .catch(() => {
          this.setState({ message: "", loader: false });
          // this.handleRejectModal()
          toasterMessage("error", "Something bad happened");
        });
      console.log(this.state.message);
    } else {
      toasterMessage("error", "Please Enter Message");
    }
  };

  cancel = () => {
    this.setState({ message: "" });
  };
  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.root}>
          <Nav
            handleDrawerOpen={this.handleDrawerOpen}
            handleDrawerClose={this.handleDrawerClose}
            open={this.state.open}
            title={this.state.title}
            active={3}
          />
          <main
            className={clsx(classes.content, {
              [classes.appBarShift]: this.state.open,
            })}
          >

            {/* <Box mb={3}><Typography variant="body2" pb={0} color="textSecondary" className="ListHeading " component="p">
    <Icon> <img className={classes.imageIcon} className="arrow-position" src={leftArrow}/></Icon> 
    Tuberculosis - Cure 2020
            </Typography></Box> */}

            {this.state.loader ? (
              <Loader />
            ) : (
              <>
                <Grid container style={{ marginBottom: "60px" }}>
                  <Grid item xs={12}>
                  <TextareaAutosize
                    className={clsx(classes.inputStyle, classes.textAreWidth)}
                    rowsMax={8}
                    aria-label="maximum height"
                    placeholder="Write Your Message ...."
                    value={this.state.message}
                    onChange={(e) => {
                      let length = e.target.value.trim().length;
                      if (length > 300) {
                        return toasterMessage("error", "Message Limit exceeded!!!");
                      } else {
                        this.setState({ message: e.target.value });
                      }
                    }}
                  />
                    <span style={{color:"red"}} >Max 300 characters allowed</span>
                  </Grid>
                </Grid>
                <Button
                  class="btn-doc heading1"
                  style={{ marginBottom: "20px", marginRight: "20px" }}
                  onClick={this.sendMessage}
                  disableTouchRipple="false"
                >
                  Submit
                </Button>
                <Button
                  class="btn-doc heading1"
                  style={{ marginBottom: "20px", marginRight: "20px" }}
                  onClick={this.cancel}
                  disableTouchRipple="false"
                >
                  Cancel
                </Button>
              </>
            )}
          </main>
        </div>
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(ContactAdmin));
