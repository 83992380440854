import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// import { InputAdornment, IconButton } from "@material-ui/core";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import linkExpired from '../assets/linkExpired.png';



const useStyles = theme => ({
  root: {
    height: '100vh',
    position: 'absolute', 
    left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  image: {
    backgroundRepeat: 'no-repeat',
    height: "420px",
    display:"block",
    margin:"40px auto",
  },
  button:{
    width: "298px",
    height: "65px",
    background: "#FFC805",
    borderRadius: "10px",
    display:"block",
    margin:"30px auto",
    fontSize:"18px",
    color:"#005A98",
    fontWeight:"700",
    textTransform: "capitalize",
  },
  centerDiv:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    minHeight:"100vh",
  },
  fontFamilyOpenSans:{
      fontFamily:"OpenSans-Regular",
      fontStyle:"normal",
  }
});

class Login extends React.Component {
  
  render() {
    const { classes } = this.props;
    return (
        <Grid container component="main" className={classes.root}>
            <Grid item xs={12} sm={12} >
            <img src={linkExpired} className={classes.image} alt="logo" />
            <Typography variant="body2" pb={0} color="textSecondary" className="head1 linkHead font-open-sans display-center" component="p">
            Link Expired!
            </Typography>
            <Typography variant="body2" pb={0} color="textSecondary" className="head2 linkHead font-open-sans display-center " component="p">
            Hi, your reset password link has expired, because you haven’t used it.<br />
Reset link expires in every 15 minutes and can only be used once.
            </Typography>
            <Link to={"/forgetPassword"}><Button className={classes.button}>Generate Another Link</Button></Link>
            </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    authData: state.login.authData
  };
}

export default connect(mapStateToProps)(withStyles(useStyles)(Login));