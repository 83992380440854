import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { loginAction } from "../../../modules/login/actions/loginActions";
import { withStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Avatar,
  TextareaAutosize,
  FormControl,
  Icon,
  Button,
  Box,
  Grid,
  Typography,
  MenuItem,
  Menu,
} from "@material-ui/core";
import leftArrow from "../../../assets/left.svg";
import Nav from "../../../component/Nav.js";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import {
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core/";
import Rating from "@material-ui/lab/Rating";
import pdf from "../../../assets/Pdf.png";
import csv from "../../../assets/CSV.svg";
import jpeg from "../../../assets/JPEG.svg";
import excel from "../../../assets/excel 1.svg";
import doc from "../../../assets/DOCX.svg";
import ppt from "../../../assets/PPT.svg";
// import downloadFrame from "../../../assets/Frame.png";
// import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import MoreVertIcon from "@material-ui/icons/MoreVert";
// import CheckBoxIcon from "@material-ui/icons/CheckBox";
// import divider from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from "@material-ui/core/FormLabel";
import Loader from "../../../component/Loader";
import axios from "axios";
import { API_URL } from "../../../actions/utilAction";
import { jsonApiHeader } from "../../../actions/utilAction";
import { toasterMessage } from "../../../actions/util";
import moment from "moment";
import Pagination from "rc-pagination";
import swal from "sweetalert";
import "rc-pagination/assets/index.css";

function mapStateToProps(state) {
  console.log(state, "from state");
  return {
    loginDetails: state.login.authData,
  };
}
// const mapDispatchToProps = (state) => ({});
const access_token = localStorage.access_token;
const drawerWidth = 316;

// const ITEM_HEIGHT = 48;
const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  rootCard: {
    maxWidth: 345,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  appBarShift: {
    marginLeft: "150px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    position: "relative",
    top: "10rem",
  },
  inputStyle: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFFFFF",
    border: "1px solid rgba(255, 207, 92, 1)",
    fontSize: 16,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    padding: "0px",
    width: "100%",
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  inputWidth: {
    minWidth: "413px",
    height: "50px",
  },
  textAreWidth: {
    minWidth: "395px",
    minHeight: "160px",
    borderRadius: "10px",
  },
  focused: {},
  iconOpen: {},
  dropdown: {
    transition: theme.transitions.create(["transform"], {
      duration: theme.transitions.duration.short,
    }),
  },
  dropdownOpen: {
    transform: "rotate(-180deg)",
  },
  dropdownClosed: {
    transform: "rotate(0)",
  },
});
/* const BootstrapInput = withStyles((theme) => ({
  minWidth: "413px",
  width: "100%",
}))(InputBase); */

class RatingAndReviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      checkedA: true,
      modal: false,
      title: "Shared With Me",
      value: "",
      anchorEl: null,
      openMenu: false,
      reviewData: [],
      loader: true,
      docName: "",
      docVersion: "",
      currentPage: 1,
      skipData: 10,
      totalDocCount: "",
      reasons: [],
      reportDescription: "",
      commentId: "",
      reviewsAll: [],
      iconActive: true,
      wordCount: 0,
    };
  }

  componentDidMount() {
    console.log(this.props, "from did");

    this.setState(
      {
        documentId: this.props.location.state.data.documentId,
        docVersion: this.props.location.state.data.versionName,
        docName: this.props.location.state.docName,
      },
      () => {
        this.getReviews();
        this.getReasons();
      }
    );
  }

  getReasons = () => {
    axios
      .get(`${API_URL}/admin/reasons/getReasons`, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then((res) => {
        if (res.data.status === 200) {
          this.setState(
            {
              reasons: res.data.data,
            },
            () => console.log(this.state.reasons, "from set")
          );
        }
      })
      .catch((error) => {
        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch(() => {
        toasterMessage("error", "Something bad happened");
      });
  };

  // /api/admin/reasons/getReasons
  getReviews = () => {
    this.setState({ loader: true });
    let postBody = {
      documentId: this.state.documentId,
      page: this.state.currentPage,
      limit: this.state.skipData,
    };
    axios
      .post(`${API_URL}/ratingReview/getReview`, postBody, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then((res) => {
        if (res.data.status === 200) {
          this.setState(
            {
              loader: false,
              reviewData: res.data.data,
              reviewsAll: res.data.data.getRatingShared,
              totalDocCount: res.data.data.getRatingSharedCount,
            },
            () => console.log(this.state.reviewData, "from set")
          );
        }
      })
      .catch((error) => {
        this.setState({ loader: false });

        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch(() => {
        this.setState({ loader: false });
        toasterMessage("error", "Something bad happened");
      });
  };

  handleChange = (event) => {
    console.log(event.target, "here is seventd");
    this.setState({ value: event.target.value });
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  flipOpen = () =>
    this.setState({ ...this.state, openMenu: !this.state.openMenu });

  handleClick = (event, id) => {
    this.setState({ commentId: id }, () =>
      console.log(this.state.commentId, id, "here is")
    );
    this.state.ancherEl
      ? this.setState({ anchorEl: event.currentTarget }, () => this.flipOpen())
      : this.setState({ anchorEl: event.currentTarget }, () => this.flipOpen());
  };
  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
    this.flipOpen();
  };

  handleModalOpen = (data) => {
    this.setState({ modal: true });
  };
  handleModalClose = (e) => {
    // e.preventDefault();
    this.setState({ modal: false, reportDescription: "", value: "", openMenu: !this.state.openMenu });
  };

  submitFlag = () => {
    if (!this.state.reportDescription.trim()) {
      return toasterMessage("error", "Please Add Description");
    }

    let reportReasonId = this.state.reasons.filter((item) => {
      return item.flagReason === this.state.value;
    });

    if (!reportReasonId[0]._id) {
      return toasterMessage("error", "Please Select Reason");
    }

    this.setState({ loader: true });
    let postBody = {
      ratingId: this.state.commentId,
      reasonComment: this.state.reportDescription,
      reportReasonId: reportReasonId[0]._id,
    };
    console.log(postBody, reportReasonId, "reportReasonId");
    axios
      .post(`${API_URL}/flagRating/postFlag`, postBody, {
        headers: jsonApiHeader(access_token, "application/json"),
      })
      .then((res) => {
        if (res.data.status === 200) {
          toasterMessage("success", "Report posted");
          this.setState(
            {
              loader: false,
            },
            () => {
              this.getReviews();
              this.handleModalClose();
            }
          );
        }
      })
      .catch((error) => {
        this.setState({ loader: false });
        this.handleModalClose();

        toasterMessage("error", error.response.data.error);
        if (error.response.status === 300) {
          toasterMessage("error", error.response.data.error);
        }
      })
      .catch(() => {
        this.setState({ loader: false });
        toasterMessage("error", "Something bad happened");
      });
  };
  onPageChange = (page) => {
    let skip = (page - 1) * 10;
    this.setState(
      {
        currentPage: page,
        skipData: skip,
      },
      () => {
        this.getR();
      }
    );
  };
  goBack = () => {
    this.props.history.goBack();
  };

  reverseArray = () => {
    // reviewData.getRatingShared

    this.setState({
      reviewsAll: this.state.reviewsAll.reverse(),
      iconActive: !this.state.iconActive,
    });
  };

  render() {
    console.log(this.props, this.props.loginDetails._id, "here are props");
    const mimeType = "image/png";
    const { classes } = this.props;
    // const openMenu = this.state.anchorEl === null ? false : true;
    // const id = this.state.openMenu ? "simple-popper" : null;
    return (
      <div className={classes.root}>
        <Nav
          handleDrawerOpen={this.handleDrawerOpen}
          handleDrawerClose={this.handleDrawerClose}
          open={this.state.open}
          title={this.state.title}
          active={1}
        />
        <main
          className={clsx(classes.content, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Box mb={3}>
            <Typography
              variant="body2"
              pb={0}
              color="textSecondary"
              className="ListHeading "
              component="p"
            >
              <Icon onClick={(e) => this.goBack()}>
              <img
                className={clsx(classes.imageIcon, "arrow-position")}
                src={leftArrow}
                alt="Left Arrow Icon"
              />

              </Icon>
              Rating and Reviews
            </Typography>
          </Box>

          {this.state.loader ? (
            <Loader />
          ) : (
            <>
              <Grid container style={{ marginBottom: "31px" }}>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2" component="p">
                    <span
                      className="mr-r-16 filter-text head-filter"
                      style={{ color: "rgba(51, 51, 51, 1)" }}
                    >
                      {this.state.reviewData.documentName}
                    </span>
                    {/* <span className="heading2">
                        Sep 01 2020 - Sep 30 2020
                               </span> */}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography
                    variant="body2"
                    style={{ color: "rgba(51, 51, 51, 1)" }}
                    component="p"
                  >
                    <span
                      className="label-heading mr-r-16"
                      style={{ color: "rgba(51, 51, 51, 1)" }}
                    >
                      Current version:{" "}
                      {this.state.reviewData &&
                      this.state.reviewData.currentAprrovDocument
                        ? 1 +
                          this.state.reviewData.currentAprrovDocument
                            .versionName /
                            10
                        : null}
                    </span>
                    <span
                      className="version-font"
                      style={{ color: "rgba(51, 51, 51, 1)" }}
                    >
                      {/* V{this.state.reviewData.currentApprovDocument.versionName} */}
                    </span>
                    {/* <span className="heading2">
                        Sep 01 2020 - Sep 30 2020
                               </span> */}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    <span
                      onClick={(e) => this.reverseArray()}
                      className="heading1 mr-r-16"
                    >
                      Sort By{" "}
                      <ArrowDownwardIcon
                        style={{ fontSize: "16px" }}
                        className={`${
                          this.state.iconActive ? "rotateUp" : "rotatedown"
                        }`}
                      />{" "}
                    </span>
                    {/* <span className="heading2">
                        Sep 01 2020 - Sep 30 2020
                               </span> */}
                  </Typography>
                </Grid>
              </Grid>
              {this.state.reviewsAll &&
              this.state.reviewsAll &&
              this.state.reviewsAll.length ? (
                this.state.reviewsAll.map((review) => {
                  return (
                    <Card
                      className="card-rate-and-reviews card-reviews"
                      style={{ marginTop: "20px" }}
                    >
                      <CardContent className="card-rate-and-reviews card-reviews">
                        <Grid container>
                          <Grid item xs={3} style={{ display: "contents" }}>
                            <div className="img-side-rate display-center">
                              {" "}
                              <img
                                className={classes.media}
                                style={{
                                  margin: "auto",
                                  height: "70px",
                                  width: "65px",
                                }}
                                src={
                                  review.documentVersionId.fileType === ".pdf"
                                    ? pdf
                                    : review.documentVersionId.fileType ===
                                      "application/pdf"
                                    ? pdf
                                    : review.documentVersionId.fileType ===
                                      ".csv"
                                    ? csv
                                    : review.documentVersionId.fileType ===
                                      "text/csv"
                                    ? csv
                                    : review.documentVersionId.fileType ===
                                      ".jpeg"
                                    ? jpeg
                                    : review.documentVersionId.fileType ===
                                      ".xls"
                                    ? excel
                                    : review.documentVersionId.fileType ===
                                      ".jpeg"
                                    ? jpeg
                                    : review.documentVersionId.fileType ===
                                      ".docx"
                                    ? doc
                                    : review.documentVersionId.fileType ===
                                      ".ppt"
                                    ? ppt
                                    : review.documentVersionId.fileType ===
                                      ".pptx"
                                    ? ppt
                                    : null
                                }
                                alt="Document Icon"
                              />{" "}
                            </div>
                            <div
                              style={{ display: "grid", margin: "0px 20px" }}
                            >
                              <IconButton
                                style={{
                                  width: "32px",
                                  height: "48px",
                                  display: "block",
                                  marginBottom: "17px",
                                }}
                                aria-haspopup="true"
                                disableTouchRipple
                              >
                                <Avatar
                                  alt={review.givenBy.userName}
                                  borderColor="primary.main"
                                  className="shared-profile"
                                  src={`data:${mimeType};base64,${review.givenBy.userImage}`}
                                />
                              </IconButton>
                              <span className="listName">
                                {review.givenBy.userName}
                              </span>
                              <Rating
                                name="size-small"
                                className="pd-0"
                                value={review.rating}
                                disabled={true}
                                size="small"
                                style={{ marginTop: "6px", right: "5px" }}
                              />
                            </div>
                          </Grid>
                          <divider
                            orientation="vertical"
                            style={{
                              border: "1px solid rgba(0, 90, 152, 0.1)",
                            }}
                          />
                          <Grid
                            item
                            xs={4}
                            className="display-center"
                            style={{
                              padding: "0px 40px",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              component="h5"
                              variant="h5"
                              className="label-font input-label"
                              style={{
                                lineHeight: "20px",
                                wordBreak: "break-all",
                              }}
                            >
                              {review.comment}
                            </Typography>
                          </Grid>
                          <divider
                            orientation="vertical"
                            style={{
                              border: "1px solid rgba(0, 90, 152, 0.1)",
                            }}
                          />
                          <Grid
                            item
                            xs={2}
                            className="align-item-center "
                            style={{ padding: "0px 40px" }}
                          >
                            <Typography component="h5" variant="h5">
                              <span
                                className="label-font input-label"
                                style={{ fontWeight: "bold" }}
                              >
                                V{1 + review.documentVersionId.versionName / 10}
                              </span>
                              <br />
                              <span className="label-font input-label">
                                {moment(
                                  review.documentVersionId.created_at
                                ).format("DD/MM/YYYY")}
                              </span>
                            </Typography>
                          </Grid>
                          <divider
                            orientation="vertical"
                            style={{
                              border: "1px solid rgba(0, 90, 152, 0.1)",
                            }}
                          ></divider>
                          <Grid
                            item
                            xs={2}
                            className="align-item-center "
                            style={{ padding: "0px 40px" }}
                          >
                            <Typography component="h5" variant="h5">
                              <span
                                className="history-text"
                                style={{
                                  color:
                                    review.documentVersionId.status ===
                                    "REVISION_REQUESTED"
                                      ? "#ff8b7b"
                                      : review.documentVersionId.status ===
                                        "PENDING_REVIEW"
                                      ? "#ff5551"
                                      : "#00c213",
                                }}
                              >
                                {review.documentVersionId.status}
                              </span>
                            </Typography>

                            {review.isFlagged === false &&
                            review.givenBy._id !==
                              this.props.loginDetails._id ? (
                              <box className="align-item-end">
                                <IconButton
                                  style={{
                                    position: "absolute",
                                    right: "44px",
                                  }}
                                  color="inherit"
                                  aria-controls="long-menu"
                                  aria-label="open drawer"
                                  onClick={(event) =>
                                    this.handleClick(event, review._id)
                                  }
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </box>
                            ) : null}

                            <Menu
                              id="long-menu"
                              anchorEl={this.state.anchorEl}
                              keepMounted
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              getContentAnchorEl={null}
                              open={this.state.openMenu}
                              onClose={this.handleCloseMenu}
                            >
                              <MenuItem
                                key="Report"
                                onClick={this.handleModalOpen}
                              >
                                Report
                              </MenuItem>
                            </Menu>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  );
                })
              ) : (
                <p>No reviews</p>
              )}

              <Pagination
                onChange={this.onPageChange}
                current={1}
                total={this.state.totalDocCount}
                pageSize={10}
                style={{
                  float: "right",
                  marginTop: "10px",
                  marginRight: "12.5px",
                }}
              />
              <Dialog
                open={this.state.modal}
                onClose={(e) => this.handleModalClose(e)}
                aria-labelledby="form-dialog-title"
                className="dialog-main-div"
              >
                <DialogContent className="dialog-content pd-70">
                  <Grid container className="">
                    <Grid
                      item
                      className="mr-b-40"
                      style={{ display: "contents" }}
                    >
                      <span className="report-modal font-open-sans">
                        {" "}
                        I want to report this review because of-
                      </span>
                    </Grid>
                    <Grid item>
                      <FormControl component="fieldset">
                        <RadioGroup
                          name="radio"
                          value={this.state.value}
                          onChange={this.handleChange}
                          style={{ margin: "35px 0px" }}
                        >
                          {this.state.reasons.map((item) => {
                            return (
                              <FormControlLabel
                                value={item.flagReason}
                                control={<Radio />}
                                label={item.flagReason}
                                className="font-open-sans radio-option"
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item>
                    <TextareaAutosize
                      className={clsx(classes.inputStyle, classes.textAreWidth)}
                      rowsMax={8}
                      aria-label="maximum height"
                      placeholder="Write Description"
                      defaultValue=""
                      onChange={(e) => {
                        if (e.target.value.trim().length <= 300) {
                          this.setState({
                            reportDescription: e.target.value,
                            wordCount: e.target.value.trim().length,
                          });
                        } else {
                          return swal("Max 300 Characters allowed");
                        }
                      }}
                      value={this.state.reportDescription}
                    />
                      <span>{this.state.wordCount}/300</span>
                      {/* <span style={{ color: "red" }}>Max 300 Characters</span> */}
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className="display-center">
                  <Button
                    class="btn-doc heading1"
                    onClick={(e) => this.submitFlag(e)}
                    style={{ marginBottom: "20px", marginRight: "20px" }}
                    disableTouchRipple="false"
                  >
                    Submit
                  </Button>{" "}
                  <Button
                    class="btn-doc heading1"
                    onClick={(e) => this.handleModalClose(e)}
                    style={{ marginBottom: "20px", marginRight: "20px" }}
                    disableTouchRipple="false"
                  >
                    Cancel
                  </Button>
                  {/* /api/flagRating/postFlag */}
                </DialogActions>
              </Dialog>
            </>
          )}
        </main>
      </div>
    );
  }
}
export default connect(mapStateToProps, { loginAction })(
  withStyles(useStyles)(RatingAndReviews)
);

/* const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
]; */
