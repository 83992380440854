import { jsonApiHeader } from '../constants/apiConstants';

import { API_URL } from '../../../../actions/utilAction';
import axios from 'axios';
import * as constant from '../../../../actions/constant';
import { toasterMessage } from "../../../../actions/util";

let access_token = localStorage.access_token;
var CancelToken = axios.CancelToken
var cancel
var cancelMsg

export const getRecentUsers = (payload, cb) => async () => {
  try {
    const response = await axios.post(API_URL + constant.RECENT_USERS , {payload}, {
      headers: jsonApiHeader(access_token, 'application/json')
    })
    if(response.data.data.length < 1){
      cb && cb(false, null);
      return toasterMessage("error", "No User Present");
    }
    cb && cb(true, response.data.data[0])
  } catch (err) {
    cb && cb(false, null)
    toasterMessage("error", err?.response?.data?.error || 'Something bad happened');
  }
}

export const getMessageById = (id, cb) => async () => {
  try {
    cancelMsg && cancelMsg();
    const response = await axios.post(API_URL + constant.USER_MESSAGE, {userId: id}, {
      headers: jsonApiHeader(access_token, 'application/json'),
      cancelToken: new CancelToken((c) => {
        cancelMsg = c
      })
    })
    cb && cb(true, response.data.data)
  } catch (err) {
    cb && cb(false, null)
    if(err && err?.response){
      toasterMessage("error", err?.response?.data?.error || 'Something bad happened');
    }
  }
}

export const sendMessage = (payload, cb) => async () => {
  try {
    const response = await axios.post(API_URL + constant.SEND_MESSAGE, payload, {
      headers: jsonApiHeader(access_token, 'application/json')
    })
    cb && cb(true, response.data.data)
  } catch (err) {
    cb && cb(false, null)
    toasterMessage("error", err?.response?.data?.error || 'Something bad happened');
  }
}

export const getAllUsers = (payload, cb) => async () => {
  try {
    const response = await axios.post(API_URL + constant.GET_ALL_USERS, payload, {
      headers: jsonApiHeader(access_token, 'application/json')
    })
    if(response.data.data.length < 1){
      cb && cb(false, null);
      return toasterMessage("error", "No User Present");
    }
    cb && cb(true, response.data.data[0])
  } catch (err) {
    cb && cb(false, null)
    toasterMessage("error", err?.response?.data?.error || 'Something bad happened');
  }
}

export const searchUser = (payload, cb) => async () => {
  try {
    cancel && cancel();
    const response = await axios.post(API_URL + constant.SEARCH_USERS, payload, {
      headers: jsonApiHeader(access_token, 'application/json'),
      cancelToken: new CancelToken((c) => {
        cancel = c
      })
    })
    if(response.data.data.length < 1){
      return toasterMessage("error", "No User found");
    }
    cb && cb(true, response.data.data[0])
  } catch (err) {
    cb && cb(false, null)
    toasterMessage("error", err?.response?.data?.error || 'Something bad happened');
  }
}